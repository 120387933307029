define("client/pods/components/dispatch/processes-item-waybills-seven-eleven/component", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    tagName: '',
    dispatchProcessCount: Ember.computed('dispatchProcessesByCount._data.processes.[]', 'dispatchProcess._data.code', function () {
      const dispatchProcessesByCount = this.dispatchProcessesByCount;
      if (dispatchProcessesByCount) {
        const code = this.get('dispatchProcess._data.code');
        return R.pathOr(0, ['_data', code, 'count'])(dispatchProcessesByCount);
      }
      return 0;
    }),
    isEditable: Ember.computed('dispatchProcess._data._key', 'dispatchProcessCount', function () {
      if (this.get('dispatchProcess._data._key')) {
        return true;
      }
      return false;
    }),
    badgeClassNames: Ember.computed('waybillProcessReport', function () {
      const waybillProcessReport = this?.waybillProcessReport || [];
      const daysDanger = 2;
      const docCountMoreThanDays = R.pipe(R.map(data => {
        return R.pipe(R.propOr([], 'groupedByDaysOld'), R.filter(data => R.gt(data.daysOld, daysDanger)), R.pluck('total'), R.reject(RA.isNilOrEmpty), R.sum)(data);
      }), R.sum)(waybillProcessReport);
      if (docCountMoreThanDays > 0) {
        return '';
      }
      return 'element-btn__badge-light';
    }),
    waybillProcessReportCount: Ember.computed('waybillProcessReport.@each.docCount', function () {
      const waybillProcessReport = this?.waybillProcessReport || [];
      return R.pipe(R.map(R.propOr(0, 'docCount')), R.sum)(waybillProcessReport);
    }),
    hasWaybillProcessReportCount: Ember.computed('waybillProcessReportCount', function () {
      const waybillProcessReportCount = this.waybillProcessReportCount || 0;
      if (waybillProcessReportCount > 0) {
        return true;
      }
      return false;
    }),
    waybillProcessReport: Ember.computed('report', 'waybillProcessModel', function () {
      const report = this?.report || {};
      const waybillProcessModel = this?.waybillProcessModel || {};
      const type = waybillProcessModel?.value || '';
      if (type && RA.isNotNilOrEmpty(report)) {
        return report[type];
      }
      return false;
    }),
    isDispatchedWaybills: Ember.computed('waybillProcessModel.value', function () {
      const value = this?.waybillProcessModel?.value || '';
      if (value === 'shipped' || value === 'returns') {
        return true;
      }
      return false;
    }),
    displayWaybillProxy({
      waybillLastStatus = '',
      daysOld = ''
    }) {
      this.onDisplayWaybill({
        waybillProcess: this.waybillProcessModel,
        waybillLastStatus,
        daysOld
      });
    }
  }, _applyDecoratedDescriptor(_obj, "displayWaybillProxy", [_dec], Object.getOwnPropertyDescriptor(_obj, "displayWaybillProxy"), _obj), _obj));
});