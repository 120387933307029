define("client/pods/components/layouts/info-row/card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "G1d7ExlV",
    "block": "{\"symbols\":[\"@isBtn\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"info-row-card \",[28,\"if\",[[23,1,[]],\"info-row-card--btn\"],null],\" flex-container-row flex-container--align-items-center flex-container--wrap\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"info-row-card-content\"],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"info-row-card-action\"],[8],[0,\"\\n      \"],[5,\"elements/element-icon\",[],[[\"@icon\"],[\"fas fa-chevron-right\"]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/info-row/card/template.hbs"
    }
  });
});