define("client/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "": "",
    "#": "#",
    "%": "%",
    "...": "...",
    "1 day": "1 day",
    "1 hour": "1 hour",
    "1 point equal to": "1 Point = $",
    "1 week": "1 week",
    "120+ days": "120+ Days",
    "120cm": "120cm",
    "12Months": "1 year",
    "15 minutes": "15 minutes",
    "150cm": "150cm",
    "2 days": "2 days",
    "2 hours": "2 hours",
    "24Months": "2 years",
    "3 days": "3 days",
    "3 hours": "3 hours",
    "30 days": "30 Days",
    "30 minutes": "30 minutes",
    "31 characters max": "31 characters maximum",
    "3rd party": "3rd Party",
    "3rd-party": "Third Party",
    "4 days": "4 days",
    "4 digit code": "4 digit code",
    "4 hours": "4 hours",
    "5 hours": "5 hours",
    "6 hours": "6 hours",
    "60 days": "60 Days",
    "60cm": "60cm",
    "6Months": "6 Months",
    "90 days": "90 Days",
    "90cm": "90cm",
    "ATM_BOT": "Bank of Taiwan ATM",
    "ATM_CATHAY": "Cathay United Bank ATM (Not available)",
    "ATM_CHINATRUST": "Chinatrust Bank ATM",
    "ATM_ESUN": "E.SUN Bank ATM (Not available)",
    "ATM_FIRST": "First Bank ATM",
    "ATM_FUBON": "Taipei Fubon Bank ATM (Not available)",
    "ATM_LAND": "Land Bank of Taiwan ATM",
    "ATM_TACHONG": "Ta Chong Bank ATM (Has been merged by Yuanta Bank Since 2018)",
    "ATM_TAISHIN": "Taishin Bank ATM",
    "Api Key": "Api Key",
    "B2C": "B2C",
    "C2C": "C2C",
    "CODE128": "CODE128",
    "CODE39": "CODE39",
    "EAN13": "EAN13",
    "EAN2": "EAN2",
    "EAN5": "EAN5",
    "EAN8": "EAN8",
    "HCT": "HCT",
    "Mailgun": "Mailgun",
    "Taiwan": "Taiwan",
    "Wrong format of param CustomerPhone or length exceeded.": "Wrong format of param Customer Phone or length exceeded",
    "_blank_": " ",
    "abandoned": "Abandoned",
    "access token": "Access token",
    "account": "Account",
    "account code": "Account Code",
    "account description": "Account Description",
    "account last 5 digits": "Acc Last 5 Digits",
    "account number": "Account Number",
    "account type": "Account Type",
    "accountant (view only)": "Accountant (View Only)",
    "accounts": "Accounts",
    "active": "Active",
    "activity": "Activity",
    "add": "Add",
    "add activity": "Add Activity",
    "add all": "Add All",
    "add attachment": "Add Attachment",
    "add automation": "Add Automation",
    "add cell": "Add Cell",
    "add criteria": "Add Criteria",
    "add custom worksheet": "Add Custom Worksheet",
    "add department": "Add Department",
    "add detail": "Add Detail",
    "add display tags": "Add Image Tags",
    "add form step": "Add Form Step",
    "add help": "Add Help",
    "add link": "Add link",
    "add new adjustment": "Add New Adjustment",
    "add new choices": "Add New Choices",
    "add new customization": "Add New Customization",
    "add new record": "Add New Record",
    "add new rule": "Add New Rule",
    "add new schedule": "Add New Schedule",
    "add new variant type": "Add New Variant Type",
    "add person": "Add Person",
    "add prize": "Add Prize",
    "add product": "Add Product",
    "add product to shop": "Add product",
    "add row": "Add Row",
    "add schedule": "Add Schedule",
    "add spec": "Add Spec",
    "add status": "Add status",
    "add step": "Add Step",
    "add surcharge": "Add Surcharge",
    "add tab": "Add Tab",
    "add to cart": "Add to Cart",
    "add to shop": "Add To Shop",
    "addAll": "Add All",
    "addToCartAndChooseOptions": "Show add to cart and choose options",
    "addToCartForAll": "Show add to cart for all",
    "added": "Added",
    "added member level points will be added to current member level points": "Added member level points will be added to current member level points",
    "additional description": "Additional Description",
    "additional free shipping rule": "Additional free shipping rule",
    "additional information": "Additional Information",
    "additional message": "Additional Message",
    "address": "Address",
    "addresses": "Addresses",
    "addtional checkout information": "Additional description on the checkout page",
    "addtional item checkout information": "Add additional description of the product, e.g. allergen labeling",
    "adjust all commission base costs": "Adjust all commission base costs",
    "adjust currently available preOrder qty": "Adjust Currently Available Pre Order Qty",
    "adjust currently available qty": "Adjust Currently Available Qty",
    "adjust indexes": "Adjust Indexes",
    "adjust inventory": "Adjust Inventory",
    "adjust limit preorder qty": "Adjust Limit Pre Order Qty",
    "adjust limit qty": "Adjust Limit Qty",
    "adjust limited preorder qty": "Adjust Limited Pre Order Qty",
    "adjust limited qty": "Adjust Limited Qty",
    "adjust limited qty by": "Adjust Limited Qty By",
    "adjust member level": "Adjust Member Level",
    "adjust prices": "Adjust Prices",
    "adjust total qty": "Adjust Total Qty",
    "adjusted commission base cost": "Adjusted Commission Base Cost",
    "adjusted commission total amount": "Adjusted Commission Total Amount",
    "adjusted shipping fee": "Adjusted Shipping Fee",
    "adjustment": "Adjustment",
    "adjustment amount": "Return Points Usage Adjustment",
    "adjustment this month": "Adjustment This Month",
    "adjustments": "Adjustments",
    "admin": "Admin",
    "admin->credit card": "Admin > Credit Card",
    "admin->maintenance": "Admin > maintenance",
    "admin->members": "Admin > Members",
    "admin->tw_eInvoicing": "Admin > E-Invoices",
    "advanced": "Advanced",
    "advanced search": "Advanced Search",
    "advanced-settings": "Advanced Settings",
    "advancedTemplate": "Advanced Template",
    "advertisement": "Advertisement",
    "advertiser id": "Advertiser_ID",
    "after checkout": "After Checkout",
    "after noon": "After Noon",
    "afterNoon": "After Noon",
    "age categories": "Age Categories",
    "ages": "Ages",
    "alcoholic beverage / oil / vinegar / sauce": "Alcoholic Beverage / Oil / Vinegar / Sauce",
    "align_center": "Center",
    "align_left": "Left",
    "align_right": "Right",
    "all": "All",
    "all activity": "all activity",
    "all banks": "All Banks",
    "all qty": "All Qty",
    "all undispatched": "All Undispatched",
    "allTags": "Show Group of Tags Together (Any single or combination of tag matches)",
    "allow access date end": "Allow Access Date End",
    "allow access date start": "Allow Access Date Start",
    "allow all campaigns": "allow all campaigns",
    "allow all campaigns per website": "Allow all campaigns per website",
    "allow credit card installments": "allow credit card installments",
    "allow custom pick up location": "Allow Custom Pick Up Location",
    "allow customers to see enter promo code on checkout": "Allow customers to see \"enter promo\" code on checkout",
    "allow discount for on sale items": "Allow Discount For On Sale Items",
    "allow e-invoices to be issued": "Allow e-invoices to be issued",
    "allow linked campaigns": "Allow Linked Campaigns",
    "allow on sale items": "Allow On Sale Items",
    "allow order messages": "Allow Order Messages",
    "allow pick up": "Allow Pick Up",
    "allow pos terminals": "Allow POS Terminals",
    "allow preOrder": "Allow Pre Order",
    "allow print all waybills": "Allow Print All Waybills",
    "allow printing from": "Allow printing from",
    "allow public template editor": "Allow public template editor",
    "allow recurring schedules": "Allow Recurring Schedules",
    "allow rss feeds on campaigns": "Allow RSS feeds on campaigns",
    "allow seo": "Allow SEO",
    "allow shipping": "Allow Shipping",
    "allow sync product properties": "Allow Sync Product Properties",
    "allow tax numbers per shop": "Allow tax numbers per shop",
    "allow to earn reward points": "Allow to Earn Reward Points",
    "allow to re-index all products": "Allow to Re-Index All Products",
    "allow to spend reward points": "Allow to Spend Reward Points",
    "allow today pickup": "Allow Today To Pickup",
    "allow track reward for non member": "Allow Track Reward For Non Member",
    "allow transporter arrive time period": "Allow Transporter Arrive Time Period (Only applicable for BlackCat Transporter)",
    "allowApiAccess": "Allow api access",
    "allowShipSeparately": "Allow Free Items To Ship Separately",
    "allowed emails": "Allowed Emails",
    "allowed ip addresses": "Allowed ip addresses",
    "allowed product tags": "Allowed Product Tags",
    "allowed shipping zones": "Allowed Shipping Zones",
    "allowed shop": "Allowed Shop",
    "allowed telephones": "Allowed Telephones",
    "already imported": "Already imported",
    "already sent": "Already Sent",
    "always charge shipping fee": "Always Charge Shipping Fee",
    "always display wizard": "Always Display Wizard",
    "always hide": "Always hide",
    "always hide menu": "Always Hide Menu",
    "amount": "Amount",
    "amount due": "Amount Due",
    "answer": "Answer",
    "anti-fraud info": "Anti-fraud Informations",
    "anti-fraud text": "ref：We will not ask you to operate ATM. ATMs serve only for remittence and money transfer, and cannot deal with cancelation of installment payment or order error. Call 165 to access more anti-fraud informations.",
    "anyNextAvailableNumbers": "Any next available numbers",
    "anytime": "Anytime",
    "api access": "Api Access",
    "api_settings wizard description": "Settings Wizard Description",
    "apply adjustment amount": "Adjust refund amount when there are insufficient points to deduct",
    "apply schedules to all images": "Apply Schedules to All Images",
    "apply schedules to all pages": "Apply Schedule To All Pages",
    "apply to all orders": "Apply to all orders",
    "apply to all product": "Apply To All Product",
    "apply to first order only": "Apply to first order only",
    "apply to last order only": "Apply to last order only",
    "approve": "Approve",
    "archive": "Archive",
    "archived": "Archived",
    "are you sure you want to archive": "Are you sure you want to archive?",
    "are you sure you want to cancel invoice number": "Are you sure you want to cancel Invoice Number",
    "are you sure you want to cancel this order?": "Are you sure you want to cancel this Order?",
    "are you sure you want to cancel?": "Are you sure you want to Cancel?",
    "are you sure you want to clear page": "Are you sure you want to clear page?",
    "are you sure you want to close?": "Are you sure you want to Close?",
    "are you sure you want to comment and notify customer": "Are you sure you want to comment and notify customer?",
    "are you sure you want to copy from master to children": "Are you sure you want to copy from master to children?",
    "are you sure you want to delete": "Are you sure you want to Delete?",
    "are you sure you want to delete comment": "Are you sure you want to delete comment",
    "are you sure you want to delete image?": "Are you sure you want to Delete Image?",
    "are you sure you want to delete this campaign": "Are you sure you want to delete this campaign? You would not be able to revert this change.",
    "are you sure you want to delete this step? all linked steps will be deleted too": "Are you sure you want to delete this step? All linked steps will be deleted too.",
    "are you sure you want to delete variant option": "Are you sure you want to delete variant option? Related child products will be deleted.",
    "are you sure you want to dispatch all?": "Are you sure you want to dispatch all?",
    "are you sure you want to hold all?": "Are you sure you want to hold all?",
    "are you sure you want to logout": "Are you sure you want to Logout?",
    "are you sure you want to mark as completed?": "Are you sure you want to mark as completed?",
    "are you sure you want to merge contacts": "Are you sure you want to Merge Contacts?",
    "are you sure you want to re-index all products? this may take some time.": "Are you sure you want to re-index all products? This may take some time.",
    "are you sure you want to re-sync product": "Are you sure you want to Re-Sync product?",
    "are you sure you want to remove": "Are you sure you want to Remove?",
    "are you sure you want to remove entire row?": "are you sure you want to remove entire row?",
    "are you sure you want to remove item": "Are you sure you want to Remove: {item}?",
    "are you sure you want to remove this cell?": "are you sure you want to remove this cell?",
    "are you sure you want to remove this custom sheet?": "are you sure you want to remove this custom sheet?",
    "are you sure you want to reprint": "Are you sure you want to reprint?",
    "are you sure you want to reset all rows?": "Are you sure you want to reset all rows?",
    "are you sure you want to revert to draft": "Are you sure you want to Revert to Draft?",
    "are you sure you want to save as template": "Are you sure you want to Save as Template?",
    "are you sure you want to unarchive": "Are you sure you want to unarchive?",
    "are you sure you want to undispatch all?": "Are you sure you want to undispatch all?",
    "are you sure you want to undo cancel?": "Are you sure you want to Undo Cancel?",
    "are you sure you want to undo void for document": "Are you sure you want to undo void for document",
    "are you sure you want to undo?": "Are you sure you want to undo?",
    "are you sure you want to unhold all?": "Are you sure you want to unhold all?",
    "are you sure you want to void document": "Are you sure you want to void document",
    "area": "Area",
    "array": {
      "min": "minimum requirement not met. {limit}"
    },
    "arrival date": "Arrival Date",
    "arrow settings": "Arrow settings",
    "assets": "Assets",
    "assign to": "Assign To",
    "associates": "Associates",
    "at least 6 characters": "At least 6 characters",
    "atm_approved": "Approved",
    "atm_attentionRequired": "Attention Required",
    "atm_bsp": "永豐銀行 Bank SinoPac",
    "atm_eSun": "玉山銀行 E. Sun",
    "atm_expired": "Expired",
    "atm_pending": "Pending",
    "atm_pending_short": "Pending",
    "atm_ubot": "聯邦銀行 Union Bank Of Taiwan",
    "attentionRequired": "Attention Required",
    "authorize refund": "Authorize Refund",
    "auto": "Auto",
    "auto add new product to website": "Auto Add New Product To Website",
    "auto cancel expired atm orders": "Auto cancel expired atm orders",
    "auto expand": "Auto Expand",
    "auto fill column": "Auto Fill Column",
    "auto fill commissions": "Auto fill commissions",
    "auto fill shipping fee adjustment": "Auto fill shipping fee adjustment",
    "auto fill variant children's code": "Auto fill variant children's code",
    "auto fill variant children's name": "Auto fill variant children's name",
    "auto generate detail row": "Auto Generate Detail Row",
    "auto generate sales history (optional)": "Auto Generate Sales History (optional)",
    "auto hide": "Auto Hide",
    "auto index": "Auto Index",
    "auto link member": "Auto Link Member",
    "auto mark as printed": "Automatically Mark as Printed",
    "auto print invoices": "Auto Print Invoices",
    "auto redirect to url when campaign has ended": "Auto redirect to url when campaign has ended",
    "auto send dispatched email at 10pm every day": "Auto send dispatched email at 10pm every day",
    "automatically end campaign": "Automatically End Campaign",
    "automatically send payment reminders": "Automatically Send Payment Reminders",
    "automatically update all following invoices": "Automatically update all following Invoices",
    "automation": "Automation",
    "automation index": "Automation Index",
    "automation key": "Automation Key",
    "automation priority": "Automation Priority",
    "automation_action_mayAdd": "Show Additional Items",
    "automation_action_mustAdd": "Automatically Add Items to Cart",
    "automation_qty": "Value",
    "automation_selection_type": "How to add",
    "automation_then": "Then",
    "automation_title": "Message",
    "automation_when": "When",
    "automations": "Automations",
    "automations shipping behavior": "Automation Shipping Behavior",
    "automations-settings": "Automations Settings",
    "available": "Available",
    "available preOrder qty": "Available Pre Order Qty",
    "available promo code": "Available promo code",
    "available qty": "Available Qty",
    "available to sell qty": "Available To Sell Qty",
    "available_sales_qty": "Available Sales Qty",
    "average": "Average",
    "average item price": "Av. Item Price",
    "average order value": "Av. Order Value",
    "average price": "Average Price",
    "awaiting payment": "Awaiting Payment",
    "awaitingPayment": "Awaiting Payment",
    "awaitingRecurringPayment": "Awaiting Recurring Payment",
    "awaitingReplyFromCustomer": "Awaiting reply from customer",
    "awaitingReplyFromMerchant": "Awaiting reply from merchant",
    "b2b": "B2B,",
    "b2c": "B2C",
    "back to previous": "Back",
    "backInDown": "Back In Down",
    "backInUp": "Back In Up",
    "back_page": "Back",
    "background color": "Background Color",
    "background color options": "Background Color Options",
    "background image": "Background Image",
    "balance sheet": "Balance Sheet",
    "bank": "Bank",
    "bank accounts": "Bank Accounts",
    "bank of china": "Bank of China",
    "bank transfer": "Bank Transfer",
    "bankTransfer": "Bank Transfer",
    "banking details": "Banking Details",
    "banner": "Banner",
    "banner bottom": "Banner Bottom",
    "banner top": "Banner Top",
    "barcode": "Barcode",
    "barcode type": "Barcode Type",
    "barcode types": "Barcode Types",
    "barcode_ean13": "Barcode (EAN13)",
    "barcode_generator_error": "Cannot generate barcode.",
    "barcodes": "Barcodes",
    "base cost": "Base Cost",
    "basic": "Basic",
    "basic information": "Basic Information",
    "basic settings": "Basic Settings",
    "basic-settings": "Basic Settings",
    "batch number": "Batch Number",
    "before checkout": "Before Checkout",
    "before noon": "Before Noon",
    "beforeNoon": "Before Noon",
    "beginning of month": "Beginning Of Month",
    "beta": "Beta",
    "beta settings": "Beta Settings",
    "beta-settings": "Beta Settings",
    "better images": "better images",
    "bigger": "Bigger",
    "bigordr": "BigOrdr",
    "bigordr customer": "BigOrdr Customer",
    "bigordr-background-fixed": "Fixed Background",
    "bigordr-background-fixed-background": "bigordr-background-fixed-background",
    "bigordr-background-fixed-cover": "bigordr-background-fixed-cover",
    "bigordr-banner": "Banner",
    "bigordr-cell": "Cell",
    "bigordr-flex-cell": "Flex Cell",
    "bigordr-form": "Form",
    "bigordr-grid": "Grid",
    "bigordr-grid-images": "Grid Images",
    "bigordr-inner-row": "Inner Row",
    "bigordr-picture-block": "Picture Block",
    "bigordr-picture-layer-container-block": "Picture Layer",
    "bigordr-scratch-card": "Scratch Card",
    "bigordr-stories-block": "Stories",
    "bigordr-strip": "Strip",
    "bigordr-strip-image": "Strip Image",
    "bigordr-tabs-with-header-block": "Tabs With Header",
    "billing address": "Billing Address",
    "billingAddress": "Billing Address",
    "birthday": "Birthday",
    "birthday email": "Birthday Email",
    "birthday months": "Birthday Months",
    "birthday reward recipients": "Birthday Reward Recipients",
    "birthday rewards": "Birthday Rewards",
    "birthday years": "Birthday Years",
    "blog": "Blog",
    "blogs": "Blogs",
    "blogs images ratio": "Blogs Images Ratio",
    "blogs preview settings": "Blogs Preview Settings",
    "blogs settings": "Blogs Settings",
    "blogs show breadcrumb": "Show Breadcrumb Navigation",
    "blogs style": "Blogs Style",
    "blogs tags": "Blogs Tags",
    "blogs_preview_author": "Show Blogs Author in preview",
    "blogs_preview_date": "Show Blogs Date in preview",
    "blogs_preview_description": "Show Blogs Description in preview",
    "blogs_preview_tags": "Show Blogs Tags in preview",
    "blogs_preview_title": "Show Blogs Title in preview",
    "blogs_style1": "Blogs Style 1 (Grid)",
    "blogs_style2": "Blogs Style 2 (Horizontal)",
    "book qty": "Book Qty",
    "bot": "Bot",
    "bot builder": "Bot Builder",
    "bottom": "Bottom",
    "bounce": "Bounce",
    "brand": "Brand",
    "brands": "Brands",
    "browse": "Browse",
    "browse mode": "Browse",
    "bsp": "永豐銀行 Bank SinoPac",
    "bsp_hash": "Hash",
    "bsp_merchant_id": "Merchant ID",
    "bsp_payments": "永豐銀行 Bank SinoPac",
    "bsp_virtualAccount": "永豐銀行 VirtualAccount Enable",
    "bulk add": "Bulk Add",
    "bulk discount": "Bulk Discount",
    "bulk discount conflicts": "Bulk Discount Conflicts",
    "bulk discount schedules": "Bulk Discount Schedules",
    "bulk discounts": "Bulk Discounts",
    "bulk edit": "Bulk Edit",
    "bulk insert products into document": "Bulk insert products",
    "bulk split": "Bulk Split",
    "bulk update document status and print": "Bulk Update Document Status And Print",
    "button": "Button",
    "button click": "Button Click",
    "button image options": "Button Image Options",
    "button image radius": "Button Image Radius",
    "button image type": "Button Image Type",
    "button label": "Button Label",
    "button1": "button1",
    "button2": "button2",
    "button3": "button3",
    "buttons": "Buttons",
    "by documents": "By Documents",
    "by invoices": "By Invoices",
    "by orders": "By Orders",
    "by products": "By Products",
    "byAllOrder": "Shipping Data - All Order",
    "byAllOrdersWithOneColumnRow": "One Row Per Detail",
    "byAllOrdersWithOneColumnRowWithSummary": "One Row Per Detail + Summary",
    "byDetails": "Shipping Data with Custom Detail",
    "byDetailsWithGrandTotals": "Shipping Data with Custom Detail + Grand Totals",
    "byDispatchProcesses": "By Dispatch Processes",
    "byDocumentsStatuses": "By Documents Statuses",
    "c2c": "C2C",
    "calculate dispatch days starting from today": "Calculate Dispatch Days Starting From Today",
    "calendar settings": "Calendar Settings",
    "calendar settings type": "Calendar Settings Type",
    "callback url": "Callback Url",
    "campaign": "Campaign",
    "campaign detail product tags": "Campaign Detail Product Tags",
    "campaign ended settings": "Campaign Ended Settings",
    "campaign expired settings": "Campaign Expired Settings",
    "campaign images": "Campaign Images",
    "campaign key": "Campaign Key",
    "campaign notification": "Campaign Notification",
    "campaign period": "Campaign Period",
    "campaign products": "Campaign Products",
    "campaign qty": "Campaign Qty",
    "campaign reports": "Campaign Reports",
    "campaign review is not set!": "Campaign review is not set!",
    "campaign settings": "Campaign Settings",
    "campaign status": "Campaign Status",
    "campaign-settings": "Campaign Settings",
    "campaign_description": "Campaign Description",
    "campaign_expired_template": "Expired Template",
    "campaigns": "Campaigns",
    "campaigns finished syncing": "Campaigns finished syncing",
    "campaigns review": "Campaigns Review",
    "campaigns review settings": "Campaigns Review Settings",
    "campaigns_are_syncing": "Campaigns are syncing",
    "can preOrder": "Can pre-order",
    "can split by transporter": "Can Split By Transporter",
    "cancel": "Cancel",
    "cancel invoice": "Cancel Invoice",
    "cancel invoice reason": "Cancel Invoice Reason",
    "cancel order": "Cancel Order",
    "cancel_save": "Cancel",
    "cancelled": "Cancelled",
    "cancelled invoices": "Cancelled Invoices",
    "cannot have empty commission": "Cannot have empty commission!",
    "cannot import due to incorrect data": "Cannot import due to incorrect data",
    "cannot issue e-invoices": "Cannot Issue E-invoices",
    "cannot merge. contacts are linked to different members": "Cannot merge, contacts are linked to different members.",
    "cannot refund credit card": "Cannot Refund Credit Card",
    "cannot send": "Cannot Send",
    "cannot upload file, files must be under 10mb": "Cannot upload file. Files must be under 10 MB.",
    "cannot void because document has used reward points": "Cannot void because document has used reward points, please use return",
    "cart": "cart",
    "cart button style": "Cart Button Style",
    "cart items": "cart items",
    "cash": "Cash",
    "cash before delivery": "Cash Before Del.",
    "cash book": "Cash Book",
    "cash on delivery": "Cash On Delivery",
    "cash payment fee": "Cash Payment Fee",
    "cashBeforeDelivery": "Cash Before Del.",
    "category": "Category",
    "cathay united bank": "Cathay United Bank",
    "cc_approved": "Approved",
    "cc_awaitingPayment": "Awaiting Credit Card Payment",
    "cc_awaiting_3ds_verification": "Awaiting 3DS Verification",
    "cc_declined": "Declined",
    "cc_paidOut": "Paid Out",
    "cc_partial_card_number": "Partial Card Number",
    "cc_ref": "Payment Ref",
    "cc_refund": "Credit Card Refund",
    "cc_refundRequested": "Refund Requested",
    "cc_refundRequested date": "Refund Requested Date",
    "cc_refund_total": "Refund Total",
    "cc_refunded": "Refunded",
    "cc_refunded date": "Refunded Date",
    "cc_total": "Total",
    "cc_unsuccessful": "unsuccessful",
    "center": "Center",
    "chang hwa bank": "Chang Hwa Bank",
    "change convenience store": "change convenience store",
    "change language? will reload page": "Change Language? Will reload Page",
    "change logs": "Change Logs",
    "change password": "Change Password",
    "change pickup method": "Change Pickup Method (DANGER)",
    "change return convenience store": "Change",
    "changes summary": "Changes Summary",
    "changes_deployed": "Changes has already been deployed",
    "channel": "Channel",
    "channel id": "Channel Id",
    "channel secret": "Channel Secret",
    "channels": "Channels",
    "charge shipping fee": "Charge Shipping Fee",
    "charge to different account": "Charge to Different Account",
    "charge to different customer account": "Charge to Different Customer Account",
    "charge to different supplier account": "Charge to Different Supplier Account",
    "chat messages": "Chat Messages",
    "check history": "Check History",
    "check order status": "check order status",
    "checkout": "Checkout",
    "checkout convenience store description": "Customize Convenience Store Description",
    "checkout description": "Product Description When Checkout",
    "checkout details description": "Customize Description on Checkout Details Step",
    "checkout logs": "Checkout Logs",
    "checkout page on select dispatch or arrival date": "Checkout Page On Select Expected Dispatch Date Or Arrival Date",
    "checkout payment description": "Customize Description on Checkout Payment Step",
    "checkout settings": "Checkout Settings",
    "checkout summary description": "Customize Description on Checkout Summary Step",
    "checkout/details": "Checkout Shipping (Step 2/4)",
    "checkout/payment": "Checkout Payment (Step 3/4)",
    "checkout/success": "Checkout Sucessful (Step 4/4)",
    "checkout/summary": "Checkout Summary (Step 1/4)",
    "checkoutId logs": "CheckoutId Logs",
    "children campaign": "Children Campaign",
    "choice": "Choice",
    "choice name": "Choice Name",
    "choice price": "Choice Price",
    "choiceIsEmpty": "Empty choices",
    "choices": "Choices",
    "choose between": "Choose Between",
    "choose form": "Choose Form",
    "choose how to adjust qty": "Choose How To Adjust Qty",
    "choose link": "Choose Link",
    "choose qty": "Choose Qty",
    "choose template": "Choose a Template",
    "chooseBetween": "Choose Between",
    "choose_all": "Choose All",
    "choose_between": "Choose Between",
    "citizenDigitalCertificate": "Citizen Cert",
    "city": "city",
    "ckeditor-language": "en",
    "clear": "Clear",
    "clear all": "Clear All",
    "clear column": "Clear Column",
    "clear page": "Clear page",
    "click id": "Click_ID",
    "close": "Close",
    "close preview": "Close Preview",
    "closing balance": "Closing Balance",
    "clothing and accessories": "Clothing And Accessories",
    "cm": "cm",
    "code": "Code",
    "collapse under product name": "Collapse Under Product Name",
    "colon not allowed": "Colon (:) not allowed",
    "color template": "Color Template",
    "color theme": "Color Theme",
    "colorOptions_solid": "Solid",
    "colorOptions_transparent": "Transparent",
    "combine labels": "Combine Labels",
    "combinedTagsOnly": "Show Group of Tags Together (Must match exact tags combinations)",
    "comment": "Comment",
    "comments": "Comments",
    "comments settings": "Comment Settings",
    "commission": "Commission",
    "commission %": "Commission %",
    "commission % per item": "Commission % Per Item",
    "commission amount": "Commission Amount",
    "commission amount per item": "Commission Amount Per Item",
    "commission base cost": "Commission Base Cost",
    "commission base cost per item": "Commission Base Cost per Item",
    "commission base costs": "Commission Base Costs",
    "commission calculation": "Commission Calculation",
    "commission due date": "Commission Due Date",
    "commission due from last month": "Commission Due From Last Month",
    "commission due this month": "Commission Due This Month",
    "commission payable to": "Commission Payable To",
    "commission payout": "Commission Payout",
    "commission per unit": "Commission per unit",
    "commission qty": "Commission Qty",
    "commission ratio": "Commission Ratio (%)",
    "commission refunded this month": "Commissions Refunded This Month",
    "commission total": "Total Commission",
    "commission total amount": "Commission total amount",
    "commission_adjustment_error_msg": "Error! Max discount amount is 10",
    "commission_byAmount": "Commission Amount",
    "commission_byBaseCost": "Commission Base Cost",
    "commission_normalPercent": "Commission (%)",
    "commission_twPercent": "Commission (折)",
    "commissions": "Commissions",
    "commissions adjustment": "Commissions Adjustment",
    "commissions calculated": "Comissions generated",
    "commissions calculation method": "Commissions calculation method",
    "commissions due": "Commissions Due",
    "commissions due returned": "Commissions Due Returned",
    "commissions generated": "Commissions Generated",
    "commissions generated returned": "Commissions Generated Returned",
    "commissions payouts": "Commissions Payouts",
    "commissions refunded": "Commissions Refunded",
    "commissions settings": "Commissions Settings",
    "commissions summary for this month": "Commissions Summary for This Month",
    "commissionsSettings": "Commmissions Settings",
    "company": "Company",
    "company address": "Company Address",
    "company info": "Company Info",
    "company name": "Company Name",
    "company opening hours": "Oompany Hpening hours",
    "company telephone": "Company Telephone",
    "complete": "Complete",
    "complex": "Complex",
    "compound": "Compound",
    "computer, communication and consumer electronics": "Computer, Communication And Consumer Electronics",
    "confirm": "confirm",
    "confirm password": "Confirm Password",
    "confirm_ignore": "confirm ignored",
    "connect domain": "Connect Domain",
    "connect shop": "Connect Shop",
    "connection error": "Connection Error",
    "consignment": "Consignment",
    "consignment delivery": "Consignment Delivery",
    "consignment qty": "Consignment Qty",
    "consignment receipt": "Consignment Receipt",
    "consignments": "Consignments",
    "contact": "Contact",
    "contact email": "Contact email",
    "contact info": "Contact Info",
    "contact is not a member, send reward": "Contact is not a member, send reward?",
    "contact key": "Contact Key",
    "contact name": "Contact name",
    "contact person": "Contact Person",
    "contact phone number": "Contact Phone Number",
    "contact tags report summary option": "Contact Tags Report Summary Option",
    "contact us": "Contact Us",
    "contacts": "Contacts",
    "contacts rewards": "Contacts Rewards",
    "contacts without member level": "Contacts Without Member Level",
    "contacts_groups": "Groups",
    "contains an invalid value": "contains an invalid value",
    "contains duplicate value": "contains duplicate value",
    "contra type": "Contra Type",
    "contract end date": "Contract End Date",
    "contract fee": "Contract Fee",
    "contract start date": "Contract Start Date",
    "contract type": "Contract Type",
    "convenience store": "Convenience Store",
    "convenience store brand": "Convenience Store Brand",
    "convenience store id": "Store ID",
    "convenience store name": "Store Name",
    "conversion rate": "conversion rate",
    "convert set items to only show set child items": "Convert Bundle to only show bundle child items",
    "convert to date": "Convert To Date",
    "convert to index": "Convert To Index",
    "convert to number": "Convert To Number",
    "copy": "Copy",
    "copy & paste": "Copy & Paste",
    "copy existing bulk discounts": "Copy Existing Bulk Discounts",
    "copy from above": "Copy From Above",
    "copy images": "Copy Images",
    "copy link": "Copy Link",
    "copy payment link": "Copy Payment Link",
    "copy preview url": "Copy Preview Url",
    "cost of sales": "Cost Of Sales",
    "could not sync": "Could not sync",
    "countries": "Countries",
    "country": "Country",
    "countryCode": "Country Code",
    "create from": "Create From",
    "create hct contact": "Create HCT Contact",
    "create new adjustment": "Create New Adjustment",
    "create new commissions": "Create New Commissions",
    "create product and add to shop": "Create Product And Add To Shop",
    "create seven-eleven contact": "Create 7-11 Contact",
    "create t-cat contact": "Create TCat Contact",
    "create workflow": "create workflow",
    "created by": "Created By",
    "created date": "Created Date",
    "credentials": "Credentials",
    "credit": "Credit",
    "credit card": "Credit Card",
    "credit card history": "Credit Card History",
    "credit card installments: use defaults from settings": "Credit card installments: use defaults from settings",
    "credit card provider installments: tap pay": "Credit Card Provider (Installments): Tap Pay",
    "credit card provider recurring: tap pay": "Credit Card Provider (Recurring Payments): Tap Pay",
    "credit card provider: tap pay": "Credit Card Provider: Tap Pay",
    "credit card refund authorization request": "Credit card refund authorization request",
    "credit card refunded email": "Credit Card Refunded Email",
    "credit card status": "Credit Card Status",
    "credit card transactions": "Credit Card Transactions",
    "creditCard": "Credit Card",
    "creditors": "Creditors",
    "criteria": "Criteria",
    "criterias": "Criterias",
    "ctbc": "CTBC",
    "cumulative": "Cumulative",
    "cumulative total": "Cumulative Total",
    "currency": "Currency",
    "currency decimal rounding": "Currency Decimal Rounding",
    "current": "Current",
    "current active": "Current Active",
    "current available qty": "Current Available Qty",
    "current bulk discount": "Current Bulk Discount",
    "current document": "Current Document",
    "current inventory book qty": "Current Inventory Book Qty",
    "current month": "Current Month",
    "current on hand qty": "Current On Hand Qty",
    "current points": "Current Points",
    "current sale": "Current Sale",
    "current schedule": "Current Schedule",
    "current year": "Current Year",
    "current_lang": "ENGLISH",
    "currently active schedule": "Currently Active Schedule",
    "custom": "Custom",
    "custom author display": "Custom Author Display",
    "custom date display": "Custom Date Display",
    "custom id": "Custom Id (Facebook/Google Product Catalog)",
    "custom limited qty per person text": "Custom Limited Qty Per Person Text",
    "custom message for additional free shipping": "Custom message for additional free shipping",
    "custom message for quoted rate shipping": "Custom message for quoted rate shipping",
    "custom pick up locations": "Custom Pick Up Locations",
    "custom products display settings": "Custom Products Display Settings",
    "custom width": "Custom width",
    "custom worksheet": "Custom Worksheet",
    "customer": "Customer",
    "customer account": "Customer Account",
    "customer activity": "Customer Activity",
    "customer comments": "Customer Comments",
    "customer expected arrival date": "Customer Expected Arrival (PickUp) Date",
    "customer expected dispatch date": "Customer Expected Dispatch (PickUp) Date",
    "customer payment method": "Customer Payment Method",
    "customer payment terms": "Customer Payment Terms",
    "customer service": "Customer Service",
    "customer statements": "Customer Statements",
    "customer tags": "Customer Tags",
    "customerService": "Customer Service",
    "customerSettings": "Customer Settings",
    "customer_export": "Contact",
    "customization options": "Customization Options",
    "customization prefix": "Prefix (Will be added to product name)",
    "customization type": "Customization Type",
    "customizations": "Customizations",
    "customizations price behavior": "Customizations Price Behavior",
    "customizations settings": "Customizations Settings",
    "customize worksheet": "Customize Worksheet",
    "customized export": "Custom Export",
    "daily": "Daily",
    "daily necessities": "Daily Necessities",
    "daily revenue": "Daily Revenue",
    "danger editing message": "Editing may cause irreversable change in records. Are you sure you want to continue?",
    "dark color": "Dark Color",
    "darkColor": "Dark Color",
    "dashboard": "Dashboard",
    "data": "Data",
    "data exceeds its size limit. cannot save": "data exceeds its size limit. Cannot save.",
    "data feed": "Data feed",
    "data out of date": "Data out of date",
    "data was updated": "Data was Updated",
    "dataType_date": "Date",
    "dataType_email": "Email",
    "dataType_mobile": "Mobile",
    "dataType_number": "Number",
    "dataType_telephone": "Telephone",
    "dataType_text": "Text",
    "date": "Date",
    "date conflict": "Date conflict",
    "date end": "Date End",
    "date error": "Date Error",
    "date is required": "Date is required on checkout",
    "date period for overview": "Date Period for Overview",
    "date range": "Date Range",
    "date settings": "Date Settings",
    "date start": "Date Start",
    "date_ascending": "Date Oldest to Newest",
    "date_descending": "Date Newest to Oldest",
    "date_period": "Period",
    "day": "Day",
    "day of month": "Day Of Month",
    "day of week": "Day",
    "days": "Days",
    "days after checkout": "Days after checkout",
    "daysOld_0": "0",
    "daysOld_1": "1",
    "daysOld_2": "2",
    "daysOld_3": "3",
    "daysOld_4": "4",
    "daysOld_5": "5",
    "daysOld_6": "6",
    "daysOld_7": "7+",
    "debit": "Debit",
    "debtors": "Debtors",
    "decrease": "Decrease",
    "deduct points earned": "Deduct points earned",
    "default": "Default",
    "default campaigns review": "Default Campaigns Review",
    "default email content": "Default Email Content",
    "default facebook page": "Default Facebook Page",
    "default for documents": "Default For Documents",
    "default image": "Default image",
    "default item tax": "Default Item Tax",
    "default name": "Default Name",
    "default payment method": "Default Payment Method",
    "default settings": "Default Settings",
    "default shop price filter": "Default Shop Price Filter",
    "default status for when over sales limited qty": "Default Status For When Over Sales Limited Qty",
    "default tax": "Default Tax",
    "default tax on purchases for products": "Default Tax On Purchases For Products",
    "default tax on purchases for services": "Default Tax On Purchases For Services",
    "default tax on sales for products": "Default Tax On Sales For Products",
    "default tax on sales for services": "Default Tax On Sales For Services",
    "default tax rate": "Default Tax Rate",
    "default transporter settings": "Default Transporter Settings",
    "default value for allow seo for new shops": "Default value for Allow SEO for new shops",
    "default weight unit": "Default Weight Unit",
    "defaultCanceledEmailMessage": "Your order has been cancelled, the following is the information about canceling this order",
    "defaultDispatchedEmailMessage": "Your order has been shipped for you today",
    "defaultOrderedEmailMessage": "Thank you for your support and love, you have completed the order, the following is your order details",
    "defaultRefundedEmailMessage": "Has been refunded, the following products have been refunded",
    "defaultShopRecurringLastOrderEmailMessage": "This is the last order of your current recurring order. If you would like to buy again, please click on the link.",
    "defaults": "Defaults",
    "defaults faqs": "Default FAQs",
    "delete": "Delete",
    "delete campaign": "Delete Campaign",
    "delete_keyword": "DELETE",
    "deleted": "Deleted",
    "delivered": "Delivered",
    "delivered qty": "Delivered Qty",
    "deliveries": "Deliveries",
    "delivery": "Delivery",
    "delivery address": "Delivery Address",
    "delivery date": "Delivery Date",
    "delivery date is required": "Delivery Date is required on checkout",
    "delivery date options": "Delivery Date Options",
    "delivery date settings": "Delivery Date Settings",
    "delivery dates settings": "Delivery Dates Settings",
    "delivery max day description": "If You Choose Method Expected Arrival Date, The Latest Available Date For Customers Is : {descriptionValue}",
    "delivery max days": "Max Selectable Expected Delivery Day(s) (Days After Order Date)",
    "delivery min day description": "If You Choose Method Expected Arrival Date, Need {inputValue} Working Days, The Earliest Available Date For Customers Is : {descriptionValue}",
    "delivery min days": "Min Selectable Expected Delivery Day(s) (Days After Order Date)",
    "delivery note": "Delivery Note",
    "delivery note print format": "Print Delivery Note Print Format",
    "delivery notes": "Delivery Notes",
    "delivery time": "Delivery Time",
    "delivery-settings": "Delivery Settings",
    "deliveryAddress": "Delivery Address",
    "deliveryTime": "Delivery Time",
    "denied product tags": "Denied Product Tags",
    "department": "Department",
    "department name": "Department Name",
    "departments": "Departments",
    "description": "Description",
    "description attachments": "Description Attachments",
    "deselect all": "Deselect All",
    "designated zone": "Designated Zone",
    "desktop": "Desktop",
    "detail": "Detail",
    "detailIsEmpty": "Empty set details",
    "detailLimitTypes_campaign": "campaign qty",
    "detailLimitTypes_inventory": "inventory",
    "detailType_address": "Address",
    "detailType_email": "Email",
    "detailType_note": "Note / Link",
    "detailType_phone": "Phone / Fax / Mobile",
    "detailed statement": "Detailed Statement",
    "details": "Details",
    "did not receive an activation email? resend activation email": "Did not receive an activation email? Resend activation email-",
    "dietary supplement": "Dietary Supplement",
    "differentChildLength": "Child items not found in database",
    "differentChoiceLength": "Child items not found in database",
    "dimensions": "Dimensions",
    "dimensions_height": "H",
    "dimensions_length": "L",
    "dimensions_width": "W",
    "disabled": "Disabled",
    "disallow shopping": "Disallow Shopping",
    "disallowed arrival days": "Set Disallowed Arrival Days",
    "disallowed dispatch days": "Set Disallowed Dispatch Days",
    "disallowed shipping zones": "Disallowed Shipping Zones",
    "disc": "Disc",
    "discInclTotal": "Inclusive Discount",
    "discount": "Discount",
    "discount %": "Discount %",
    "discount amount": "Discount Amount",
    "discount for on sale items": "Disount For On Sale Items",
    "discount rate": "Discount %",
    "discount rate for on sale items": "Disount Percentage For On Sale Items",
    "discount total": "Discount Total",
    "discount when buy items": "discount when buy items",
    "discount when items value": "discount when items value",
    "discounts": "Discounts",
    "dispatch": "Dispatch",
    "dispatch (can create and update)": "Dispatch (Can create and update Sales Invoices)",
    "dispatch (view only)": "Dispatch (View Only)",
    "dispatch all": "Dispatch All",
    "dispatch date": "Dispatch Date",
    "dispatch dates settings": "Dispatch Dates Settings",
    "dispatch list number of records": "Dispatch List Number Of Records",
    "dispatch list sort by": "Dispatch List Sort by",
    "dispatch max day description": "If You Choose Method Expected Dispatch Date, The Latest Available Date For Customers Is : {moreDescriptionValue}",
    "dispatch max days": "Max Selectable Expected Disptach Day(s) (Days After Order Date)",
    "dispatch min day description": "If You Choose Method Expected Dispatch Date, Need {inputValue} Working Days, The Earliest Available Date For Customers Is : {moreDescriptionValue}",
    "dispatch min days": "Min Selectable Expected Disptach Day(s) (Days After Order Date)",
    "dispatch notification": "Dispatch Notification",
    "dispatch process for new orders": "Dispatch process for new orders",
    "dispatch process shortcut": "Dispatch Process Shortcut",
    "dispatch qty": "Dispatch Qty",
    "dispatch status": "Dispatch Status",
    "dispatch today": "Dispatch Today",
    "dispatch-settings": "Dispatch Settings",
    "dispatched": "Dispatched",
    "dispatched by date": "Dispatched By Date",
    "display": "Display",
    "display blogs per row": "Display blogs per row",
    "display blogs tags as filters": "Display blogs tags as filters",
    "display in shop": "Display in Shop",
    "display items per row": "Display items per row",
    "display news per row": "Display news per row",
    "display news tags": "Display news tags",
    "display order": "Display Order",
    "display products in shop": "Display Products In Shop",
    "display style": "Display Style",
    "display tag": "Image Tag",
    "display tags": "Image Tags",
    "display wizard": "Display Wizard",
    "display_faq": "Display",
    "district": "District",
    "districts": "Districts",
    "dividends": "Dividends",
    "do not use inventory qty": "Override: Don't Use Inventory Qty",
    "doc": "Doc",
    "doc-pending": "Pending Orders",
    "docNo": "Doc No.",
    "document": "Document",
    "document delivery dates": "Document Delivery Dates",
    "document number": "Document Number",
    "document settings": "Document Settings",
    "document_row_action_buttons": "Document Row Action Buttons",
    "documentation": "Documentation",
    "documentationId": "documentationId",
    "documents": "Documents",
    "documents purchases": "Document Purchases",
    "documents sales": "Documents Sales",
    "documents summary behavior": "Documents Summary Behavior",
    "documents-public": "Customer Service Messages",
    "does not contain required value": "does not contain required value",
    "domain": "Domain",
    "domain connected": "Domain Connected",
    "domain not connected": "Domain Not Connected",
    "domain_validation_step1": "<ol class='spaced-list'> <li>In a second browser window or tab, sign in to your domain host account. For details, see&nbsp;<a href='/a/answer/48323' rel='noopener'>Identify your domain&nbsp;host</a>.</li> <li>Go to your domain’s DNS records. The page might be called something like <strong>DNS Management</strong>, <strong>Name</strong> <strong>Server Management</strong>, <strong>Control Panel</strong>, or <strong>Advanced Settings</strong>. </li> <li>Select the option to add a new record.</li> </ol>",
    "domain_validation_step1_label": "Step 1: Go to your domain's DNS records",
    "domain_validation_step2": "<ol class='spaced-list'> <li>For the record type, select <strong>TXT</strong>.</li> <li>In the <strong>Name/Host/Alias</strong> field, enter <strong>@</strong> or leave it blank.<br> Your host might require you&nbsp;to enter your domain, which looks like <em>example.com</em>,&nbsp;into this field. Your other DNS records might indicate what you should enter. </li> <li>In the <strong>Time to Live (TTL)</strong> field, enter <strong>86400</strong> or leave the default.</li> <li>In the <strong>Value/Answer/Destination</strong> field, paste the verification record you copied in step 1.</li> <li>Save the record.</li> </ol>",
    "domain_validation_step2_label": "Step 2: Add the verification record to your domain host",
    "domain_validation_step3_label": "Step 3: Come back and verify",
    "done": "Done",
    "dont have an account? try control pod for free": "Don't have an account? Try Control Pod for FREE!",
    "dot settings": "Dot settings",
    "double-row": "Double Row",
    "download": "Download",
    "draft": "Draft",
    "drafts": "Drafts",
    "dropdown": "Dropdown",
    "due": "Due",
    "due date": "Due Date",
    "duplicate": "Duplicate",
    "duplicate addresses": "Duplicate Addresses",
    "duplicate document": "Copy",
    "duplicate product": "Duplicate Product",
    "duplicate value": "Duplicate Value",
    "duplicate waybills": "Duplicate Waybills",
    "duplicated": "Duplicated",
    "duplicated contact data": "Duplicated Contact Data",
    "duration": "Duration",
    "eInvoice": "E-Invoice",
    "eInvoicePrinterTerminal": "E-Invoice Printer Terminal",
    "each": "Each",
    "earliest": "Earliest",
    "earliest delivery date": "The Earliest Available Date On Checkout Page",
    "ecPay": "EC Pay",
    "ecPay_creditCard": "Enable EcPay Credit Card",
    "ecPay_eInvoice": "EC Pay: E-Invoice",
    "ecPay_hash_iv": "Hash IV",
    "ecPay_hash_key": "Hash Key",
    "ecPay_installments": "EC Pay: Installments",
    "ecPay_merchant": "Merchant",
    "ecPay_merchant_id": "Merchant ID",
    "ecPay_payments": "綠界科技 EcPay Payments",
    "ecPay_virtualAccount": "VirtualAccount Enable",
    "edit": "Edit",
    "edit campaign detail": "Edit Campaign Detail",
    "edit convenience store address": "Edit Convenience Store Address (DANGER)",
    "edit functions": "Edit Functions",
    "edit product": "Edit Product",
    "edit sales invoice": "Edit Sales Invoice",
    "edit sales order": "Edit Sales Order",
    "edit status": "Edit status",
    "edit store location address": "Edit Store Location Address (DANGER)",
    "edit template": "Edit Template",
    "einvoice printer terminal": "E-Invoice Printer Terminal",
    "email": "Email",
    "email attachments": "Email Attachments",
    "email campaign": "Email Campaign",
    "email campaigns": "Email Campaigns",
    "email delivery note": "Email Delivery Note",
    "email document": "Email Document",
    "email header": "Email Header",
    "email message": "Email Message",
    "email message for recipient": "Email Message For Recipient",
    "email message for referrer": "Email Message For Referrer",
    "email message to buy more": "Email Message For Last Recurring Order",
    "email not sent": "Email not sent",
    "email payment reminder": "Email Payment Reminder",
    "email placeholder": "Email Placeholder",
    "email sent to": "Email sent to",
    "email settings": "Email Settings",
    "email signature": "Email Signature",
    "email subject": "Email Subject",
    "email_canceled_order_default_subject": "Your order has been canceled",
    "email_dispatched_default_subject": "Dispatch Notification",
    "email_event_delivered": "Delivered",
    "email_event_failed": "Failed",
    "email_event_opened": "Opened",
    "email_payment_reminder_subject": "Payment Reminder",
    "email_placeholder_desc": "Copy and paste the placeholder in your text to where you want to display",
    "email_queued": "Queued",
    "email_refunded_order_default_subject": "Your order has been refunded",
    "email_test_to_address": "Test Email Address",
    "email_test_to_name": "Test Contact Name",
    "emails": "Emails",
    "emails to send": "Emails to send",
    "emails->all activity": "Emails > all activity",
    "emails->email campaign": "Emails > Email Campaign",
    "employee": "Employee",
    "empty": "Empty",
    "enable": "Enable",
    "enable allow print all waybills on any dispatch process": "Enable Allow Print All Waybills On Any Dispatch Process",
    "enable customizations": "Enable Customizations",
    "enable facebook chat": "Enable Facebook Chat",
    "enable facebook chat by default": "Enable Facebook Chat By Default",
    "enable facebook pixel conversion api": "Enable Facebook Pixel Conversion API",
    "enable installments by default": "Enable installments by default",
    "enable line chat": "Enable Line Chat",
    "enable line chat by default": "Enable Line Chat By Default",
    "enable recurring schedules": "Enable Recurring Schedules",
    "enable shop price filter": "Enable Price Filter",
    "enable shop.com service": "Enable Shop.com service",
    "enabled": "Enabled",
    "encountered unknown error": "We encountered an error, it is recommended that you save all your work and refresh your browser",
    "end": "End",
    "end date": "End Date",
    "end of invoice month": "End Of Invoice Month",
    "end of month": "End Of Month",
    "end on": "End On",
    "end time": "End Time",
    "enquiry": "Enquiry",
    "enter duration (sec)": "Enter Duration (sec)",
    "enter fullscreen": "Enter Fullscreen",
    "enter pos mode": "Enter POS Mode",
    "entered amount exceeds the outstanding amount": "Entered amount exceeds the outstanding amount",
    "equals": "Equals",
    "equity": "Equity",
    "error fetching barcode": "Error Fetching Barcode",
    "error message": "Error Message",
    "error occurred": "Error Occurred",
    "error occurred please try again later": "Error occurred. Please try again later.",
    "error syncing. could not sync": "Error syncing. Could not sync",
    "error while generating waybill numbers": "Error while generating waybill numbers",
    "errors": "Errors",
    "estimate delivery date": "Estimate Delivery Date",
    "estimated commissions": "Estimated Commissions",
    "estimated commissions percentage": "Estimated Commissions Percentage",
    "estimated delivery date": "Estimated Delivery Date",
    "estimated earnings": "Estimated earnings",
    "estimated total commissions": "Estimated Total Commissions ({percentage}%)",
    "esun": "E Sun",
    "event": "Event",
    "everytime you print it will generate a new Waybill number. are you sure you want to print?": "Everytime you print it will generate a new Waybill number. Are you sure you want to print?",
    "exact date": "Exact Date",
    "example": "Example",
    "exclude all stores": "Exclude All Stores",
    "exclude shipping from sales history": "Exclude shipping from sales history",
    "exclude specific dates": "Exclude Specific Date(s)(Click on the date to add to excluded list, the selected date(s) will be grey like; if want to remove the date, just click on the date again)",
    "excludeFreeShipping": "Exclude Free Shipping",
    "excluded dates": "Excluded Dates",
    "excluding tax discount": "Excluding Tax Discount",
    "exclusive amount": "Excl. Amount",
    "exclusive base cost": "Exclusive Base Cost",
    "exclusive discount": "Exclusive Discount",
    "exclusive price": "Excl. Price",
    "exclusive selling price": "Excl. Selling Price",
    "exclusive total": "Exclusive Total",
    "exclusive total after discount": "Exclusive Total After Discount",
    "existing": "Existing",
    "exit fullscreen": "Exit Fullscreen",
    "expand": "Expand",
    "expand sub menus": "Expand Sub Menus",
    "expand to show other aliases": "Expand To Show Other Aliases",
    "expected arrival date": "Expected Arrival Date",
    "expected arrival date subtitle": "Checkout Page Expected Arrival Date Subtitle",
    "expected close date": "Expected Close Date",
    "expected dispatch date": "Expected Dispatch Date",
    "expected prepare date": "Expected Prepare Date",
    "expenses": "Expenses",
    "expire after days": "Expire after days",
    "expired": "Expired",
    "expiring reward points notification": "Expiring Reward Points Notification",
    "expiring reward points notification email": "Expiring Reward Points Notification Email",
    "expiry end at": "Expiry end at",
    "expiry start from": "Expiry start from",
    "export": "Export",
    "export HCT data": "Export HCT Data",
    "export all data": "Export All Data",
    "export by": "Export By",
    "export campaign reports": "Export Campaign Reports",
    "export count": "Export Count",
    "export data": "Export Data",
    "export data and update status": "Export Data & Update Status",
    "export dispatched data": "Export Dispatch Data",
    "export file format": "Export File Format",
    "export invoices data": "Export Invoices Data",
    "export items by payment status": "Export items by payment status",
    "export limited qtys": "Export Limited Qtys",
    "export method": "Export Method",
    "export order": "Export Order",
    "export order details using specified order": "Export Order Details Using Specified Order",
    "export products": "Export Products",
    "export type": "Export Type",
    "export_all_transporters": "All Shipping Data",
    "export_eInvoices_all": "All E-Invoices",
    "export_eInvoices_cancelled": "Unsuccessful E-Invoices",
    "export_eInvoices_details": "Export E-Invoices & Details",
    "export_eInvoices_successful": "Successful E-Invoices",
    "export_orders_details": "Order Product Details",
    "export_orders_info": "Orders",
    "export_orders_per_row": "By Item Rows",
    "export_orders_total": "All Orders (1)",
    "export_orders_total_two": "All Orders (2)",
    "export_paid": "Paid",
    "export_paid-available": "Paid (Available)",
    "export_paid-preOrder": "Paid (Pre-Order)",
    "export_paid-total": "Paid (Available + Pre-Order)",
    "export_paid_export_orders_info": "Paid-Orders",
    "export_paid_export_transporter": "Paid-Shipping data",
    "export_pay_on_delivery": "Pay On Delivery",
    "export_pay_on_delivery_export_orders_info": "Pay On Delivery-Orders",
    "export_pay_on_delivery_export_transporter": "Pay On Delivery-Shipping Data",
    "export_preOrderLimitedQty": "Pre Order Qty",
    "export_preOrderLimitedQty_remaining": "Remaining Pre Order Qty",
    "export_salesLimitedQty": "Sales Limited Qty",
    "export_salesLimitedQty_remaining": "Remaining Sales Limited Qty",
    "export_total-available": "Total (Available)",
    "export_total-preOrder": "Total (Pre-Order)",
    "export_total-total": "Total (Available + Pre-Order)",
    "export_transporter": "Shipping Data",
    "export_unpaid-available": "Not Paid (Available)",
    "export_unpaid-preOrder": "Not Paid (Pre-Order)",
    "export_unpaid-total": "Not Paid (Available + Pre-Order)",
    "extensions": "Extensions",
    "external": "External",
    "facebook": "Facebook",
    "facebook chat": "Facebook Chat",
    "facebook login": "Facebook Login",
    "facebook login short intro": "Automatically enable Facebook messenger chat plugin on your website. Once you login, select the page you would like to use for your website's customer service chat.",
    "facebook page": "Facebook Page",
    "facebook page id": "Facebook Page Id",
    "facebook page inbox": "FACEBOOK PAGE INBOX",
    "facebook page name": "Facebook Page Name",
    "facebook page url": "Facebook Page Url",
    "facebook pixel": "Facebook Pixel",
    "facebook plugin": "Facebook Plugin",
    "facebook store": "Facebook Store",
    "fadeIn": "Fade In",
    "fadeInDown": "Fade In Down",
    "family mart only provides room temperature delivery": "* Family Mart only provides room temperature delivery",
    "familyMart": "Family mart",
    "faqs": "FAQs",
    "faqs for product": "FAQs For Product",
    "faqs for shop": "FAQs For Shop",
    "faqs-settings": "FAQs Settings",
    "favicon": "Favicon",
    "favorite": "Favorite",
    "favorites": "Favorites",
    "fax": "Fax",
    "features": "Features",
    "fees": "Fees",
    "female": "Female",
    "fetch waybills": "fetch waybills",
    "fetching data": "Fetching Data",
    "field": "Field",
    "file is too large": "File is too Large",
    "file is too small": "File is too Small",
    "file name": "File Name",
    "files": "Files",
    "files manager": "Files Manager",
    "files_manager_tags": "Tags",
    "fill to the bottom": "Fill to the bottom",
    "fill to the top": "Fill to the top",
    "fill to the top and bottom": "Fill to the top and bottom",
    "filters": "Filters",
    "final": "Final",
    "first bank": "First Bank",
    "first name": "First Name",
    "first waybill number": "First Waybill Number",
    "fiscal": "Fiscal",
    "flash": "Flash",
    "flat fee": "Flat Fee",
    "flat shipping fee": "Flat Shipping Fee",
    "flip": "Flip",
    "flipInX": "Flip In X",
    "flipInY": "Flip In Y",
    "floor": "Floor",
    "flow_allow_custom_choice": "Allow Custom Text Reply",
    "flow_chat_message": "Chat Message",
    "flow_collect_user_information_and_play_a_game": "Collect user information and then play a game",
    "flow_collect_user_information_generate_and_email_promo_code": "Collect user information, generate and email promo code",
    "flow_event": "Event",
    "flow_event_allow_backdating": "Allow Backdating",
    "flow_event_display": "Display",
    "flow_event_greeting": "Greeting",
    "flow_event_has_criterias": "Has criterias",
    "flow_form": "Form",
    "flow_form_steps": "Form Steps",
    "flow_game_unsuccessful_message": "Game Unsuccessful Message",
    "flow_generate_promo_code": "Generate Promo Code",
    "flow_limits": "Limits",
    "flow_message": "Message",
    "flow_payload_value": "Value",
    "flow_popup": "Popup",
    "flow_question": "Question",
    "flow_reply": "Question & Reply",
    "flow_reply_choices": "Multiple choice",
    "flow_scratch_card": "Scratch Card",
    "flow_scratch_card_background": "Scratch Card Background",
    "flow_scratch_card_background_color": "Scratch Card Background Color",
    "flow_scratch_card_background_image": "Scratch Card Background Image (4:3 ratio)",
    "flow_scratch_card_inner_background_image": "Scratch Card Inner Background Image (4:3 ratio)",
    "flow_scratch_card_inner_text_color": "Scratch Card Inner Text Color",
    "flow_scratch_card_text": "Scratch Card Text",
    "flow_scratch_card_text_color": "Scratch Card Text Color",
    "flow_scrolling_message": "Scrolling Message",
    "flow_scrolling_message_link": "Scrolling Message Link",
    "flow_scrolling_message_timer": "Scrolling Message Timer(Unit:sec, please set a number between 1 to 20)",
    "flow_steps": "Steps",
    "flow_wheel_of_fortune": "Wheel of Fortune",
    "flows": "Flows",
    "flows-scratchCardImage": "Scratch Card Image",
    "flows-scratchCardImageLose": "Scratch Card Image (Lose)",
    "flows-scratchCardImageWin": "Scratch Card Image (Win)",
    "font alignment": "Font alignment",
    "font alignment for blogs": "Font Alignment for Blogs",
    "font color": "Font color",
    "font family for blogs": "Font Family for Blogs",
    "font size": "Font Size",
    "font size for product name": "Font Size for product name",
    "font weight for blogs title": "Font Weight for Blogs Title",
    "font_weight_bold": "Bold",
    "font_weight_normal": "Normal",
    "footer": "Footer",
    "for documents": "For Documents",
    "force update": "force update",
    "force user to update to current version": "force user to update to current version",
    "forgot your password": "Forgot your Password?",
    "form title": "Form Title",
    "fourByThree": "4x3",
    "free": "Free",
    "free shipping condition": "Free Shipping Condition",
    "free shipping for orders greater and equal to": "Free Shipping For Orders Greater And Equal To",
    "free_shipping_any": "When match any conditions",
    "free_shipping_none": "None",
    "free_shipping_only": "When match all conditions",
    "frequently asked questions": "Frequently Asked Questions",
    "friday": "Friday",
    "friday opening hours": "Friday Opening Hours",
    "from": "From",
    "from day of order": "From day of Order",
    "from email address": "From Email Address",
    "from email name": "From Email Name",
    "from end of invoice month": "From End Of Invoice Month",
    "from invoice date": "From Invoice Date",
    "from name": "From Name",
    "from now": "From Now",
    "from now on only persons with the latest code will be linked": "From now on only persons with the latest code will be linked",
    "from person": "From Person",
    "frozen": "Frozen",
    "ftp password": "FTP Password",
    "ftp user": "FTP User",
    "fubon": "Fubon",
    "full width": "Full width",
    "full width video": "Full Width Video",
    "full-width-image": "Full Width Image",
    "g": "g",
    "gender": "Gender",
    "general": "General",
    "general food": "General Food",
    "generate": "Generate",
    "generate new waybills": "Generate New Waybills",
    "generate new waybills and print": "Generate new waybills and print",
    "generate password": "Generate Password",
    "generate sales invoice data": "Generate Sales Invoice Data",
    "generate settings": "Generate Settings",
    "generate total qty per row": "Generate total qty per row",
    "generate waybills": "Generate Waybills",
    "gjs-Attachment": "Attachment",
    "gjs-Background": "Background",
    "gjs-Background Color": "Background Color",
    "gjs-Blur": "Blur",
    "gjs-Border": "Border",
    "gjs-Border Color": "Border Color",
    "gjs-Border Radius": "Border Radius",
    "gjs-Border Style": "Border Style",
    "gjs-Border Width": "Border Width",
    "gjs-Bottom": "Bottom",
    "gjs-Box Shadow": "Box Shadow",
    "gjs-Center": "Center",
    "gjs-Color": "Color",
    "gjs-Dimension": "Dimension",
    "gjs-Font Family": "Font Family",
    "gjs-Font Size": "Font Size",
    "gjs-Height": "Height",
    "gjs-Image": "Image",
    "gjs-Images": "Images",
    "gjs-Insert your text here": "Insert your text here",
    "gjs-Inside": "Inside",
    "gjs-Justify": "Justify",
    "gjs-Layer 1": "Layer 1",
    "gjs-Left": "Left",
    "gjs-Line-height": "Line Height",
    "gjs-Margin": "Margin",
    "gjs-Max-width": "Max width",
    "gjs-Min-height": "Min height",
    "gjs-Opacity": "Opacity",
    "gjs-Outside": "Outside",
    "gjs-Padding": "Padding",
    "gjs-Position": "Position",
    "gjs-Repeat": "Repeat",
    "gjs-Repeat-x": "Repeat-x",
    "gjs-Repeat-y": "Repeat-y",
    "gjs-Right": "Right",
    "gjs-Shadow type": "Shadow type",
    "gjs-Size": "Size",
    "gjs-Spread": "Spread",
    "gjs-Style": "Style",
    "gjs-Text align": "Text align",
    "gjs-Top": "Top",
    "gjs-Width": "Width",
    "gjs-X position": "X position",
    "gjs-Y position": "Y position",
    "gjs-arrows-background-hidden": "Arrows Background Hidden",
    "gjs-arrows-color": "Arrows color",
    "gjs-arrows-display": "Arrows Display",
    "gjs-arrows-position": "Arrows Position",
    "gjs-arrows-size": "Arrows size",
    "gjs-auto": "auto",
    "gjs-autoplay": "Autoplay",
    "gjs-autoplay-delay": "Autoplay Delay",
    "gjs-box-shadow-color": "Color",
    "gjs-center bottom": "center bottom",
    "gjs-center center": "center center",
    "gjs-center top": "center top",
    "gjs-contain": "contain",
    "gjs-cover": "cover",
    "gjs-dashed": "dashed",
    "gjs-default-sliders-status": "Default Sliders Status",
    "gjs-dots-active-color": "Dots Active color",
    "gjs-dots-background-hidden": "Dots Background Hidden",
    "gjs-dots-color": "Dots color",
    "gjs-dots-display": "Dots Display",
    "gjs-dots-position": "Dots Position",
    "gjs-dots-size": "Dots size",
    "gjs-dots-style": "Dots Style",
    "gjs-dots-style-dot": "Dots",
    "gjs-dots-style-line": "Lines",
    "gjs-dotted": "dotted",
    "gjs-double": "double",
    "gjs-fix-visible-items": "Fix Visible Items",
    "gjs-fixed": "fixed",
    "gjs-hidden": "Hidden",
    "gjs-infinite": "Infinite",
    "gjs-inside": "Inside",
    "gjs-left bottom": "left bottom",
    "gjs-left center": "left center",
    "gjs-left top": "left top",
    "gjs-local": "local",
    "gjs-no-repeat": "non-Repeat",
    "gjs-non-Repeat": "non-Repeat",
    "gjs-none": "none",
    "gjs-outside": "Outside",
    "gjs-re-index": "Re-Index",
    "gjs-repeat": "Repeat",
    "gjs-repeat-x": "Repeat-x",
    "gjs-repeat-y": "Repeat-y",
    "gjs-rewind": "Rewind",
    "gjs-rewind-speed": "Rewind speed",
    "gjs-right bottom": "right bottom",
    "gjs-right center": "right center",
    "gjs-right top": "right top",
    "gjs-schedules": "Schedules",
    "gjs-scroll": "scroll",
    "gjs-shadow-type": "Shadow type",
    "gjs-show-bigordr-story": "Show story",
    "gjs-slide-speed": "Slide speed",
    "gjs-slides-per-page": "Slides per page",
    "gjs-slides-to-scroll": "Slides to scroll",
    "gjs-smooth-scroll": "Smooth Scroll",
    "gjs-solid": "solid",
    "gjs-status-active": "Active",
    "gjs-status-inactive": "Inactive",
    "gjs-timing": "Timing",
    "gjs-title": "title",
    "gjs-total-items": "Total items",
    "gjs-visible": "Visible",
    "gjs_landscape": "Landscape",
    "gjs_portrait": "Portrait",
    "gjs_video ratio": "Video Ratio",
    "global settings": "Global settings",
    "go back to old version": "Go Back To Old Version",
    "go to": "Go To",
    "google add to cart conversion label": "Add to Cart Conversion Label",
    "google merchant center": "Google Merchant Center",
    "google purchase conversion label": "Purchase Event Conversion Label",
    "google tags manager": "Google Tags Manager",
    "google tracking id": "Google Tracking ID",
    "grains, vegetables and fruits": "Grains, Vegetables And Fruits",
    "greater than": "Greater Than",
    "greater than or equal to": "Greater Than Or Equal To",
    "greaterThan": "Greater Than",
    "greaterThanOrEqualTo": "Greater Than Or Equal To",
    "greeting": "Greeting",
    "grid": "Grid",
    "gross profit": "Gross Profit",
    "group by product set with choices": "Group by Set With Choices",
    "group by tags": "Group By Tags",
    "group by transporter": "Group by transporter",
    "group picking list by transporter": "Group Picking List by transporter",
    "group product by transporter": "Group Product By Transporter",
    "grouped to": "Grouped To",
    "grouped with": "Grouped With",
    "has active campaigns": "Active campaigns",
    "has break time": "Has Break Time",
    "has cash payment fee": "Has Cash Payment Fee",
    "has choices": "Has Choices",
    "has custom limited qty per person text": "Has Custom Limited Qty Per Person Text",
    "has disallowed product tags": "Has Disallowed Product Tags",
    "has image": "Has Image",
    "has images": "Has Images?",
    "has limit": "Has Limit",
    "has limits": "Has limits",
    "has limits per person": "Has Limits per Person",
    "has no image": "No Image",
    "has reply": "Has Reply",
    "has surcharge on free shipping": "Has Surcharge On Free Shipping",
    "has times per order": "Has Times per Order",
    "has unset weight": "Has Unset weight",
    "has usage limit per person": "Has Usage Limit Per Person",
    "has variants": "Has Variants",
    "hasPreOrder": "Pre Order",
    "hasPreOrderCount": "Pre Order: {count}",
    "hasSameValuesWithDatabase": "No changes",
    "hasStock": "In Stock",
    "hasVariants": "Has Variants",
    "have not reissued e-invoices": "Have Not Reissued E-Invoices",
    "having both linked consignments and orders in one document is not allowed": "Having both linked consignments and orders in one document is not allowed!",
    "hct": "HCT",
    "hct contact": "HCT Contact",
    "hct credentials": "HCT Credentials",
    "hct extensions": "HCT Extensions",
    "hct summary": "HCT Summary",
    "hct summary successfully submitted": "HCT Summary Successfully Submitted",
    "hct waybills": "HCT Waybills",
    "hct_region_error": "查無此區號，請檢查配送地址",
    "header": "Header",
    "header-settings": "Header & Footer",
    "heartbeat": "Heart Beat",
    "height": "Height",
    "help": "Help",
    "help editor": "Help Editor",
    "hidden": "Hidden",
    "hide": "Hide",
    "hide add to cart button with a password": "Hide Add To Cart Button With A Password",
    "hide all": "Hide All",
    "hide all customization prices": "Hide All Customization Prices",
    "hide all printed": "Hide All Printed",
    "hide customization prices if 0": "Hide Customization Price When 0",
    "hide payouts details": "Hide Payouts Details",
    "hide price when has password": "Hide Price When Has Password",
    "hide printed": "Hide already printed",
    "hide printed today": "Hide Printed Today",
    "hide product code": "Hide Product Code",
    "hide products with missing data": "Hide products with missing data (negative product qty)",
    "hide products with no activity": "Hide products with no activity",
    "hide shipping fee": "Hide Shipping Fee",
    "hide shop description for all shops": "Hide Shop Description For All Shops",
    "hide tax number for all shops": "Hide Tax Number For All Shops",
    "hide unit": "Hide Unit",
    "highest": "Highest",
    "hold all": "Hold All",
    "home": "Home",
    "home fax": "Home Fax",
    "home page": "home page",
    "home phone": "Home Phone",
    "horizontal": "Horizontal",
    "hours": "Hours",
    "household appliances": "Household Appliances",
    "href": "Href",
    "html-custom-code": "Html Code",
    "i have a referral code": "I have a referral code",
    "icon": "Icon",
    "if there are multiple payment types, waybills will be grouped by payment type": "If there are multiple payment types, waybills will be grouped by payment type.",
    "image": "Image",
    "image alignment": "Image Alignment",
    "image alt": "Image Alternative Text",
    "image caption": "Image Caption",
    "image link": "Image Link",
    "image name": "Image Name",
    "image not found, maybe deleted": "Image not found, maybe deleted",
    "image settings": "Image Settings",
    "images": "Images",
    "images hover brightness effect": "Images hover brightness effect",
    "images hover zoom effect": "Images hover zoom effect",
    "import": "Import",
    "import data": "Import Data",
    "import dispatched": "Import Dispatched",
    "import limited qtys": "Import Limited Qtys",
    "import paid": "Import Paid",
    "import products": "Import Products",
    "import to bulk update status and print": "Import To Bulk Update Status And Print",
    "import_transporter_template": "Download Import Transporter Template",
    "in pixel": " (unit: px)",
    "in sync": "In Sync",
    "in use by": "In use by",
    "inactive": "Inactive",
    "inactive variant children": "Inactive Variant Children",
    "inclTotal": "Inclusive Total",
    "include in seo shop locations": "Include in seo shop locations",
    "include pos": "Include Pos",
    "includeFreeShipping": "Include Free Shipping",
    "including tax discount": "Including Tax Discount",
    "inclusive amount": "Incl. Amount",
    "inclusive discount": "Inclusive Discount",
    "inclusive discounted price": "Inclusive Discounted Price",
    "inclusive price": "Incl. Price",
    "inclusive selling price": "Incl. Selling Price",
    "inclusive total": "Inclusive Total",
    "inclusive total after discount": "Inclusive Total After Discount",
    "inclusive total after returns": "Inclusive Total After Returns",
    "inclusive_total_short": "Incl. Total",
    "income statement": "Income Statement",
    "incoming transactions summary": "Incoming Transactions Summary",
    "incomplete pdf. please re-download": "Incomplete PDF, Please re-download.",
    "incomplete telephone/address": "Incomplete telephone/address",
    "incorrect": "incorrect",
    "incorrect array order": "incorrect array order",
    "incorrect array order and/or incorrect number of items": "incorrect array order and/or incorrect number of items",
    "incorrect byte": "incorrect byte",
    "incorrect bytes": "incorrect bytes",
    "incorrect date": "incorrect date",
    "incorrect date format": "incorrect date format",
    "incorrect date range": "incorrect date range",
    "incorrect decimal places": "incorrect decimal places",
    "incorrect length": "Incorrect Length",
    "incorrect number": "incorrect number",
    "incorrect number of items": "incorrect number of items",
    "incorrect uri scheme": "incorrect uri scheme",
    "increase": "Increase",
    "increase/decrease limited qty": "+/- limited qty",
    "increase/decrease preOrder qty": "+/- Pre Order qty",
    "index": "Index",
    "individual": "Individual",
    "infinite": "Infinite",
    "influencers": "Influencers",
    "information": "Information",
    "init": "Start",
    "input value": "Input Value",
    "insert": "Insert",
    "insert documents ref here": "Insert Documents Ref here",
    "insert or replace templates": "Insert or Replace Templates",
    "insert text or drag content here, remove this text block if not needed": "Insert text or drag content here, remove this text block if not needed.",
    "inside image": "Inside Image",
    "insideImage": "Inside Image",
    "instagram url": "Instagram Url",
    "installment": "Installment",
    "installment periods": "installment periods",
    "internal comments": "Internal Comments",
    "invalid": "Invalid",
    "invalid image type": "Invalid Image Type",
    "invalid number of days": "Invalid number of Days",
    "invalid number of months": "Invalid number of Months",
    "invalid password": "密碼錯誤",
    "invalid rate": "Invalid Rate",
    "invalid returns": "Invalid returns",
    "invalid returns. returned qty greater than invoiced qty": "Invalid returns. Returned qty greater than invoiced qty",
    "invalid status": "Invalid Status",
    "invalid tax types. Provider does not allow mixed types": "Invalid tax types. Provider does not allow mixed types",
    "invalid totals. totals for document - returns does not equal to issue total": "Invalid totals. Totals for document minus returns does not equal to issue total",
    "invalid two factor authentication code": "Invalid two factor authentication code",
    "invalid username or password": "Invalid Username or Password!",
    "inventory": "Inventory",
    "inventory activity": "Inventory Activity",
    "inventory adjustment": "Inventory Adjustment",
    "inventory available": "Inventory Available",
    "inventory book qty": "Inventory Book Qty",
    "inventory filters": "Inventory Filters",
    "inventory in": "Inventory In",
    "inventory management": "Inventory Management",
    "inventory notification": "Inventory Notification",
    "inventory on hand": "Inventory On Hand",
    "inventory on hand qty": "Inventory On Hand Qty",
    "inventory out": "Inventory Out",
    "inventory qty": "Inventory Qty",
    "inventory report": "Inventory Report",
    "inventory reports": "Inventory Reports",
    "inventory status report": "Inventory Status Report",
    "invoice": "Invoice",
    "invoice date": "Invoice Date",
    "invoice description": "Invoice Description",
    "invoice number": "Invoice Number",
    "invoice numbers": "Invoice Numbers",
    "invoiced": "Invoiced",
    "invoices": "Invoices",
    "ip address": "IP address",
    "is associates": "Is Associates",
    "is between": "Is Between",
    "is child campaign": "Is Child Campaign",
    "is custom response": "Is Custom Response?",
    "is finished": "Is Finished",
    "is master campaign": "Is Master Campaign",
    "is member": "Is Member",
    "is not allowed": "is not allowed",
    "is partner": "Is Partner",
    "is required": "Is Required",
    "is transporter": "Is Transporter",
    "is unlimited": "Is Unlimited",
    "isBetween": "Is Between",
    "isComplete": "Completed",
    "isIncomplete": "Incomplete",
    "isPinned": "Pinned",
    "isRecurringChild": "Recurring",
    "isRecurringMaster": "Recurring",
    "isSet": "Bundle",
    "isSetChild": "Bundle Child",
    "isSetWithChoices": "Bundle With Choices",
    "isVariantChild": "Variant Child",
    "issue invoice": "Issue Invoice",
    "issue invoices now": "Issue Invoices Now",
    "item": "Item",
    "item key": "item Key",
    "item name": "Item Name",
    "item quantity": "Item Quantity",
    "item value": "Item Value",
    "item was not invoiced": "Item was not invoiced",
    "itemName": "Item",
    "itemQty": "Item Quantity",
    "itemQuantity": "Item Quantity",
    "itemValue": "Item Value",
    "items": "Items",
    "items total price is not equal to the set price": "Items' total price is not equal to the set price!",
    "jello": "Jello",
    "job_position": "Position",
    "join with": "Join With",
    "kg": "kg",
    "kgi Bank": "KGI Bank",
    "knowledge base": "Knowledge Base",
    "label": "Label",
    "label for transporter": "Label For Transporter",
    "language is now set to": "Language is now set to",
    "large": "Large",
    "last 1 month": "Last 1 month",
    "last 1 year": "Last 1 year",
    "last 3 months": "Last 3 months",
    "last 4 digits": "Last 4 Digits",
    "last 6 months": "Last 6 months",
    "last button label": "Last Button Label",
    "last month": "Last Month",
    "last name": "Last Name",
    "last waybill number": "Last Waybill Number",
    "lastest delivery date": "The Latest Available Date On Checkout Page",
    "latest": "Latest",
    "latest customers": "Latest Customers",
    "latest dispatch date": "Latest Dispatch Date",
    "latest dispatch time": "Latest Time For Dispatch Of The Day Of Order",
    "latest dispatch time description": "If the order is after Latest Time For Dispatch, it will automatically be postponed to the next day",
    "latest pickup time": "Latest Time For Store Pickup Of The Day Of Order",
    "latest pickup time description": "If the order is after Latest Time For Store Pickup, it will automatically be postponed to the next day",
    "latest suppliers": "Latest Suppliers",
    "launch": "Launch",
    "lead time": "Lead Time",
    "ledger": "Ledger",
    "ledger account": "Ledger Account",
    "ledgerAccount": "Ledger Account",
    "ledgers": "Ledgers",
    "ledgers->cash book": "Ledgers > Cash Book",
    "ledgers->creditors": "Ledgers > Creditors",
    "ledgers->debtors": "Ledgers > Debtors",
    "left": "Left",
    "less than": "Less Than",
    "less than or equal to": "Less Than Or Equal To",
    "lessThan": "Less Than",
    "lessThanOrEqualTo": "Less Than Or Equal To",
    "level change": "Level Change",
    "liabilities": "Liabilities",
    "light color": "Light Color",
    "lightColor": "Light Color",
    "limit by": "Limit By",
    "limit preOrder qty": "Limit Pre Order Qty",
    "limit sales qty": "Limit Sales Qty",
    "limited preOrder qty": "Limited Pre Order Qty",
    "limited qty": "Limited Qty",
    "limited qty per person": "Limited Qty Per Person",
    "limited sales qty": "Limited Sales Qty",
    "limits for specific dates": "Limits For Specific Dates",
    "line": "Line",
    "line chat": "Line Chat",
    "line count for blogs description": "Line Count for Blogs Description",
    "line count for blogs title": "Line Count for Blogs Title",
    "line id": "Line Id",
    "line pay": "Line Pay",
    "line pay history": "Line Pay History",
    "line pay provider: tap pay": "Line Pay Provider: Tap Pay",
    "line pay transactions": "Line Pay Transactions",
    "linePay": "Line Pay",
    "linePay Providers": "Line Pay Provider",
    "linePay_awaitingPayment": "Awaiting Line Pay Payment",
    "link": "Link",
    "link description": "Link Description",
    "link thumbnail image": "Link Thumbnail Image",
    "link thumbnail image ratio": "Link Thumbnail Image Ratio",
    "link title": "Link Title",
    "link to flow": "Link To Flow",
    "link_target": "Target",
    "linked campaigns": "Linked Campaigns",
    "linked documents have conflicts": "Linked documents have conflicts!",
    "linked to": "Linked To",
    "links": "Links",
    "list all": "List All",
    "list all abandoned": "List All Abandoned",
    "loading": "Loading",
    "lock": "Lock",
    "locked": "Locked",
    "log into new company?": "Log into [ {company} ]? Will close all tabs",
    "logged out": "Logged Out",
    "login": "Login",
    "logo": "Logo",
    "logo icon position": "Logo Icon Position",
    "logo menu position": "Logo Menu Position",
    "logo mobile": "Logo (Mobile)",
    "logo on top": "Logo on Top",
    "logo position": "Logo Position",
    "logo size": "Logo Size",
    "logo size desktop only": "Logo Size (Desktop Only)",
    "logoCenteredScrollMethod_hide": "Hide",
    "logoCenteredScrollMethod_shrink": "Shrink",
    "logout": "Logout",
    "lowest": "Lowest",
    "mailing list": "Mailing List",
    "mailing_groups": "List",
    "main": "Main",
    "main account number": "Main Account Number",
    "main island": "Main Island",
    "maintenance": "Maintenance",
    "make sure that this is the intended qty": "please make sure that this is the intended qty",
    "makeup and cosmetics": "Makeup And Cosmetics",
    "male": "Male",
    "manage duplicates": "Manage Duplicates",
    "manage rules": "Manage Rules",
    "margin": "Margin",
    "mark as completed": "Mark As Complete",
    "mark as inactive": "Mark as Inactive",
    "mark as paid": "Mark as paid",
    "mark as resolved": "Mark as resolved",
    "mark as unresolved": "Mark as unresolved",
    "master campaign": "Master Campaign",
    "match_contacts_groups": "Match",
    "match_contacts_groups_criteria": "Criteria",
    "match_free_shipping_any": "Any Products",
    "match_free_shipping_for": "For",
    "match_free_shipping_only": "Only if cart contains products",
    "max length": "Max Length",
    "max order price": "Maximum Order Price",
    "max order qty": "Maximum Order Quantity",
    "max order weight": "Maximum Order Weight",
    "max price": "Max Price",
    "max qty": "Maximum Qty",
    "max range 3 month": "Maximum Range 3 Month",
    "may contain duplicate data": "May Contain Duplicate Data",
    "mayAdd": "Show Additional Items",
    "medical supplies": "Medical Supplies",
    "medium": "Medium",
    "member": "Member",
    "member email": "Member Email",
    "member id": "Member Id",
    "member level": "Member Level",
    "member level adjustment email": "Member Level Adjustment Email",
    "member level description": "Member Level Description",
    "member level expiry date": "Member Level Expiry Date",
    "member level expiry time": "Member Level Expiry Time",
    "member level name": "Member Level Name",
    "member level point required": "Point Required",
    "member level points": "Member Level Points",
    "member level points will be set to member level minimum points": "Member level points will be set to member level minimum points",
    "member level status": "Status",
    "member levels": "Member Levels",
    "members": "Members",
    "menu": "Menu",
    "menu expand alignment": "Menu Expand Alignment",
    "menu expand behavior": "Menu Expand Behavior",
    "menu font size": "menu font size",
    "menu position": "Menu Position",
    "menu style": "Menu Style",
    "merchant center settings": "Merchant Center Settings",
    "merchantId": "Merchant ID",
    "merchantIv": "Merchant IV",
    "merchantKey": "Merchant Key",
    "merge": "Merge",
    "merge_document": "Merge",
    "merged": "Merged",
    "merged to": "Merged To",
    "merged with": "Merged With",
    "merged_document": "Merged",
    "message": "Message",
    "message to display when campaign has ended": "Message To Display When Campaign Has Ended",
    "messages": "Messages",
    "method": "Method",
    "min length": "Min Length",
    "min order price": "Minimum Order Price",
    "min order qty": "Minimum Order Quantity",
    "min order to allow shipping": "Min order to allow shipping: {amount}",
    "min order weight": "Minimum Order Weight",
    "min price": "Min Price",
    "min qty": "Minimum Qty",
    "min requirement amount per period": "Min. requirement amount per period",
    "minimize logo on scroll": "Minimize logo on scroll",
    "minimum cart amount": "Minimum Cart Amount",
    "minimum spend requirement": "Minimum Spend Requirement",
    "minutes": "Minutes",
    "miscellaneous": "Miscellaneous",
    "missing data": "Missing Data",
    "mm": "mm",
    "mm1": "January",
    "mm10": "October",
    "mm11": "November",
    "mm12": "December",
    "mm2": "February",
    "mm3": "March",
    "mm4": "April",
    "mm5": "May",
    "mm6": "June",
    "mm7": "July",
    "mm8": "August",
    "mm9": "September",
    "mobile": "Mobile",
    "mobile logo size": "Mobile Logo Size",
    "monday": "Monday",
    "monday opening hours": "Monday Opening Hours",
    "month": "Month",
    "month end": "Month End",
    "month1": "January",
    "month10": "October",
    "month11": "November",
    "month12": "December",
    "month2": "February",
    "month3": "March",
    "month4": "April",
    "month5": "May",
    "month6": "June",
    "month7": "July",
    "month8": "August",
    "month9": "September",
    "monthly": "Monthly",
    "monthly revenue": "Monthly Revenue",
    "months": "Months",
    "more": "more",
    "moreThanFiveDays": "5+ Days",
    "multiple": "Multiple",
    "multiple delivery dates": "Multiple Delivery dates",
    "multiple discount rate": "Multiple Discount Rate",
    "multiple discounts": "Multiple Discounts",
    "must be a bigger number": "must be a bigger number",
    "must be a boolean": "must be a boolean",
    "must be a buffer or a string": "must be a buffer or a string",
    "must be a credit card": "must be a credit card",
    "must be a float or double": "must be a float or double",
    "must be a negative number": "must be a negative number",
    "must be a number": "must be a number",
    "must be a positive number": "must be a positive number",
    "must be a smaller number": "must be a smaller number",
    "must be a string": "must be a string",
    "must be a valid GUID": "must be a valid GUID",
    "must be a valid ISO 8601 date": "must be a valid ISO 8601 date",
    "must be a valid base64 string": "must be a valid base64 string",
    "must be a valid date": "must be a valid date",
    "must be a valid email": "must be a valid email",
    "must be a valid hostname": "must be a valid hostname",
    "must be a valid ip address": "must be a valid ip address",
    "must be a valid port": "must be a valid port",
    "must be a valid relative uri": "must be a valid relative uri",
    "must be a valid taiwanese address": "Must Be A Valid Taiwanese Address",
    "must be a valid timestamp or number of milliseconds": "must be a valid timestamp or number of milliseconds",
    "must be a valid timestamp or number of second": "must be a valid timestamp or number of second",
    "must be a valid uri": "must be a valid uri",
    "must be an array": "must be an array",
    "must be an integer": "must be an integer",
    "must be smaller than inclusive price": "Must be smaller than inclusive price",
    "must be unicode normalized": "must be unicode normalized",
    "must dispatch today": "Must Dispatch Today",
    "must exclude value": "must exclude value",
    "must not have leading or trailing whitespace": "must not have leading or trailing whitespace",
    "must only contain alpha-numeric and underscore characters": "must only contain alpha-numeric and underscore characters",
    "must only contain alpha-numeric characters": "must only contain alpha-numeric characters",
    "must only contain hexadecimal characters": "must only contain hexadecimal characters",
    "must only contain uppercase characters": "must only contain uppercase characters",
    "must use shipping zones to set limits per day": "Must Use Shipping Zones To Set Limits Per Day",
    "mustAdd": "Automatically Add Items to Cart",
    "mutliple documents found. please capture manually": "Mutliple documents found. please capture manually",
    "my profile": "My Profile",
    "name": "Name",
    "national credit card center of ROC": "National Credit Card Center of ROC",
    "nav container position": "Nav Container Position",
    "nav dot": "Nav Dot",
    "nav dots": "Nav Dots",
    "nav item dot": "Dot",
    "nav item line": "Line",
    "nav item style": "Nav Item Style",
    "nav next": "Nav Next",
    "nav previous": "Nav Previous",
    "navItemDot": "Dot",
    "navItemLine": "Line",
    "never expire": "Never Expire",
    "new": "New",
    "new account": "New Account",
    "new bulk discount": "New Bulk Discount",
    "new category page": "New Category Page",
    "new customer": "New Customer",
    "new customer and supplier": "New Customer & Supplier",
    "new feature": "New Feature",
    "new features": "New Features",
    "new link": "New Link",
    "new member level": "new member level",
    "new member level points": "New Member Level Points",
    "new orders": "New Orders",
    "new page": "New Page",
    "new product": "New Product",
    "new products editor": "New Products Editor",
    "new sales invoice": "New Sales Invoice",
    "new sales order": "New Sales Order",
    "new shipping zone": "New Shipping Zone",
    "new supplier": "New Supplier",
    "new template": "New Template",
    "new total must be equal to the original document total": "New total must be equal to the original document total",
    "new value": "New Value",
    "new window": "New Window",
    "newCustomer": "New Customer",
    "new_contacts_groups": "New Group",
    "neweb Pay": "Neweb Pay",
    "newestFirst": "Newest First",
    "news": "News",
    "news images ratio": "News Images Ratio",
    "news settings": "News Settings",
    "news show breadcrumb": "Show Breadcrumb Navigation",
    "next": "Next",
    "next active": "Next Active",
    "next button label": "Next Button Label",
    "next schedule": "Next Schedule",
    "next step": "Next Step",
    "next step conversion rate": "Next Step Conversion Rate",
    "next workflow": "Next Workflow",
    "night": "Night",
    "no": "No",
    "no active schedule": "No active schedule",
    "no applicable shipping zones": "No Applicable Shipping Zones",
    "no background colour": "No Background Colour",
    "no comments": "No Comments",
    "no commission this month": "No Commission This Month!",
    "no data": "no data",
    "no data to export": "No Data To Export",
    "no details": "No Details",
    "no dispatched data": "no dispatched data",
    "no emails to send": "No emails to send",
    "no master campaign found": "No master campaign found",
    "no member level changes": "No member level changes!",
    "no next available number": "no next available number",
    "no price history found": "No price history found",
    "no products added yet": "No Products Added Yet",
    "no products found, double click to refresh or add new products": "no products found, double click to refresh or add new products",
    "no rates": "No Rates",
    "no reward expiry set": "No reward expiry set",
    "no reward points set": "No reward points set",
    "no shipping zones set in campaign": "No shipping zones set in campaign",
    "no tax allowed": "No Tax Allowed",
    "no transporter": "No transporter",
    "no transporter selected": "No Transporter Selected",
    "noItemInDatabase": "No item found in database",
    "no_of_documents_unit": "documents",
    "no_of_times": "times",
    "nonMember": "Non-Member",
    "none": "None",
    "noon": "Noon",
    "normal": "Normal",
    "normal percentage": "Normal Percentage",
    "normal product": "Normal Product",
    "not a day of the month (1-31)": "Not a day of the month (1-31)",
    "not a url": "Not a Url",
    "not an email": "Not an Email",
    "not date": "Date Required",
    "not dispatched": "Not Dispatched",
    "not editable": "Not Editable",
    "not email": "Not Email",
    "not equals": "Not Equals",
    "not float": "Not a number",
    "not found": "Not Found",
    "not matching any of the allowed alternatives": "not matching any of the allowed alternatives",
    "not member": "Not Member",
    "not number": "Not Number",
    "not unique": "Not Unique",
    "not yet addded to any shops": "not yet addded to any shops",
    "not yet dispatched": "Not Yet Dispatched",
    "not yet generated": "Not yet generated",
    "not yet invoiced": "Not Yet Invoiced",
    "not yet saved": "Not yet saved",
    "not yet set": "Not yet set",
    "notEquals": "Not Equals",
    "note": "Note",
    "note is required on checkout": "Note is Required on Checkout",
    "notes": "Notes",
    "notification": "Notification",
    "notifications": "Notifications",
    "notifications_read": "Read",
    "notifications_unread": "New",
    "number": "Number",
    "number of days": "Number Of Days",
    "number of days after dispatch date": "Number Of Days After Dispatch Date",
    "number of games per person": "Number of games per person",
    "number of lines for product name": "Number of line for product name",
    "number of months": "Number Of Months",
    "number of orders": "Number of Orders",
    "number of orders (after split)": "Number of Orders (After Split)",
    "number of orders (before split)": "Number of Orders (Before Split)",
    "number of results": "Number of Results",
    "number of selections": "Number of Selections",
    "number of transactions": "number of transactions",
    "off": "Off",
    "offer id": "Offer_ID",
    "office fax": "Office Fax",
    "office phone": "Office Phone",
    "offline": "Offline",
    "old exclusive price": "Old Exclusive Price",
    "old inclusive price": "Old Inclusive Price",
    "old member level points": "Old Member Level Points",
    "old store id": "Old Store ID",
    "old store name": "Old Store Name",
    "on": "On",
    "on checkout delivery date selection": "Checkout Page Delivery Date Selection",
    "on checkout dispatch date selection": "Checkout Page Dispatch Date Selection",
    "on checkout pickup date selection": "Checkout Page Pickup Date Selection",
    "on consignment qty": "On consignment qty",
    "on hand qty": "On Hand Qty",
    "on hold": "On Hold",
    "on hold qty (checkout in progress)": "On hold qty (checkout in progress)",
    "on paid send email": "On Paid Send Email",
    "on sale": "On Sale",
    "on sale schedules": "On Sale Schedules",
    "on sale tag display position": "On Sale Tag Display Position",
    "on sale tag display style": "On Sale Tag Display Style",
    "on set date": "On set Date",
    "on total": "On Total",
    "onHold": "On Hold",
    "onSaleScheduleWarning_notOnSale": "* When there is no active schedule, this item will use [original price]",
    "onSaleScheduleWarning_onSale": "* When there is no active schedule, this item will use [discounted price]",
    "on_consignment_exclusive_total": "On consignment excl. total",
    "on_consignment_inclusive_total": "On consignment incl. total",
    "once": "Once",
    "once off": "Once Off",
    "one item limit per checkout": "One Item Limit Per Checkout",
    "oneByOne": "1x1 (square)",
    "oneToThreeDays": "1-3 Days",
    "online": "Online",
    "only allow recurring schedules in shop": "Only allow recurring schedules in shop",
    "only categories": "only categories",
    "only for B2C": "Only for B2C",
    "only for mobile preview and slider display": "Only for mobile preview and slider display",
    "only include items with sales activity": "Only include items with sales activity",
    "only one bulk discount allowed with all product tags": "Only one bulk discount allowed with all product tags",
    "only send when customer won prizes": "Only send when customer won prizes",
    "only show set details (hide parents)": "Only show bundle details (hide parents)",
    "only show set parents (hide details)": "Only show bundle parents (hide details)",
    "only show shipping description, rate is quoted after order": "Only show shipping description, rate is quoted after order",
    "only show variant parents": "Only show variant parents",
    "only show variant parents in products search": "Only show variant parents in products search",
    "only tags": "only tags",
    "open graph": "Open Graph",
    "open help": "Open Help",
    "open hours": "Open Hours",
    "open link": "Open Link",
    "open shop settings": "Go To Shop Settings",
    "open_tab": "Open Tab",
    "open_window": "Open Window",
    "opening balance": "Opening Balance",
    "opening inventory book qty": "Opening Inventory Book Qty",
    "opening inventory book value": "Opening Inventory Book Value",
    "opening inventory on hand qty": "Opening Inventory On Hand Qty",
    "opening inventory on hand value": "Opening Inventory On Hand Value",
    "opening points": "Opening Points",
    "operator": "Operator",
    "operator->change logs": "Operator > Change Logs",
    "optimized-image": "Optimized Image",
    "optional": "Optional",
    "options": "Options",
    "order": "Order",
    "order #": "Order #",
    "order activity showing options": "Order Activity Showing Options Setting",
    "order canceled email": "Order Canceled Email",
    "order date": "Order Date",
    "order dispatched email": "Order Dispatched Email",
    "order number": "Order Number",
    "order percentage": "Order Percentage",
    "order requirements": "Order Requirements",
    "order success email": "Order Success Email",
    "order summary": "Order Summary",
    "order value": "Order Value",
    "order-activity-settings": "Order Activity Settings",
    "orderValue": "Order Value",
    "ordered": "Ordered",
    "ordered date": "Ordered Date",
    "ordered qty": "Ordered Qty",
    "ordered_exclusive_total": "Ordered excl. total",
    "ordered_inclusive_total": "Ordered incl. total",
    "orders": "Orders",
    "orders by date": "Orders By Date",
    "original commission base cost": "Original Commission Base Cost",
    "original commission total amount": "Original Commission Total Amount",
    "original document total": "Original document total",
    "original inclusive price": "Original Inclusive Price",
    "original total commission": "Original Total Commission",
    "original value": "Original Value",
    "other": "Other",
    "other income": "Other Income",
    "out of stock": "Out Of Stock",
    "outOfStock": "Out Of Stock",
    "outer region": "Outer Region",
    "outgoing transactions summary": "Outgoing Transactions Summary",
    "outlying islands": "Outlying Islands",
    "output value": "Output Value",
    "outside image": "Outside Image",
    "outsideImage": "Outside Image",
    "outstanding": "Outstanding",
    "outstanding qty": "Outstanding Qty",
    "outstanding total": "Outstanding Total",
    "outstanding_exclusive_total": "Outstanding excl. total",
    "outstanding_inclusive_total": "Outstanding incl. total",
    "over limit": "Over Limit",
    "overdue commission": "Overdue Commission",
    "overlap date description": "Overlap date for the schedule",
    "overpaid": "Overpaid",
    "overview": "Overview",
    "package size": "Package Size",
    "package temperature": "Package Temperature",
    "package type": "Product Type",
    "packageSize": "Package Size",
    "packageType": "Product Type",
    "packages": "Packages",
    "packages_short": "Pkg",
    "packaging_dimensions": "Packaging Dimensions",
    "packaging_dimensions_unit_default": "Default Packaging Dimensions Unit",
    "page": "Page",
    "page is hidden on menu": "Page is Hidden On Menu",
    "page schedules": "Page Schedules",
    "page status": "Page Status",
    "page url": "Page Url",
    "page view": "Page View",
    "page views": "Page Views",
    "page views per person": "Page Views Per Person",
    "pageBlocks_bio": "Bio",
    "pageBlocks_facebookPosts": "Facebook Posts",
    "pageBlocks_flow": "Chatbot",
    "pageBlocks_images": "Images",
    "pageBlocks_instagramPosts": "Instagram Posts",
    "pageBlocks_links": "Links",
    "pageBlocks_mailingList": "Mailing List",
    "pageBlocks_products": "Products",
    "pageBlocks_socialLinks": "Social Links",
    "pageBlocks_text": "Text",
    "pageBlocks_threadsPosts": "Threads Posts",
    "pageBlocks_tikTokPosts": "TikTok Posts",
    "pageBlocks_tips": "Tips/Donations",
    "pageBlocks_youtubeVideo": "YouTube Video",
    "paid": "Paid",
    "parent page": "Parent Page",
    "passed allowed refund period": "Passed Allowed Refund Period",
    "password": "Password",
    "password (at least 4 characters)": "Password (At Least 4 Characters)",
    "password (at least 8 characters)": "Password (At Least 8 Characters)",
    "password button label": "Password Button Label",
    "password description": "Password Description",
    "paste as plain text": "paste as plain text",
    "pay": "Pay",
    "pay to account auto expiry": "Virtual Account Auto Expiry",
    "payers phone number": "Payer's Phone Number",
    "payment": "Payment",
    "payment amount": "Payment Amount",
    "payment date": "Payment Date",
    "payment due": "Payment Due",
    "payment due date": "Payment Due Date",
    "payment last 5 digits": "Acc Last 5 Digits",
    "payment link": "Payment Link",
    "payment method": "Payment Method",
    "payment methods": "Payment Methods",
    "payment number": "payment number",
    "payment overdue date": "Payment Overdue Date",
    "payment reminder email": "Payment Reminder Email",
    "payment reminder email message": "Payment Reminder Email Message",
    "payment reminder email subject": "Payment Reminder Email Subject",
    "payment reminder email templates": "Payment Reminder Email Templates",
    "payment reminders": "Payment Reminders",
    "payment settings": "Payment Settings",
    "payment status": "Payment Status",
    "payment terms": "Payment Terms",
    "payment-flow": "Payment Flow",
    "payment-settings": "Payment Settings",
    "paymentAndCheckout": "Payment and Checkout",
    "payments": "Payments",
    "payments and due date": "Payments and Due Date",
    "payments->credit card": "Payments > Credit Card",
    "payments->line pay": "Payments > Line Pay",
    "payments->virtual account": "Payments > Virtual Account",
    "payout": "Payout",
    "payouts this month": "Payouts This Month",
    "payuni": "PayUni",
    "payuni-seven-eleven": "PayUni 7-11",
    "payuni-seven-eleven credentials": "PayUni 7-11 Credentials",
    "payuni-seven-eleven waybill": "Payuni 7-11 Waybill",
    "payuni_hash_iv": "Hash IV",
    "payuni_hash_key": "Hash Key",
    "payuni_import from contact": "Import from contact",
    "payuni_merchant_id": "Merchant ID",
    "payuni_payments": "PayUni Payments",
    "pending": "Pending",
    "pending points": "Pending Points",
    "pending products": "Pending Products",
    "pending qty": "Pending Qty",
    "pendingCancellation": "Pending Cancellation",
    "per line": "Per Line",
    "percentage of price": "Percentage Of Price",
    "permanently unavailable variant children": "Permanently Unavailable Variant Children",
    "person": "Person",
    "person + contact": "Person + Contact",
    "person name": "Person Name",
    "person_export": "Person",
    "persons": "Persons",
    "persons_count": "No. of People",
    "pet supplies": "Pet Supplies",
    "phone": "Phone",
    "pick a color theme": "Pick a color theme",
    "pick up": "Pick Up",
    "pick up locations": "Pickup Locations",
    "picking list": "Picking List",
    "pickup address": "Pickup Address",
    "pickup date options": "Pickup Date Options",
    "pickup date settings": "Pickup Date Settings",
    "pickup date subtitle": "Checkout Page Pickup Date Subtitle : Show On Checkout Page",
    "pickup locations": "Pickup Locations",
    "pickup methods": "Pickup Methods",
    "pickup settings": "Pickup Settings",
    "pickup settings instruction": "Pickup setiings will be applied to all campaigns by default, make sure to go to Shop > Campaigns to check for campaign settings",
    "pickup-settings": "Pickup Settings",
    "pickupAddress": "Pickup Address",
    "picture-desktop": "picture (desktop)",
    "picture-tablet": "picture (tablet)",
    "pin": "Pin",
    "pinned": "Pinned",
    "pixel id": "Pixel ID",
    "plan": "Plan",
    "please allow popups to open": "Please allow popups to open",
    "please check email for two factor authentication code": "Please check email for two factor authentication code",
    "please press print again to see changes": "please press print again to see changes",
    "please select": "Please Select",
    "podKey": "PodKey",
    "point": "point",
    "points": "{points} points",
    "points adjustment email": "Points Adjustment Email",
    "points become valid after number of days": "points become valid after number of days",
    "points earned": "Points Earned",
    "points expiry date": "Points Expiry Date",
    "points expiry time": "Points Expiry Time",
    "points spent": "Points Spent",
    "points to adjust": "Points To Adjust",
    "points to adjust expiry date": "Points To Adjust Expiry Date",
    "points to send": "Points To Send",
    "pos": "POS",
    "pos profile": "POS Profile",
    "pos profile not found": "POS profile Not Found",
    "pos terminal": "POS Terminal",
    "position": "Position",
    "position_center-bottom": "Center Bottom",
    "position_center-center": "Center",
    "position_center-top": "Center Top",
    "position_left-bottom": "Left Bottom",
    "position_left-top": "Left Top",
    "position_right-bottom": "Right Bottom",
    "position_right-top": "Right Top",
    "possibly missing data": "Possibly missing data",
    "post office": "Post Office",
    "postCode": "Postal Code",
    "postal codes": "Postal Codes",
    "power": "Power",
    "pre order": "Pre Order",
    "preOrder": "Pre Order",
    "preOrder stats": "Pre Order Stats",
    "preOrder-notes": "Pre Order Notes",
    "preferred delivery time": "Preferred Delivery Time",
    "preferred supplier": "Preferred Supplier",
    "preferred supplier fulfillment method": "Preferred Supplier Fulfillment Method",
    "prepare for dispatch": "Prepare For Dispatch",
    "preparing": "Preparing",
    "preset commissions": "Preset Commissions",
    "presets": "Presets",
    "press": "Press",
    "press enter or search button to search": "Press Enter or Search Button to Search",
    "press enter to select, and search button to search": "Press Enter to Select, and Search Button to Search",
    "preview": "Preview",
    "preview merge": "Preview Merge",
    "previous": "Previous",
    "previous button label": "Previous Button Label",
    "previous step": "Previous Step",
    "price": "Price",
    "price (for accounting purpose)": "Price (for Accounting Purpose)",
    "price history": "Price History",
    "price range": "Price Range",
    "priceAsc": "Price from low to high",
    "priceDesc": "Price from high to low",
    "priceLessThanPriceDiscounted": "Inclusive Discounted Price: Must be smaller than inclusive price",
    "pricing": "Pricing",
    "print": "Print",
    "print all": "Print All",
    "print all delivery notes": "Print All Delivery Notes",
    "print all eInvoices": "Issue All E-Invoices",
    "print all invoices": "Print All Invoices",
    "print all waybills": "Print All Waybills",
    "print delivery note": "Print Delivery Note",
    "print hct summary": "Print HCT Summary",
    "print images": "Print Images",
    "print images on documents": "Print Images On Documents",
    "print invoice": "Print Invoice",
    "print invoices": "Print Invoices",
    "print picking list": "Print Picking List",
    "print statement": "Print Statement",
    "print waybill": "Print Waybill",
    "print waybills starting position": "Print Waybills Starting Position",
    "print_delivery_note_prices": "Print Delivery Note (with prices)",
    "printed": "Printed",
    "printed products": "Printed Products",
    "printer": "Printer",
    "printer error, please check printer connection": "Printer Error, Please check printer connection.",
    "printer id": "Printer ID",
    "printerAllocatedNumbers": "Printer allocated numbers",
    "private": "private",
    "private notes": "private notes",
    "private templates": "My Templates",
    "prize": "Prize",
    "prize description": "Description",
    "prize_successfulMessage": "Prize Message",
    "prize_successfulTitle": "Prize Title",
    "probably missing data": "Probably missing data!",
    "process_order": "Order",
    "processing": "Processing",
    "processing please wait": "Processing please wait",
    "product": "Product",
    "product code": "Product Code",
    "product decimal places": "Product Decimal Places",
    "product display": "Product Display",
    "product grouping": "Product Grouping",
    "product image ratio": "Product Image Ratio",
    "product images": "Product Images",
    "product images size": "Product Images Size",
    "product info": "Product Info",
    "product information": "Product information",
    "product limits": "Product Limits",
    "product name": "Product Name",
    "product price rounding": "Product Price Rounding",
    "product schedules": "Product Schedules",
    "product set": "Bundle",
    "product set with choices": "Bundle With Choices",
    "product sets": "Bundles",
    "product settings": "Product Settings",
    "product size": "Product Size",
    "product stats": "Product Stats",
    "product status": "Product Status",
    "product tags": "Product Tags",
    "product type": "Product Type",
    "product url": "Product Url",
    "product_description": "Product Description",
    "product_description_short": "Desc",
    "product_general": "General",
    "product_set": "Bundle",
    "product_set_price": "Bundle Price",
    "product_sets": "Bundle",
    "product_sets_contents": "Bundle Contents",
    "product_sets_with_choices": "Bundle With Choices",
    "product_sets_with_choices_contents": "Bundle With Choices Contents",
    "product_short description": "Short Description",
    "product_sync": "Re-Sync With Products",
    "product_sync_warning": "Changes to product will not reflect in shop. Please press the re-sync buttton to update shop.",
    "product_tags_any": "Contains",
    "product_tags_only": "Only",
    "product_type_inventory": "Product or Service",
    "product_type_service": "Service Product",
    "product_type_virtual": "Virtual Product",
    "products": "Products",
    "products images hover brightness effect": "Products images hover brightness effect",
    "products images hover zoom effect": "Products images hover zoom effect",
    "products images ratio": "Products Images Ratio",
    "products images size": "Products Images Size",
    "products in this shop": "Products in this Shop",
    "products overview name": "Shop overview name",
    "products overview style": "Shop overview style",
    "products sort by": "Products Sort By",
    "products to be converted to normal product": "Products to be converted to normal product",
    "products to be converted to variant child product": "Products to be converted to variant child product",
    "profile": "Profile",
    "promo code": "Promo Code",
    "promo code (at least 6 characters)": "Promo Code (at least 6 characters)",
    "promo codes": "Promo Codes",
    "promotion": "Promotion",
    "promotion email": "Promotion Email",
    "provider": "Provider",
    "public": "public",
    "public description": "Public Description",
    "public release notes": "public release notes",
    "public status": "Public Status (Visible to the public)",
    "public templates": "Default Templates",
    "pulse": "Pulse",
    "purchases": "Purchases",
    "purchases consignment": "Purchases Consignment",
    "purchases consignment overview": "Purchases Consignment Overview",
    "purchases consignment receipt": "Purchases Consignment Receipt",
    "purchases consignment return": "Purchases Consignment Return",
    "purchases enquiry": "Purchases Enquiry",
    "purchases invoice": "Purchases Invoice",
    "purchases invoices this month": "Purchases Invoices This Month",
    "purchases order": "Purchases Order",
    "purchases price history": "Purchases Price History",
    "purchases return": "Purchases Return",
    "purchases returns this month": "Purchases Returns This Month",
    "purchases terms": "Purchases Terms",
    "purchases->consignments": "Purchases > Consignments",
    "purchases->consignments->purchases-consignments": "Purchases > Consignments > Consignment Receipt",
    "purchases->consignments->purchases-consignments-returns": "Purchases > Consignments > Consignment Return",
    "purchases->doc-pending": "Purchases > Pending Orders",
    "purchases->purchases-invoices": "Purchases > Purchases Invoice",
    "purchases->purchases-orders": "Purchases > Purchases Order",
    "purchases->purchases-quotes": "Purchases > Purchases Enquiry",
    "purchases->purchases-returns": "Purchases > Debit Note",
    "purchases-consignments": "Consignment Receipt",
    "purchases-consignments-returns": "Consignment Return",
    "purchases-invoices": "Purchases Invoice",
    "purchases-orders": "Purchases Order",
    "purchases-pending": "Purchases: Pending",
    "purchases-pending: display tax method": "Purchases Pending Report: Display tax method",
    "purchases-purchases-consignments": "Consignment Receipt",
    "purchases-purchases-consignments-returns": "Consignment Return",
    "purchases-purchases-invoices": "Purchases Invoice",
    "purchases-purchases-orders": "Purchases Order",
    "purchases-purchases-quotes": "Purchases Enquiry",
    "purchases-purchases-returns": "Debit Note",
    "purchases-quotes": "Purchases Enquiry",
    "purchases-returns": "Debit Note",
    "purchases: on consignment": "Purchases: On Consignment",
    "purchases: on consignment qty": "Purchases: On Consignment Qty",
    "purchases_delivered_exclusive_total": "Received excl. total",
    "purchases_delivered_inclusive_total": "Received incl. total",
    "purchasing exclusive price": "Purchasing Exclusive Price",
    "purchasing inclusive price": "Purchasing inclusive Price",
    "qr code": "QR Code",
    "qty": "Qty",
    "qty cannot be empty or have value below one": "Qty cannot be empty or have value below one!",
    "qty cannot be greater than the allowed qty": "Qty cannot be greater than the allowed qty!",
    "qty sold": "Qty Sold",
    "qty sold (to invoice)": "qty sold (to invoice)",
    "quantity": "Quantity",
    "quantity based rates": "Quantity Based Rates",
    "query": "Query",
    "question": "Question",
    "questions": "Questions",
    "queued": "Queued",
    "quick add": "Quick Add",
    "quick login": "Quick Login",
    "quickTip": "QuickTip",
    "quote": "Quote",
    "rate": "Rate",
    "rate table": "Rate table",
    "ratio_original": "Original",
    "re-download already generated waybills": "Re-Download already generated Waybills",
    "re-download waybills": "Re-Download Waybills",
    "re-index": "Re-Index",
    "re-index all products": "Re-Index All Products",
    "re-login": "re-login",
    "re-order qty": "Re-Order Qty",
    "re-sync product stats": "Re-Sync Product Stats",
    "real qty sold": "Real qty sold",
    "reason": "Reason",
    "receipt": "Receipt",
    "receipts": "Receipts",
    "received qty": "Received qty",
    "received_exclusive_total": "Received excl. total",
    "received_inclusive_total": "Received incl. total",
    "receivers mobile number": "Receivers Mobile Number",
    "recipient": "Recipient",
    "recommended": "Recommended",
    "record commissions": "Record Commissions",
    "recurring order email": "Recurring Order Email",
    "recurring orders": "Recurring orders",
    "recurring schedules": "Recurring Schedules",
    "recurring settings": "Recurring Settings",
    "recurring-settings": "Recurring Settings",
    "recurring_discount_behavior": "Recurring Discounts Behavior",
    "recurring_discount_paymentReminder": "Recurring Payment Reminder",
    "recurring_only": "Recurring only",
    "recurring_options": "Options",
    "recurring_shipping_behavior": "Recurring Shipping Fee Behavior",
    "ref": "Ref",
    "refech barcode": "Refech Barcode",
    "reference": "Reference",
    "referenced field is not a date": "referenced field is not a date",
    "referenced field is not a number": "referenced field is not a number",
    "referral email": "Referral Email",
    "referral_reward": "Referral Reward",
    "referral_reward_details": "Referral reward event details",
    "referral_reward_limit per day": "Limit per Day",
    "referral_reward_recipient": "Recipient",
    "referral_reward_referrer": "Referrer",
    "refresh": "Refresh",
    "refrigerated": "Refrigerated",
    "refund": "Refund",
    "refund amount": "Refund Amount",
    "refund credit card": "Refund Credit Card",
    "refund credit card total": "Refund Credit Card Total",
    "refund line pay": "Refund Line Pay",
    "refund payment": "Refund Payment",
    "refund reminder amount": "Refund Reminder Amount",
    "refunds": "Refunds",
    "refunds require authorization": "Refunds require authorization",
    "refunds settings": "Refunds Settings",
    "regenerate access code": "regenerate access code",
    "regenerate document number": "regenerate document number",
    "region": "Region",
    "region code": "Region Code",
    "regions": "Regions",
    "related": "Related",
    "relationship": "Relationship",
    "relationships": "Relationships",
    "reload inventory reports": "Reload inventory reports",
    "remember credit card": "Remember credit card",
    "reminder limits qty": "Add Reminder When Product Qty Falls Below Specific Qty",
    "remove": "Remove",
    "remove all row": "Remove All Row",
    "remove bulk discount": "Remove Bulk Discount",
    "remove cell": "Remove Cell",
    "remove item from page": "Remove Item From Page",
    "remove product from shop": "Remove Product From Shop",
    "remove selected products": "Remove Selected Products",
    "repeat order info for each detail row": "Repeat order info for each detail row",
    "replace": "Replace",
    "report": "Report",
    "report link": "report link",
    "report password": "Report Password",
    "reports": "Reports",
    "reports->commissions": "Reports > Commissions",
    "reports->sales": "Reports > Sales",
    "request missing waybills": "request missing waybills",
    "request waybills": "Request Waybills",
    "requestId": "Request ID",
    "requested a todo from": "requested a todo from",
    "required": "Required",
    "resend": "Resend",
    "resend order webhook event": "Webhook: resend order data",
    "reset commission presets": "Reset commission presets",
    "reset inventory data": "Reset Inventory Data",
    "reset inventory date": "Reset Inventory Date",
    "resolved": "Resolved",
    "resource": "Resource",
    "restrict login using ip addresses": "Restrict login using ip addresses",
    "results": "Results",
    "return": "Return",
    "return all discount": "Return all discount",
    "return convenience store": "Return Convenience Store",
    "return points spent": "Return points spent",
    "return qty": "Return Qty",
    "return storeAddress": "Return Store Address",
    "return storeId": "Return Store Id",
    "return storeName": "Return Store Name",
    "return_points_adjustment_explanation": "Adjust refund amount when there are insufficient points to deduct (points earned on this order may have already been used)",
    "returningCustomer": "Returning Customer",
    "returns": "Returns",
    "returns application email": "Returns Application Email",
    "returns inclusive total": "Returns Inclusive Total",
    "revenue": "Revenue",
    "revenue by age": "Revenue By Age",
    "revenue by campaign": "Revenue By Campaign",
    "revenue by channel": "Revenue By Channel",
    "revenue by contact": "Revenue By Contact",
    "revenue by contact tags": "Revenue By Contact Tags",
    "revenue by customer type": "Revenue By Customer Type",
    "revenue by day": "Revenue By Day",
    "revenue by gender": "Revenue By Gender",
    "revenue by member type": "Revenue By Member Type",
    "revenue by month": "Revenue By Month",
    "revenue by payment method": "Revenue By Payment Method",
    "revenue by product": "Revenue By Product",
    "revenue by promo code": "Revenue By Promo Code",
    "revenue for campaign": "Revenue For Campaign",
    "revenue for month": "Revenue For Month",
    "revenue for year": "Revenue For Year",
    "revenue percentage": "Revenue Percentage",
    "revert to draft": "Revert to Draft",
    "review steps": "Review Steps",
    "reviewSettings": "Review Settings",
    "reward": "Reward",
    "reward earning rate from total order": "{rate}% from total price of order",
    "reward event": "Reward Event",
    "reward event allow on sale": "Allow on Sale",
    "reward event allow to earn points": "Allow To Earn Points",
    "reward event allow with promo code": "Allow with Promo Code",
    "reward event earn as promo code": "Earn as promo code",
    "reward event earning": "Reward Earning",
    "reward event expiry": "Expiry Time",
    "reward event max deduction": "Maximum Discount",
    "reward event rate per Point": "Exchange Rate Per Point",
    "reward event requirement": "Reward Requirement",
    "reward event start": "Start Time",
    "reward event status": "Status",
    "reward event type": "Reward Type",
    "reward for specific product tags": "Reward for Specific Product Tags",
    "reward history": "Reward History",
    "reward name": "Reward Name",
    "reward notification email": "Reward Notification Email",
    "reward points": "Reward Points",
    "rewardInclTotal": "Rewards Inclusive Total",
    "reward_adjustment": "Adjustment",
    "reward_adjustment_points": "Adjustment Points",
    "reward_birthday": "Birthday",
    "reward_birthday_points": "Birthday Points",
    "reward_current_points": "Current Points",
    "reward_earned_points": "Earned Points",
    "reward_expire_soon": "Expire Soon",
    "reward_memberLevelAdjustment": "Member Level Adjustment",
    "reward_no_data": "No reward history at this periods",
    "reward_opening": "Opening Balance",
    "reward_opening_points": "Opening Points",
    "reward_order": "Order",
    "reward_pointsAdjustment": "Points Adjustment",
    "reward_promotion": "Promotion",
    "reward_promotion_points": "Promotion Points",
    "reward_referral": "Referral",
    "reward_referral_points": "Referral Points",
    "reward_return": "Return",
    "reward_returns": "Return",
    "reward_signup": "Sign up",
    "reward_signup_points": "Sign Up Points",
    "reward_spent_points": "Spent Points",
    "rewards": "Rewards",
    "rewards inclusive total": "Rewards Inclusive Total",
    "ribbon": "Ribbon",
    "rid": "RID",
    "right": "Right",
    "role": "Role",
    "rotate": "Rotate",
    "rotateIn": "Rotate In",
    "row": "Row",
    "rss feeds": "RSS feeds",
    "rss_description": "Enable RSS feeds for Facebook and Google",
    "rss_feed_name": "RSS Consumer Name",
    "rss_feed_url": "RSS URL",
    "rubberBand": "Rubber Band",
    "rules_notes": "You can write down your internal rules and best practices here. This allows everyone on your team to follow the same rules and procedures.",
    "sales": "Sales",
    "sales consignment": "Sales Consignment",
    "sales consignment delivery": "Sales Consignment Delivery",
    "sales consignment overview": "Sales Consignment Overview",
    "sales consignment return": "Sales Consignment Return",
    "sales invoice": "Sales Invoice",
    "sales limits": "Sales Limits",
    "sales limits by specific dates": "Sales Limits By Specific Dates",
    "sales limits by weekdays": "Sales Limits By Weekdays (Repeats weekly)",
    "sales limits per day": "Sales Limits Per Day",
    "sales order": "Sales Order",
    "sales person": "Sales Person",
    "sales persons": "Sales Persons",
    "sales price history": "Sales Price History",
    "sales quote": "Sales Quote",
    "sales report": "Sales Report",
    "sales return": "Sales Return",
    "sales terms": "Sales Terms",
    "sales->consignments": "Sales > Consignments",
    "sales->consignments->sales-consignments": "Sales > Consignments > Consignment Delivery",
    "sales->consignments->sales-consignments-returns": "Sales > Consignments > Consignment Return",
    "sales->doc-pending": "Sales > Pending Orders",
    "sales->sales-invoices": "Sales > Sales Invoice",
    "sales->sales-orders": "Sales > Sales Order",
    "sales->sales-quotes": "Sales > Sales Quote",
    "sales->sales-returns": "Sales > Credit Note",
    "sales-consignments": "Consignment Delivery",
    "sales-consignments-returns": "Consignment Return",
    "sales-invoices": "Sales Invoice",
    "sales-orders": "Sales Order",
    "sales-pending": "Sales: Pending",
    "sales-pending: display tax method": "Sales Pending Report: Display tax method",
    "sales-quotes": "Sales Quote",
    "sales-returns": "Credit Note",
    "sales-sales-consignments": "Consignment Delivery",
    "sales-sales-consignments-returns": "Consignment Return",
    "sales-sales-invoices": "Sales Invoice",
    "sales-sales-orders": "Sales Order",
    "sales-sales-quotes": "Sales Quote",
    "sales-sales-returns": "Credit Note",
    "sales: on consignment": "Sales: On Consignment",
    "sales: on consignment qty": "Sales: On Consignment Qty",
    "sales_delivered_exclusive_total": "Delivered excl. total",
    "sales_delivered_inclusive_total": "Delivered incl. total",
    "same as date end": "Same as date end",
    "same as drop off": "Same as drop off",
    "same line": "Same Line",
    "saturday": "Saturday",
    "saturday opening hours": "Saturday Opening Hours",
    "save": "Save",
    "save and edit shop prices": "Save and Edit Shop Prices",
    "save and go next": "Save and Go Next",
    "save and update next invoice number": "Save and Update Next Invoice Number",
    "save as color template": "Save As Color Template",
    "save as copy": "Save as Copy",
    "save as draft": "Save as Draft",
    "save as template": "Save as Template",
    "save as void": "Save as Void",
    "save this search": "Save this search",
    "saved": "Saved",
    "schedule": "Schedule",
    "schedule status": "Schedule Status",
    "scheduled": "Scheduled",
    "scheduled payment": "Scheduled Payment",
    "scheduled_tasks": "Scheduled Tasks",
    "scheduled_time": "Scheduled Time",
    "schedules": "Schedules",
    "scratch card background": "Scratch Card (Background)",
    "scratch card background background color": "Scratch Card Background Background Color",
    "scratch card background image": "Scratch Card Background Image",
    "scratch card background text": "Scratch Card Background Text",
    "scratch card background text color": "Scratch Card Background Text Color",
    "scratch card cover": "Scratch Card (Cover)",
    "scratch card cover background color": "Scratch Card Cover Background Color",
    "scratch card cover image": "Scratch Card Cover Image",
    "scratch card cover text": "Scratch Card Cover Text",
    "scratch card cover text color": "Scratch Card Cover Text Color",
    "scratch card image size": "Scratch Card Image Size",
    "scratch card image size warning text": "If the scratch card image ratio is inconsistant with the image ratio, it may leads to distortion",
    "scratch card settings": "Scratch Card Settings",
    "scratch card type": "Type",
    "scrolling-notification": "Navbar Scrolling Notification",
    "seafood / meat": "Seafood / Meat",
    "search": "Search",
    "search bulk discounts": "Search bulk discounts",
    "search filters": "Search Filters",
    "search flows": "Search Flows",
    "search images": "Search Images",
    "search products": "Search Products",
    "search reference": "Search Reference",
    "search reward name": "Search Reward Name",
    "search shipping zones": "Search Shipping Zones",
    "select": "Select",
    "select all": "Select All",
    "select all main island": "Select All Main Island",
    "select all outlying": "Select All Outlying",
    "select animation": "Select Animation",
    "select company": "Select Company",
    "select dispatch date": "Select Dispatch Date",
    "select dispatch or arrival date": "Set Dispatch Date Or Arrival Date",
    "select einvoice printer terminal": "Select E-Invoice Printer Terminal",
    "select images": "Select",
    "select iteration count": "Select Iteration Count",
    "select periods": "Select Periods",
    "select product": "Select Product",
    "select reward program first": "Select reward program first",
    "select specific dates": "Select Exclude Specific Date(s)",
    "select stage": "Select Stage",
    "select store closed day": "Set Store Closed Day(s)",
    "select workflow": "Select Workflow",
    "selectable": "Selectable",
    "selected campaigns review": "Selected Campaigns Review",
    "selected items": "Selected Items",
    "selected orders": "Selected Orders",
    "selling exclusive price": "Selling Exclusive Price",
    "selling inclusive price": "Selling Inclusive Price",
    "send": "Send",
    "send a copy of email to addresses": "Send a copy of email to addresses",
    "send a copy of order email to addresses": "Send a copy of order email to addresses",
    "send a copy of order messages to addresses": "Send a copy of order messages to addresses",
    "send all dispatched emails": "Send All Dispatched Emails",
    "send all emails": "Send All Emails",
    "send birthday reward": "Send Birthday Reward",
    "send birthday rewards manually": "Send Birthday Rewards Manually",
    "send dispatched email": "Send Dispatched Email",
    "send document": "Send document",
    "send email": "Send Email",
    "send email message": "Send Email Message",
    "send email to customer": "Send email to customer (need to have customer's email!)",
    "send emails": "Send Emails",
    "send emails when successful": "Send e-mail after flow is submitted",
    "send from website": "Send From Website",
    "send payment reminder when overdue by": "Send Payment Reminder When Overdue By",
    "send reward": "Send Reward",
    "send reward manually": "Send Reward Manually",
    "send test email": "Send Test Email",
    "send void email": "Send Void Email",
    "sender address": "Sender Address",
    "sender company": "Sender Company",
    "sender name": "Sender Name",
    "sender telephone": "Sender Telephone",
    "sender_address": "Sender Address",
    "sent_member_level_adjustment": "Sent: Member Level Adjustment",
    "sent_valid_points_adjustment": "Sent: Valid Points Adjustment",
    "seo": "SEO",
    "seo-settings": "SEO Settings",
    "separate documents based on customizations name": "Separate Documents Based On Customizations Name",
    "separate each keyword using comma": "Separate each keywords using comma",
    "separate line": "Separate Line (Desktop Only)",
    "separator": "Separator",
    "server logs": "Server Logs",
    "session expired due to inactivity": "session expired due to inactivity",
    "set as default": "Set as Default",
    "set as default business": "Set as Default Business",
    "set as default for": "Set as Default For",
    "set as shop default": "Set As Shop Default",
    "set commission amount": "Set commission amount",
    "set commission base cost": "Set commission base cost",
    "set default": "Set Default",
    "set link": "Set Link",
    "set member level": "Set member level",
    "set member level by points": "Set member level by points",
    "set number of days": "Set Number of Days",
    "set period": "Set Period",
    "set price": "Set Price",
    "set price on bundle": "Set price on Bundle",
    "set prices later": "Set prices later",
    "set prices now": "Set prices now",
    "set schedules": "Click to Set",
    "set shipping zones": "Set Shipping Zones",
    "set shop default shipping zones": "Set Shop Default Shipping Zones",
    "set today as the first day": "Set Today As The First Day",
    "setType_": "Normal Product",
    "setType_hasVariants": "Variant Parent",
    "setType_isSet": "Bundle",
    "setType_isSetWithChoices": "Bundle With Choices",
    "setType_isVariantChild": "Variant Child",
    "setTypes": "Set Types",
    "set_price_confirmation": "Are you sure to use {price} for the current item price?",
    "settings": "Settings",
    "settings_documents": "Sales & Purchases",
    "settings_invoices": "Invoicing",
    "settings_payments": "Payments",
    "settings_shipping": "Shipping",
    "settings_social": "Social",
    "settings_style": "Styling",
    "setup wizards": "Setup Wizards",
    "seven-eleven": "7-11",
    "seven-eleven contact": "7-11 contact",
    "seven-eleven credentials": "7-11 Credentials",
    "seven-eleven none payment on pickup": "7-11 None payment on Pickup",
    "seven-eleven none payment on pickup frozen": "7-11 None payment on Pickup Frozen",
    "seven-eleven none payment on pickup frozen print password": "7-11 None payment on Pickup Frozen Print password",
    "seven-eleven none payment on pickup frozen shop id": "7-11 None payment on Pickup Frozen Shop Id",
    "seven-eleven none payment on pickup frozen sub shop id": "7-11 None payment on Pickup Frozen Sub Shop Id",
    "seven-eleven none payment on pickup print password": "7-11 None payment on Pickup Print password",
    "seven-eleven none payment on pickup shop id": "7-11 None payment on Pickup Shop Id",
    "seven-eleven none payment on pickup sub shop id": "7-11 None payment on Pickup Sub Shop Id",
    "seven-eleven out of service": "seven-eleven out of service",
    "seven-eleven pay on checkout": "Pay on Checkout",
    "seven-eleven pay on checkout print password": "7-11 Pay on Checkout Print password",
    "seven-eleven pay on checkout shop id": "7-11 Pay on Checkout Shop Id",
    "seven-eleven pay on checkout sub shop id": "7-11 Pay on Checkout Sub Shop Id",
    "seven-eleven pay on pickup": "Pay on Pickup",
    "seven-eleven pay on pickup print password": "7-11 Pay on Pickup Print password",
    "seven-eleven pay on pickup shop id": "7-11 Pay on Pickup Shop Id",
    "seven-eleven pay on pickup sub shop id": "7-11 Pay on Pickup Sub Shop Id",
    "seven-eleven payment on pickup": "7-11 Payment on Pickup",
    "seven-eleven print password": "Print password",
    "seven-eleven return store description": "Please specify the delivery store for package returns. If not specified, it will default to the store where the package was originally sent.",
    "seven-eleven sftp": "7-11 SFTP",
    "seven-eleven sftp frozen": "7-11 SFTP Frozen",
    "seven-eleven sftp frozen password": "7-11 sftp frozen password",
    "seven-eleven sftp frozen username": "7-11 sftp frozen username",
    "seven-eleven sftp password": "7-11 sftp password",
    "seven-eleven sftp username": "7-11 sftp username",
    "seven-eleven shop id": "Shop Id",
    "seven-eleven sub shop id": "Sub Shop Id",
    "seven-eleven waybill": "7-11 Waybill",
    "seven-eleven: out of service": "seven-eleven: out of service",
    "sevenEleven": "7-11",
    "shakeX": "Shake X",
    "shakeY": "Shake Y",
    "share": "Share",
    "shipWithCartItems": "Ship Free Items With Cart Items",
    "shipped": "Shipped",
    "shipping": "Shipping",
    "shipping days": "Shipping Days (Default to 1, If you choose method expected dispatch date,this value is only calculated for email's expected arrival date)",
    "shipping default payment method": "Shipping Default Payment Method",
    "shipping fee": "Shipping Fee",
    "shipping fee adjustment": "Shipping fee adjustment",
    "shipping fee type": "Shipping Fee Type",
    "shipping fee using zones": "Shipping Fee Using Zones",
    "shipping payment method": "Shipping Payment Method",
    "shipping rate": "Shipping Rate",
    "shipping rate method": "Shipping Rate Method",
    "shipping rates": "Shipping Rates",
    "shipping rates warning": "Shipping Rates are incorrect",
    "shipping settings": "Shipping Settings",
    "shipping type": "Shipping Type",
    "shipping zone": "Shipping Zone",
    "shipping zone details": "Shipping Zone Details",
    "shipping zones": "Shipping Zones",
    "shipping zones according to selected cart items": "Shipping Zones According To Selected Cart Items",
    "shipping-settings": "Shipping Settings",
    "shippingStatus_addressChangeRequired": "Address Change Required",
    "shippingStatus_attentionRequired": "Attention Required",
    "shippingStatus_awaitingDispatch": "Awaiting Dispatch",
    "shippingStatus_cancelled": "Cancelled",
    "shippingStatus_completed": "Completed",
    "shippingStatus_delivered": "Delivered",
    "shippingStatus_exception": "Exception",
    "shippingStatus_noWaybill": "Waybill Not Yet Created",
    "shippingStatus_outForDelivery": "Out For Delivery",
    "shippingStatus_readyForPickupAtDestination": "Ready For Pickup At Destination",
    "shippingStatus_readyForPickupAtReturnDestination": "Ready For Pickup At Return Destination",
    "shippingStatus_shipped": "Shipped",
    "shippingStatus_toReturn": "To Return",
    "shippingStatus_waybillCreated": "Waybill Created",
    "shipping_convenience_store": "Convenience Store Shipping Fee",
    "shipping_multiple": "Additional Shipping Fee",
    "shipping_single": "Shipping",
    "shop": "Shop",
    "shop checkout note character limit": "Shop Checkout Note Character Limit",
    "shop filters": "Shop Filters",
    "shop lists": "shop lists",
    "shop name": "Shop name",
    "shop not found": "Shop Not Found",
    "shop or dispatch status is empty!": "Shop or dispatch status is empty!",
    "shop or website": "Shop or Website",
    "shop review": "shop review",
    "shop settings": "Shop Settings",
    "shop url": "Shop Url",
    "shop url (at least 6 characters)": "Shop Url (At Least 6 Characters)",
    "shop url prefix": "Shop Url Prefix",
    "shop->campaigns": "Shop > Campaigns",
    "shop->customer service": "Shop > Customer Service",
    "shop->promo codes": "Shop > Promo Codes",
    "shop->rewards": "Shop > Rewards",
    "shop->shipping zones": "Shop > Shipping Zones",
    "shop->web": "Shop > Web",
    "shop.com": "Shop.com",
    "shop.com characters limit": "Shop.com Characters Limit: ",
    "shop.com instruction": "It can only be activated after you get the Offer_ID and Advertiser_ID from Shop.com",
    "shopDisplaySettings": "Shop Display Settings",
    "shopDotComCommission": "Shop.com Commission",
    "shop_dot_com_notyetsent": "Not yet Sent",
    "shop_dot_com_successful": "Successfully Sent",
    "shop_dot_com_unsuccessful": "Failed to Send",
    "shop_products_overview_style_condensed": "Condensed",
    "shop_products_overview_style_full": "Full",
    "shop_status_expiredHaveCommissionData": "Completed - Have Commission Data",
    "shop_status_expiredNoCommissionData": "Completed - No Commission Data",
    "shop_status_inProgress": "In Progress",
    "shop_status_linked_all": "Linked: All",
    "shop_status_linked_out_of_sync": "Linked: Out of Sync",
    "shop_status_noDate": "Without Date",
    "shop_status_upcoming": "Upcoming",
    "shopping is disallowed": "Shopping is Disallowed",
    "shops": "Shops",
    "short description": "Short Description",
    "should be unique values": "Should Be Unique Values",
    "show activity tab in guest view": "Can see activity tab in guest view",
    "show advanced CSS settings": "Show Advanced CSS Settings",
    "show aging in statements": "show aging in statements",
    "show all": "Show All",
    "show all colors": "Show All Colors",
    "show all customization prices (including 0)": "Show All Customization Prices (Including 0)",
    "show available quantity": "Show Available Quantity",
    "show back to shopping button": "Show Back To Shopping Button",
    "show bot": "Show Bot",
    "show campaign header": "Show campaign header",
    "show commissions tab in guests view": "Show commissions tab in guests view",
    "show contents": "Show Contents",
    "show countdown timer": "Show Countdown Timer",
    "show data for campaign period only": "Show Data For Campaign Period Only",
    "show description": "Show description",
    "show descriptions": "Show descriptions",
    "show discounted price vs original price": "Show Discounted Price vs. Original Price",
    "show estimated delivery date": "Show Estimated Delivery Date",
    "show faqs on footer": "Show Faqs On Footer",
    "show form on checkout": "Show Form On Checkout",
    "show grouped documents from different shops": "Show Grouped Documents from different shops",
    "show grouped documents into one": "Show grouped documents into one",
    "show groups": "Show Groups",
    "show images": "Show Images",
    "show images on documents": "Show Images On Documents",
    "show imported waybills only": "Show Imported Waybills Only",
    "show item code": "Show Item Code",
    "show label \"more\" on product": "show label \"more\" on product",
    "show label more": "show label more",
    "show layer manager": "Show Layer Manager",
    "show less": "Show Less",
    "show limited qty": "Show Limited Qty",
    "show limits": "Show Limits",
    "show logo on menu": "Show Logo On Menu",
    "show more": "Show More",
    "show number of lines for description": "Show Number Of Lines for Description",
    "show on sale tag": "Show On Sale Tag",
    "show one blog per slide on mobile": "Show one blog per slide on mobile",
    "show original price": "Show Original Price",
    "show page settings": "Show Page Settings",
    "show payouts details": "Show Payouts Details",
    "show prices": "Show Prices",
    "show print modal after import": "Show Print Modal After Import",
    "show product code": "Show Product Code",
    "show product code below items": "Show Product Code Below Items",
    "show product code next to items": "Show Product Code Next to Items",
    "show product description": "Show Product Description",
    "show product info": "Show Product Info",
    "show product set with choices": "Show Product Set With Choices",
    "show quick nav": "Show Quick Nav (Bottom Right)",
    "show returns": "Show Returns",
    "show revenue by activity": "Show revenue by Activity",
    "show revenue by channel and shop": "Show revenue by Channel and Shop",
    "show revenue by contact": "Show revenue by Contact",
    "show revenue by payment method": "Show revenue by Payment Method",
    "show revenue by payment method and promo code": "Show revenue by Payment Method and Promo Code",
    "show revenue by product": "Show revenue by Product",
    "show revenue by shop": "Show revenue by Shop",
    "show sales quantity": "Show Sales Quantity",
    "show set details on export tables": "Show bundle details on export tables",
    "show shipping options on dispatching": "Show Shipping Options On Dispatching",
    "show shipping waybills on dispatching": "Show Shipping Waybills On Dispatching",
    "show shop.com commission": "Show Shop.com Commission",
    "show style manager": "Show Style Manager",
    "show tags on filter": "Show tags on filter",
    "show tags on page": "Show tags on page",
    "show total due": "Show Total Due",
    "show total due when printing contact statements": "Show total due when printing contact statements",
    "show total quantity when printing documents": "Show total quantity when printing documents",
    "show unit": "Show Unit",
    "show unit below items": "Show Unit Below Items",
    "show unit next to items": "Show Unit Next to Items",
    "show units": "Show Units",
    "show website name on menu": "Show Website Name On Menu",
    "sigint": "Restart",
    "sign up": "Sign Up",
    "signature": "Signature",
    "signup": "Sign up",
    "signup reward email": "Signup Reward Email",
    "simple": "Simple",
    "simpleEditor_blocks": "Blocks",
    "single delivery date": "Single Delivery date",
    "single discount": "Single Discount",
    "single discount rate": "Single Discount Rate",
    "single time purchase": "Single time purchase",
    "single_month": "Single Month",
    "sinopac": "SinoPac",
    "skip weekends": "Skip Weekends (Saturday/Sunday)",
    "slide": "Slide",
    "slideInDown": "Slide In Down",
    "slider": "Slider",
    "slider active buttons color": "Slider Active Buttons Color",
    "slider buttons color": "Slider Buttons Color",
    "slider frame": "Slider Frame",
    "slides": "Slides",
    "small": "Small",
    "smaller": "Smaller",
    "social links": "Social Links",
    "social-media": "Social Media",
    "sold out": "Sold Out",
    "soldOut": "Sold Out",
    "some": "Some",
    "some items has applicable shipping zones": "Some items has applicable shipping zones",
    "sort by": "Sort By",
    "sort customer order using shop product index": "Sort Customer Order Using Shop Product Index",
    "sort detail row by campaign detail index": "Sort detail row by campaign detail index",
    "sort direction": "Sort Direction",
    "spacing settings": "Spacing Settings",
    "spec": "Spec",
    "spec_detail": "Spec Detail",
    "spec_name": "Spec Name",
    "specialty and local good": "Specialty And Local Good",
    "specify first and last date": "Specify Start And End Date",
    "specs": "Specs",
    "spend reward points": "Spend Reward Points",
    "split": "Split",
    "split from": "Split From",
    "split from original": "Split From Original",
    "split qty": "Split Qty",
    "split_document": "split",
    "split_shipping": "Split",
    "stage": "Stage",
    "stages": "Stages",
    "start": "Start",
    "start date": "Start Date",
    "start from": "Start From",
    "start time": "Start Time",
    "startup": "Startup",
    "statement": "Statement",
    "statement count": "Statement Count",
    "statments": "Statements",
    "stats for campaign": "Stats For Campaign",
    "stats for month": "Stats For Month",
    "stats for year": "Stats For Year",
    "status": "Status",
    "status for when over sales limited qty": "Status For When Over Sales Limited Qty",
    "status will be applied to all schedules for current bulk discount": "Status will be applied to all schedules for current bulk discount",
    "step": "step",
    "stereotyped contract content": "Stereotyped Contract Content",
    "sticky top": "Sticky top",
    "store location": "Store Location",
    "store location address": "Store Location Address",
    "store location pick up": "Store Location Pick Up",
    "store locations": "Store Locations",
    "storeAddress": "Store Address",
    "storeId": "Store ID",
    "storeName": "Store Name",
    "street": "Street",
    "string": {
      "max": "\"{value}\" max length {limit}",
      "min": "\"{value}\" min length {limit}",
      "pattern": {
        "name": "\"{value}\" is not a valid number"
      }
    },
    "styles settings": "Styles Settings",
    "sub account number": "Sub Account Number",
    "sub page": "Sub Page",
    "subject": "Subject",
    "submit": "Submit",
    "submit hct summary": "Submit HCT Summary",
    "subtitle": "Subtitle",
    "subtotal": "Subtotal",
    "success order description": "Customize Description on Success Order",
    "successful": "Successful",
    "suggested": "Suggested",
    "sunday": "Sunday",
    "sunday opening hours": "Sunday Opening Hours",
    "sunny Bank": "Sunny Bank",
    "super": "Super",
    "supplier": "Supplier",
    "supplier account": "Supplier Account",
    "supplier activity": "Supplier Activity",
    "supplier information": "Supplier Information",
    "supplier payment method": "Supplier Payment Method",
    "supplier payment terms": "Supplier Payment Terms",
    "supplier reconciliations": "Supplier Reconciliations",
    "surcharge": "Surcharge",
    "surcharge type": "Surcharge Type",
    "surcharge_floor": "Floor",
    "suspended": "Suspended",
    "swing": "Swing",
    "sync campaigns": "Sync Campaigns",
    "sync date": "Sync Date",
    "sync limit qty with inventory": "Sync limit qty with inventory",
    "sync master to all children": "Sync master to all children",
    "sync product properties": "Sync Product Properties",
    "system generated": "System Generated",
    "systemlead": "Systemlead",
    "systemlead_companyId": "Company Id",
    "systemlead_remind": "Remind when E-Invoices are low",
    "systemlead_remindCount": "Remind when E-Invoices Count is Below Number",
    "systemlead_salt": "Salt",
    "systemlead_showZeroValueItems": "Show Zero Value Items",
    "t-cat": "TCat",
    "t-cat contact": "TCat Contact",
    "t-cat credentials": "TCat Credentials",
    "t-cat custom product name": "TCat Custom Product Name",
    "t-cat default package size": "TCat Default Package Size",
    "t-cat default product type": "TCat Default Product Type",
    "t-cat default thermosphere": "TCat Default Thermosphere",
    "t-cat delivery time": "TCat Delivery Time",
    "t-cat id": "TCat ID",
    "t-cat package size": "TCat Package Size",
    "t-cat print type": "TCat Print Type",
    "t-cat temperature": "TCat Temperature",
    "t-cat thermosphere": "TCat Thermosphere",
    "t-cat token": "TCat Token",
    "t-cat use custom product name": "TCat Use Custom Product Name",
    "t-cat waybill": "TCat Waybill",
    "tab": "Tab Button",
    "tab container": "Tab Container",
    "tab content": "Tab Content",
    "tab contents": "Tab Contents",
    "tab name": "Tab Name",
    "table lookup": "Table Lookup",
    "tablet": "Tablet",
    "tabs": "Tabs",
    "tada": "Tada",
    "tag": "Tag",
    "tags": "Tags",
    "tags_filters_sort": "Filter Display Order",
    "taishin": "TaiShin",
    "taiwan cooperative bank": "Taiwan Cooperative Bank",
    "taiwan percentage": "Taiwan Percentage",
    "tapPay": "Tap Pay",
    "tapPay_3ds": "3DS Verification",
    "tapPay_isBig5": "Only Big5",
    "tapPay_merchant_id": "Merchant Id",
    "tapPay_partner_key": "Partner Key",
    "task": "Task",
    "tax": "Tax",
    "tax %": "Tax %",
    "tax after discount": "Tax After Discount",
    "tax allowed": "Tax Allowed",
    "tax exclusive": "Tax Exclusive",
    "tax inclusive": "Tax Inclusive",
    "tax method": "Tax Method",
    "tax number": "Tax Number",
    "tax on purchases": "Tax On Purchases",
    "tax on sales": "Tax On Sales",
    "tax rate": "Tax Rate",
    "tax settings": "Tax Settings",
    "tax total": "Tax Total",
    "tax_auto": "Auto",
    "tax_companyName": "Company Name (Tax)",
    "tax_exclusive": "Tax Exclusive",
    "tax_inclusive": "Tax Inclusive",
    "tax_none": "None",
    "taxes": "Taxes",
    "tcat_sender_address": "TCat Sender Address",
    "tel": "Tel",
    "telephone": "Telephone",
    "template": "Template",
    "templates": "Templates",
    "terms": "Terms",
    "test code field is only for test purposes. it must be empty in production": "Test Code field is only for test purposes. It must be empty in production",
    "test user": "Test user",
    "text": "Text",
    "text block": "Text Block",
    "text-block": "title with text",
    "text-block-with-subtitle": "Title With Subtitle",
    "text_content": "Content",
    "the company name, {companyName}, does not match the company name for the tax number. Do you want to use {taxNumberCompanyName}?": "the company name, {companyName}, does not match the company name for the tax number. Do you want to use {taxNumberCompanyName}?",
    "the current product is still a draft. The product schedule will not work.": "The current product is still a draft. The product schedule will not work.",
    "the system will schedule a refund after voiding the order": "The system will schedule a refund after voiding the order",
    "themeGeneralLetterSpacing": "General Letter Spacing",
    "themeHeaderH1LetterSpacing": "H1 Letter Spacing",
    "themeHeaderH2LetterSpacing": "H2 Letter Spacing",
    "themeHeaderH3LetterSpacing": "H3 Letter Spacing",
    "themeHeaderH4LetterSpacing": "H4 Letter Spacing",
    "themeHeaderH5LetterSpacing": "H5 Letter Spacing",
    "themeHeaderH6LetterSpacing": "H6 Letter Spacing",
    "themeNavTreeItemMarginX": "Menu Item Margin (left/right)",
    "themeNavTreeItemPaddingX": "Menu Item Padding (left/right)",
    "themes": "Themes",
    "thermal": "thermal",
    "thermal printer": "Thermal Printer",
    "this comment will be public": "This comment will be public",
    "this image exceeds max dimensions": "This image exceeds max dimensions",
    "this is the legacy way to share shop reports. please use the new way": "This is the legacy way to share shop reports. Please use the new way!",
    "this is the new way to share shop reports": "this is the new way to share shop reports",
    "this may already have been dispatched on a previous day- are you sure you want to change its dispatch status": "This may already have been dispatched on a previous day. Are you sure you want to change its dispatch status?",
    "this month": "This Month",
    "this product exists in documents/campaigns! the products will be set as inactive instead": "This product exists in documents/campaigns! the products will be set as inactive instead",
    "this product set is outdated": "This product bundle is outdated",
    "this product set is outdated, click here to change it to the new type (set item prices individually)": "This product set is outdated. Click here to change it to the new type (set item prices individually)",
    "this row contains invoices with partial returns and/or returns without linked invoice": "This row contains invoices with partial returns and/or returns without linked invoice",
    "this status will override all schedule statuses": "This status will override all schedule statuses",
    "this window": "This Window",
    "three per page": "three per page",
    "threeByFour": "3x4",
    "threeByOne": "3x1",
    "threeByTwo": "3x2",
    "threeToFiveDays": "3-5 Days",
    "three_months": "Three Months",
    "threw an error when running default method": "threw an error when running default method",
    "thursday": "Thursday",
    "thursday opening hours": "Thursday Opening Hours",
    "time": "time",
    "times per order": "Times per Order",
    "title": "Title",
    "to": "To",
    "to change please check allow seo settings on website": "To change please check allow seo settings on website",
    "to issue e-invoices": "To Issue E-Invoices",
    "to print": "To Print",
    "to send": "To Send",
    "to send birthday": "To Send Birthday Reward",
    "to send promotion": "To Send Promotion Reward",
    "today": "Today",
    "toggle off all override": "turn off all override toggle",
    "toggles_no": "No",
    "toggles_yes": "Yes",
    "too long": "Too Long",
    "top": "Top",
    "total": "Total",
    "total active contacts": "Total Active Contacts",
    "total adjustment": "Total Adjustment",
    "total amount paid": "Total Amount Paid",
    "total commission": "Total commission",
    "total commission base cost": "Total Commission Base Cost",
    "total commissions due": "Total Commissions Due",
    "total commissions due returned": "Total Commissions Due Returned",
    "total commissions due this month": "Total Commission Due This Month",
    "total commissions generated": "Total Commissions Generated",
    "total commissions generated returned": "Total Commissions Generated Returned",
    "total commissions generated this month": "Total Commissions Generated This Month",
    "total credit card amount": "Total Credit Card Amount",
    "total details without shipping zones": "{total} Items Without Applicable Shipping Zones!",
    "total due": "Total Due",
    "total due after adjustment": "Total Due After Adjustment",
    "total due after payouts": "Total Due After Payouts",
    "total due at": "Total Due At {date}",
    "total inclusive discounted price": "Total Inclusive Discounted Price",
    "total inclusive price": "Total Incl. Price",
    "total limit": "Total Limit",
    "total limited qty": "Total Limited Qty",
    "total orders": "Total Orders",
    "total periods": "Total Periods",
    "total points expiring": "Total Points Expiring",
    "total qty": "Total Qty",
    "total qtys": "Total Qtys",
    "total quantity": "Items Total Quantity",
    "total returns": "Total Returns",
    "total revenue": "Total Revenue",
    "total sales": "Total Sales",
    "total times per person": "Total Times per Person",
    "total_data": "Total",
    "total_dispatched": "Total Dispatched",
    "total_invoices_amount": "Invoices Amount",
    "total_invoices_attentionRequired": "Invoices Attention Required",
    "total_invoices_cancelled": "Invoices Cancelled",
    "total_invoices_pending": "Invoices Pending",
    "total_invoices_pendingCancellation": "Invoices Pending Cancellation",
    "total_invoices_successful": "Invoices Successful",
    "total_rounding_difference_explanation": "Difference in total is due to rounding.",
    "track inventory": "Track Inventory",
    "tracking": "Tracking",
    "tracking code": "Tracking Code",
    "tracking codes": "Tracking Codes",
    "transaction number": "Transaction Number",
    "transaction type": "Transaction Type",
    "transport": "Transport",
    "transporter": "Transporter",
    "transporter (optional)": "Transporter (Optional)",
    "transporter account": "Transporter Account",
    "transporter account (optional)": "Transporter Account (Optional)",
    "transporter account number": "Transporter Account Number",
    "transporter accounts": "Transporter Accounts",
    "transporter credentials": "Transporter Credentials",
    "transporter notes": "Transporter Notes",
    "transporter package temperature": "Transporter Package Temperature",
    "transporter pickup location": "Pickup Location For Transporter",
    "transporter pickup location name": "Pickup Location Name",
    "transporter settings": "Transporter Settings",
    "transporter type": "Transporter Type",
    "transporter-settings": "Transporter Settings",
    "transporterAccount": "Transporter Account",
    "transporterPackageTemperature": "Transporter Package Temperature",
    "transporter_account_short": "Transporter Acc",
    "try it now": "Try It Now",
    "try latest version with latest features (beta)": "Try Latest Version With Latest Features (Beta)",
    "tuesday": "Tuesday",
    "tuesday opening hours": "Tuesday Opening Hours",
    "twEInvoicePrint": "E-Invoice Allow Print",
    "tw_auto_issue_eInvoices": "Auto issue eInvoices",
    "tw_auto_print_invoices": "Default settings to issue E-invoices for manually created documents",
    "tw_auto_print_invoices_for_shop": "Default settings to issue E-invoices for Shop Orders",
    "tw_default_invoices_view": "Default E-invoices View",
    "tw_eInvoice_allow_print": "Allow Print",
    "tw_eInvoice_auto_issue_fourteen_days_after": "14 Days After Dispatch",
    "tw_eInvoice_auto_issue_on_dispatch": "On Dispatch",
    "tw_eInvoice_auto_issue_seven_days_after": "7 Days After Dispatch",
    "tw_eInvoice_carrierType_app": "Mobile",
    "tw_eInvoice_carrierType_b2b": "B2B",
    "tw_eInvoice_carrierType_person": "Citizen Cert",
    "tw_eInvoice_carrier_id": "Carrier Id",
    "tw_eInvoice_carrier_type": "Carrier Type (Optional)",
    "tw_eInvoice_carrier_type_optional": "E-Invoice Type",
    "tw_eInvoice_company_name": "Company Name",
    "tw_eInvoice_print_custom_block": "Print Custom Block",
    "tw_eInvoice_print_custom_block_placeholder": "You are allow the following characters amount per line\\\\n 34 English Character per row\\\\n 17 Chinese Character per row\\\\n NOTE: If you mix both character sets on the same line we cannot guarantee a correct printing layout.\\\\n\n",
    "tw_eInvoice_print_reload": "Reload E-Invoice Printers",
    "tw_eInvoice_provider_bigOrdr": "EcPay",
    "tw_eInvoice_provider_systemlead": "Systemlead",
    "tw_eInvoice_queued_successfully": "Queued Successfully. Invoices will automatically be Issue.",
    "tw_eInvoice_reprint": "Reprint Invoice",
    "tw_eInvoices": "E-Invoices",
    "tw_eInvoices_allocate_numbers": "Allocate E-Invoice Numbers",
    "tw_eInvoices_allow_numbers": "Allowed E-Invoice Track Numbers",
    "tw_eInvoices_allow_numbers_off_warning": "If numbers are not allowed, this row cannot be changed after saving.",
    "tw_eInvoices_attention_required": "Attention Required",
    "tw_eInvoices_available_any": "Non-designated E-Invoices",
    "tw_eInvoices_blankUploaded_successful": "Uploaded",
    "tw_eInvoices_blankUploaded_unsuccessful": "Not Yet Uploaded",
    "tw_eInvoices_book": "Book",
    "tw_eInvoices_captured": "Captured",
    "tw_eInvoices_captured_invoices": "Captured Invoices",
    "tw_eInvoices_captured_returns": "Captured Returns",
    "tw_eInvoices_count": "Count",
    "tw_eInvoices_custom_description": "Description",
    "tw_eInvoices_disallowed": "Disallowed E-Invoices",
    "tw_eInvoices_edit_and_reissue": "Edit and Re-Issue E-Invoice",
    "tw_eInvoices_first_number": "First Number",
    "tw_eInvoices_include_until_current_date": "Include All E-Invoices",
    "tw_eInvoices_issue_results": "E-Invoice Issue Results",
    "tw_eInvoices_last_number": "Last Number",
    "tw_eInvoices_last_number_used": "Last Number",
    "tw_eInvoices_no_allocated": "No Allocated E-Invoice Numbers",
    "tw_eInvoices_no_printer_setup": "Printer has not been setup yet. Please login to BigOrdr printer app.",
    "tw_eInvoices_no_track_numbers": "No E-Invoice Track Numbers",
    "tw_eInvoices_notify_email_address": "E-Invoice Notify Email Address",
    "tw_eInvoices_numbers": "E-Invoice Numbers",
    "tw_eInvoices_numbers_auto_upload_blanks": "System will automatically upload blank numbers at 21:00 on 5th of every odd months",
    "tw_eInvoices_numbers_available_count": "Available E-Invoices",
    "tw_eInvoices_numbers_blank_end": "Blank Numbers End",
    "tw_eInvoices_numbers_blank_numbers": "Blank Numbers",
    "tw_eInvoices_numbers_blank_remaining_unused": "Unused E-Invoice Numbers",
    "tw_eInvoices_numbers_blank_start": "Blank Numbers Start",
    "tw_eInvoices_numbers_blank_upload_all_remaining_unused": "Include All Unused E-Invoice Numbers",
    "tw_eInvoices_numbers_books_end": "Books End",
    "tw_eInvoices_numbers_books_range": "Books Range",
    "tw_eInvoices_numbers_books_start": "Books Start",
    "tw_eInvoices_numbers_current_books_range": "Current Numbers Range",
    "tw_eInvoices_numbers_end": "End",
    "tw_eInvoices_numbers_enter_blanks": "Enter Blank Numbers",
    "tw_eInvoices_numbers_last": "Last",
    "tw_eInvoices_numbers_manager": "Manage E-Invoice Numbers",
    "tw_eInvoices_numbers_next": "Next",
    "tw_eInvoices_numbers_no_available_count": "No Available E-Invoices",
    "tw_eInvoices_numbers_no_available_count_create_docSales_without_eInvoice": "No Available E-Invoices, Should create DocSales without issue an eInvoice?",
    "tw_eInvoices_numbers_remaining": "Remaining Numbers",
    "tw_eInvoices_numbers_start": "Start",
    "tw_eInvoices_numbers_upload_blanks": "Upload Blank Numbers",
    "tw_eInvoices_numbers_upload_blanks_not_uploaded": "Not Yet Uploaded",
    "tw_eInvoices_only_remaining": "Issue All Unallocated E-Invoices",
    "tw_eInvoices_prefix": "Prefix",
    "tw_eInvoices_print_aesKey": "E-Invoice AESKey",
    "tw_eInvoices_print_allow_print": "E-Invoice Allow Print",
    "tw_eInvoices_print_allow_shop": "E-Invoice Allow Print on Shop",
    "tw_eInvoices_printed_count": "Count",
    "tw_eInvoices_printed_pending": "Print Pending",
    "tw_eInvoices_printed_successful": "Printed Successfully",
    "tw_eInvoices_scheduled": "e-invoice scheduled for auto creating tonight: 9:00pm",
    "tw_eInvoices_total_available": "Total Available",
    "tw_eInvoices_total_available_per_printer": "Designated To E-Invoice Printers",
    "tw_eInvoices_uncaptured_total": "Uncaptured Inclusive Total",
    "tw_eInvoicing": "E-Invoices",
    "tw_invoicing": "Invoicing",
    "tw_issue_print_eInvoice": "Issue and Print E-Invoice",
    "tw_print_eInvoices": "Print E-Invoices",
    "tw_print_eInvoices_now": "Issue E-Invoices Now",
    "tw_print_invoices": "Print Invoices",
    "two factor authentication": "Two Factor Authentication",
    "two factor authentication code has been resent": "A new two factor authentication code has been resent to your email",
    "two per page": "two per page",
    "twoByOne": "2x1",
    "twoByThree": "2x3",
    "two_factor_authentication_resendCode": "Resend code",
    "txt verification": "TXT Verification",
    "type": "Type",
    "type delete to delete": "Type DELETE to delete",
    "type_content": "Type something...",
    "typing": "Typing",
    "unable to add new option- please try again later-": "Unable to add new option- Please try again later-",
    "unable to create profile- please try again later-": "Unable to create profile- Please try again later-",
    "unable to update profile- please try again later-": "Unable to update profile- Please try again later-",
    "unable to verify account- please try again later-": "Unable to verify account- Please try again later-",
    "unallocated": "Unallocated",
    "unarchive": "Unarchive",
    "unassigned": "Unassigned",
    "uncaughtException": "Uncaught Exception",
    "underpaid": "Underpaid",
    "undispatched": "Not Yet Dispatched",
    "undispatched or due": "Not Yet Dispatched / Due",
    "undo": "Undo",
    "undo cancel invoice": "Undo Cancel Invoice",
    "undo void": "Undo Void",
    "unfinished": "Unfinished",
    "ungroup_document": "Unmerge",
    "unhandledRejection": "Unhandled Rejection",
    "unhold all": "Unhold All",
    "union bank Of taiwan": "Union Bank Of Taiwan",
    "uniqueTagsOnly": "Show Each Tag Separately (Any single tags matching)",
    "unit": "Unit",
    "units": "Units",
    "unlink": "Unlink",
    "unlock": "Unlock",
    "unpaid total": "Unpaid Total",
    "unprepared": "Unprepared",
    "unprinted": "Unprinted",
    "unrefrigerated": "Normal",
    "unsuccessful": "Unsuccessful",
    "unsuccessfulDetail": "Error: Child items",
    "untag": "Untag",
    "update": "Update",
    "update bulk discount": "Update bulk discount",
    "update failed the data is out of date as it may have been changed by someone else please cancel and try again": "Update failed, the data is out of date as it may have been changed by someone else, please cancel and try again",
    "update failed the data is out of date as it may have been changed by someone else please try again": "Update failed, the data is out of date as it may have been changed by someone else. Please try again.",
    "update hct contact": "Update Hct Contact",
    "update now": "Update Now",
    "update required, please reload": "Update required. Please reload",
    "update seven-eleven contact": "Update 7-11 Contact",
    "update status": "Update Status",
    "update t-cat contact": "Update TCat Contact",
    "update waybill with latest changes. are you sure you want to update?": "Update waybill with latest changes. Are you sure you want to update?",
    "update waybills": "Update Waybills",
    "update waybills with the latest changes. the update will omit any expired waybill. are you sure you want to update?": "Update waybills with the latest changes. The update will omit any expired waybill. Are you sure you want to update?",
    "updated": "Updated",
    "updated date": "Updated Date",
    "updated time": "Updated Time",
    "upload": "Upload",
    "upload files": "Upload Files",
    "upload image": "Upload Image",
    "upload images": "Upload Images",
    "upload images to campaigns": "Upload Images to Campaigns",
    "upload images to products": "Upload Images to Products",
    "upload logo": "Upload Logo",
    "upload status": "Upload Status",
    "uploaded files": "Uploaded Files",
    "uploaded to shops": "Uploaded To Shops",
    "uploading please wait": "uploading please wait...",
    "url": "Url",
    "usage limit": "Total Usage Limit",
    "usage limit per person": "Usage Limit Per Person",
    "usage limits": "Usage Limits",
    "use advanced address": "Use advanced address",
    "use average prices for each product": "Use average price for each product",
    "use campaign period": "Use Campaign Period",
    "use company email address when sending email manually": "Use Company Email Address When Sending Email Manually",
    "use custom date": "Use Custom Date",
    "use custom message for additional free shipping": "Use custom message for additional free shipping",
    "use custom message for quoted rate shipping": "Use custom message for quoted rate shipping",
    "use default auto redirect url from settings": "Use Default Auto Redirect Url From Settings",
    "use default birthday message in rewards": "Use default birthday message in rewards",
    "use default birthday message in settings": "Use default birthday message in settings",
    "use default description": "Use Default Description",
    "use default message in settings": "Use default message in settings",
    "use default products display settings from settings": "Use default products display settings from settings",
    "use default promotion message in rewards": "Use default promotion message in rewards",
    "use default promotion message in settings": "Use default promotion message in settings",
    "use default width": "Use default width",
    "use defaults estimated delivery date in settings": "Use defaults estimated delivery date in settings",
    "use defaults flat fee": "Use Defaults Flat Fee",
    "use defaults from products": "Use Defaults From Products",
    "use defaults from settings": "Use defaults from settings",
    "use defaults shipping zones": "Use Defaults Shipping Zones",
    "use defaults shop price filter": "Use Default Shop Price Filter",
    "use exclusive selling price to calculate commission": "Use exclusive selling price to calculate commission",
    "use expiry date instead": "Use expiry date instead",
    "use inventory qty as product limits": "Use Inventory Qty As Product Limit",
    "use inventory qty as product limits defaults": "Use Inventory Qty As Product Limit Defaults",
    "use normal percentage": "Use normal percentage",
    "use original amount at dispatch date": "Use original amount at dispatch",
    "use password": "Use Password",
    "use payment terms": "Use Payment Terms",
    "use preset commissions to fill commission value": "Use preset commissions to fill commission value",
    "use product set children": "Use Bundle children to calculate commissions",
    "use shipping fee adjustment": "Use shipping fee adjustment",
    "use taiwan percentage": "Use taiwan percentage",
    "use text area": "Use Text Area",
    "use website logo for delivery note": "Use website logo for delivery note",
    "user": "User",
    "user colors": "User Colors",
    "user didnt complete the transaction": "user didnt complete the transaction",
    "user-agreement-settings": "User Agreement Settings",
    "username": "Username",
    "username or email": "Username or Email",
    "users": "Users",
    "users to authorize refunds": "Users to authorize refunds",
    "valid": "Valid",
    "valid input": "Valid Input",
    "valid points": "Valid Points",
    "validate domain": "Validate Domain",
    "validity date": "Validity Date",
    "value based rates": "Value Based Rates",
    "value is required": "Value is Required",
    "variant": "Variant",
    "variant child": "Variant Child",
    "variant children": "Variant Children",
    "variant code": "Variant Code",
    "variant options": "Variant Options",
    "variant selector style": "Variant Selector Style",
    "variant type": "Variant Type",
    "variantOptionsNameChanges": "Will change {originalOptionName} to {newOptionName}",
    "variant_options_save_warning": "You have not finished editing variant options, please complete before saving.",
    "variants": "Variants",
    "vat": "Vat",
    "verified payment": "Verified Payment",
    "version": "Version",
    "vertical": "Vertical",
    "video": "Video",
    "video_source": "Video Source",
    "view": "View",
    "view available placeholders": "View available placeholders",
    "view locked": "View Locked",
    "view original file": "View Original File",
    "view schedules": "View Schedules",
    "view webpage": "View Webpage",
    "virtual": "Virtual Product",
    "virtual account": "Virtual Account",
    "virtual account bank code": "Virtual Account Bank Code",
    "virtual account bank name": "Virtual Account Bank Name",
    "virtual account expiry date": "Virtual Account Expiry Date",
    "virtual account number": "Virtual Account Number",
    "virtual content attachments": "Virtual Content Attachments",
    "virtualAccount": "Virtual Account",
    "virtual_account_from_account": "From account",
    "virtual_account_provider_eSun": "E. Sun 玉山銀行虛擬帳號",
    "virtual_account_provider_ecPay": "EC Pay 綠界科技虛擬帳號",
    "virtual_account_provider_ubot": "Union Bank of Taiwan 聯邦銀行虛擬帳號",
    "virtual_content": "Virtual Content",
    "void": "Void",
    "warnings": "Warnings",
    "waybill": "Waybill",
    "waybill status": "Waybill Status",
    "waybills": "Waybills",
    "waybills created": "Waybills created",
    "waybills first row bottom padding": "Waybills First Row Bottom Padding",
    "waybills last row top padding": "Waybills Last Row Top Padding",
    "waybills not yet created": "Waybills Not Yet Created",
    "waybills per page": "Waybills Per Page",
    "waybills per page b2c": "Waybills Per Page B2C",
    "waybills per page c2c": "Waybills Per Page C2C",
    "waybills-settings": "Waybills Settings",
    "web": "Web",
    "webhook: notify on order URL": "Webhook: Notify On Order URL",
    "webhook_event_sent_failed": "Notify Event Failed",
    "webhook_event_sent_successful": "Notify Event Successful",
    "webhooks: enable notify on order": "Webhooks: Enable Notify On Order",
    "webpage": "Webpage",
    "webpage name": "Webpage Name",
    "webpage seo description": "Webpage SEO Description",
    "webpage seo keywords": "Webpage SEO Keywords",
    "webpage settings": "Webpage Settings",
    "webpage_content": "Webpage Content",
    "webpage_home_page_redirect": "Home Page",
    "webpage_show_breadcrumb": "Show BreadCrumb Navigation",
    "webpage_show_on_filters": "Show In Products Filters",
    "webpage_show_on_filters_short": "Products Filters",
    "webpage_show_on_footer": "Show On Footer",
    "webpage_show_on_footer_short": "Footer",
    "webpage_show_on_menu": "Show On Menu",
    "webpage_show_on_menu_short": "Menu",
    "webpage_template": "Webpage Template",
    "webpages": "Webpages",
    "webpages_content": "Webpage Content",
    "website": "Website",
    "website admin mode": "Website Admin Mode",
    "website info": "Website Info",
    "website template": "Website Template",
    "website url": "Website URL",
    "website url (at least 6 characters)": "Website Url (At Least 6 Characters)",
    "websiteForAllCampaigns": "Campaigns Website",
    "website_action_color": "Action Button Color",
    "website_action_final_color": "Action Final Color",
    "website_action_final_hover_color": "Action Final Hover Color",
    "website_action_final_text": "Checkout Button Text",
    "website_action_final_text_color": "Action Final Text Color",
    "website_action_hover_color": "Hover Color",
    "website_action_text_color": "Action Button Text Color",
    "website_active_color": "Selected Color",
    "website_background_primary_color": "Primary Background Color",
    "website_background_secondary_color": "Secondary Background Color",
    "website_border_color": "Border Color",
    "website_button_border_color": "Button Border Color",
    "website_button_border_color2": "Button border color 2",
    "website_button_border_color3": "Button border color 3",
    "website_button_border_radius": "Button Border Radius",
    "website_button_border_radius_2": "Button Border Radius 2",
    "website_button_border_radius_3": "Button Border Radius 3",
    "website_button_color": "Button Color",
    "website_button_color2": "Button color 2",
    "website_button_color3": "Button color 3",
    "website_button_hover_color": "Button Hover Color",
    "website_button_hover_color2": "Button hover color 2",
    "website_button_hover_color3": "Button hover color 3",
    "website_button_hover_text_color": "Button Hover Text Color",
    "website_button_hover_text_color2": "Button Hover Text Color 2",
    "website_button_hover_text_color3": "Button Hover Text Color 3",
    "website_button_text_color": "Button Text Color",
    "website_button_text_color2": "Button text color 2",
    "website_button_text_color3": "Button text color 3",
    "website_campaign_header": "Campaign Header",
    "website_campaign_header_background_color": "Campaign Header Background Color",
    "website_campaign_header_notice_color": "Campaign Header Notice Color",
    "website_campaign_header_subtitle_color": "Campaign Header Subtitle Color",
    "website_campaign_header_title_color": "Campaign Header Title Color",
    "website_chat_button_color": "Chat Button Color",
    "website_chat_button_hover_color": "Chat Button Hover Color",
    "website_chat_button_text_color": "Chat Button Text Color",
    "website_count_down_color": "Count Down Color",
    "website_count_down_text_color": "Countdown Text Color",
    "website_faqs_answer_color": "FAQs Answer Color",
    "website_faqs_question_color": "FAQs Question Color",
    "website_faqs_question_hover_color": "FAQs Question Hover Color",
    "website_footer_background_color": "Footer Background Color",
    "website_footer_background_primary_color": "Footer Background Color",
    "website_footer_header_color": "Website Footer Header Color",
    "website_footer_link_color": "Footer Link Color",
    "website_footer_link_hover_color": "Footer Link Hover Color",
    "website_footer_message": "Footer Message",
    "website_footer_text_color": "Footer Text Color",
    "website_headers": "Custom HTML Head Content",
    "website_input_background_color": "Input Background Color",
    "website_input_border_color": "Input Border Color",
    "website_input_border_focus_color": "Input Border Focus Color",
    "website_input_text_color": "Input Text Color",
    "website_link_color": "Link Color",
    "website_link_hover_color": "Link Hover Color",
    "website_menu_background_primary_color": "Menu Background Color",
    "website_menu_background_secondary_color": "Menu Background Secondary Color",
    "website_menu_border_color": "Menu Border Color",
    "website_menu_button_color": "Menu Button Color",
    "website_menu_button_hover_color": "Menu Button Hover Color",
    "website_menu_item_border_color": "Menu Item Divider Color(Vertical  Only)",
    "website_menu_link_color": "Menu Text Color",
    "website_menu_link_hover_color": "Menu Text Hover Color",
    "website_menu_type": "Type",
    "website_original_price_text_color": "Original Price Text Color",
    "website_primary_color": "Primary Color",
    "website_sale_color": "On Sale Tag Color",
    "website_sale_price_text_color": "On Sale Price Text Color",
    "website_sale_text_color": "On Sale Tag Text Color",
    "website_scripts": "Javascript",
    "website_section_action": "Add To Cart Button",
    "website_section_action_final_btn": "Checkout Button",
    "website_section_backgrounds": "Backgrounds",
    "website_section_button_1": "Button 1",
    "website_section_button_2": "Button 2",
    "website_section_button_3": "Button 3",
    "website_section_buttons": "Buttons",
    "website_section_carousel_banner": "Carousel Banner",
    "website_section_carousel_banner_color": "Carousel Banner Background Color",
    "website_section_carousel_banner_link_hover_color": "Carousel Banner Link Hover Color",
    "website_section_carousel_banner_text_color": "Carousel Banner Link Text Color",
    "website_section_chat": "Line/FB Chat",
    "website_section_count_down": "Countdown",
    "website_section_faqs": "FAQs",
    "website_section_footer": "Footer",
    "website_section_inputs": "Inputs",
    "website_section_links": "Links",
    "website_section_nav": "Menu",
    "website_section_other": "Other",
    "website_section_sales": "Promotions",
    "website_section_submenu": "Submenu",
    "website_section_tags": "Tags",
    "website_section_text": "Text",
    "website_section_text_basic": "Basic",
    "website_section_variant_button": "Variant Button",
    "website_seo_broken_links": "Old Broken Links",
    "website_seo_description": "SEO Description",
    "website_seo_keywords": "SEO Keywords",
    "website_seo_url_redirects": "Website Redirect Urls",
    "website_submenu_background_primary_color": "Submenu Background Color",
    "website_submenu_background_secondary_color": "Submenu Background Secondary Color",
    "website_submenu_border_color": "Submenu Border Color",
    "website_submenu_item_border_color": "Submenu Item Divider Color(Vertical  Only)",
    "website_submenu_link_color": "Submenu Text Color",
    "website_submenu_link_hover_color": "Submenu Text Hover Color",
    "website_tag_color": "Tag Color",
    "website_tag_text_color": "Tag Text Color",
    "website_text_2_color": "Text 2 Color",
    "website_text_3_color": "Text 3 Color",
    "website_text_color": "Text Color",
    "website_type_category": "Product Category",
    "website_type_link": "Link",
    "website_type_link_blogs": "Blogs",
    "website_type_link_flows": "Flows",
    "website_type_link_news": "News",
    "website_type_link_page": "Page",
    "website_type_link_pageAndCategory": "Page And Category",
    "website_type_link_product": "Product",
    "website_type_link_store-location": "Store Locations",
    "website_type_link_url": "Url",
    "website_type_page": "Page",
    "website_variant_button_border_color": "Variant Button Border Color",
    "website_variant_button_border_radius": "Variant Button Border Radius",
    "website_variant_button_color": "Variant Button Color",
    "website_variant_button_hover_border_color": "Variant Button Hover Border Color",
    "website_variant_button_hover_color": "Variant Button Hover Color",
    "website_variant_button_hover_text_color": "Variant Button Hover Text Color",
    "website_variant_button_selected_border_color": "Variant Button Selected Border Color",
    "website_variant_button_selected_color": "Variant Button Selected Color",
    "website_variant_button_selected_text_color": "Variant Button Selected Text Color",
    "website_variant_button_text_color": "Variant Button Text Color",
    "websites": "Websites",
    "wednesday": "Wednesday",
    "wednesday opening hours": "Wednesday Opening Hours",
    "weekday1": "Monday",
    "weekday2": "Tuesday",
    "weekday3": "Wednesday",
    "weekday4": "Thursday",
    "weekday5": "Friday",
    "weekday6": "Saturday",
    "weekday7": "Sunday",
    "weeks": "Weeks",
    "weight": "Weight",
    "weight based rates": "Weight Based Rates",
    "weight need to be set from each child items": "Weight need to be set from each child items",
    "weight unit": "weight Unit",
    "welcome to bigordr": "Welcome to BigOrdr!",
    "width": "Width",
    "width height ratio": "Width Height Ratio",
    "will be autocompleted": "Will Be Autocompleted",
    "will not export campaigns without start and end date": "Will not export campaigns without start and end date!",
    "win percentage": "Win Percentage",
    "with grand totals": "with Grand Totals",
    "with prices": "With Prices",
    "without prices": "Without Prices",
    "wizard": "Wizard",
    "wizard pending": "PENDING...",
    "wizard success": "SUCCESS!",
    "wizard_start": "Start",
    "wizards": "Wizards",
    "wobble": "Wobble",
    "workflow": "Workflow",
    "workflows": "Workflows",
    "workflows_items_activities_attentionRequired": "Attention Required",
    "workflows_items_activities_authorization": "Authorization",
    "workflows_items_activities_call": "Call",
    "workflows_items_activities_email": "Email",
    "workflows_items_activities_event": "Event",
    "workflows_items_activities_meeting": "Meeting",
    "workflows_items_activities_notes": "Notes",
    "workflows_items_activities_refund": "Refund",
    "workflows_items_activities_review": "Review",
    "workflows_items_activities_todo": "Todo",
    "workflows_items_activity": "Activity",
    "workflows_items_status_open": "Open",
    "workflows_items_status_re-open": "Re-Open",
    "workflows_items_status_successful": "Successful",
    "workflows_items_status_unsuccessful": "Unsuccessful",
    "year": "Year",
    "years": "Years",
    "years_old": "Years",
    "yes": "Yes",
    "you are currently using the new version (beta)": "You are currently using the new version (beta)",
    "you have less than to issue": "you have less than {availableCountToIssue} eInvoices to issue",
    "youtube": "YouTube",
    "youtube channel url": "Youtube Channel Url",
    "zone priority": "Zone Priority",
    "zones fee": "Zones Fee",
    "zoomIn": "Zoom In"
  };
});