define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/component", ["exports", "ramda", "client/mixins/crud", "ember-concurrency", "client/utils/nventor", "ramda-extension", "ramda-adjunct"], function (_exports, R, _crud, _emberConcurrency, _nventor, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj; // import jQuery from 'jquery'
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, _obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    server: Ember.inject.service(),
    constants: Ember.inject.service(),
    uploadcare: Ember.inject.service(),
    productsService: Ember.inject.service('products'),
    campaignsService: Ember.inject.service('channels/shop/campaigns'),
    campaignsProducts: Ember.inject.service(),
    big: Ember.inject.service(),
    date: Ember.inject.service(),
    tagName: 'div',
    classNames: ['col-container'],
    campaignFilters: null,
    isEditing: false,
    isNew: false,
    translate: true,
    allowUploads: true,
    activeTemplateIndex: null,
    productsFiltersAdapterName: 'products/filters',
    willDestroyElement() {
      if (this?.isDestroyed === false) {
        if (this?.model?.isDirty) {
          this.crud.cancelEditing({
            adapterName: this?.model?.adapterName,
            dirty: this?.model
          });
        }
      }
    },
    didInsertElement() {
      this._super(...arguments);
      this.initTask.perform();
    },
    initTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      tab.resetTabs();
      this.set('model', null);
      this.crud.addLists(this, ['users', 'toggles/auto', 'toggles/onOff', 'channels/shop/campaigns/deliveryDateSettings', 'channels/shop/campaigns/deliveryDateSettingsItems', 'channels/shop/campaigns/overSalesLimitedQtyStatuses', 'channels/shop/campaigns/lists/itemEditorSubPanels', 'products/lists/tags', 'products/lists/display-tags', 'products/lists/product-display-position', 'extensions/apps/tw/invoicingAuto', 'shipping/feeType', 'commissions/methods', 'statuses', 'products/lists/pageImportBtnSubPanels', 'products/lists/sync']);
      if (this.isAutomations) {
        this.crud.addLists(this, ['channels/shop/campaigns/automations/statuses']);
      } else {
        this.crud.addLists(this, ['channels/shop/campaigns/statuses']);
      }
      this.setupNew();
      yield this.setupEditingTask.perform();
      if (this.isReadonly) {
        this.set('model', this.item);
      }
      const campaignFilters = this.campaignsService.getSearchFilters();
      Ember.set(this, 'campaignFilters', campaignFilters);
      Ember.set(this, 'selectedCampaign', this.campaign);
      const templateModel = this.model.getData('templates').get('firstObject');
      this.selectTemplate(templateModel);
    }),
    setupNew() {
      const isNew = this.isNew;
      if (isNew) {
        const campaign = this.campaign;
        const adapterName = this.campaignDetailAdapterName;
        const model = this.crud.setupNewRecord({
          adapterName
        });
        const master = campaign.getData('_key');
        const overSalesLimitedQtyStatus = campaign.getData('overSalesLimitedQtyStatus');
        model.setData('master', master);
        model.setData('index', '');
        model.setData('overSalesLimitedQtyStatus', overSalesLimitedQtyStatus);
        if (this.isAutomations) {
          this._setupIsAutomations(model);
        } else {
          this._setupIsCampaignDetail(model, campaign);
        }
        Ember.set(this, 'model', model);
        if (this.loadProduct) {
          this.campaignsProducts.loadProductTask.perform(campaign, model, this.loadProduct);
        }
      }
    },
    campaignOrAutomationModel: Ember.computed('isAutomations', 'campaign._data._key', function () {
      const isAutomations = this.isAutomations || false;
      if (isAutomations) {
        return this.automationModel;
      }
      return this.campaign;
    }),
    subPanels: Ember.computed('crud.lists.{channels-shop-campaigns-lists-itemEditorSubPanels.[],products-lists-sync.[]}', 'model._data.productSync.[]', function () {
      let subPanels = R_.dotPath('crud.lists.channels-shop-campaigns-lists-itemEditorSubPanels')(this) || [];
      const isAutomations = this.isAutomations || false;
      const productSync = this.model?._data?.productSync || [];
      subPanels = R.filter(panel => {
        if (panel?._data?.value === 'basic') {
          return true;
        }

        // @TODO: remove this once limits for automations are implemented on the backend
        if (panel?._data?.value === 'productLimits' && isAutomations) {
          return false;
        }
        if (panel?._data?.value === 'customizations' && RA.isNilOrEmpty(this.model?._data?.customizations)) {
          return false;
        }
        if (RA.isNilOrEmpty(panel?._data?.disallowedFor)) {
          return true;
        }
        const isDisallowed = R_.containsAll(panel?._data?.disallowedFor || [], productSync);
        if (isDisallowed) {
          return false;
        }
        return true;
      })(subPanels);
      return subPanels;
    }),
    _setupIsAutomations(model) {
      const automationModel = this.automationModel;
      const automationKey = automationModel?._data?._key;
      model.setData('automationKey', automationKey);
      const campaign = this.campaign;
      const campaignKey = campaign._data._key;
      model.setData('master', campaignKey);
    },
    _setupIsCampaignDetail(model, campaign) {
      const onSale = campaign.getData('onSale');
      model.setData('onSale', onSale);
    },
    setupEditingTask: (0, _emberConcurrency.task)(function* () {
      const campaign = this.campaign;
      const isEditing = this.isEditing;
      const editingDefaults = this.editingDefaults;
      if (isEditing) {
        const model = this.item;
        const adapterName = this.campaignDetailAdapterName;
        const dirty = this.crud.setupDirty({
          adapterName,
          model: model
        });
        R.mapObjIndexed((value, key) => {
          dirty.setData(key, value);
        })(editingDefaults);
        if (R_.dotPathEq('_data.isSetWithChoices', true, dirty)) {
          R.forEach(choice => {
            const isSetPriceOnBundle = R.pathOr('', ['_data', 'isSetPriceOnBundle'], choice);
            if (!isSetPriceOnBundle) {
              choice.setData('isSetPriceOnBundle', false);
            }
          })(_nventor.default.ensurePathOr([], ['_data', 'choices'], dirty));
        }
        const noIsSetNew = R.pipe(R_.dotPath('_data.isSetNew'), RA.isNilOrEmpty)(dirty);
        if (R_.dotPathEq('_data.isSet', true, dirty) && noIsSetNew) {
          dirty.setData('isSetNew', false);
        }
        yield this.campaignsProducts.reSyncProductTask.perform(this.campaignOrAutomationModel, dirty, model?._data?.itemKey);
        const detailData = dirty.serialize();
        const response = yield this.server.call('PATCH', 'api/protected/channels/shop/campaigns/details/limits/to-update', detailData);
        let limitsSortedByItem = response?.data || [];
        limitsSortedByItem = R.map(limit => {
          const limitDirty = this.crud.setupNewRecord({
            adapterName: 'channels/shop/campaigns/details/limits',
            data: limit
          });
          limitDirty.product = limit.product;
          return limitDirty;
        })(limitsSortedByItem);
        dirty.set('dirtyLimitsSortedByItem', limitsSortedByItem);
        this.set('model', dirty);
      }
    }),
    cancel() {
      const model = this.model;
      let dirtyLimitsSortedByItem = model?.dirtyLimitsSortedByItem ?? [];
      R.map(limit => {
        return this.crud.cancelEditing({
          adapterName: 'channels/shop/campaigns/details/limits',
          dirty: limit
        });
      })(dirtyLimitsSortedByItem);
      const adapterName = this.campaignDetailAdapterName;
      this.crud.cancelEditing({
        adapterName,
        dirty: model
      });
      if (this.onCancel) {
        const originalModel = this.item;
        this.onCancel(originalModel);
      }
    },
    bulkDiscountSchedule: Ember.computed('model._data.bulkDiscountId', 'campaign._data.bulkDiscounts.@each.synced', function () {
      const model = this.model;
      const campaign = this?.campaign || {};
      if (RA.isNilOrEmpty(model)) {
        return {};
      }
      const bulkDiscountSchedule = model.getBulkDiscountSchedule({
        campaign
      });
      return {
        hasAnyActive: bulkDiscountSchedule.hasAnyActive,
        hasIsActive: bulkDiscountSchedule.hasIsActive,
        hasIsActiveNext: bulkDiscountSchedule.hasIsActiveNext,
        isActive: bulkDiscountSchedule.isActive,
        isActiveNext: bulkDiscountSchedule.isActiveNext
      };
    }),
    _isEditing: Ember.computed('isEditing', function () {
      // this is only needed for old components.
      // isEditing may be passed in from outside the component and set inside the component.
      // this will need to be refactored
      if (this.isEditing) {
        return true;
      }
      return false;
    }),
    isReadonly: Ember.computed('isEditing', 'isNew', 'saveTask.isRunning', function () {
      if (this.get('saveTask.isRunning')) {
        return true;
      }
      if (this.get('isEditing') === true) {
        return false;
      }
      if (this.get('isNew') === true) {
        return false;
      }

      // @TODO: QUICK FIX...
      if (this?.model?.isDirty === true) {
        return false;
      }
      return true;
    }),
    setDetailsData: Ember.computed('model._data.setDetails.@each._data', function () {
      const detailsData = R.pipe(R_.dotPathOr([], 'model._data.setDetails'), R.map(detail => {
        const data = R_.dotPathOr('', '_data')(detail);
        return data;
      }))(this);
      return detailsData;
    }),
    _filterByProductTags(zone, itemTags) {
      const allowedProductTagsInZone = R_.dotPathOr([], '_data.productTags')(zone);
      const deniedProductTagsInZone = R_.dotPathOr([], '_data.denyProductTags')(zone);
      let allowedItemsByProductTagsInZone = R_.containsAny(itemTags, allowedProductTagsInZone);
      if (RA.isNilOrEmpty(allowedProductTagsInZone)) {
        allowedItemsByProductTagsInZone = true;
      }
      let deniedItemsByProductTagsInZone = R_.containsAny(itemTags, deniedProductTagsInZone);
      if (RA.isNilOrEmpty(deniedProductTagsInZone)) {
        deniedItemsByProductTagsInZone = false;
      }
      return R.and(R.equals(true)(allowedItemsByProductTagsInZone), R.equals(false)(deniedItemsByProductTagsInZone));
    },
    // overSalesLimitedQtyStatusClass: computed('model._data.overSalesLimitedQtyStatus', function () {
    //   const status = this.get('model._data.overSalesLimitedQtyStatus')
    //   return this.get('productsService').getStatusClass(status)
    // }),

    afterReplaceRecordTask() {
      if (this.onDone) {
        this.onDone();
      }
    },
    afterCreateRecordTask() {
      if (this.onDone) {
        this.onDone();
      }
    },
    afterCancelEditing() {
      if (this.onDone) {
        this.onDone();
      }
    },
    updateContent(template) {
      this.model.setData('template', template);
    },
    selectTemplate(templateModel = false) {
      const templates = this.model?._data?.templates || [];
      let index = templates?.indexOf(templateModel) || 0;
      if (index < 0) {
        index = 0;
      }
      this.set('activeTemplateIndex', index);
    },
    saveAsDraftTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      model,
      onAfter
    }) {
      model.setData('status', this.get('constants.productsStatus.draft'));
      yield this.replaceRecordTask.perform({
        adapterName,
        model,
        onAfter
      });
    }),
    actions: {
      // replaceTemplate (onCloseDialogAction, template, templateImages = []) {
      //   const model = this.get('model')
      //   model.replaceTemplate(template, templateImages)
      //   onCloseDialogAction()
      // },

      // insertTemplate (onCloseDialogAction, template, templateImages = []) {
      //   const model = this.get('model')
      //   model.insertTemplate(template, templateImages)
      //   onCloseDialogAction()
      // },

      showBulkDiscountConflictsModal(saved) {
        if (!saved.getData('hasBulkDiscountConflictDetails')) {
          this.onDone();
        } else {
          const model = this.model;
          model.set('isShowBulkDiscountConflictDialogInDetailEditor', true);
        }
      },
      edit() {
        this.set('isEditing', true);
        this.setupEditingTask.perform();
      },
      cancel() {
        this.cancel();
      },
      updateContent(template) {
        this.model.setData('template', template);
      },
      onSubmitDeleteProductDisplayTags(productDisplayTag) {
        const productDisplayTags = this.model.getData('productDisplayTags');
        productDisplayTags.removeObject(productDisplayTag);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "updateContent", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateContent"), _obj), _applyDecoratedDescriptor(_obj, "selectTemplate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectTemplate"), _obj), _obj));
});