define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/basic-editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, R_, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsCampaignsBasicEditorInformationEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('modals'), _dec4 = Ember.inject.service('products'), _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember.computed('args.model._data.productSync.[]'), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _class = class ChannelsShopCampaignsCampaignsBasicEditorInformationEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "campaignsProducts", _descriptor2, this);
      _initializerDefineProperty(this, "modalsService", _descriptor3, this);
      _initializerDefineProperty(this, "productsService", _descriptor4, this);
      _initializerDefineProperty(this, "search", _descriptor5, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor6, this);
      _initializerDefineProperty(this, "productsList", _descriptor7, this);
      _initializerDefineProperty(this, "productsFilters", _descriptor8, this);
      _initializerDefineProperty(this, "productsFiltersAdapterName", _descriptor9, this);
      _initializerDefineProperty(this, "showPricelistsAfterChangeItem", _descriptor10, this);
      _initializerDefineProperty(this, "isShowPriceModal", _descriptor11, this);
      this.crud.addLists(this, ['users', 'toggles/auto', 'toggles/onOff', 'channels/shop/campaigns/deliveryDateSettings', 'channels/shop/campaigns/deliveryDateSettingsItems', 'channels/shop/campaigns/overSalesLimitedQtyStatuses', 'channels/shop/campaigns/lists/itemEditorSubPanels', 'products/lists/tags', 'products/lists/display-tags', 'products/lists/product-display-position', 'extensions/apps/tw/invoicingAuto', 'shipping/feeType', 'commissions/methods', 'statuses', 'products/lists/pageImportBtnSubPanels', 'products/lists/sync']);
      const isAutomations = this.args?.isAutomations;
      if (isAutomations) {
        this.crud.addLists(this, ['channels/shop/campaigns/automations/statuses']);
      } else {
        this.crud.addLists(this, ['channels/shop/campaigns/statuses']);
      }
      this.productsList = [];
      let excludes = [];
      if (isAutomations) {
        excludes = [{
          key: 'setType',
          value: 'isSetWithChoices'
        }, {
          key: 'setType',
          value: 'hasVariants'
        }];
      }
      const productsFilters = this.search.setupFilters({
        adapterName: this.productsFiltersAdapterName,
        defaultFiltersData: {
          resultsProperty: 'productsList',
          excludes,
          count: 20,
          page: 1
        }
      });
      this.productsFilters = productsFilters;
      const automationModel = this.args?.automationModel || {};
      const isAutomationMustAdd = automationModel?.isAutoAdd || false;
      if (isAutomationMustAdd) {
        const model = this.args?.model || {};
        model.setData('priceDiscounted', '0');
        model.setData('onSale', 'on');
        if (model.getData('isSet') || model.getData('isSetNew')) {
          const setDetails = model?.getData('setDetails') || [];
          R.forEach(detailChild => {
            detailChild.setData('priceDiscounted', '0');
            detailChild.setData('priceFinal', '0');
            detailChild.setData('onSale', 'on');
          })(setDetails);
        }
      }
    }
    get isPriceReadonly() {
      const isReadonly = this.args?.isReadonly;
      if (isReadonly) {
        return isReadonly;
      }
      const model = this.args?.model || {};
      if (model?._data?.isSet) {
        if (model?._data?.isSetNew) {
          return true;
        }
        return false;
      }
      if (model?._data?.isSetWithChoices) {
        return true;
      }
      return false;
    }
    get isShowPriceSelectorDialog() {
      return this.args?.showPricelists || this?.showPricelistsAfterChangeItem || false;
    }
    get campaignOrAutomationModel() {
      const isAutomations = this.args?.isAutomations || false;
      if (isAutomations) {
        return this.args?.automationModel;
      }
      return this.args.campaign;
    }
    get showItemInput() {
      if (this.args.isAutomations) {
        return true;
      }
      const productSync = this.args?.model?._data?.productSync || [];
      if (R.includes('item', productSync)) {
        return false;
      }
      return true;
    }
    *searchProductsTask({
      adapterName = 'products',
      query
    }) {
      if (query?.trim?.() !== '') {
        // const model = this.get('model')
        // const contact = model.getData('contact')
        // const contactKey = model.getData('contactKey')
        // const docType = this.get('docType')
        // const docName = this.get('docName')
        // const salesPersons = this.get('hasSalesPersons');
        // get search term
        const campaign = this?.args?.campaign || {};
        const campaignKey = campaign?._data?._key;
        const filters = this.productsFilters;
        if (this?.args?.isAutomations) {
          const automationModel = this?.args?.automationModel || {};
          const automationKey = automationModel?._data?._key;
          filters.setData('isAutomation', true);
          filters.setData('automationKey', automationKey);
        } else {
          filters.setData('isAutomation', false);
        }

        // filters.setData('query', query)
        let results = [];
        if (campaignKey) {
          results = yield this.crud.searchInputTask.perform({
            adapterName,
            appendPath: `/campaigns/${campaignKey}`,
            filters
          }, query);
          this.productsList = results;
        }
        return results;
      }
    }
    *selectProductTask(campaignOrAutomationModel, model, selectedProduct) {
      yield this.campaignsProducts.loadProductTask.perform(campaignOrAutomationModel, model, selectedProduct);
      Ember.set(this, 'showPricelistsAfterChangeItem', true);
    }
    *fetchPriceHistoryTask(itemModel = {}) {
      const campaign = this.args?.campaign || {};
      const automationModel = this.args?.automationModel || {};
      const isAutomations = this.args?.isAutomations || false;
      let item = this.args?.model || {};
      if (_ramdaAdjunct.default.isNotNilOrEmpty(itemModel)) {
        item = itemModel;
      }
      const product = this.args?.product || {};
      const campaignKey = campaign?._data?._key;
      const itemKey = item?._data?.itemKey;
      const isSet = item?._data?.isSet || item?._data?.isSetNew || false;
      const isSetWithChoices = item?._data?.isSetWithChoices || false;
      const thenAction = automationModel?._data?.thenAction;
      const priceHistory = yield this.campaignsProducts.fetchHistoryCampaignsTask.perform(product, itemKey, isSet, isSetWithChoices, campaignKey, {
        isAutomation: isAutomations,
        thenAction
      });
      return priceHistory;
    }
    resetItem(model) {
      model.resetDetail();
    }
    showPriceModal(
      /* modalDialogComponent */
    ) {
      this.isShowPriceModal = true;
    }
    updateOnSale(model) {
      if (model.isStandardProduct) {
        return;
      }
      let isOnSale = model?.isOnSale;
      if (isOnSale === true) {
        isOnSale = 'on';
      } else {
        isOnSale = 'off';
      }
      model.setData('onSale', isOnSale);
      const priceDiscounted = model.getData('priceDiscounted') || '';
      if (isOnSale === 'on' && !priceDiscounted) {
        const activeOnSaleSchedules = R.pipe(R.pathOr([], ['_data', 'onSaleSchedules']), R.find(R.propEq('isActive', true)))(model) || {};
        const scheduledPriceDiscounted = activeOnSaleSchedules?._data?.priceDiscounted || '0';
        model.setData('priceDiscounted', scheduledPriceDiscounted);
      }
    }
    toggleIsSetNew(child, model) {
      child.setData('isSetNew', true);
      model.recalculatePriceForSet(child, model);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "campaignsProducts", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modalsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "productsService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "productsList", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "productsFilters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "productsFiltersAdapterName", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'products/filters';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showPricelistsAfterChangeItem", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isShowPriceModal", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showItemInput", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "showItemInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchProductsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchProductsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectProductTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "selectProductTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchPriceHistoryTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchPriceHistoryTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetItem", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "resetItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showPriceModal", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "showPriceModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateOnSale", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "updateOnSale"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleIsSetNew", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "toggleIsSetNew"), _class.prototype), _class);
});