define("client/pods/components/elements/element-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "b1H68NAs",
    "block": "{\"symbols\":[\"isTooltipVisible\",\"@tooltipLabel\",\"&default\",\"@isTooltipAlwaysVisible\",\"@isRunning\"],\"statements\":[[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[23,5,[]]]],{\"statements\":[[0,\"  \"],[5,\"helpers/info-tooltip\",[],[[\"@customBlock\",\"@clickToView\",\"@isAlwaysVisible\"],[true,true,[23,4,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"      \"],[14,3],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isStacked\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[11,\"class\",[22,\"stackedClassNames\"]],[8],[0,\"\\n        \"],[7,\"i\",true],[11,\"class\",[22,\"iconTop\"]],[8],[9],[0,\"\\n        \"],[7,\"i\",true],[11,\"class\",[22,\"iconBottom\"]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"i\",true],[11,\"class\",[29,[[22,\"_icon\"],\" \",[22,\"iconClassNames\"]]]],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[4,\"helpers/info-tooltip-content\",null,[[\"closeOnOverlayClick\",\"hasModalOverlay\",\"isViewable\",\"hasContent\"],[true,false,[23,1,[]],[23,2,[]]]],{\"statements\":[[0,\"        \"],[1,[28,\"tt\",[[23,2,[]]],[[\"translate\"],[[23,0,[\"_tooltipTranslate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-icon/template.hbs"
    }
  });
});