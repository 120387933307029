define("client/pods/components/products/panels-display/email-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gEnBZdWM",
    "block": "{\"symbols\":[\"@tab\",\"@model\",\"@isReadonly\",\"@isDisabled\",\"@resourceList\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n    \"],[5,\"products/products-editor/email-editor\",[],[[\"@tab\",\"@model\",\"@isReadonly\",\"@isDisabled\",\"@resourceList\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-display/email-display/template.hbs"
    }
  });
});