define("client/pods/components/elements/element-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['element-icon'],
    classNameBindings: ['iconClassName'],
    stackedClassNames: 'fa-stack fa-1x',
    iconTop: '',
    iconBottom: '',
    handleMouseEnter(self) {
      self.set('isHover', true);
    },
    handleMouseLeave(self) {
      self.set('isHover', false);
    },
    didInsertElement() {
      this._super(...arguments);
      this.element.addEventListener('mouseenter', this.handleMouseEnter(this));
      this.element.addEventListener('mouseleave', this.handleMouseLeave(this));
    },
    willDestroyElement() {
      if (this?.isDestroyed === false) {
        this._super(...arguments);
        this.element.removeEventListener('mouseenter', this.handleMouseEnter);
        this.element.removeEventListener('mouseleave', this.handleMouseLeave);
      }
    },
    _tooltipTranslate: Ember.computed('tooltipTranslate', function () {
      const tooltipTranslate = this.get('tooltipTranslate');
      if (tooltipTranslate === false) {
        return false;
      }
      if (tooltipTranslate === true) {
        return true;
      }
      return this.translate;
    }),
    _icon: Ember.computed('icon', 'isHover', function () {
      if (this.isHover) {
        return this.hoverIcon || this.icon;
      }
      return this.icon;
    })
  });
});