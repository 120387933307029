define("client/pods/components/documents/btn-merge/component", ["exports", "ramda", "ember-concurrency", "client/utils/nventor", "client/mixins/crud"], function (_exports, R, _emberConcurrency, _nventor, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, {
    tagName: '',
    label: '',
    bigService: Ember.inject.service('big'),
    init() {
      this._super(...arguments);
      const mergeModel = Ember.Object.create({
        documents: []
      });
      this.set('mergeModel', mergeModel);
      this.set('toMergeDirty', '');
    },
    hasDuplicates: Ember.computed('model._data.{transporterKey,address}', 'duplicateAddressesDocNos.[]', function () {
      const model = this.model;
      const modelAddress = this.get('model._data.address');
      const modelContact = this.get('model._data.contact');
      const duplicates = this.duplicateAddressesDocNos || [];
      const transporterKey = this.get('model._data.transporterKey');
      const found = R.filter(duplicate => {
        const hasSameContact = R.equals(duplicate?.contact, modelContact);
        if (!hasSameContact) {
          return false;
        }
        const hasSameAddress = R.equals(duplicate?.value, modelAddress);
        if (!hasSameAddress) {
          return false;
        }
        const hasSameDocType = R.equals(duplicate?.docType, model?._data?.docType);
        if (!hasSameDocType) {
          return false;
        }
        const hasSameDocName = R.equals(duplicate?.docName, model?._data?.docName);
        if (!hasSameDocName) {
          return false;
        }
        return true;
      })(duplicates);
      if (!R.isEmpty(found)) {
        const DuplicateDocumentModel = Ember.Object.extend({
          label: Ember.computed('docNo', 'ref', function () {
            let label = `#${this.docNo}`;
            if (this.ref) {
              label += ` : ${this.ref}`;
            }
            return label;
          })
        });
        return R.map(docData => {
          docData.disabled = false;
          if (docData.transporterKey !== transporterKey) {
            docData.disabled = true;
          }
          return DuplicateDocumentModel.create(docData);
        }, found);
      }
      return false;
    }),
    allowMergeWith: Ember.computed('model._data.docNo', 'allowMerge', 'hasDuplicates.[]', function () {
      if (!this.allowMerge) {
        return [];
      }
      const docNo = this.get('model._data.docNo');
      const duplicates = this.hasDuplicates || [];
      return R.reject(doc => {
        const duplicateDocNo = doc.docNo || '';
        if (duplicateDocNo === docNo) {
          return true;
        }
        return false;
      })(duplicates);
    }),
    allowMerge: Ember.computed('model._data.isDispatched', 'hasDuplicates.[]', function () {
      const model = this.model;
      const isDispatched = model.getData('isDispatched');
      if (isDispatched) {
        return false;
      }
      if (this.hasDuplicates) {
        return true;
      }
      return false;
    }),
    hasNoMergeDocuments: Ember.computed('mergeModel.documents.[]', function () {
      const docs = this.get('mergeModel.documents') || [];
      return R.isEmpty(docs);
    }),
    mergeDocumentsTask: (0, _emberConcurrency.task)(function* () {
      this.set('mergedDetails', []);
      this.set('toMergeWithModels', []);
      const documents = this.get('mergeModel.documents') || [];
      const model = this.model;
      const adapterName = model.get('docAdapterName');
      const mergeToMaster = model.getData('_key');
      const toMergeDirty = this.setupDirty({
        adapterName,
        model
      });
      const mergedWith = [];
      toMergeDirty.setData('mergedWith', mergedWith);
      const q = R.map(docNo => {
        return this.findConcurrentTask.perform({
          adapterName,
          appendPath: `/doc/${docNo}`
        });
      }, documents);
      let toMergeWithDocumentModels = yield (0, _emberConcurrency.all)(q);
      let newDiscountAmount = this.bigService.newBig(toMergeDirty.getData('discountAmount'));
      let newRewardAmount = this.bigService.newBig(toMergeDirty.getData('rewardAmountTotal'));
      let newRewardInclTotal = this.bigService.newBig(toMergeDirty.getData('rewardInclTotal'));
      let newRewardPointApplied = this.bigService.newBig(toMergeDirty.getData('rewardPointApplied'));
      let newRewardAmountTotalWithoutPromoCode = this.bigService.newBig(toMergeDirty.getData('rewardAmountTotalWithoutPromoCode'));
      let groupedDocumentModels = toMergeDirty.getData('groups') || [];
      R.forEach(docModel => {
        const groups = docModel.getData('groups') || [];
        groupedDocumentModels = R.concat(groupedDocumentModels, groups);
      })(toMergeWithDocumentModels);
      toMergeWithDocumentModels = R.concat(toMergeWithDocumentModels, groupedDocumentModels);
      const toMergeWithDirtyModels = R.map(model => {
        const dirty = this.setupDirty({
          adapterName,
          model
        });
        dirty.setData('isMerged', true);
        dirty.setData('mergedTo', mergeToMaster);
        mergedWith.pushObject(dirty.getData('_key'));
        newDiscountAmount = newDiscountAmount.plus(dirty.getData('discountAmount') || 0);
        newRewardAmount = newRewardAmount.plus(dirty.getData('rewardAmountTotal') || 0);
        newRewardInclTotal = newRewardInclTotal.plus(dirty.getData('rewardInclTotal') || 0);
        newRewardPointApplied = newRewardPointApplied.plus(dirty.getData('rewardPointApplied') || 0);
        newRewardAmountTotalWithoutPromoCode = newRewardAmountTotalWithoutPromoCode.plus(dirty.getData('rewardAmountTotalWithoutPromoCode') || 0);
        return dirty;
      })(toMergeWithDocumentModels);
      toMergeDirty.setData('discountAmount', newDiscountAmount.toFixed(toMergeDirty.getData('roundingCurrency')));
      toMergeDirty.setData('rewardAmountTotal', newRewardAmount.toFixed(toMergeDirty.getData('roundingCurrency')));
      toMergeDirty.setData('rewardInclTotal', newRewardInclTotal.toFixed(toMergeDirty.getData('roundingCurrency')));
      toMergeDirty.setData('rewardPointApplied', newRewardPointApplied.toFixed(0));

      // const mergeDetailsProps = model.get('mergeDetailsProps')
      const isTaxExclusive = model.get('isTaxExclusive');
      let newIndex = 0;
      const mergedToDocNo = toMergeDirty.getData('docNo');
      const toMergeDetailsData = R.pipe(R.concat([toMergeDirty]), R.pluck('_data'), R.pluck('details'), R.flatten, R.map(detail => {
        if (detail?._data?.docNo !== mergedToDocNo) {
          detail.setData('docNo', mergedToDocNo);
          detail.setData('master', toMergeDirty.getData('master'));
        }
        if (isTaxExclusive) {
          detail.setData('price', detail?._data?.exclPrice);
        } else {
          detail.setData('price', detail?._data?.inclPrice);
        }
        if (!detail?._data?.isSetChild) {
          newIndex = newIndex + 1;
        }
        detail.setData('index', newIndex);
        return detail;
      }))(toMergeWithDirtyModels);
      toMergeDirty.setData('details', toMergeDetailsData);
      toMergeDirty.sumTotals();
      this.set('toMergeDirty', toMergeDirty);
      this.set('toMergeWithModels', toMergeWithDirtyModels);
      // merge all details to current model
    }).enqueue().maxConcurrency(4),
    actions: {
      afterMerge(onCloseDialogAction) {
        onCloseDialogAction();
        const onAfterMerge = this.onAfterMerge;
        if (onAfterMerge) {
          onAfterMerge();
        }
      },
      addMergeDoc(mergeWithData, isSelected) {
        const documents = this.get('mergeModel.documents');
        if (isSelected) {
          documents.pushObject(mergeWithData.docNo);
        } else {
          documents.removeObject(mergeWithData.docNo);
        }
        const uniqDocs = new Set(documents);
        this.set('mergeModel.documents', Array.from(uniqDocs));
      },
      clearMergeModel() {
        this.set('mergeModel.documents', []);
      }
    }
  });
});