define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/limits-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "emm8hFxN",
    "block": "{\"symbols\":[\"formInitData\",\"campaignDetailLimit\",\"@formInitData\",\"@campaign\",\"@isReadonly\",\"@model\",\"@wizardContainerComponent\",\"@resourceList\",\"@tab\",\"@documentationIsMinimized\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid panel-grid--has-header\"],[11,\"data-form-id\",[23,6,[\"instanceId\"]]],[8],[0,\"\\n\"],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n    \"],[5,\"forms/form-container\",[],[[\"@containerId\",\"@hasColumns\",\"@isUserAllowed\",\"@formInitData\",\"@tab\"],[\"limits\",false,true,[28,\"hash\",null,[[\"documentationResource\",\"documentationIsMinimized\",\"tab\",\"resourceList\",\"wizardContainerComponent\"],[\"campaignsDetails\",[23,10,[]],[23,9,[]],[23,8,[]],[23,7,[]]]]],[23,9,[]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[5,\"forms/form-group\",[[12,\"class\",[23,0,[\"formGroupclassNames\"]]]],[[\"@tab\",\"@context\",\"@formInitData\",\"@title\"],[[23,9,[]],\"productLimit_group\",[23,1,[]],\"product limits\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,6,[\"dirtyLimitsSortedByItem\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[5,\"channels/shop/campaigns/campaigns-item-editor/limits-editor/limits-editor-row\",[],[[\"@formInitData\",\"@model\",\"@campaign\",\"@limitAdjustType\",\"@isReadonly\"],[[23,3,[]],[23,2,[]],[23,4,[]],[23,0,[\"settings\",\"settingsModel\",\"limitAdjustType\"]],[23,5,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-item-editor/limits-editor/template.hbs"
    }
  });
});