define("client/utils/nventor", ["exports", "client/constants", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _constants, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @TODO:
  // move to vendor/bower_components
  // refactor for better readability and best practices
  // (reason: some functions below require the use of other functions,
  // the functions need to be created in a specific order for them
  // to be available to other functions)
  //
  // better?  (not sure how much better the below is)
  // export function fmt() { ... }
  //
  // export function isObject() { ... }
  //
  // export function get(obj) {
  //   USE FUNCTION HERE (try: reversing isObject and get function orders)
  //   if (isObject(obj)) {
  //
  //   }
  // }
  //
  // export default {
  //   fmt: fmt,
  //   confirm: {
  //     isObject: isObject
  //   }
  // };

  const nventor = {
    log(data) {
      console.log(data);
      return data;
    },
    /**
     * make a copy javascript object
     * @param  {object} object
     * @return {object}        copy
     */
    copy(object) {
      const self = this;
      if (!self.confirm.isObject(object)) {
        return object;
      }
      return JSON.parse(JSON.stringify(object));
    },
    safeParseJSON(jsonString) {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        return {};
      }
    },
    throw(msg, data) {
      const isDev = true;
      if (isDev) {
        console.log('----error----');
        console.log(msg);
        console.log('^^^^^^^^^^^^^');
        if (data) {
          console.log('----error context----');
          if (!R.is(Array, data)) {
            data = [data];
          }
          R.forEach(d => {
            console.log(d);
            console.log('^^^^^^^^^^^^^');
          }, data);
          console.log('=============');
        }
        throw msg;
      } else {

        // log message somewhere
      }
    },
    /**
     * creates an error
     */
    error(name, details) {
      var error = new Error(name);
      error.details = details;
      return error;
    },
    resolve(data) {
      return new Ember.RSVP.Promise(function (resolve) {
        resolve(data);
      });
    },
    reject(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        reject(data);
      });
    },
    isEven(n) {
      n = parseInt(n);
      return n % 2 === 0;
    },
    isNilOrEmpty(data) {
      if (R.isNil(data) || R.isEmpty(data)) {
        return true;
      }
      return false;
    },
    isNilOrEmptyDotPath: R.curry((dotPath, data) => {
      const val = R_.dotPath(dotPath)(data);
      if (R.isNil(val) || R.isEmpty(val)) {
        return true;
      }
      return false;
    }),
    sortTextAscending: R.sort((a, b) => a.localeCompare(b)),
    sortAscending: R.sort((a, b) => a - b),
    sortTextAscendingByPath: R.curry((path, array) => {
      return R.sort((a, b) => {
        const aV = R.path(path)(a);
        const bV = R.path(path)(b);
        return aV.localeCompare(bV);
      })(array);
    }),
    sortTextDescendingByPath: R.curry((path, array) => {
      return R.sort((a, b) => {
        const aV = R.path(path)(a);
        const bV = R.path(path)(b);
        return bV.localeCompare(aV);
      })(array);
    }),
    sortTextAndNumericAscendingWithGetter: R.curry((getter, a, b) => {
      const aV = getter(a);
      const bV = getter(b);
      return aV.localeCompare(bV, undefined, {
        isNumeric: true
      });
    }),
    sortTextAndNumericDescendingWithGetter: R.curry((getter, a, b) => {
      const aV = getter(a);
      const bV = getter(b);
      return bV.localeCompare(aV, undefined, {
        isNumeric: true
      });
    }),
    mapIndexed: R.addIndex(R.map),
    try: R.curry((fn, arg) => {
      try {
        return fn(arg);
      } catch (e) {
        return false;
      }
    }),
    safeLast(array) {
      if (R.isEmpty(array) || !R.is(Array, array)) {
        return '';
      }
      return R.last(array);
    },
    safeLastOr: R.curry((defaultValue, array) => {
      if (R.isEmpty(array) || !R.is(Array, array)) {
        return defaultValue;
      }
      return R.last(array);
    }),
    safeHead(array) {
      if (R.isEmpty(array) || !R.is(Array, array)) {
        return '';
      }
      return R.head(array);
    },
    safeHeadOr: R.curry((defaultValue, array) => {
      if (R.isEmpty(array) || !R.is(Array, array)) {
        return defaultValue;
      }
      return R.head(array);
    }),
    ensurePathOr(type, pathArray, detail) {
      const value = R.path(pathArray, detail) || type;
      if (!R.equals(R.type(type), R.type(value))) {
        return type;
      }
      return value;
    },
    mergeRightWhenNotEmpty(obj1, obj2) {
      const overwriteIfEmpty = (key, left, right) => {
        if (this.isNilOrEmpty(right)) {
          return left;
        }
        return right;
      };
      return R.mergeWithKey(overwriteIfEmpty, obj1, obj2);
    },
    /**
     * merge an array of objects using supplied function with signature (left, right) => return either left/right
     */
    mergeAllWith: R.curry((fn, array) => {
      return R.reduce(R.mergeWith(fn), {}, array);
    }),
    /**
     * merge an array of objects by key using supplied function with signature (key, left, right) => return either left/right
     */
    mergeAllWithKey: R.curry((fn, array) => {
      return R.reduce(R.mergeWithKey(fn), {}, array);
    }),
    /**
     * checks if all properties supplied in array are equal in two objects
     */
    allEqProps: R.curry((props, obj1, obj2) => {
      // @TODO: deprecated. use allEqDotPaths
      return R.reduceWhile(R.equals(true), (acc, prop) => {
        return R.eqProps(prop, obj1, obj2);
      }, true, props);
    }),
    /**
     * checks if all dot paths supplied in array are equal in two objects
     */
    allEqDotPaths: R.curry((dotPaths, obj1, obj2) => {
      return R.reduceWhile(R.equals(true), (acc, dotPath) => {
        const path = R.split('.', dotPath);
        return R.equals(R.path(path, obj2), obj1[dotPath]);
      }, true, dotPaths);
    }),
    dotPath: R.curry((dotPath, obj) => {
      const path = R.split('.')(dotPath);
      return R.path(path, obj);
    }),
    dotPathOr: R.curry((orValue, dotPath, obj) => {
      const path = R.split('.')(dotPath);
      return R.pathOr(orValue, path, obj);
    }),
    /**
     * find by props
     * @param  {object} objectToMatch - key value pair. prop => value to match
     * @param  {array} array - array of objects
     * @return {object} object found
     */
    findByProps: R.curry((objectToMatch, array) => {
      // @TODO: deprecated. just use findByDotPaths
      const keysToMatch = R.keys(objectToMatch);
      const matchAllProps = nventor.allEqProps(keysToMatch, objectToMatch);
      const found = R.filter(matchAllProps)(array);
      if (found.length > 0) {
        return R.head(found);
      }
      return false;
    }),
    /**
     * find by paths
     * @param  {object} objectToMatch - key value pair. prop => value to match
     * @param  {array} array - array of objects
     * @return {object} object found
     */
    findByDotPaths: R.curry((objectToMatch, array) => {
      const keysToMatch = R.keys(objectToMatch);
      const matchAll = nventor.allEqDotPaths(keysToMatch, objectToMatch);
      const found = R.filter(matchAll)(array);
      if (found.length > 0) {
        return R.head(found);
      }
      return false;
    }),
    propNotEq: R.curry((prop, value, obj) => {
      return !R.propEq(prop, value, obj);
    }),
    /**
     * group an array and group by array of prop values.
     * will return an array of array
     */
    groupByEqProps: R.curry((props, array) => {
      const byEqProps = item => {
        const key = R.pipe(R.pick(props), R.values, R.join('--'))(item);
        return key;
      };
      return R.pipe(R.groupBy(byEqProps), R.values)(array);
    }),
    /**
     * creates a model instance
     * @param {object} - reference to model object
     * @param {object} data - data to populate model with
     * @param {object} attrs - (optional) attributes to set
     * return model instance
     */
    createModel(model, data, attrs) {
      attrs = attrs || {};
      attrs.constants = _constants.default;
      model = model.create(attrs);
      // if (model.setup) {
      //   model.setup(data)
      // } else {
      // @TODO: should move this to model base
      // @TODO: instead of .populate use .start (or something)
      // @TODO: which then will call .populate and .setOriginalData
      // if (data) {
      if (model.populate) {
        let newRecordData = {};
        // try {
        //   // newRecordData = nventor.copy(data) || {}
        // } catch (e) {
        //   newRecordData = data || {}
        //   // nventor.throw('Cannot clone data ' + adapterName + '. Error: ' + e, data)
        // }
        newRecordData = data || {};
        // custom model with sync abilities (extended from base)
        model.populate(newRecordData, attrs);
      } else {
        // @TODO: remove this
        // plain ember object model (used for tabs)
        model.setProperties(data);
      }
      // }
      // }

      return model;
    },
    // fmt (string) {
    //   // if is array then return multiline, else just return unescaped
    //   if (string != null) {
    //     var lines = string.split(/\r\n|\r|\n/g)
    //     if (lines.length > 1) {
    //       lines.map(line => {
    //         // escape user data
    //         return Ember.Handlebars.Utils.escapeExpression(line)
    //       })

    //       // @todo: consider wrapping each line in a custom tag
    //       // eg. <p class='multi-line'>line</p>
    //       // then multi-line class can handle styling
    //       return htmlSafe(lines.join('<br/>'))
    //     }
    //   }

    //   // not multiline, do nothing, return original string
    //   return string
    // },

    /**
     * check dot path for isNilOrEmpty and then returns default value if isNilOrEmpty
     */
    isNilOrEmptyDotPathOr: R.curry((defaultValue, path, obj) => {
      return R.pipe(R_.dotPath(path), R.when(RA.isNilOrEmpty, R.always(defaultValue)))(obj);
    }),
    /**
     * check path for isNilOrEmpty and then returns default value if isNilOrEmpty
     */
    isNilOrEmptyPathOr: R.curry((defaultValue, path, obj) => {
      return R.pipe(R.path(path), R.when(RA.isNilOrEmpty, R.always(defaultValue)))(obj);
    }),
    convertToArray: array => {
      if (R.is(Array, array)) {
        return array;
      }
      if (nventor.isNilOrEmpty(array)) {
        return [];
      }
      return [array];
    },
    arrayToObject(array, keyField) {
      return array.reduce((obj, item) => {
        obj[item[keyField]] = item;
        return obj;
      }, {});
    },
    /**
     * get max (positive) number from list
     * @param {array} array
     * @returns {number}
     */
    maxInList(array) {
      return R.reduce(R.maxBy(Math.abs), 0)(array);
    },
    /**
     * return without aplha
     * eg. A1 => 1
     * @param {string} string
     * @returns {string}
     */
    withoutAlpha(string) {
      return R.replace(/\D/g, '')(string);
    },
    /**
     * return without number
     *
     * @param {*} string
     * @returns
     */
    withoutNumbers(string) {
      return R.replace(/\d/g, '')(string);
    },
    /**
     * returns default value if value does not exist
     * @param  {mixed} value
     * @param  {mixed} defaultValue
     * @return {mixed} either value or default
     */
    returnDefault(value, defaultValue) {
      if (defaultValue != null) {
        return defaultValue;
      }
      return value;
    },
    getFilesArray(filesList) {
      let filesArray = [];
      if (filesList.length === 0) {
        filesArray = false;
      } else {
        filesArray = Array.prototype.slice.call(filesList);
      }
      return filesArray;
    },
    confirm: {
      /**
       * confirms whether variable is an object
       * @param  {mixed} obj
       * @return {Boolean}
       */
      isObject(obj) {
        if (obj !== null && typeof obj === 'object') {
          // arrays are still considered objects..
          if (!R.is(Array, obj)) {
            return true;
          }
        }
        return false;
      },
      /**
       * determines if value is a function
       * @param  {mixed}  value
       * @return {Boolean}
       */
      isFunction(value) {
        var getType = {};
        return value && getType.toString.call(value) === '[object Function]';
      },
      /**
       * determines if value is numeric
       * @param  {mixed}  n
       * @return {Boolean}
       */
      isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
      },
      isNotNumeric(n) {
        if (!isNaN(parseFloat(n)) && isFinite(n)) {
          return false;
        }
        return true;
      },
      /**
       * returns value if exists
       * @param  {object/array}  obj
       * @param  {string}  key
       * @param  {mixed}  defaultValue
       * @return {value}
       */
      get(obj, key, defaultValue) {
        var self = nventor;
        var value;
        // var value = ''
        if (self.confirm.isEmpty(obj) !== true) {
          if (self.confirm.isObject(obj)) {
            // this has problems sometimes. (when using template hash to create object?)
            // this should be deprecated.
            var exists = obj?.hasOwnProperty?.(key);
            if (exists) {
              value = obj[key];
            }
          }
          if (R.is(Array, obj)) {
            if (key in obj) {
              value = obj[key];
            }
          }
        }

        // if default value exists (defaultValue of false would also return false)
        if (defaultValue != null && value == null) {
          value = defaultValue;
        }
        return value;
      },
      /**
       * checks whether object/array/string is empty
       * (empty = true when value = false, '', null, undefined, array.length is 0, object has no own properties)
       * @param  {mixed}  value
       * @param {mixed} defaultTrue value to return if empty is true
       * @param {mixed} defaultFalse value to return if empty is false
       * @return {Boolean|mixed}
       */
      isEmpty(value, defaultTrue, defaultFalse) {
        var self = nventor;
        if (value === '' || value === null || typeof value === 'undefined' || value === false) {
          return self.returnDefault(true, defaultTrue);
        }
        if (self.confirm.isObject(value)) {
          // is an object
          for (var prop in value) {
            if (value.hasOwnProperty(prop)) {
              return self.returnDefault(false, defaultFalse);
            }
          }
          return self.returnDefault(true, defaultTrue);
        }
        if (R.is(Array, value)) {
          // is an array
          if (value.length > 0) {
            return self.returnDefault(false, defaultFalse);
          }
          return self.returnDefault(true, defaultTrue);
        }
        if (self.confirm.isNumeric(value)) {
          if (value === 0) {
            return self.returnDefault(true, defaultTrue);
          }
          return self.returnDefault(false, defaultFalse);
        }
        return self.returnDefault(false, defaultFalse);
      }
    },
    random: {
      _alpha() {
        return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      },
      _digits() {
        return '0123456789';
      },
      digits(length) {
        let random = '';
        const possible = this._digits();
        for (let i = 0; i < length; i++) {
          random += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return random;
      },
      alpha(length) {
        let random = '';
        const possible = this._alpha();
        for (var i = 0; i < length; i++) {
          random += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return random;
      },
      alphaNum(length) {
        let random = '';
        const possible = this._alpha() + this._digits();
        for (var i = 0; i < length; i++) {
          random += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return random;
      }
    },
    pluckPath(dotPath, obj) {
      const path = R.split('.')(dotPath);
      return R.pipe(R.map(R.pathOr(null, path)))(obj);
    },
    /**
     * parent: the container that has scroll behaviour, default to be window
     * goTo: the element to be scrolled to
     * offset: default to be 0
     * horizontal: default to be false, determines the scroll direction
     * @param {Object} params
     * @void
     */
    goToElement({
      parent,
      goTo,
      offset = 0,
      horizontal = false
    }) {
      Ember.run.scheduleOnce('afterRender', () => {
        if (RA.isNilOrEmpty(parent)) {
          parent = window;
        }
        if (RA.isNotNilOrEmpty(goTo)) {
          if (horizontal) {
            const scrollLeft = goTo?.offsetLeft - offset;
            parent.scrollTo({
              left: scrollLeft,
              behavior: 'smooth'
            });
          } else {
            const scrollTop = goTo?.offsetTop - offset;
            parent.scrollTo({
              top: scrollTop,
              behavior: 'smooth'
            });
          }
        }
      });
    },
    goToTop(offset) {
      window.$('html, body').animate({
        scrollTop: window.$('body').offset().top + offset
      }, 200);
      // offset = offset || 0
      // scheduleOnce('afterRender', () => {
      //   window.$('html, body').animate({
      //     scrollTop: window.$('body').offset().top + offset
      //   }, 200)
      // })
    },
    parseParams(location) {
      if (!location) {
        location = window.location;
      }

      // need to handle params that are arrays
      // a[]=1&a[]=2 ==> a: [1, 2]

      // a=&b=2
      // { a: '', b: 2}

      return R.pipe(R.pathOr('', ['search']),
      // ?a=1&b=2
      R.split('?'),
      // [?, a=1&b=2]
      R.drop(1),
      // [a=1&b=2]
      R.map(R.split('&')),
      // [ [a=1], [b=2] ]
      R.map(R.map(R.split('='))),
      // [ [a, 1], [b, 2] ]
      R.unnest, R.fromPairs)(location);
    },
    removeAndUpdateQueryParams(toRemoveParams = []) {
      const currentUrl = R.pipe(R.split('?'), this.safeHeadOr(false))(window.location.href);
      if (currentUrl) {
        const params = this.parseParams(window.location);
        const updatedParams = R.omit(toRemoveParams)(params);
        let newUrl = currentUrl;
        if (!R.isEmpty(updatedParams)) {
          const queryString = R.pipe(R.toPairs, R.map(R.join('=')), R.join('&'))(updatedParams);
          newUrl = `${newUrl}?` + queryString;
        }
        window.history.replaceState(updatedParams, null, newUrl);
        return newUrl;
      }
      return false;
    },
    /**
     * makes dash separated words into camel case
     */
    dashToCamelCase(string) {
      return R.pipe(R.split('-'), RA.mapIndexed((word, index) => {
        word = R.trim(word) || '';
        if (index > 0) {
          return R.pipe(RA.mapIndexed((char, pos) => {
            if (pos === 0) {
              return R.toUpper(char);
            }
            return char;
          }), R.join(''))(word);
        }
        return word;
      }), R.join(''))(string);
    },
    truncate({
      text,
      char = 100,
      byLines = false
    }) {
      let truncated = text;
      if (text.length > char && !byLines) {
        truncated = R.take(char, text) + '...';
      }
      return truncated;
    },
    maskString(str, {
      start = 0,
      end = 0,
      placeholder
    }) {
      let startStr = '';
      if (start) {
        if (str.length <= start) {
          startStr = R.take(1)(str);
        } else {
          startStr = R.take(start)(str);
        }
      }
      let endStr = '';
      if (end) {
        if (str.length <= end) {
          endStr = R.takeLast(1)(str);
        } else {
          endStr = R.takeLast(end)(str);
        }
      }
      if (startStr || endStr) {
        return `${startStr}${placeholder}${endStr}`;
      }
      return '';
    },
    getOuterWidth(element, includeMargin = false) {
      // Get the computed style of the element
      const styles = window.getComputedStyle(element);

      // Calculate the outer width
      let outerWidth = element.offsetWidth; // Includes content and padding

      // Add the border width
      outerWidth += parseInt(styles.borderLeftWidth, 10) + parseInt(styles.borderRightWidth, 10);

      // Optionally, add the margin if includeMargin is true
      if (includeMargin) {
        outerWidth += parseInt(styles.marginLeft, 10) + parseInt(styles.marginRight, 10);
      }
      return outerWidth;
    },
    moveObjectUp(obj, array) {
      const index = R.findIndex(R.equals(obj), array);
      if (index <= 0 || index === -1) {
        return array; // Object not found or already at the top
      }
      return R.update(index, array[index - 1], R.update(index - 1, array[index], array));
    },
    moveObjectDown(obj, array) {
      const index = R.findIndex(R.equals(obj), array);
      if (index === -1 || index === array.length - 1) {
        return array; // Object not found or already at the bottom
      }

      // swap places
      return R.update(index, array[index + 1], R.update(index + 1, array[index], array));
    },
    getObjectDifference(obj1 = {}, obj2 = {}) {
      const keysDiff = R.difference(R.keys(obj2), R.keys(obj1)); // Keys in obj2 but not in obj1
      const valueDiff = R.filter(key => !R.equals(obj1[key], obj2[key]),
      // Check for value changes
      R.keys(obj1));
      const differentKeys = R.concat(keysDiff, valueDiff);
      return R.pick(differentKeys, obj2); // Extract differences from obj2
    }
  };
  var _default = _exports.default = nventor;
});