define("client/pods/components/comments/rules-sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wRFQkU5l",
    "block": "{\"symbols\":[\"@tab\",\"@sidebarOptions\"],\"statements\":[[5,\"layouts/section-container\",[],[[],[]],{\"statements\":[[0,\"\\n\\t\"],[5,\"comments/comments-manager\",[],[[\"@componentName\",\"@tab\",\"@btnLabel\",\"@type\",\"@subType\",\"@master\"],[\"comments/comments-manager/modal-btn\",[23,1,[]],\"manage rules\",[23,2,[\"type\"]],\"rules\",[23,0,[\"master\"]]]]],[0,\"\\n\\n\\t\"],[7,\"p\",true],[10,\"class\",\"u-container help-container u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"tt\",[\"rules_notes\"],null],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"comments/comments-manager\",[],[[\"@componentName\",\"@tab\",\"@type\",\"@subType\",\"@master\",\"@hasCommentEditorFooter\"],[\"comments/comments-manager/comments-container\",[23,1,[]],[23,2,[\"type\"]],\"rules\",[23,0,[\"master\"]],false]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/comments/rules-sidebar/template.hbs"
    }
  });
});