define("client/pods/components/operator/change-logs/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YCV0n6D6",
    "block": "{\"symbols\":[\"@errors\",\"@onDisplay\",\"@onEdit\",\"@tab\",\"@filters\",\"@onPaginationTask\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content table-view-container flex-container-row flex-container--wrap\"],[8],[0,\"\\n\\n    \"],[5,\"layouts/table-view\",[],[[\"@detailsRowComponent\",\"@errors\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onResetSearchRecordsTask\",\"@onPaginationTask\",\"@onDisplay\",\"@onEdit\",\"@onMainAction\",\"@results\",\"@tab\",\"@tableClassNames\",\"@tableViewOptions\"],[\"operator/change-logs/table-view/detail-row\",[23,1,[]],[23,0,[\"filters\"]],[28,\"perform\",[[23,0,[\"crud\",\"searchAdvancedTask\"]],[28,\"hash\",null,[[\"filters\"],[[23,0,[\"filters\"]]]]]],null],[28,\"perform\",[[23,0,[\"crud\",\"reloadSearchRecordsTask\"]],[28,\"hash\",null,[[\"filters\"],[[23,0,[\"filters\"]]]]]],null],[28,\"perform\",[[23,0,[\"crud\",\"reloadSearchRecordsTask\"]],[28,\"hash\",null,[[\"filters\",\"reset\"],[[23,0,[\"filters\"]],true]]]],null],[28,\"perform\",[[23,0,[\"crud\",\"paginateSearchRecordsTask\"]],[28,\"hash\",null,[[\"filters\"],[[23,0,[\"filters\"]]]]]],null],[23,2,[]],[23,3,[]],[23,2,[]],[23,0,[\"filters\",\"results\"]],[23,4,[]],\"operator-change-logs-dash\",[28,\"hash\",null,[[\"adapterName\"],[\"operator/change-logs\"]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"helpers/pagination-controls\",[],[[\"@filters\",\"@list\",\"@onPaginationTask\",\"@showPageNumbers\"],[[23,5,[]],[23,5,[\"results\"]],[23,6,[]],true]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/operator/change-logs/panels-dash/template.hbs"
    }
  });
});