define("client/pods/components/products/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9cbqX7Hn",
    "block": "{\"symbols\":[\"@onDisplay\",\"@onDash\",\"@tab\",\"@isFromShop\"],\"statements\":[[5,\"products/products-editor\",[],[[\"@adapterName\",\"@isEditing\",\"@isRunning\",\"@isShopDotComEnabled\",\"@model\",\"@onCancel\",\"@onDash\",\"@onSaveTask\",\"@originalModel\",\"@tab\",\"@isFromShop\"],[[23,0,[\"adapterName\"]],true,[28,\"or\",[[23,0,[\"crud\",\"updateRecordTask\",\"isRunning\"]],[23,0,[\"crud\",\"removeRecordTask\",\"isRunning\"]]],null],[23,0,[\"settings\",\"settingsModel\",\"isShopDotComEnabled\"]],[23,0,[\"dirty\"]],[28,\"fn\",[[23,0,[\"crud\",\"cancelEditing\"]],[28,\"hash\",null,[[\"adapterName\",\"dirty\",\"onAfter\"],[[23,0,[\"adapterName\"]],[23,0,[\"dirty\"]],[23,1,[]]]]]],null],[23,2,[]],[28,\"perform\",[[23,0,[\"updateRecordTask\"]]],null],[23,0,[\"model\"]],[23,3,[]],[23,4,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-edit/template.hbs"
    }
  });
});