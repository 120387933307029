define("client/pods/components/elements/date-multiple-picker/component", ["exports", "moment", "client/utils/nventor", "ramda"], function (_exports, _moment, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Pikaday from 'pikaday'
  var _default = _exports.default = Ember.Component.extend({
    dateService: Ember.inject.service('date'),
    pikadayService: Ember.inject.service('pikaday'),
    selectedDates: null,
    init() {
      const elementId = 'multi-date-picker-' + _nventor.default.random.alpha(4);
      this.set('elementId', elementId);
      const containerId = 'multi-date-picker-container' + _nventor.default.random.alpha(4);
      this.set('containerId', containerId);
      this._super(...arguments);
    },
    didInsertElement() {
      this._super(...arguments);
      const elementId = this.elementId;
      const containerId = this.containerId;
      const datepicker = document.getElementById(elementId);
      const container = document.getElementById(containerId);
      const selectedDates = this.get('selectedDates') || [];
      let hasSelectedDate = false;
      const picker = this.pikadayService.setup({
        field: datepicker,
        container: container,
        keyboardInput: false,
        setDefaultDate: false,
        theme: 'pikaday--default-theme',
        onSelect: function (date) {
          const isoDateString = (0, _moment.default)(date).toISOString();
          const index = R.indexOf(isoDateString)(selectedDates);
          if (index > -1) {
            selectedDates.removeObject(isoDateString);
          } else {
            selectedDates.pushObject(isoDateString);
          }
          if (this.onSelect) {
            this.onSelect(selectedDates);
          }
          hasSelectedDate = true;
        },
        onDraw: () => {
          R.map(selectedDateZ => {
            const formattedDate = this.dateService?.formatDate(selectedDateZ, 'YYYY-MM-DD');
            if (!formattedDate) {
              return;
            }
            const [year, month, day] = formattedDate.split('-').map(num => parseInt(num, 10));
            // Adjust month to be 0-indexed to match data-pika-month attribute, NOTE:January is 0, December is 11
            const zeroIndexedMonth = month - 1;
            if (picker.isVisible()) {
              const pikaButtons = picker.el.querySelectorAll('.pika-button[data-pika-day]') || [];
              const dayElement = R.find(element => {
                return parseInt(element.getAttribute('data-pika-day')) === day && parseInt(element.getAttribute('data-pika-month')) === zeroIndexedMonth && parseInt(element.getAttribute('data-pika-year')) === year;
              })(pikaButtons);
              if (dayElement) {
                dayElement.classList.add('selected-date');
              }
            }
          })(selectedDates);
        },
        onClose: function () {
          if (hasSelectedDate) {
            this.show();
          }
          hasSelectedDate = false;
        },
        i18n: {
          previousMonth: '上個月',
          nextMonth: '下個月',
          months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
          weekdays: ['日', '一', '二', '三', '四', '五', '六'],
          weekdaysShort: ['日', '一', '二', '三', '四', '五', '六']
        }
      });
      this.set('picker', picker);
    },
    willDestroyElement() {
      this._super(...arguments);
      if (this.picker) {
        this.picker.destroy();
      }
    }
  });
});