define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/when-editor-flows/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fiWLE2Aw",
    "block": "{\"symbols\":[\"@automationModel\",\"@master\",\"@formInitData\",\"@isReadonly\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@context\",\"@formInitData\",\"@label\"],[\"automationWhen\",[23,3,[]],\"automation_when\"]],{\"statements\":[[0,\"\\n\\t\"],[5,\"lists/list-dropdown\",[],[[\"@isBtn\",\"@readonly\",\"@disabled\",\"@translate\",\"@classNames\",\"@list\",\"@itemValueKey\",\"@itemLabelKey\",\"@value\"],[true,true,[23,4,[]],true,\"table-form__input\",[23,0,[\"crud\",\"lists\",\"automations-when\"]],\"_data.value\",\"_data.label\",[23,1,[\"_data\",\"when\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"selectedAutomation\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"component\",[[23,0,[\"conditionsComponent\"]]],[[\"formInitData\",\"master\",\"automationModel\",\"isHeader\"],[[23,3,[]],[23,2,[]],[23,1,[]],true]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/when-editor-flows/template.hbs"
    }
  });
});