define("client/pods/components/products/panels-shop-wizard/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, R_, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ProductsPanelsShopWizardComponent = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service('channels/shop/campaigns'), _dec3 = Ember.inject.service('products'), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember.computed('campaignDetailInstructions._data.shopKey'), _class = class ProductsPanelsShopWizardComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "campaignsService", _descriptor2, this);
      _initializerDefineProperty(this, "productsService", _descriptor3, this);
      _initializerDefineProperty(this, "campaignsProducts", _descriptor4, this);
      _initializerDefineProperty(this, "crud", _descriptor5, this);
      _initializerDefineProperty(this, "search", _descriptor6, this);
      _initializerDefineProperty(this, "settings", _descriptor7, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor8, this);
      _initializerDefineProperty(this, "selectedCampaign", _descriptor9, this);
      _initializerDefineProperty(this, "selectedCampaignDetail", _descriptor10, this);
      _initializerDefineProperty(this, "isLoaded", _descriptor11, this);
      _initializerDefineProperty(this, "product", _descriptor12, this);
      _initializerDefineProperty(this, "campaignFilters", _descriptor13, this);
      _initializerDefineProperty(this, "campaignDetailInstructions", _descriptor14, this);
      _initializerDefineProperty(this, "campaignDetailsForProduct", _descriptor15, this);
      _initializerDefineProperty(this, "hasFetchedAllCampaigns", _descriptor16, this);
      this.setupTask.perform();
    }
    *setupTask() {
      const campaignFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/filters'
      });
      Ember.set(this, 'campaignFilters', campaignFilters);
      const campaignDetailInstructions = this.campaignsProducts.getCampaignDetailModel();
      Ember.set(this, 'campaignDetailInstructions', campaignDetailInstructions);
      const tab = this.args.tab;
      const product = tab.model;
      Ember.set(this, 'product', product);
      Ember.set(this, 'campaignDetailsForProduct', product.campaignDetailsArr || []);
      const isAutoAddNewProductToShop = this.settings.getProp('isAutoAddNewProductToShop');
      if (!isAutoAddNewProductToShop) {
        Ember.set(this, 'isLoaded', true);
        return;
      }
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);
      const campaignDetailToLoad = loadInstructions?._data?.campaignDetailInstructions;
      let shopKey = campaignDetailToLoad?._data?.autoAddNewProductToShopKey;
      if (!shopKey) {
        const autoAddNewProductToShopKey = this.settings.getProp('autoAddNewProductToShopKey');
        if (isAutoAddNewProductToShop && autoAddNewProductToShopKey) {
          shopKey = autoAddNewProductToShopKey;
        }
      }
      if (!shopKey) {
        Ember.set(this, 'isLoaded', true);
        return;
      }
      return yield this.setupCampaignAndCampaignDetailTask.perform(shopKey);
    }
    get disabled() {
      const shopKey = this.campaignDetailInstructions?._data?.shopKey;
      if (!shopKey) {
        return true;
      }
      return false;
    }
    *setupCampaignAndCampaignDetailTask(shopKey) {
      const campaign = yield this.crud.findTask.perform({
        adapterName: 'channels/shop/campaigns',
        appendPath: shopKey
      });
      if (!campaign?._data?._key) {
        Ember.set(this, 'isLoaded', true);
        return;
      }
      Ember.set(this, 'selectedCampaign', campaign);
      yield this.setupCampaignDetailTask.perform(campaign);
      Ember.set(this, 'isLoaded', true);
    }
    *setupCampaignDetailTask(campaign) {
      const product = this.product;
      const itemKey = product?._data?._key;
      const shopKey = campaign?._data?._key;
      let campaignDetail = yield this.crud.findTask.perform({
        adapterName: 'channels/shop/campaigns/details',
        appendPath: `/${itemKey}/master/${shopKey}`
      });
      if (!campaignDetail?._data?._key) {
        // @TODO: add product to shop
        campaignDetail = yield this.campaignsProducts.addProductTask.perform({
          campaign,
          product
        });
      }
      Ember.set(this, 'selectedCampaignDetail', campaignDetail);
    }
    *fetchCampaignsForProductTask() {
      const product = this.product;
      const productKey = product?._data?._key;
      const campaignDetails = yield this.productsService.fetchCampaignsForProductTask.perform(productKey);
      Ember.set(this, 'campaignDetailsForProduct', campaignDetails);
      Ember.set(this, 'hasFetchedAllCampaigns', true);
    }
    get isShowFetchAllCampaignsBtn() {
      const hasManyCampaignDetails = this.product.hasManyCampaignDetails;
      if (!hasManyCampaignDetails) {
        return false;
      }
      if (this.hasFetchedAllCampaigns) {
        return false;
      }
      return true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "campaignsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "productsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "campaignsProducts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedCampaign", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedCampaignDetail", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isLoaded", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "product", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "campaignFilters", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "campaignDetailInstructions", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "campaignDetailsForProduct", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "hasFetchedAllCampaigns", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "disabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupCampaignAndCampaignDetailTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupCampaignAndCampaignDetailTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupCampaignDetailTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupCampaignDetailTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchCampaignsForProductTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCampaignsForProductTask"), _class.prototype), _class);
});