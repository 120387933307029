define("client/pods/components/layouts/section/sub/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qoDQFNdg",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"layouts-section-sub\"],[13,1],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/section/sub/template.hbs"
    }
  });
});