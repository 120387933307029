define("client/pods/components/menus/progress-bar/step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Rm9NHnEb",
    "block": "{\"symbols\":[\"@index\",\"@step\",\"@itemClassNames\",\"@onSelect\"],\"statements\":[[7,\"li\",false],[12,\"class\",[29,[\"progress-step u-tappable \",[23,0,[\"stepStatus\"]],\" \",[28,\"if\",[[23,3,[]],[23,3,[]],\"\"],null]]]],[3,\"on\",[\"click\",[28,\"fn\",[[23,4,[]],[23,2,[]]],null]]],[8],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[\"_data\",\"label\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"tt\",[[23,2,[\"_data\",\"label\"]]],[[\"translate\"],[[23,2,[\"_data\",\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,2,[\"label\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"tt\",[[23,2,[\"label\"]]],[[\"translate\"],[[23,2,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"concat\",[[28,\"tt\",[\"step\"],null],[28,\"sum\",[[23,1,[]],1],null]],null],false],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/progress-bar/step/template.hbs"
    }
  });
});