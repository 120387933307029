define("client/pods/channels/shop/campaigns/linked/adapter", ["exports", "ramda", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/channels/shop/campaigns/model"], function (_exports, R, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    emails: Ember.inject.service(),
    settings: Ember.inject.service(),
    resourcePath: 'api/protected/channels/shop/campaigns',
    idParam: '_id',
    idPrefix: 'campaigns/linked',
    modelObj: _model.default,
    serialize(model) {
      const data = this._super(model);

      // dont allow '__' in url
      let url = data.shopUrl || '';
      url = R.replace(/__/g, '')(url);
      url = R.replace(/--/g, '')(url);
      data.shopUrl = url;
      data.orderedEmailCopyTo = this.emails.splitToArray(data.orderedEmailCopyTo);
      data.orderMessagesEmailCopyTo = this.emails.splitToArray(data.orderMessagesEmailCopyTo);
      const settingsData = this.settings?.settingsModel?._data;
      if (data.useDefaultsCheckoutSummaryDescription) {
        data.checkoutSummaryDescription = R.propOr('', 'shopCheckoutSummaryDescription')(settingsData);
      }
      if (data.useDefaultsCheckoutDetailsDescription) {
        data.checkoutDetailsDescription = R.propOr('', 'shopCheckoutDetailsDescription')(settingsData);
      }
      if (data.useDefaultsCheckoutPaymentDescription) {
        data.checkoutPaymentDescription = R.propOr('', 'shopCheckoutPaymentDescription')(settingsData);
      }
      delete data.details;
      return data;
    }
  });
});