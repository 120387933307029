define("client/pods/components/helpers/multiline-truncate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Y/bWqh0J",
    "block": "{\"symbols\":[\"isTooltipVisible\",\"@content\",\"@hasWrapper\",\"@contentDisplayClassName\",\"@modalPos\",\"@tooltipClassName\",\"&attrs\"],\"statements\":[[5,\"helpers/info-tooltip\",[[12,\"class\",[23,6,[]]]],[[\"@modalPos\",\"@customBlock\",\"@clickToView\"],[[23,5,[]],true,[23,0,[\"clickToView\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"span\",false],[12,\"class\",\"auto-truncate\"],[13,7],[8],[0,\"\\n    \"],[1,[23,0,[\"shortenedContent\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"hasTooltip\"]]],null,{\"statements\":[[4,\"helpers/info-tooltip-content\",null,[[\"modalPos\",\"closeOnOverlayClick\",\"hasModalOverlay\",\"isViewable\",\"hasContent\"],[[23,5,[]],true,false,[23,1,[]],[23,0,[\"hasTooltip\"]]]],{\"statements\":[[0,\"      \"],[5,\"helpers/content-display\",[],[[\"@content\",\"@hasWrapper\",\"@className\"],[[23,2,[]],[23,3,[]],[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/multiline-truncate/template.hbs"
    }
  });
});