define("client/pods/components/files/files-gallery/gallery-item-modal/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    uploadsService: Ember.inject.service('uploads'),
    tagName: 'span',
    classNames: [''],
    hasError: false,
    onRemove: null,
    onSetDefault: null,
    defaultImage: null,
    confirmationOnRemove: true,
    removeConfirmationMsg: 'are you sure you want to delete image?',
    isHover: false,
    transform: '',
    allowModal: true,
    showModal: false,
    wasSaved: false,
    closeOnOverlayClick: true,
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['uploads/lists/tags']);
    },
    isDefaultImage: Ember.computed('defaultImage', function () {
      if (this.get('file._data.uuid') === this.defaultImage) {
        return true;
      }
      return false;
    }),
    hasModalFooter: Ember.computed('onRemove', 'allowRemove', 'onSetAsDefaultImageTask', 'allowSetDefault', function () {
      const allowRemove = this.allowRemove;
      const onSetAsDefaultImageTask = this.onSetAsDefaultImageTask;
      const allowSetDefault = this.allowSetDefault;
      if (allowRemove || onSetAsDefaultImageTask && allowSetDefault) {
        return true;
      }
      return false;
    }),
    removeTask: (0, _emberConcurrency.task)(function* (file, onCloseDialogAction) {
      yield this.uploadsService.removeTask.perform(null, null, file);
      if (this.onAfterRemove) {
        this.onAfterRemove(onCloseDialogAction);
      }
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    })
  });
});