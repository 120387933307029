define("client/pods/components/files/files-browser-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6cf/qI9E",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@allowSrcSet\",\"@allowMultipleItems\",\"@resource\",\"@resourceKey\",\"@finderLabel\",\"@tab\",\"@previousImage\",\"@isFroala\"],\"statements\":[[5,\"modals/modal-dialog\",[],[[\"@title\",\"@modalDialogIsVisible\",\"@closeOnOverlayClick\",\"@onToggle\",\"@modalDialogContentClassName\"],[\"search images\",[22,\"modalDialogIsVisible\"],true,[28,\"fn\",[[28,\"mut\",[[24,[\"modalDialogIsVisible\"]]],null]],null],\"u-overflow-y-hidden-important\"]],{\"statements\":[[0,\"\\n  \"],[5,\"files-manager/finder\",[],[[\"@allowSrcSet\",\"@allowMultipleItems\",\"@resource\",\"@resourceKey\",\"@finderLabel\",\"@onSubmitTask\",\"@onRemoveTask\",\"@onCloseDialogAction\",\"@tab\",\"@previousImage\",\"@isFroala\",\"@indexProp\"],[[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,0,[\"onSubmitTask\"]],[23,0,[\"uploadsService\",\"removeTask\"]],[23,1,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,0,[\"indexProp\"]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/files/files-browser-modal/template.hbs"
    }
  });
});