define("client/pods/components/elements/btns-prev-next/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ElementsBtnsPrevNextComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('application'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed('args.menu.[]', 'applicationService.isMobile'), _dec10 = Ember.computed('args.{tabs.@each.isActive,menu.[]}', 'showBtns'), _dec11 = Ember.computed('args.{tabs.@each.isActive,menu.[]}', 'showBtns'), _dec12 = Ember.computed('args.{tabs.@each.isActive,menu.[]}', 'showBtns'), _dec13 = Ember.computed('args.{tabs.@each.isActive,menu.[]}', 'showBtns'), _class = class ElementsBtnsPrevNextComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "tabsManager", _descriptor, this);
      _initializerDefineProperty(this, "applicationService", _descriptor2, this);
      _initializerDefineProperty(this, "tooltipLabelPrev", _descriptor3, this);
      _initializerDefineProperty(this, "btnClassNamesPrev", _descriptor4, this);
      _initializerDefineProperty(this, "iconPrev", _descriptor5, this);
      _initializerDefineProperty(this, "tooltipLabelNext", _descriptor6, this);
      _initializerDefineProperty(this, "btnClassNamesNext", _descriptor7, this);
      _initializerDefineProperty(this, "iconNext", _descriptor8, this);
    }
    get showBtns() {
      if (this.applicationService.isMobile) {
        return false;
      }
      const menu = this.args.menu || [];
      const menuLength = menu.length || 0;
      if (menuLength > 1) {
        return true;
      }
      return false;
    }
    get disabledPrev() {
      if (!this.showBtns) {
        return true;
      }
      const menu = this.args.menu || [];
      const tabs = this.args.tabs || [];
      const index = this.tabsManager.getActiveMenuIndexByActiveTab(tabs, menu);
      if (index === 0) {
        return true;
      }
      return false;
    }
    get prevLabel() {
      const menu = this.args.menu || [];
      const tabs = this.args.tabs || [];
      const menuItem = this.tabsManager.getPrevMenuItem({
        tabs,
        menu
      });
      return menuItem?._data?.label || 'previous step';
    }
    get nextLabel() {
      const menu = this.args.menu || [];
      const tabs = this.args.tabs || [];
      const menuItem = this.tabsManager.getNextMenuItem({
        tabs,
        menu
      });
      return menuItem?._data?.label || 'next step';
    }
    get disabledNext() {
      const menu = this.args.menu || [];
      const tabs = this.args.tabs || [];
      const menuLength = menu.length || 0;
      const index = this.tabsManager.getActiveMenuIndexByActiveTab(tabs, menu);
      if (index === menuLength - 1) {
        return true;
      }
      return false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tooltipLabelPrev", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return RA.defaultWhen(RA.isNilOrEmpty, 'previous step', this.args.tooltipLabelPrev);
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "btnClassNamesPrev", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return RA.defaultWhen(RA.isNilOrEmpty, '', this.args.btnClassNamesPrev);
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "iconPrev", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return RA.defaultWhen(RA.isNilOrEmpty, 'fas fa-arrow-left', this.args.iconPrev);
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tooltipLabelNext", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return RA.defaultWhen(RA.isNilOrEmpty, 'next step', this.args.tooltipLabelNext);
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "btnClassNamesNext", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return RA.defaultWhen(RA.isNilOrEmpty, '', this.args.btnClassNamesNext);
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "iconNext", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return RA.defaultWhen(RA.isNilOrEmpty, 'fas fa-arrow-right', this.args.iconNext);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showBtns", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "showBtns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disabledPrev", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "disabledPrev"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "prevLabel", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "prevLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextLabel", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "nextLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disabledNext", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "disabledNext"), _class.prototype), _class);
});