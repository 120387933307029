define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/when-editor-flows/component", ["exports", "client/mixins/crud", "ramda", "ramda-extension"], function (_exports, _crud, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    constants: Ember.inject.service(),
    isNew: false,
    isEditing: false,
    hasThen: true,
    adapterName: 'channels/shop/campaigns/automations',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['automations/criterias', 'automations/when', 'automations/when/itemQty', 'channels/shop/campaigns/automations/shipping-behavior']);
    },
    selectedAutomation: Ember.computed('crud.lists.automations-when.[]', 'automationModel._data.when', function () {
      const when = this.get('automationModel._data.when');
      const list = R_.dotPath('crud.lists.automations-when')(this) || [];
      return list.findBy('_data.value', when);
    }),
    conditionsComponent: Ember.computed('selectedAutomation.[]', function () {
      return this.selectedAutomation.getData('component');
    })
  });
});