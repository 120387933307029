define("client/pods/grapesjs/plugins/slider-native/components/index", ["exports", "client/pods/grapesjs/plugins/slider-native/constants", "client/pods/grapesjs/plugins/slider-native/components/Slider", "client/pods/grapesjs/plugins/slider-native/components/Slides", "client/pods/grapesjs/plugins/slider-native/components/Slide", "client/pods/grapesjs/plugins/slider-native/components/NavPrev", "client/pods/grapesjs/plugins/slider-native/components/NavNext", "client/pods/grapesjs/plugins/slider-native/components/SliderFrame", "client/pods/grapesjs/plugins/slider-native/components/NavDots", "client/pods/grapesjs/plugins/slider-native/components/NavDot"], function (_exports, _constants, _Slider, _Slides, _Slide, _NavPrev, _NavNext, _SliderFrame, _NavDots, _NavDot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (editor, config = {}) => {
    const dc = editor.DomComponents;
    const defaultType = dc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const {
      sliderName,
      slideName,
      sliderId,
      slideId
    } = _constants.default;
    (0, _Slider.default)(dc, config);
    (0, _Slides.default)(dc, config);
    (0, _Slide.default)(dc, config);
    (0, _NavPrev.default)(dc, config);
    (0, _NavNext.default)(dc, config);
    (0, _SliderFrame.default)(dc, config);
    (0, _NavDots.default)(dc, config);
    (0, _NavDot.default)(dc, config);
    editor.on('component:script:mount:before', (component, ...args) => {
      if (!component) {
        return;
      }
      console.log(args);
    });
    editor.on('component:script:mount', (component, ...args) => {
      if (!component) {
        return;
      }
      console.log(args);
    });
    editor.on('component:script:unmount', (component, ...args) => {
      if (!component) {
        return;
      }
      console.log(args);
    });
  };
  _exports.default = _default;
});