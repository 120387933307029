define("client/pods/components/dispatch/panels-display/display-list/component", ["exports", "ramda", "ramda-adjunct", "ember-concurrency", "client/mixins/dispatch", "client/mixins/transporter"], function (_exports, R, RA, _emberConcurrency, _dispatch, _transporter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_dispatch.default, _transporter.default, {
    dispatch: Ember.inject.service(),
    tagName: 'div',
    afterRender: false,
    scheduleOnceAfterRender: true,
    init() {
      this._super(...arguments);
      let transporters = this.get('dashData.transporters') || [];
      transporters = R.pluck('transporter')(transporters);
      const filterByTransportersData = {};
      R.forEach(transporter => {
        filterByTransportersData[transporter] = true;
      }, transporters);
      this.set('filterByTransporters', Ember.Object.create(filterByTransportersData));
    },
    didInsertElement() {
      this._super(...arguments);
      if (!this.isPrinting) {
        this.fetchAllProductsTask.perform();
      }
      Ember.run.scheduleOnce('afterRender', this, () => {
        if (this.scheduleOnceAfterRender) {
          this.set('afterRender', true);
        }
      });
    },
    fetchAllProductsTask: (0, _emberConcurrency.task)(function* () {
      const summaryData = this.summaryData;
      const adapterName = 'products';
      const fetchAllProductsQ = R.pipe(R.propOr([], 'items'), R.pluck('itemKey'), R.reject(R.anyPass([R.isNil, R.isEmpty])), R.uniq, R.map(itemKey => {
        const appendPath = itemKey;
        return this.dataManager.findRecord({
          adapterName,
          appendPath
        });
      }))(summaryData);
      const productsList = yield (0, _emberConcurrency.all)(fetchAllProductsQ);
      this.set('productsList', productsList);
      // this.set('afterRenderAndLoaded', true)
    }),
    models: Ember.computed('filteredResults.[]', function () {
      const filteredResults = this.filteredResults;
      return filteredResults.map(result => {
        return result.model;
      });
    }),
    modelDatas: Ember.computed('models.@each._data', function () {
      const models = this.models;
      return models.map(result => {
        return result._data;
      });
    }),
    duplicateWaybills: Ember.computed('dashData.duplicates.waybills', function () {
      return this.get('dashData.duplicates.waybills') || {};
    }),
    duplicateWaybillsDocNos: Ember.computed('duplicateWaybills', function () {
      const duplicateData = this.duplicateWaybills || {};
      return this._getDuplicateDocNos(duplicateData);
    }),
    duplicateAddresses: Ember.computed('dashData.duplicates.addresses.values.[]', function () {
      return this.get('dashData.duplicates.addresses') || {};
    }),
    duplicateAddressesDocNos: Ember.computed('duplicateAddresses.values.[]', function () {
      const duplicateData = this.duplicateAddresses || {};
      return this._getDuplicateDocNos(duplicateData);
    }),
    _getDuplicateDocNos(duplicateData) {
      return R.pipe(R.propOr([], 'values'), R.pluck('docs'), R.flatten)(duplicateData);
    },
    actions: {
      setTransporterFilter(transporter, value) {
        const filterByTransporters = this.filterByTransporters;
        filterByTransporters.set(transporter, value);
      },
      afterSplit() {
        const dispatchProcess = this.dispatchProcess;
        const onAfterSplit = this.onAfterSplit;
        onAfterSplit(dispatchProcess);
      },
      afterMerge() {
        const dispatchProcess = this.dispatchProcess;
        const onAfterMerge = this.onAfterMerge;
        onAfterMerge(dispatchProcess);
      },
      toggleAllDetails(filteredResults) {
        this.toggleProperty('showDetails');
        const showDetails = this.showDetails;
        filteredResults.forEach(modelProxy => {
          modelProxy.set('showDetails', showDetails);
        });
      }
    }
  });
});