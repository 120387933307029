define("client/pods/channels/shop/campaigns/lists/sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "ramda"], function (_exports, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // const filterTabs = (list, filterByLabel) => R.map(
  //   R.pipe(
  //     R.unless(
  //       R.propSatisfies(R.includes(R.__, filterByLabel), 'label'),
  //       R.set(R.lensProp('component'), null)
  //     )
  //   )
  // )(list)
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,
    findAll({
      data = {},
      appendPath = '',
      serverType
    } = {}) {
      const customData = {
        list: [{
          group: 'overview',
          value: 'overview',
          label: 'overview',
          component: 'channels/shop/campaigns/panels-display/sub-panels-overview',
          tabType: 'replaceSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/overview',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/settings-editor'
        }, {
          group: 'overview',
          value: 'activity',
          label: 'activity',
          component: 'channels/shop/campaigns/panels-display/sub-panels-activity',
          tabType: 'replaceSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/activity',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/settings-editor'
        }, {
          group: 'settings',
          value: 'settings',
          label: 'settings',
          hasSubTabs: true,
          component: 'channels/shop/campaigns/panels-display/sub-panels-settings',
          tabType: 'reuseSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/settings',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/settings-editor'
        },
        // {
        //   group: 'settings',
        //   value: 'emails',
        //   label: 'emails',
        //   component: 'channels/shop/campaigns/panels-display/sub-panels-emails',
        //   tabType: 'reuseSubTab',
        //   resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/emails',
        //   editingComponent: 'channels/shop/campaigns/campaigns-editor/emails-editor'
        // },
        // {
        //   group: 'settings',
        //   value: '3rd-party',
        //   label: '3rd-party',
        //   component: 'channels/shop/campaigns/panels-display/sub-panels-3rd-party',
        //   tabType: 'reuseSubTab',
        //   resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/3rd-party',
        //   editingComponent: 'channels/shop/campaigns/campaigns-editor/3rd-party-editor'
        // },
        {
          group: 'products',
          value: 'products',
          label: 'products',
          component: 'channels/shop/campaigns/panels-display/sub-panels-products',
          tabType: 'replaceSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/products'
        }, {
          group: 'products',
          value: 'productsLimits',
          label: 'product limits',
          component: 'channels/shop/campaigns/panels-display/sub-panels-products-limits',
          tabType: 'replaceSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/products-limits',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/products-editor'
        }, {
          group: 'products',
          value: 'salesLimits',
          label: 'sales limits',
          component: 'channels/shop/campaigns/panels-display/sub-panels-sales-limits',
          tabType: 'replaceSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/sales-limits',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/sales-limits-editor'
        }, {
          group: 'webpages',
          value: 'webpages',
          label: 'webpages',
          component: 'channels/shop/campaigns/panels-display/sub-panels-pages',
          tabType: 'reuseSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/webpages',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/pages-editor'
        }, {
          group: 'webpages',
          value: 'advancedTemplate',
          label: 'advancedTemplate',
          component: 'websites/websites-editor/pages-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/webpages',
          // this allows free plan to use advanced templates
          tabType: 'reuseSubTab',
          editingComponent: 'websites/websites-editor/pages-editor'
        }, {
          group: 'webpages',
          value: 'faqs',
          label: 'faqs',
          component: 'channels/shop/campaigns/panels-display/sub-panels-faqs',
          tabType: 'reuseSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/faqs',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/settings-editor'
        }, {
          group: 'promo',
          value: 'automations',
          label: 'automations',
          component: 'channels/shop/campaigns/panels-display/sub-panels-automations',
          tabType: 'replaceSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/automations',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/settings-editor'
        }, {
          group: 'promo',
          value: 'reward',
          label: 'reward',
          component: 'channels/shop/campaigns/panels-display/sub-panels-reward',
          tabType: 'replaceSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/reward',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/reward-editor'
        }, {
          group: 'promo',
          value: 'bulk discounts',
          label: 'bulk discounts',
          component: 'channels/shop/campaigns/panels-display/sub-panels-bulk-discounts',
          tabType: 'replaceSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/bulk-discounts',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/bulk-discounts-editor'
        }, {
          group: 'commissions',
          value: 'commissions',
          label: 'commissions',
          component: 'channels/shop/campaigns/panels-display/sub-panels-commissions',
          tabType: 'replaceSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/commissions',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/commissions-editor'
        }]
      };
      // debugger
      // customData.list = filterTabs(customData.list, ['overview', 'activity', 'settings', 'webpages', 'advancedTemplate', 'faqs', 'products', 'product limits', '3rd-party', 'automations', 'reward', 'commissions', 'bulk discounts'])

      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }
  });
});