define("client/pods/products/lists/editor-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import nventor from '../../../../utils/nventor'
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,
    findAll({
      data = {},
      appendPath,
      serverType
    } = {}) {
      const list = [{
        value: 'details',
        label: 'details',
        resource: 'api/protected/products/sub-panels-edit/details',
        component: 'products/products-editor/details-editor',
        tabType: 'reuseSubTab',
        allowedFor: ['isFromShop', 'isNew', 'isEditing']
      }, {
        value: 'isSet',
        label: 'product_sets',
        resource: 'api/protected/products/sub-panels-edit/sets',
        component: 'products/products-editor/sets-editor',
        tabType: 'reuseSubTab',
        allowedFor: ['isFromShop', 'isNew', 'isEditing']
      }, {
        value: 'isSetWithChoices',
        label: 'product_sets_with_choices',
        resource: 'api/protected/products/sub-panels-edit/sets-with-choices',
        component: 'products/products-editor/sets-with-choices-editor',
        tabType: 'reuseSubTab',
        allowedFor: ['isFromShop', 'isNew', 'isEditing']
      }, {
        value: 'hasVariants',
        label: 'variants',
        resource: 'api/protected/products/sub-panels-edit/variants',
        component: 'products/products-editor/variants-editor',
        tabType: 'reuseSubTab',
        allowedFor: ['isFromShop', 'isNew', 'isEditing']
      }, {
        value: 'webpages',
        label: 'webpages_content',
        resource: 'api/protected/products/sub-panels-edit/webpages',
        component: 'products/products-editor/pages-editor',
        tabType: 'reuseSubTab',
        allowedFor: ['isFromShop', 'isNew', 'isEditing']
      },
      // @TODO: this currently does not do anything yet.
      // this will work, but to complete feature, this needs to send data out on order success email
      // {
      //   value: 'virtual',
      //   label: 'virtual_content',
      //   resource: 'api/protected/products/sub-panels-edit/virtual',
      //   component: 'products/products-editor/virtual-contents-editor',
      //   tabType: 'reuseSubTab'
      //   allowedFor: ['isFromShop', 'isNew'],
      // },
      {
        value: 'seo',
        label: 'seo',
        resource: 'api/protected/products/sub-panels-edit/seo',
        component: 'products/products-editor/seo-editor',
        tabType: 'reuseSubTab',
        allowedFor: ['isFromShop', 'isNew', 'isEditing']
      }, {
        value: 'email',
        label: 'email',
        resource: 'api/protected/products/sub-panels-edit/email',
        component: 'products/products-editor/email-editor',
        tabType: 'reuseSubTab',
        allowedFor: ['isFromShop', 'isNew', 'isEditing']
      }];
      if (this.settings.getProp('beta.allowRecurringSchedules') && this.settings.getProp('features.allowRecurringSchedules')) {
        list.pushObject({
          value: 'recurringSchedules',
          label: 'recurring schedules',
          resource: 'api/protected/recurring',
          component: 'products/products-editor/recurring-schedules-editor',
          tabType: 'reuseSubTab',
          allowedFor: ['isFromShop', 'isNew', 'isEditing'],
          editingComponent: 'products/products-editor/recurring-schedules-editor'
        });
      }
      list.pushObject({
        value: 'customizations',
        label: 'customizations',
        resource: 'api/protected/products/customizations',
        component: 'products/products-editor/customizations-editor',
        tabType: 'reuseSubTab',
        allowedFor: ['isFromShop', 'isNew', 'isEditing'],
        editingComponent: 'products/products-editor/customizations-editor'
      });
      list.pushObject({
        value: 'shop',
        label: 'shop',
        resource: 'api/protected/products/sub-panels-edit/shop',
        component: 'products/products-editor/shop-editor',
        tabType: 'reuseSubTab',
        allowedFor: ['isNew']
      });

      // const isVirtualOnly = this.settings?.isFeatureAllowed('features.isVirtualOnly')
      // if (isVirtualOnly) {
      //   const allowed = [
      //     'details',
      //     'webpages',
      //     'virtual',
      //     'seo',
      //     'activity'
      //   ]
      //   list = R.filter(item => {
      //     return R.includes(item.value)(allowed)
      //   })(list)
      // }

      const customData = {
        list
      };
      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }
  });
});