define("client/pods/components/files-manager/finder/component", ["exports", "@glimmer/component", "ramda-adjunct", "ramda", "ember-concurrency-decorators"], function (_exports, _component, RA, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FilesManagerFinderComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('application'), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _class = class FilesManagerFinderComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "applicationService", _descriptor3, this);
      _initializerDefineProperty(this, "search", _descriptor4, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor5, this);
      _initializerDefineProperty(this, "showResults", _descriptor6, this);
      _initializerDefineProperty(this, "loading", _descriptor7, this);
      _initializerDefineProperty(this, "selectedResource", _descriptor8, this);
      _initializerDefineProperty(this, "selectedTag", _descriptor9, this);
      _initializerDefineProperty(this, "tab", _descriptor10, this);
      _initializerDefineProperty(this, "filters", _descriptor11, this);
      _initializerDefineProperty(this, "selectedItem", _descriptor12, this);
      _initializerDefineProperty(this, "selectedItems", _descriptor13, this);
      _initializerDefineProperty(this, "selectedItemPrevious", _descriptor14, this);
      _initializerDefineProperty(this, "selectedLabel", _descriptor15, this);
      _defineProperty(this, "adapterName", 'uploads');
      // individual model in uploadsCollection._data.uploads
      _defineProperty(this, "collectionAdapterName", 'uploads/collection');
      // collection model that holds _data.uploads
      _defineProperty(this, "filtersAdapterName", 'uploads/filters');
      _defineProperty(this, "finderLabel", this.args.finderLabel || 'files_manager_tags');
      this.crud.addLists(this, ['uploads/lists/resources', 'uploads/lists/tags']);
      const tab = R.path(['args', 'tab'])(this);
      if (tab) {
        this.tab = this.tabsManager.getActiveSubTab(tab);
        if (RA.isNilOrEmpty(this.tab)) {
          this.tab = this.args.tab;
        }
      }
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          excludeCopies: true,
          count: 20
        }
      });
      this.filters = filters;
    }
    get options() {
      const externalOptions = this?.args?.options || {};
      return R.mergeRight({
        isDefault: true
      }, externalOptions);
    }
    get filesBrowserFilter() {}
    get allowMultipleItems() {
      return R.pathOr(true, ['args', 'allowMultipleItems'])(this);
    }
    willDestroy() {
      if (this?.isDestroyed === false) {
        const subTabs = this?.tab?.subTabs || [];
        const firstSubTab = R.head(subTabs);
        const hasSubTab = RA.isNotNilOrEmpty(firstSubTab);
        const hasSubTabs = RA.isNotNilOrEmpty(subTabs);
        if (hasSubTabs && hasSubTab && firstSubTab.set) {
          this.tabsManager.closeTab(subTabs, firstSubTab);
        }
      }
    }
    *onSubmitTask(onCloseDialogAction, options, selectedItems) {
      const alreadyAddedFiles = this.args.alreadyAddedFiles || [];
      selectedItems = R.pipe(RA.ensureArray, R.reject(file => {
        const fileUuid = file?._data?.uuid;
        const found = R.find(R.pathEq(['_data', 'uuid'], fileUuid))(alreadyAddedFiles);
        return found;
      }))(selectedItems);
      if (RA.isNilOrEmpty(selectedItems)) {
        this.resetSelectedItems();
        if (onCloseDialogAction) {
          onCloseDialogAction();
        }
        return;
      }
      const results = yield this.args.onSubmitTask.perform(onCloseDialogAction, options, selectedItems);
      this.resetSelectedItems();
      return results;
    }
    resetSelectedItems() {
      this.selectedItems = [];
    }
    *onRemoveTask(files, {
      onAfter
    } = {}) {
      const options = this.options;
      const onCloseDialogAction = this.args.onCloseDialogAction;
      const isDeleted = yield this.args.onRemoveTask.perform(onCloseDialogAction, options, files);
      if (!isDeleted) {
        return;
      }
      const fileKeys = R.map(R.path(['_data', '_key']))(files);
      R.forEach(file => {
        const fileKey = R.path(['_data', '_key'])(file);
        if (R.includes(fileKey, fileKeys)) {
          file.set('isSelected', false);
          this.selectedItems.removeObject(file);
        }
      })(files);
      if (onAfter) {
        try {
          yield onAfter.perform();
        } catch (error) {
          console.error('ERROR WHILE REMOVING ITEM', error);
          try {
            yield onAfter();
          } catch (error) {}
        }
      }
      return isDeleted;
    }
    setResource(resourceName) {
      this.display = false;
      this.selectedResource = resourceName;
      this.selectedTag = '';
      this.selectedLabel = this.intl.t(resourceName);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          excludeCopies: true,
          count: 20
        }
      });
      this.filters = filters;
      this.filters.setData('resource', resourceName);
      this.filters.setData('tagKey', '');
      this.filters.setData('query', '');
      const panelOptions = {
        component: 'files/files-browser',
        fetchUploadsOnInit: true,
        isModal: true
      };
      this.tabsManager.replaceSubTab(this.tab, panelOptions);
    }
    filterByTagKey(tag) {
      this.display = false;
      const panelOptions = {
        component: 'files/files-browser',
        fetchUploadsOnInit: true
      };
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          excludeCopies: true,
          count: 20
        }
      });
      this.filters = filters;
      this.filters.setData('tagKey', tag?._data?._key);
      this.filters.setData('query', '');
      this.selectedResource = '';
      this.selectedTag = tag?._data?._key;
      this.selectedLabel = tag?._data?.label;
      this.tabsManager.replaceSubTab(this.tab, panelOptions);
    }
    showResultsAfterSearch() {
      this.display = false;
      const panelOptions = {
        component: 'files/files-browser',
        fetchUploadsOnInit: false
      };
      this.filters.setData('tagKey', '');
      this.filters.setData('resource', '');
      this.selectedResource = '';
      this.selectedTag = '';
      this.tabsManager.replaceSubTab(this.tab, panelOptions);
    }
    onSelectItem(selected) {
      if (this.allowMultipleItems) {
        this.selectedItems.addObject(selected);
      } else {
        this.selectedItems.clear();
        this.selectedItems.addObject(selected);
      }
    }
    onDeselectItem(selected) {
      this.selectedItems.removeObject(selected);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showResults", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loading", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedResource", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedTag", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "tab", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "selectedItem", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "selectedItems", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "selectedItemPrevious", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "selectedLabel", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSubmitTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetSelectedItems", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "resetSelectedItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRemoveTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onRemoveTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setResource", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "setResource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterByTagKey", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "filterByTagKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showResultsAfterSearch", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "showResultsAfterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectItem", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDeselectItem", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "onDeselectItem"), _class.prototype), _class);
});