define("client/pods/components/payments/card/panels-transactions/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PaymentsCardPanelsTransactionsComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('application'), _dec3 = Ember.inject.service('search'), _dec4 = Ember.inject.service('date'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _class = class PaymentsCardPanelsTransactionsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "applicationService", _descriptor2, this);
      _initializerDefineProperty(this, "searchService", _descriptor3, this);
      _initializerDefineProperty(this, "dateService", _descriptor4, this);
      _initializerDefineProperty(this, "period", _descriptor5, this);
      _initializerDefineProperty(this, "pastMonths", _descriptor6, this);
      _defineProperty(this, "adapterName", 'payments/card/transactions');
      _defineProperty(this, "adapterNameFilters", 'payments/card/transactions/filters');
      const nowZ = this.dateService.getMoment();
      const monthNow = nowZ.month() + 1;
      const yearNow = nowZ.year();
      this.mm = monthNow;
      this.yyyy = yearNow;
      this.setPeriod();
      this.pastMonths = this.dateService.getPastMonths(14);
      this.searchRecordsTask.perform();
      if (!this.args.showResults) {
        this.args.toggleSidebar();
      }
    }
    updateFilters() {
      this.args.filters.setData('provider', this?.args?.selectedProvider);
      this.args.filters.setData('dateStart', this?.period?.dateStart);
      this.args.filters.setData('dateEnd', this?.period?.dateEnd);
    }
    *searchOnPeriodUpdateTask(period) {
      this.setPeriod(period);
      yield this.searchRecordsTask.perform();
    }
    *searchRecordsTask() {
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: this?.args?.filters,
        resultsProperty: 'results',
        resultsToggle: 'resultsToggle'
      });
    }

    // @task
    // * onReloadSearchRecordsTask () {
    //   yield this.crud.reloadSearchRecordsTask.perform({
    //     adapterName: this.adapterName,
    //     filters: this?.args?.filters,
    //     resultsProperty: 'results',
    //     resultsToggle: 'resultsToggle',
    //     reset: true
    //   })
    // }

    setPeriod(period) {
      period = period || this?.args?.period || this.dateService.getPeriod();
      this.period = R.mergeRight(period, this.dateService.displayByMonth(period));
      this.updateFilters();
    }
    setupFilename() {
      const period = this.period;
      const {
        dateStart,
        dateEnd
      } = period;
      const filename = `${this.intl.t('credit card transactions')} ${dateStart}-${dateEnd}`;
      Ember.set(this, 'filename', filename);
    }
    displayByMonth(period) {
      period.dateStart = this.dateService.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      period.dateEnd = this.dateService.formatDateForUrl(this.dateService.getMonthEnd(period.yyyy, period.mm));
      this.setPeriod(period);
      this.searchRecordsTask.perform();
      Ember.set(this, 'mm', period.mm);
      Ember.set(this, 'yyyy', period.yyyy);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "searchService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "period", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pastMonths", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchOnPeriodUpdateTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchOnPeriodUpdateTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchRecordsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchRecordsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPeriod", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setPeriod"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupFilename", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setupFilename"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayByMonth", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "displayByMonth"), _class.prototype), _class);
});