define("client/pods/uploads/service", ["exports", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "ember-concurrency"], function (_exports, _emberConcurrencyDecorators, R, RA, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * @module UploadsService
   */
  let UploadsService = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = (0, _emberConcurrencyDecorators.enqueueTask)({
    maxConcurrency: 10
  }), _class = class UploadsService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "server", _descriptor4, this);
      _defineProperty(this, "adapterName", 'uploads');
      _defineProperty(this, "adapterNameFilters", 'uploads/filters');
      _defineProperty(this, "collectionAdapterName", 'uploads/collection');
    }
    /**
     * Fetch upload file by uuid
     * @param {string} uuid
     * @returns {Generator<*, void, *>}
     */
    *fetchByUuid(uuid) {
      const filters = this.search.setupFilters({
        adapterName: this.adapterNameFilters,
        defaultFiltersData: {
          excludeCopies: true
        }
      });
      return yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters,
        data: {
          uuid
        }
      });
    }
    *removeTask(onCloseDialogAction, options, uploadsModel) {
      const msg = this.intl.t('are you sure you want to delete');
      uploadsModel = RA.ensureArray(uploadsModel);
      if (window.confirm(msg)) {
        const removeAllTasks = R.map(item => this.removeAllTask.perform(item))(uploadsModel);
        yield (0, _emberConcurrency.all)(removeAllTasks);
        return true;
      }
      return false;
    }
    *removeAllTask(file) {
      const data = {
        _key: file?._data?._key,
        uuid: file?._data?.uuid,
        resource: file?._data?.resource,
        resourceKey: file?._data?.resourceKey
      };
      return yield this.server.call('DELETE', 'api/protected/uploads/uploadcare/file', data);
    }
    *fetchUploadsTask(options = {}) {
      const {
        excludeCopies = true,
        onlyDisplayDefault = false,
        metadata
      } = options;
      this.uploadsCollection = yield this.crud.fetchUploadsTask.perform({
        ...metadata,
        adapterName: this.collectionAdapterName,
        params: {
          excludeCopies,
          onlyDisplayDefault,
          count: 30
        }
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchByUuid", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchByUuid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAllTask", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeAllTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchUploadsTask", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "fetchUploadsTask"), _class.prototype), _class);
});