define("client/pods/components/menus/tab-menu-new/component", ["exports", "@glimmer/component", "client/utils/nventor", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _component, _nventor, _ramda, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let MenusTabMenuNewComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _class = class MenusTabMenuNewComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "tabsManager", _descriptor, this);
      _initializerDefineProperty(this, "selected", _descriptor2, this);
      _initializerDefineProperty(this, "hasWrap", _descriptor3, this);
      _initializerDefineProperty(this, "alreadySelected", _descriptor4, this);
      _initializerDefineProperty(this, "autoSelectFirstTab", _descriptor5, this);
      _initializerDefineProperty(this, "hideWhenOnlyOneMenuItem", _descriptor6, this);
      _defineProperty(this, "menuLabelKey", this.args?.menuLabelKey || '_data.label');
      _defineProperty(this, "classNames", this.args?.classNames || 'u-tab-menu');
    }
    get menu() {
      return this.args.menu || this.args.wizardContainerComponent?.stepsList || [];
    }
    get showMenu() {
      const hideWhenOnlyOneMenuItem = this.hideWhenOnlyOneMenuItem;
      const menu = this.args.menu || [];
      if (hideWhenOnlyOneMenuItem && menu.length <= 1) {
        return false;
      }
      return true;
    }
    setup() {
      const menu = this.menu || [];
      if (this.autoSelectFirstTab) {
        const tab = this.args.tab;
        const initialPanel = this.tabsManager.getInitialPanel(tab, menu);
        this.select(initialPanel);
      }
      this.alreadySelected = false;
    }
    setSelected(activeSubTabMenu) {
      const menu = this.menu || [];
      if (menu?.length <= 0) {
        return {};
      }
      if (RA.isNilOrEmpty(activeSubTabMenu)) {
        this.selected = null;
        return {};
      }
      this.alreadySelected = true;
      this.selected = activeSubTabMenu;
      return activeSubTabMenu;
    }
    get translate() {
      return _ramda.default.pathOr(true, ['args', 'translate'])(this);
    }
    get groupedMenu() {
      const menu = this.menu || [];
      const hasGroupProp = _ramda.default.find(_ramda.default.path(['_data', 'group']))(menu);
      if (!hasGroupProp) {
        return {
          '': menu
        };
      }
      const groupByPath = this.args?.groupByPath || '_data.group';
      const grouped = _ramda.default.groupBy(R_.dotPath(groupByPath))(menu);
      return grouped;
    }

    /**
     * Select tab item
     * @param {Object} item
     * @void
     */
    select(item) {
      this.setSelected(item);
      if (this.args?.onSelect) {
        this.args.onSelect(item);
      }
    }

    /**
     * Set css prop of tab header-title
     * @param {Object} params
     * @param {HTMLElement} params.tabMenuContainer
     * @param {HTMLElement|Null} params.tabGridContent
     * @param {HTMLElement|Null} params.headerTitle
     * @param {HTMLElement|Null} params.outerPanelContent if not null, that means it is a panel grid inside another one
     * @void
     */
    _setHeaderTitleCss({
      tabMenuContainer,
      tabGridContent,
      headerTitle,
      outerPanelContent
    } = {}) {
      if (tabGridContent) {
        const tabContentOnly = this.args?.tabContentOnly || false;
        if (tabContentOnly) {
          tabMenuContainer.style.top = 'auto';
        } else {
          const offsetTop = tabGridContent.offsetTop || 128;
          tabMenuContainer.style.top = `${offsetTop}px`;
        }
      }
      if (outerPanelContent) {
        if (headerTitle) {
          headerTitle.style.zIndex = 'unset';
        }
        tabMenuContainer.style.zIndex = 'unset';
      } else {
        // if (headerTitle && !headerTitle.classList.contains('hidden')) {
        //   const tabMenuTop = tabMenuContainer?.offsetTop
        //   const tabMenuHeight = tabMenuContainer?.offsetHeight

        //   if (!tabMenuTop || !tabMenuHeight) {
        //     return
        //   }

        //   const tabMenuBottom = tabMenuTop + tabMenuHeight
        //   headerTitle.style.top = `${tabMenuBottom}px`
        //   headerTitle.style.position = 'sticky'
        // }
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasWrap", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _ramda.default.pathOr(true, ['args', 'hasWrap'])(this);
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "alreadySelected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "autoSelectFirstTab", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _ramda.default.pathOr(true, ['args', 'autoSelectFirstTab'])(this);
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hideWhenOnlyOneMenuItem", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _ramda.default.pathOr(false, ['args', 'hideWhenOnlyOneMenuItem'])(this);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSelected", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _class);
});