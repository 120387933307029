define("client/pods/components/products/panels-edit/component", ["exports", "client/mixins/crud", "ember-concurrency-decorators", "ramda-extension", "ramda-adjunct", "ramda", "client/utils/nventor"], function (_exports, _crud, _emberConcurrencyDecorators, R_, RA, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    settings: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    adapterName: 'products',
    init() {
      this.setup();
      this._super(...arguments);
    },
    setup() {
      this.crud.addLists(this, ['products/lists/displaySubPanels']);
      const tab = this.tab;
      const model = tab.model;
      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model,
        tab
      });
      this.set('dirty', dirty);
      this.set('model', model);
      this.tabsManager.setHeader(this.tabParent, dirty.getData('name'));
    },
    displaySubPanels: Ember.computed('crud.lists.products-lists-displaySubPanels.[]', 'model._data.setType', function () {
      let panels = R_.dotPath('crud.lists.products-lists-displaySubPanels')(this) || [];
      let removeAll = false;
      const setType = this.get('model._data.setType');
      if (_nventor.default.isNilOrEmpty(setType)) {
        removeAll = true;
      }
      if (setType !== 'isSet' && setType !== 'isSetWithChoices' && setType !== 'hasVariants') {
        removeAll = true;
      }
      if (setType === 'isSet' || removeAll) {
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
      }
      if (setType === 'isSetWithChoices' || removeAll) {
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
      }
      if (setType === 'hasVariants' || removeAll) {
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
      }
      return panels;
    }),
    *updateRecordTask({
      campaignDetailInstructions,
      updateShopPrices = false
    } = {}) {
      const tab = this.tab;
      const model = this.dirty;
      const adapterName = this.adapterName;
      const onAfter = this.onAfterSave;
      Ember.set(this, 'campaignDetailInstructions', campaignDetailInstructions);
      Ember.set(this, 'updateShopPrices', updateShopPrices);
      yield this.crud.updateRecordTask.perform({
        adapterName,
        model,
        excludeProps: ['faqs'],
        onAfter,
        tab
      });
    },
    onAfterSave(model) {
      if (this.updateShopPrices) {
        const campaignDetailInstructions = this.campaignDetailInstructions;
        const tabOptions = {
          loadInstructions: {
            campaignDetailInstructions: campaignDetailInstructions
          }
        };
        return this.showShopWizard(model, tabOptions);
      }
      const tab = this.tab;
      const activeSubTab = this.tabsManager.getActiveSubTab(tab);
      const subPanels = this.displaySubPanels;
      let subTabOptions = subPanels.findBy('_data.value', activeSubTab.get?.('value'));
      if (RA.isNilOrEmpty(subTabOptions)) {
        subTabOptions = subPanels.findBy('_data.editingComponent', activeSubTab.get?.('component')) || _nventor.default.safeHeadOr({}, subPanels);
      }
      const tabOptions = {
        loadInstructions: {
          component: subTabOptions.get?.('_data.component'),
          label: subTabOptions.get?.('_data.label'),
          value: subTabOptions.get?.('_data.value')
        }
      };
      return this.onDisplay(model, {
        tabOptions
      });
    }
  }, _applyDecoratedDescriptor(_obj, "updateRecordTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "updateRecordTask"), _obj), _applyDecoratedDescriptor(_obj, "onAfterSave", [_dec], Object.getOwnPropertyDescriptor(_obj, "onAfterSave"), _obj), _obj));
});