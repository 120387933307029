define("client/pods/components/dispatch/processes-item-waybills-seven-eleven/last-milestone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9v1Tj9Wu",
    "block": "{\"symbols\":[\"newGroupedByDaysOld\",\"@milestoneData\",\"@onSubmit\",\"@isRunning\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[\"lastMilestone\"]],\"readyForPickupAtDestination\"],null]],null,{\"statements\":[[4,\"each\",[[23,2,[\"newGroupedByDaysOld\"]]],null,{\"statements\":[[0,\"    \"],[5,\"elements/element-btn\",[],[[\"@label\",\"@suffix\",\"@translate\",\"@classNames\",\"@hasBadge\",\"@badge\",\"@badgeClassNames\",\"@onSubmit\",\"@isRunning\"],[[28,\"tt\",[[28,\"concat\",[\"shippingStatus_\",[23,2,[\"lastMilestone\"]]],null]],null],[28,\"concat\",[\"(\",[28,\"tt\",[[23,1,[\"daysOld\"]]],null],\")\"],null],false,[22,\"btnClassNames\"],true,[23,1,[\"total\"]],[23,0,[\"badgeClassNames\"]],[28,\"fn\",[[23,3,[]],[28,\"hash\",null,[[\"waybillLastStatus\",\"daysOld\"],[[23,2,[\"lastMilestone\"]],[23,1,[\"daysOld\"]]]]]],null],[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"not-eq\",[[23,2,[\"lastMilestone\"]],[23,0,[\"constants\",\"shippingStatus\",\"waybillCreated\"]]],null]],null,{\"statements\":[[0,\"    \"],[5,\"elements/element-btn\",[],[[\"@label\",\"@translate\",\"@classNames\",\"@hasBadge\",\"@badge\",\"@badgeClassNames\",\"@onSubmit\",\"@isRunning\"],[[28,\"tt\",[[28,\"concat\",[\"shippingStatus_\",[23,2,[\"lastMilestone\"]]],null]],null],false,[22,\"btnClassNames\"],true,[23,2,[\"docCount\"]],[23,0,[\"badgeClassNames\"]],[28,\"fn\",[[23,3,[]],[28,\"hash\",null,[[\"waybillLastStatus\"],[[23,2,[\"lastMilestone\"]]]]]],null],[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dispatch/processes-item-waybills-seven-eleven/last-milestone/template.hbs"
    }
  });
});