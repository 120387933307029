define("client/pods/filters/model", ["exports", "ramda", "ramda-adjunct", "ramda-extension", "client/pods/base/model", "client/utils/nventor", "client/pods/filters/sort/model"], function (_exports, R, RA, R_, _model, _nventor, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = _model.default.extend((_dec = Ember._action, _obj = {
    defaults() {
      return {
        page: 1,
        count: 20
      };
    },
    populate(data = {}) {
      this._super(...arguments);
    },
    reset(options = {}) {
      this._super(...arguments);
      this.setData('query', '');
      this.set('currentQuerySubmitted', '');
    },
    transformSort(rawData) {
      const sort = R.propOr([], 'sort')(rawData);
      if (!R.isEmpty(sort)) {
        rawData.sort = R.map(sortData => _nventor.default.createModel(_model2.default, sortData))(sort);
      }
      return rawData;
    },
    // const localecompare = R.curry((getter, a, b) => {
    //   const aV = getter(a)
    //   const bV = getter(b)
    //   return bV.localeCompare(aV)
    // })

    // const alice = {
    //   name: 'alice',
    //   age: 40
    // };
    // const bob = {
    //   name: 'bob',
    //   age: 30
    // };
    // const clara = {
    //   name: 'Blara',
    //   age: 30
    // };
    // const people = [clara, bob, alice];
    // const ageNameSort = R.sortWith([
    //   R.descend(R.prop('age')),
    //   lc(R.prop('name'))
    // ]);
    // ageNameSort(people); //=> [alice, clara, bob]

    getRamdaSortWithArray(propPrefix = '_data.') {
      const sortArray = this?._data?.sort || [];
      return R.pipe(R.map(sortByObject => {
        const sortBy = sortByObject?.by;
        if (!sortBy) {
          return false;
        }
        const prop = `${propPrefix}${sortBy}`;
        if (R.toLower(sortByObject?.direction || '') === 'desc') {
          return _nventor.default.sortTextAndNumericDescendingWithGetter(R_.dotPathOr('', prop));
        }
        return _nventor.default.sortTextAndNumericAscendingWithGetter(R_.dotPathOr('', prop));
      }), R.reject(R.equals(false)))(sortArray);
    },
    addSort(by, direction, {
      toNumber = false,
      paramPrefix = '',
      keepParamPrefix = false
    } = {}) {
      return this._setSortBy('add', by, direction, {
        toNumber,
        paramPrefix,
        keepParamPrefix
      });
    },
    replaceSort(by, direction, {
      toNumber = false,
      paramPrefix = '',
      keepParamPrefix = false
    } = {}) {
      return this._setSortBy('replace', by, direction, {
        toNumber,
        paramPrefix,
        keepParamPrefix
      });
    },
    addSortNoModel(by, direction, {
      toNumber = false,
      paramPrefix = '',
      keepParamPrefix = false
    } = {}) {
      if (RA.isNilOrEmpty(by)) {
        return;
      }
      if (RA.isNilOrEmpty(direction)) {
        return;
      }
      const sortArray = this?._data?.sort || [];
      sortArray.pushObject({
        by,
        direction,
        toNumber,
        paramPrefix,
        keepParamPrefix
      });
      this.setData('sort', sortArray);
    },
    replaceSortNoModel(by, direction, {
      toNumber = false,
      paramPrefix = '',
      keepParamPrefix = false
    } = {}) {
      this.setData('sort', []);
      if (RA.isNilOrEmpty(by)) {
        return;
      }
      if (RA.isNilOrEmpty(direction)) {
        return;
      }
      this.setData('sort', [{
        by,
        direction,
        toNumber,
        paramPrefix,
        keepParamPrefix
      }]);
    },
    _setSortBy(method, by, direction, {
      toNumber,
      paramPrefix,
      keepParamPrefix
    }) {
      // this.set('direction', direction)
      let sortByObject = this.getSortByObject(by);
      let sort = [];
      if (method === 'add') {
        sort = this?._data?.sort || [];
        if (sortByObject) {
          if (direction) {
            sortByObject.setData('direction', direction);
            sortByObject.setData('toNumber', toNumber);
            sortByObject.setData('paramPrefix', paramPrefix);
            sortByObject.setData('keepParamPrefix', keepParamPrefix);
          } else {
            sort.removeObject(sortByObject);
          }
        } else {
          sortByObject = _nventor.default.createModel(_model2.default, {
            by,
            direction,
            toNumber,
            paramPrefix,
            keepParamPrefix
          });
        }
      } else {
        sortByObject = _nventor.default.createModel(_model2.default, {
          by,
          direction,
          toNumber,
          paramPrefix,
          keepParamPrefix
        });
      }
      if (direction) {
        sort.pushObject(sortByObject);
      }
      return this.setData('sort', sort);
    },
    getSortByObject(by) {
      const sort = this?._data?.sort || [];
      const found = R.find(R.pathEq(['_data', 'by'], by))(sort);
      if (found) {
        return found;
      }
      return '';
    },
    getPropLength(prop) {
      const value = this.get(prop) || [];
      return value.length;
    },
    deselectParam(param) {
      this.setData(param, '');
    },
    statusesCount: Ember.computed('_data.statuses.[]', function () {
      return this.getPropLength('_data.statuses');
    }),
    hasNewSearchResults: Ember.computed('fullCount', 'previousQueries.[]', 'currentQuerySubmitted', function () {
      const currentQuerySubmitted = this.currentQuerySubmitted || '';
      if (!currentQuerySubmitted) {
        return false;
      }
      const previousQueries = this.previousQueries || [];
      const lastQuery = R.pathOr({}, [0])(previousQueries);
      if (!lastQuery) {
        return false;
      }
      if (lastQuery !== currentQuerySubmitted) {
        return false;
      }
      const fullCount = Number(this.fullCount) || 0;
      if (fullCount > 0) {
        return true;
      }
      return false;
    })
  }, _applyDecoratedDescriptor(_obj, "deselectParam", [_dec], Object.getOwnPropertyDescriptor(_obj, "deselectParam"), _obj), _obj));
});