define("client/pods/channels/shop/campaigns/lists/editor-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "ramda"], function (_exports, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,
    findAll({
      data = {},
      appendPath,
      serverType
    } = {}) {
      const customData = {
        list: [{
          value: 'settings',
          label: 'settings',
          component: 'channels/shop/campaigns/campaigns-editor/settings-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/settings',
          tabType: 'reuseSubTab',
          hasSubTabs: true,
          subTabsAdapter: 'channels/shop/campaigns/lists/settings-editor/editor-sub-panels'
        }, {
          value: 'webpages',
          label: 'webpages',
          component: 'channels/shop/campaigns/campaigns-editor/pages-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/webpages',
          tabType: 'reuseSubTab',
          errorsTracker: [{
            context: 'template',
            label: 'home page'
          }, {
            context: 'expiredTemplate',
            label: 'message to display when campaign has ended'
          }]
        }, {
          value: 'advancedTemplate',
          label: 'advancedTemplate',
          component: 'websites/websites-editor/pages-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/webpages',
          // allow free plan to use advanced templates
          tabType: 'reuseSubTab'
        }, {
          value: 'salesLimits',
          label: 'sales limits',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/sales-limits',
          // allow free plan to use advanced templates
          component: 'channels/shop/campaigns/campaigns-editor/sales-limits-editor',
          tabType: 'reuseSubTab'
        },
        // {
        //   value: 'emails',
        //   label: 'emails',
        //   component: 'channels/shop/campaigns/campaigns-editor/emails-editor',
        //   resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/emails',
        //   tabType: 'reuseSubTab',
        //   errorsTracker: [
        //     {
        //       context: 'emailFromName',
        //       label: 'from email name'
        //     },
        //     {
        //       context: 'emailFromAddress',
        //       label: 'from email address'
        //     },
        //     {
        //       context: 'emailSignature',
        //       label: 'email signature'
        //     },
        //     {
        //       context: 'orderedEmailSubject',
        //       label: 'email subject'
        //     },
        //     {
        //       context: 'orderedEmailMessage',
        //       label: 'email message'
        //     },
        //     {
        //       context: 'orderedEmailCopyTo',
        //       label: 'send a copy of order email to addresses'
        //     },
        //     {
        //       context: 'orderMessagesEmailCopyTo',
        //       label: 'send a copy of order messages to addresses'
        //     },
        //     {
        //       context: 'dispatchedEmailSubject',
        //       label: 'email subject'
        //     },
        //     {
        //       context: 'dispatchedEmailMessage',
        //       label: 'email message'
        //     }
        //   ]
        // },
        // {
        //   value: '3rd-party',
        //   label: '3rd-party',
        //   component: 'channels/shop/campaigns/campaigns-editor/3rd-party-editor',
        //   resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/3rd-party',
        //   tabType: 'reuseSubTab',
        //   errorsTracker: [
        //     {
        //       context: 'facebookUrl',
        //       label: 'facebook page url'
        //     },
        //     {
        //       context: 'instagramUrl',
        //       label: 'instagram url'
        //     },
        //     {
        //       context: 'youtubeUrl',
        //       label: 'youtube channel url'
        //     },
        //     {
        //       context: 'lineId',
        //       label: 'line id'
        //     }
        //   ]
        // },
        {
          value: 'reward',
          label: 'reward',
          component: 'channels/shop/campaigns/campaigns-editor/reward-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/reward',
          tabType: 'reuseSubTab',
          errorsTracker: [{
            context: 'rewardName',
            label: 'reward name'
          }]
        }, {
          value: 'commissions',
          label: 'commissions',
          component: 'channels/shop/campaigns/campaigns-editor/commissions-editor',
          tabType: 'reuseSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/commissions',
          errorsTracker: [{
            context: 'commissions',
            label: 'commissions'
          }]
        }
        // {
        //   value: 'bulk discounts',
        //   label: 'bulk discounts',
        //   component: 'channels/shop/campaigns/campaigns-editor/bulk-discounts-editor',
        //   resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/bulk-discounts',
        //   tabType: 'reuseSubTab',
        //   errorsTracker: [
        //     {
        //       context: 'name',
        //       label: 'name'
        //     }
        //   ]
        // }
        ]
      };
      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      return this.ajax.POST(path, customData).then(res => res?.data || []);
    }
  });
});