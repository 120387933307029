define("client/pods/components/layouts/sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jvoYemHE",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"showSidebar\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"applicationService\",\"isMobile\"]]],null,{\"statements\":[[0,\"    \"],[5,\"modals/modal-container-new\",[],[[\"@closeOnOverlayClick\",\"@isSidebar\",\"@onToggleModalDialog\",\"@modalContentClassNames\"],[true,true,[28,\"fn\",[[23,0,[\"toggleSidebar\"]]],null],\"modal-container--mobile\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasMobileCloseBtn\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",false],[12,\"class\",\"layouts__sidebar is-sticky-row-auto allow-mobile layouts__close-btn-container\"],[3,\"did-insert\",[[23,0,[\"applicationService\",\"setStickyTopMobile\"]]]],[8],[0,\"\\n          \"],[5,\"elements/element-pushbtn\",[],[[\"@classNames\",\"@onSubmit\"],[\"delete\",[28,\"fn\",[[23,0,[\"toggleSidebar\"]]],null]]]],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[14,2,[[23,0,[]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",[29,[\"layouts__sidebar is-sticky-row-auto allow-mobile \",[23,0,[\"sidebarClassNames\"]]]]],[13,1],[3,\"did-insert\",[[23,0,[\"applicationService\",\"setStickyTop\"]]]],[8],[0,\"\\n      \"],[14,2,[[23,0,[]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/sidebar/template.hbs"
    }
  });
});