define("client/helpers/dayOfWeek", ["exports", "client/mixins/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend(_date.default, {
    intl: Ember.inject.service(),
    compute(params /* ,  hash */) {
      // 0 = sunday
      const day = params[0];
      if (day === 0) {
        return 'sunday';
      }
      if (day === 1) {
        return 'monday';
      }
      if (day === 2) {
        return 'tuesday';
      }
      if (day === 3) {
        return 'wednesday';
      }
      if (day === 4) {
        return 'thursday';
      }
      if (day === 5) {
        return 'friday';
      }
      if (day === 6) {
        return 'saturday';
      }
      return '';
    }
  });
});