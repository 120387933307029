define("client/pods/components/products/panels-new/component", ["exports", "client/mixins/crud", "client/utils/nventor", "ember-concurrency-decorators"], function (_exports, _crud, _nventor, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    constants: Ember.inject.service(),
    campaignsProductsService: Ember.inject.service('campaigns-products'),
    adapterName: 'products',
    init: function () {
      this._super(...arguments);
      this.setup();
      this.tabsManager.setHeader(this.tabParent, this.intl.t('new'));
    },
    setup() {
      if (!this.model) {
        const name = this.name || '';
        const resourceKeyTemp = _nventor.default.random.alphaNum(8);
        const data = {
          name,
          resourceKeyTemp
        };

        // setup new products model
        const model = this.crud.setupNewRecord?.({
          adapterName: this.adapterName,
          data
        });
        this.set('model', model);
        this.set('newModels', [model]);
      }
    },
    *saveRecordTask({
      campaignDetailInstructions,
      updateShopPrices = false
    } = {}) {
      const tab = this.tab;
      const model = this.model;
      const adapterName = this.adapterName;
      const onAfter = this.onAfterSave;
      Ember.set(this, 'campaignDetailInstructions', campaignDetailInstructions);
      Ember.set(this, 'updateShopPrices', updateShopPrices);
      yield this.crud.createRecordTask.perform({
        adapterName,
        model,
        onAfter,
        tab
      });
    },
    onAfterSave(model, onAfterOptions) {
      if (this.updateShopPrices) {
        const campaignDetailInstructions = this.campaignDetailInstructions;
        const tabOptions = {
          loadInstructions: {
            campaignDetailInstructions: campaignDetailInstructions
          }
        };
        return this.showShopWizard(model, tabOptions);
      }
      return this.onAfterCreateRecordTask(model, onAfterOptions);
    }
  }, _applyDecoratedDescriptor(_obj, "saveRecordTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "saveRecordTask"), _obj), _applyDecoratedDescriptor(_obj, "onAfterSave", [_dec], Object.getOwnPropertyDescriptor(_obj, "onAfterSave"), _obj), _obj));
});