define("client/pods/campaigns-products/service", ["exports", "ramda", "ramda-adjunct", "ramda-extension", "client/utils/nventor", "ember-concurrency"], function (_exports, R, RA, R_, _nventor, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    intl: Ember.inject.service(),
    crud: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    settings: Ember.inject.service(),
    server: Ember.inject.service(),
    bigService: Ember.inject.service('big'),
    constants: Ember.inject.service(),
    init() {
      this._super('...arguments');
      this.set('statusClasses', {
        [this.get('constants.productsStatus.active')]: 'is-primary',
        [this.get('constants.productsStatus.inactive')]: 'is-danger',
        [this.get('constants.productsStatus.preOrder')]: 'is-warning',
        [this.get('constants.productsStatus.outOfStock')]: 'is-black',
        [this.get('constants.productsStatus.soldOut')]: 'is-black'
      });
    },
    getStatusClass(status) {
      const statusClasses = this.statusClasses;
      return R.propOr(this.unknownStatusClass, status, statusClasses);
    },
    getCampaignDetailModel(data = {}) {
      const detail = this.crud.setupNewRecord({
        adapterName: 'channels/shop/campaigns/details',
        data
      });
      return detail;
    },
    hasDuplicate(item, product) {
      // const campaignDetails = this.get('campaignDetails')

      // const hasEqualItemKey = R.pathEq(['_data', 'itemKey'], product.getData('_key'))
      // const found = R.find(hasEqualItemKey)(campaignDetails)
      // if (found) {
      //   const msg = this.get('intl').t('duplicate product')
      //   window.alert(msg)

      //   item.resetDetail(item)

      //   return true
      // }

      return false;
    },
    addProductTask: (0, _emberConcurrency.task)(function* ({
      campaign,
      product,
      campaignDetail
    }) {
      let detail = campaignDetail;
      if (!campaignDetail) {
        detail = campaign.addDetail({
          status: this?.constants?.productsStatus?.draft
        });
      }
      try {
        yield this.loadProductTask.perform(campaign, detail, product);
        const created = yield this.crud.createRecordTask.perform({
          adapterName: detail.adapterName,
          model: detail
        });
        if (created) {
          product.set('campaignDetail', created);
        }
        if (!created) {
          product.set('isSelected', false);
        }
        return created;
      } catch (e) {
        product.set('isSelected', false);
        console.log('error', e);
        return false;
      }
    }),
    removeProductTask: (0, _emberConcurrency.task)(function* ({
      campaign,
      product
    }) {
      try {
        const campaignDetail = product.campaignDetail;
        if (campaignDetail) {
          const status = campaignDetail?._data?.status;
          const removed = yield this.crud.removeRecordTask.perform({
            adapterName: campaignDetail.adapterName,
            model: campaignDetail,
            noConfirm: R.equals(status, this.constants.productsStatus.draft)
          });
          if (removed) {
            product.set('campaignDetail', '');
          } else {
            product.set('isSelected', true);
          }
        }
      } catch (e) {
        product.set('isSelected', true);
        console.log('error', e);
        return false;
      }
    }),
    loadProductTask: (0, _emberConcurrency.task)(function* (campaignOrAutomationModel, item, product) {
      if (!this.hasDuplicate(item, product)) {
        return yield this._loadProductTask.perform(...arguments);
      }
      return item;
    }),
    _loadProductTask: (0, _emberConcurrency.task)(function* (campaignOrAutomationModel, item, product, {
      isResync = false
    } = {}) {
      yield item.loadProduct(campaignOrAutomationModel, item, product, {
        isResync
      });
      if (R_.dotPathEq('_data.isSet', true, item)) {
        item.recalculatePriceForSet(item, item);
        item.setData('isSetNew', true);
      }
      const isAutomationMustAdd = campaignOrAutomationModel?.isAutoAdd || false;
      if (isAutomationMustAdd) {
        item.setData('priceDiscounted', '0');
        item.setData('priceFinal', '0');
        item.setData('onSale', 'on');
        if (item.getData('isSet') || item.getData('isSetNew')) {
          const setDetails = item.getData('setDetails') || [];
          R.forEach(detailChild => {
            detailChild.setData('priceDiscounted', '0');
            detailChild.setData('priceFinal', '0');
            detailChild.setData('onSale', 'on');
          })(setDetails);
        }
      }
      if (R_.dotPathEq('_data.isSetWithChoices', true, item)) {
        R.forEach(choice => {
          const isSetPriceOnBundle = R.pathOr('', ['_data', 'isSetPriceOnBundle'], choice);
          if (!isSetPriceOnBundle) {
            choice.setData('isSetPriceOnBundle', false);
          }
        })(_nventor.default.ensurePathOr([], ['_data', 'choices'], item));
        item.recalculatePrice(item);
      }
      return item;
    }),
    reSyncProductTask: (0, _emberConcurrency.task)(function* (campaignOrAutomationModel, item, itemKey, {
      onAfter
    } = {}) {
      const product = yield this.crud.findTask.perform({
        adapterName: 'products',
        appendPath: `/${itemKey}`
      });
      return this._loadProductTask.perform(campaignOrAutomationModel, item, product, {
        isResync: true
      });
    }).drop(),
    fetchDocHistoryTask: (0, _emberConcurrency.task)(function* (docModel, preferredKey, product, itemKey) {
      const docType = docModel.getData('docType');
      const docName = docModel.getData('docName');
      const contactKey = docModel.getData('contactKey');
      const contact = docModel.getData('contact');
      const priceHistoryData = yield this.getHistoryTask.perform({
        docType,
        docName,
        contact,
        contactKey,
        itemKey
      });
      let preferredHistoryData;
      preferredHistoryData = this.getLatestFor(preferredKey, priceHistoryData);
      if (!preferredHistoryData) {
        preferredHistoryData = product.serialize({
          exclude: ['_key', '_rev', '_id']
        });
      }
      return {
        priceHistoryData,
        preferredHistoryData
      };
    }),
    fetchPurchasesHistoryTask: (0, _emberConcurrency.task)(function* (docModel, preferredKey, product, itemKey) {
      docModel.setData('docType', 'purchases');
      docModel.setData('docName', 'invoices');
      return yield this.fetchDocHistoryTask.perform(docModel, preferredKey, product, itemKey);
    }),
    getHistoryTask: (0, _emberConcurrency.task)(function* ({
      docType,
      docName,
      contact,
      contactKey,
      item,
      itemKey,
      docTypes
    }) {
      const data = {
        contact,
        contactKey,
        item,
        itemKey,
        docTypes
      };
      const results = yield this.server.call('GET', `api/protected/documents/data/prices/${docType}/${docName}`, data);
      return R.propOr({}, 'data', results);
    }),
    fetchHistoryCampaignsTask: (0, _emberConcurrency.task)(function* (product, itemKey, isSet, isSetWithChoices, campaignKey = '', options = {}) {
      const isAutomation = options?.isAutomation || false;
      const thenAction = options?.thenAction;
      const results = yield this.server.call('GET', 'api/protected/documents/data/prices/campaigns', {
        itemKey,
        isSet,
        isSetWithChoices,
        isAutomation,
        thenAction
      });
      const priceHistoryData = R.propOr({}, 'data', results);
      let prop = 'campaigns';
      if (isAutomation) {
        prop = 'automations';
      }
      let preferredHistoryData = this.getLatestFor(prop, priceHistoryData);
      if (!preferredHistoryData && RA.isNotNilOrEmpty(product)) {
        preferredHistoryData = product.serialize({
          exclude: ['_key', '_rev', '_id']
        });
      }
      let preferredPriceData = preferredHistoryData || {};
      // @TODO: move to backend
      const docsHistoryPrice = R.pipe(R.pathOr([], ['history', 'documents']), R.sortWith([R.descend(R.prop('dateZ')), R.descend(R.prop('timestampZ_latest'))]))(priceHistoryData);
      if (RA.isNilOrEmpty(preferredPriceData) && RA.isNotNilOrEmpty(docsHistoryPrice)) {
        // this fetches latest for current campaign. only applicable if item has been removed from campaign, and wanting to put back
        // the problem here is any other campaign is created, it will always get the price from the other campaign.
        // so this block is never really used.
        // ideally there should be a setting, ALWAYS GET FROM CURRENT CAMPAIGN or ALWAYS GET FROM ANY CAMPAIGN (by latest)
        // if (campaignKey) {
        //   preferredPriceData = R.find(
        //     R.propEq('sourceKey', campaignKey)
        //   )(docsHistoryPrice)
        // }

        // currenlty alway get from ANY LATEST CAMPAIGN
        if (RA.isNilOrEmpty(preferredPriceData)) {
          preferredPriceData = R.find(R.pipe(R.prop('sourceKey'), RA.isNotNilOrEmpty))(docsHistoryPrice);
        }
      }
      return {
        priceHistoryData,
        preferredHistoryData: preferredPriceData
      };
    }),
    getFor(prop, historyData) {
      return R.pathOr([], ['history', prop], historyData);
    },
    getLatestFor(prop, historyData) {
      // return R.pathOr([], ['history', prop], historyData)
      const forProp = this.getFor(prop, historyData);
      let found = false;
      if (!R.isEmpty(forProp)) {
        found = R.head(forProp);
      }
      return found;
    },
    getLatestDetail(props, historyData) {
      let found = '';
      R.forEach(prop => {
        if (!found) {
          const forProp = this.getFor(prop, historyData);
          if (!R.isEmpty(forProp)) {
            found = R.head(forProp);
          }
        }
      }, props);
      return found || false;
    },
    setPrice(model, historyData, onHideDropdown) {
      model.setPrice(historyData);
      if (onHideDropdown) {
        onHideDropdown();
      }
    },
    setDetailPrice(campaignOrAutomationModel, detail, child, historyData, onHideDropdown) {
      detail.setDetailPrice(campaignOrAutomationModel, child, historyData);
      if (onHideDropdown) {
        onHideDropdown();
      }
    },
    selectChildHistoryPrice(campaignOrAutomationModel, parent, detail, child, historyData, onHideDropdown) {
      parent.setChildProductPrice(campaignOrAutomationModel, child, historyData);
      if (R.pathEq(['_data', 'isSet'], true, detail)) {
        parent.recalculatePriceForSet(detail, parent);
      }
      if (R.pathEq(['_data', 'isSetWithChoices'], true, detail)) {
        parent.recalculatePrice(detail);
      }
      if (onHideDropdown) {
        onHideDropdown();
      }
    },
    getTaxRate(docType, taxes, product) {
      // @TODO: should get taxRateKey from product to fetch tax rate from lists.tax
      // also fetch tax rate label from lists.tax
      // if no taxRate from product then get from settings
      // PRO: if tax rate is removed, then assume taxrate no longer available
      //      but if using taxrate and label stored in products, it will use old taxrate/label instead of settings
      // CON: requires additional step to search lists.tax

      const taxRateKey = product.getData(`${docType}TaxRateKey`);
      let taxRate;
      if (taxRateKey) {
        const productTax = taxes.findBy('_data._key', taxRateKey);
        if (productTax) {
          taxRate = productTax.getData('value');
        }
      }
      if (!taxRateKey || taxRateKey === 'auto') {
        const defaultTaxRate = this.settings.getDefaultTaxRate(taxes);
        taxRate = defaultTaxRate;
      }
      if (taxRateKey === 'none') {
        taxRate = 0;
      }
      return taxRate;
    }
  });
});