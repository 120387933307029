define("client/pods/products/service", ["exports", "ramda", "ramda-adjunct", "ember-concurrency", "client/utils/nventor"], function (_exports, R, RA, _emberConcurrency, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Service.extend((_dec = Ember._action, _dec2 = Ember._action, _obj = {
    intl: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    settings: Ember.inject.service(),
    server: Ember.inject.service(),
    bigService: Ember.inject.service('big'),
    constants: Ember.inject.service(),
    search: Ember.inject.service(),
    crud: Ember.inject.service(),
    init() {
      this._super('...arguments');
      this.set('statusClasses', {
        [this.get('constants.productsStatus.active')]: 'is-primary',
        [this.get('constants.productsStatus.inactive')]: 'is-danger',
        [this.get('constants.productsStatus.preOrder')]: 'is-warning',
        [this.get('constants.productsStatus.draft')]: 'is-warning',
        [this.get('constants.productsStatus.outOfStock')]: 'is-black',
        [this.get('constants.productsStatus.soldOut')]: 'is-black'
      });
    },
    searchInputTask: (0, _emberConcurrency.task)(function* ({
      adapterName = 'products',
      filtersAdapterName = 'products/filters',
      query = '',
      resultsProperty = '',
      component,
      resultsToggle = false,
      global = true,
      filters
    }) {
      if (RA.isNilOrEmpty(filters)) {
        filters = this.search.setupFilters({
          adapterName: filtersAdapterName,
          defaultFiltersData: {
            query,
            match: [],
            sort: [{
              by: 'name',
              direction: 'ASC'
            }],
            status: [this.get('constants.status.active')]
          }
        });
      }
      const results = yield this.crud.searchInputTask.perform({
        adapterName,
        filters,
        resultsProperty,
        component,
        resultsToggle
      }, query);
      if (global && resultsProperty) {
        // this[resultsProperty] = results
        this.set(resultsProperty, results);
      }
      return results;
    }),
    getStatusClass(status) {
      const statusClasses = this.statusClasses;
      return R.propOr(this.unknownStatusClass, status, statusClasses);
    },
    fetchDocHistoryTask: (0, _emberConcurrency.task)(function* (docModel, preferredKey, product, itemKey) {
      const docType = docModel.getData('docType');
      const docName = docModel.getData('docName');
      const contactKey = docModel.getData('contactKey');
      const contact = docModel.getData('contact');
      const priceHistoryData = yield this.getHistoryTask.perform({
        docType,
        docName,
        contact,
        contactKey,
        itemKey
      });
      let preferredHistoryData;
      preferredHistoryData = this.getLatestFor(preferredKey, priceHistoryData);
      if (!preferredHistoryData) {
        preferredHistoryData = product.serialize({
          exclude: ['_key', '_rev', '_id']
        });
      }
      const isPos = R.pathEq(['_data', 'isPos'], true)(docModel);
      if (isPos) {
        priceHistoryData.history = R.pipe(R.propOr({}, 'history'), R.over(R.lensProp('others'), (others = []) => R.sortWith([R.descend(R.prop('isPos'))])(others)))(priceHistoryData);
      }
      return {
        priceHistoryData,
        preferredHistoryData
      };
    }).enqueue().maxConcurrency(4),
    fetchPurchasesHistoryTask: (0, _emberConcurrency.task)(function* (docModel, preferredKey, product, itemKey) {
      docModel.setData('docType', 'purchases');
      docModel.setData('docName', 'invoices');
      return yield this.fetchDocHistoryTask.perform(docModel, preferredKey, product, itemKey);
    }),
    getHistoryTask: (0, _emberConcurrency.task)(function* ({
      docType,
      docName,
      contact,
      contactKey,
      item,
      itemKey,
      docTypes
    }) {
      const data = {
        contact,
        contactKey,
        item,
        itemKey,
        docTypes
      };
      const results = yield this.server.call('GET', `api/protected/documents/data/prices/${docType}/${docName}`, data);
      return R.propOr({}, 'data', results);
    }).enqueue().maxConcurrency(4),
    getFor(prop, historyData) {
      return R.pathOr([], ['history', prop], historyData);
    },
    getLatestFor(prop, historyData) {
      // return R.pathOr([], ['history', prop], historyData)
      const forProp = this.getFor(prop, historyData);
      let found = false;
      if (!R.isEmpty(forProp)) {
        found = R.head(forProp);
      }
      return found;
    },
    getLatestDetail(props, historyData) {
      let found = '';
      R.forEach(prop => {
        if (!found) {
          const forProp = this.getFor(prop, historyData);
          if (!R.isEmpty(forProp)) {
            found = R.head(forProp);
          }
        }
      }, props);
      return found || false;
    },
    setPrice(model, historyData, onHideDropdown) {
      model.setPrice(historyData);
      if (onHideDropdown) {
        onHideDropdown();
      }
    },
    setDetailPrice(detail, child, historyData, onHideDropdown) {
      detail.setDetailPrice(child, historyData);
      if (onHideDropdown) {
        onHideDropdown();
      }
    },
    // is this used?
    selectChildHistoryPrice(detail, historyData, onHideDropdown) {
      detail.setChildProductPrice(detail, historyData);
      if (R.pathEq(['_data', 'isSet'], true, detail)) {
        detail.recalculatePriceForSet(detail);
      }
      if (onHideDropdown) {
        onHideDropdown();
      }
    },
    getTaxRate(docType, taxes, product) {
      // @TODO: should get taxRateKey from product to fetch tax rate from lists.tax
      // also fetch tax rate label from lists.tax
      // if no taxRate from product then get from settings
      // PRO: if tax rate is removed, then assume taxrate no longer available
      //      but if using taxrate and label stored in products, it will use old taxrate/label instead of settings
      // CON: requires additional step to search lists.tax

      const taxRateKey = product.getData(`${docType}TaxRateKey`);
      let taxRate;
      if (taxRateKey) {
        const productTax = taxes.findBy('_data._key', taxRateKey);
        if (productTax) {
          taxRate = productTax.getData('value');
        }
      }
      if (!taxRateKey || taxRateKey === 'auto') {
        const defaultTaxRate = this.settings.getDefaultTaxRate(taxes);
        taxRate = defaultTaxRate;
      }
      if (taxRateKey === 'none') {
        taxRate = 0;
      }
      return taxRate;
    },
    getRecurringFiltersTask: (0, _emberConcurrency.task)(function* () {
      yield this.crud.addLists(this, ['products/lists/recurring-filters', 'recurring/schedules/options']);
      const productsListsRecurringFilters = R.pathOr([], ['crud', 'lists', 'products-lists-recurring-filters'])(this);
      const recurringSchedulesOptions = R.pathOr([], ['crud', 'lists', 'recurring-schedules-options'])(this);
      let recurringFilters = R.concat(productsListsRecurringFilters, recurringSchedulesOptions);
      recurringFilters = R.map(recurringFilter => {
        const hasTranslate = R.pathEq(['_data', 'translate'], true)(recurringFilter);
        if (recurringFilter?._data) {
          Ember.set(recurringFilter, '_data.translate', hasTranslate);
        }
        return recurringFilter;
      })(recurringFilters) || [];
      return recurringFilters;
    }),
    resetInventoryDataTask: (0, _emberConcurrency.task)(function* ({
      inventoryResetDateZ
    }) {
      if (RA.isNilOrEmpty(inventoryResetDateZ)) {
        return;
      }
      yield this.server.call('POST', 'api/protected/products/reset-inventory-data', {
        inventoryResetDateZ,
        revalidateAllReport: true
      });
    }),
    setAsDefaultImageTask: (0, _emberConcurrency.task)(function* ({
      adapterName = 'products',
      resource = 'products',
      resourceKey,
      uploadsModel
    }) {
      // no need to send entire record over, should only update record, not replace
      const product = yield this.dataManager.findRecord({
        adapterName,
        appendPath: `/${resourceKey}`
      });
      const dirty = this.dataManager.getDirtyRecord(adapterName, product);
      dirty.setData('defaultImage', uploadsModel?._data?.uuid);
      try {
        return yield this.dataManager.updateRecord({
          adapterName,
          dirty
        });
      } catch (err) {
        this.set('errors', err.data);
        _nventor.default.throw('setAsDefaultImageTask failed', err);
      }
    }).drop(),
    checkForDisallowedValues({
      modelToCheck,
      models,
      prop
    }) {
      const value = R.pipe(R.pathOr('', ['_data', prop]), R.trim)(modelToCheck);
      if (!value) {
        return false;
      }
      if (R.includes(':', value)) {
        const msg = this.intl.t('colon not allowed');
        window.alert(msg);
        const strippedValue = R.replace(/:/g, '')(value);
        modelToCheck.setData(prop, strippedValue);
        return false;
      }
      const found = R.filter(model => {
        const propValueToCheck = R.pipe(R.pathOr('', ['_data', prop]), R.trim)(model);
        if (propValueToCheck === value) {
          return true;
        }
        return false;
      })(models);
      if (found.length > 1) {
        const msg = this.intl.t('duplicate value');
        window.alert(msg);
        modelToCheck.setData(prop, '');
        return false;
      }
    },
    fetchChildrenInventoryTask: (0, _emberConcurrency.task)(function* (product) {
      const master = product?._data?._key || '';
      if (master) {
        const adapterName = 'products';
        const appendPath = '/children';
        const data = {
          master
        };
        const children = yield this.dataManager.findAll({
          adapterName,
          appendPath,
          data
        });
        return children;
      }
    }),
    fetchCampaignsForProductTask: (0, _emberConcurrency.task)(function* (productKey) {
      return yield this.crud.searchRecordsTask.perform({
        adapterName: 'products/lists/campaigns',
        appendPath: productKey
      });
    })
  }, _applyDecoratedDescriptor(_obj, "selectChildHistoryPrice", [_dec], Object.getOwnPropertyDescriptor(_obj, "selectChildHistoryPrice"), _obj), _applyDecoratedDescriptor(_obj, "checkForDisallowedValues", [_dec2], Object.getOwnPropertyDescriptor(_obj, "checkForDisallowedValues"), _obj), _obj));
});