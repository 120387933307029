define("client/pods/channels/shop/campaigns/details/model", ["exports", "ramda", "client/pods/base/model", "client/mixins/products-children", "client/mixins/statuses", "client/mixins/templates", "client/mixins/date", "client/utils/nventor", "ramda-adjunct"], function (_exports, R, _model, _productsChildren, _statuses, _templates, _date, _nventor, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const ChannelsShopCampaignsDetailsBaseModel = _model.default.extend(_productsChildren.default, _statuses.default, _templates.default, _date.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _obj = {
    productsService: Ember.inject.service('products'),
    init() {
      this._super(...arguments);
      this.set('childAdapters.detailsLimits', 'channels/shop/campaigns/details/limits');
    },
    defaults() {
      return {
        productSync: ['item', 'templates', 'productTags', 'productDisplayTags', 'description', 'additionalDescription', 'recurringSchedules'],
        shop: '',
        status: this.get('constants.status.active'),
        deliveryDateSettings: this.get('constants.togglesAuto.auto'),
        onSale: this.get('constants.togglesAuto.on'),
        showLimitedQty: this.get('constants.togglesAuto.auto'),
        showCountdownTimer: this.get('constants.togglesAuto.auto'),
        shippingFeeType: this.get('constants.shippingFeeType.flat'),
        commissionMethod: this.get('constants.togglesAuto.auto'),
        hasPassword: false,
        isHidePriceWhenHasPassword: false,
        hasProductSchedules: false,
        hasOnSaleSchedules: false,
        recurringSchedulesEnabled: false,
        recurringSchedulesOnly: false,
        recurringSchedules: [],
        templates: [],
        hasCustomizations: false,
        customizations: []
      };
    },
    populate(data, attrs = {}) {
      data = this._transformLimits(data);
      data.productSchedules = this._transformSchedules({
        data,
        schedulesProp: 'productSchedules'
      });
      data.onSaleSchedules = this._transformSchedules({
        data,
        schedulesProp: 'onSaleSchedules'
      });
      data.templates = this._transformTemplates(data);
      data.conflictsList = this._transformConflictsList(data);
      data.productDisplayTags = this._transformProductDisplayTags(data);
      if (_ramdaAdjunct.default.isNilOrEmpty(attrs.commissionBaseCostNew)) {
        const commissionBaseCostNew = this._transformCommissionBaseCost(data);
        attrs.commissionBaseCostNew = commissionBaseCostNew;
      }
      data.recurringSchedules = this._transformRecurringSchedules(data);
      const isAutomationWithProduct = data?.isAutomation && _ramdaAdjunct.default.isNotNilOrEmpty(data?.product);
      if (_ramdaAdjunct.default.isNilOrEmpty(attrs.product) || isAutomationWithProduct) {
        const product = this._transformProduct(data);
        attrs.product = product;
      }
      attrs.defaultCheckoutDescription = data.defaultCheckoutDescription;
      this._super(data, attrs);
    },
    populatePartial(data, attrs) {
      data.productSchedules = this._transformSchedules({
        data,
        schedulesProp: 'productSchedules'
      });
      this._super(data, attrs);
    },
    _transformRecurringSchedules(data) {
      return R.pipe(R.propOr([], 'recurringSchedules'), R.map(schedule => this.dataManager.setAsRecord({
        adapterName: 'recurring/schedules',
        data: schedule
      })))(data);
    },
    _transformConflictsList(data) {
      const conflictsList = R.propOr([], 'conflictsList')(data);
      return R.map(conflict => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/details/bulk-discount-conflicts',
          data: conflict
        });
      })(conflictsList);
    },
    _transformProduct(data) {
      const productData = R.propOr({}, 'product')(data);
      delete data.product;
      return this.dataManager.setPartialRecord({
        adapterName: 'products',
        data: productData
      });
    },
    _getSyncProp(detailProp, productProp) {
      const productSync = this.getData('productSync') || [];
      const hasSync = R.includes(detailProp)(productSync);
      if (hasSync) {
        return this.get(productProp);
      }
      return this.getData(detailProp);
    },
    item: Ember.computed('_data.item', 'product._data.name', '_data.productSync.[]', 'synced', function () {
      return this._getSyncProp('item', 'product._data.name');
    }),
    productTags: Ember.computed('_data.productTags', 'product._data.tags', '_data.productSync.[]', function () {
      return this._getSyncProp('productTags', 'product._data.tags');
    }),
    productDisplayTags: Ember.computed('_data.productDisplayTags', 'product._data.productDisplayTags', '_data.productSync.[]', function () {
      return this._getSyncProp('productDisplayTags', 'product._data.productDisplayTags');
    }),
    description: Ember.computed('_data.description', 'product._data.description', '_data.productSync.[]', function () {
      return this._getSyncProp('description', 'product._data.description');
    }),
    additionalDescription: Ember.computed('_data.additionalDescription', 'product._data.additionalDescription', '_data.productSync.[]', function () {
      return this._getSyncProp('additionalDescription', 'product._data.additionalDescription');
    }),
    templates: Ember.computed('_data.templates', 'product._data.templates', '_data.productSync.[]', function () {
      return this._getSyncProp('templates', 'product._data.templates');
    }),
    activeProductSchedule: Ember.computed('_data.productSchedules.@each.isActive', function () {
      const productSchedules = this.getData('productSchedules') || [];
      return R.find(R.propEq('isActive', true))(productSchedules);
    }),
    nextProductSchedule: Ember.computed('_data.productSchedules.@each.isActiveNext', function () {
      const productSchedules = this.getData('productSchedules') || [];
      return R.find(R.propEq('isActiveNext', true))(productSchedules);
    }),
    calculatedAvailablePreOrderQtyOnlyBeforeCart: Ember.computed('_data.{calculatedAvailableQtyBeforeCart,calculatedAvailablePreOrderQtyBeforeCart,itemKey}', 'limits.@each.synced', function () {
      let calculatedAvailablePreOrderQtyOnlyBeforeCart = this.getData('calculatedAvailablePreOrderQtyBeforeCart');
      const calculatedAvailableQtyBeforeCart = this.getData('calculatedAvailableQtyBeforeCart');
      const itemKey = this.getData('itemKey');
      const limitsByItemKey = R.find(R.pathEq(['_data', 'itemKey'], itemKey))(this.limits) || {};
      if (R.gt(calculatedAvailableQtyBeforeCart, 0)) {
        let salesPreOrderLimitedQty = limitsByItemKey?._data?.salesPreOrderLimitedQty || '0';
        const isSet = this.getData('isSet');
        if (isSet) {
          const calculatedAvailablePreOrderQtyBeforeCart = this.getData('calculatedAvailablePreOrderQtyBeforeCart');
          const calculatedAvailableQtyBeforeCart = this.getData('calculatedAvailableQtyBeforeCart');
          salesPreOrderLimitedQty = this.big.newBig(calculatedAvailablePreOrderQtyBeforeCart).minus(calculatedAvailableQtyBeforeCart).toFixed(0);
        }
        calculatedAvailablePreOrderQtyOnlyBeforeCart = salesPreOrderLimitedQty;
      }
      if (calculatedAvailablePreOrderQtyOnlyBeforeCart < 0) {
        return 0;
      }
      return calculatedAvailablePreOrderQtyOnlyBeforeCart;
    }),
    // activeStatus: computed('_data.hasProductSchedules', 'activeProductSchedule._data.status', '_data.calculatedStatusBeforeCart', function () {
    //   const hasProductSchedules = this.getData('hasProductSchedules') || false
    //   const activeProductSchedule = this.get('activeProductSchedule')

    //   if (hasProductSchedules && RA.isNotNilOrEmpty(activeProductSchedule)) {
    //     return activeProductSchedule.getData('status')
    //   }

    //   return this.getData('calculatedStatusBeforeCart')
    // }),

    isStatusAndCalculatedStatusDifferent: Ember.computed('_data.{calculatedStatusBeforeCart,status}', function () {
      const calculatedStatusBeforeCart = this?._data?.calculatedStatusBeforeCart || '';
      const status = this?._data?.status || '';
      if (R.equals(calculatedStatusBeforeCart, status)) {
        return false;
      }
      return true;
    }),
    activeOnSaleSchedule: Ember.computed('_data.{hasOnSaleSchedules,onSaleSchedules.@each.isActive}', function () {
      const hasOnSaleSchedules = this.getData('hasOnSaleSchedules') || false;
      if (!hasOnSaleSchedules) {
        return false;
      }
      const onSaleSchedules = this.getData('onSaleSchedules') || [];
      return R.find(R.propEq('isActive', true))(onSaleSchedules);
    }),
    nextOnSaleSchedule: Ember.computed('_data.onSaleSchedules.@each.isActiveNext', function () {
      const onSaleSchedules = this.getData('onSaleSchedules') || [];
      return R.find(R.propEq('isActiveNext', true))(onSaleSchedules);
    }),
    isOnSale: Ember.computed('_data.hasOnSaleSchedules', 'activeOnSaleSchedule', '_data.onSale', function () {
      const hasOnSaleSchedules = this.getData('hasOnSaleSchedules') || false;
      if (!hasOnSaleSchedules) {
        const onSale = this.getData('onSale');
        if (onSale === 'on') {
          return true;
        }
        return false;
      }
      const activeOnSaleSchedule = this.get('activeOnSaleSchedule');
      if (_ramdaAdjunct.default.isNotNilOrEmpty(activeOnSaleSchedule)) {
        return true;
      }
      return false;
    }),
    isStandardProduct: Ember.computed('_data.hasVariants', '_data.isSet', '_data.isSetNew', '_data.isSetPriceOnBundle', '_data.isSetWithChoices', function () {
      const hasVariants = this.getData('hasVariants') || false;
      const isSet = this.getData('isSet') || false;
      const isSetNew = this.getData('isSetNew') || false;
      const isSetPriceOnBundle = this.getData('isSetPriceOnBundle') || false;
      const isSetWithChoices = this.getData('isSetWithChoices') || false;
      if (hasVariants || isSet || isSetNew || isSetPriceOnBundle || isSetWithChoices) {
        return false;
      }
      return true;
    }),
    hasUnsetWeight: Ember.computed('_data.{allowedShippingZones.@each._key,weight,weightUnit}', function () {
      const allowedShippingZones = this?._data?.allowedShippingZones || [];
      const shippingZonesWithWeight = R.filter(zone => {
        const isWeightBased = zone?.isWeightBased || false;
        const weightBasedRates = zone?.weightBasedRates || [];
        if (isWeightBased && _ramdaAdjunct.default.isNotNilOrEmpty(weightBasedRates)) {
          return true;
        }
        return false;
      })(allowedShippingZones);
      if (_ramdaAdjunct.default.isNotNilOrEmpty(shippingZonesWithWeight)) {
        const weight = this?._data?.weight || ''; // default is empty, not 0

        if (!weight) {
          return true;
        }
      }
      return false;
    }),
    hasAllowedChildItemsInDisallowedZones: Ember.computed('_data.disallowedShippingZones.@each.{allowedChildProducts}', function () {
      const disallowedShippingZones = this?._data?.disallowedShippingZones || [];
      if (_ramdaAdjunct.default.isNilOrEmpty(disallowedShippingZones)) {
        return false;
      }
      return R.pipe(R.pluck('allowedChildProducts'), R.reject(_ramdaAdjunct.default.isNilOrEmpty), R.flatten, _ramdaAdjunct.default.isNotNilOrEmpty)(disallowedShippingZones);
    }),
    _transformTemplates(data) {
      return R.pipe(R.pathOr([], ['templates']), R.map(template => this.dataManager.setAsRecord({
        adapterName: 'products/templates',
        data: this.getTemplateHash(template)
      })))(data);
    },
    getTemplateHash(args = {}) {
      const {
        template,
        header,
        status
      } = args;
      return {
        component: 'products/products-editor/template',
        get label() {
          return this.header;
        },
        action: 'new',
        tabType: 'reuseSubTab',
        header,
        template,
        status
      };
    },
    getBulkDiscountSchedule({
      campaign
    }) {
      let isActive = {};
      let isActiveNext = {};
      let hasIsActive = false;
      let hasIsActiveNext = false;
      let hasAnyActive = false;
      const campaignBulkDiscountId = this.getData('bulkDiscountId') || '';
      const bulkDiscounts = campaign?._data?.bulkDiscounts || [];
      const bulkDiscount = R.find(R.pathEq(['_data', 'bulkDiscountId'], campaignBulkDiscountId))(bulkDiscounts) || {};
      if (_ramdaAdjunct.default.isNotNilOrEmpty(bulkDiscount)) {
        const status = bulkDiscount?._data?.status || 'enabled';
        if (status === 'disabled') {
          return {
            hasAnyActive: false,
            hasIsActive: false,
            hasIsActiveNext: false,
            isActive: false,
            isActiveNext: false
          };
        }
        const schedules = bulkDiscount.getData('schedules') || [];
        isActive = R.find(R.propEq('isActive', true))(schedules) || {};
        isActiveNext = R.find(R.propEq('isActiveNext', true))(schedules) || {};
        hasIsActive = _ramdaAdjunct.default.isNotNilOrEmpty(isActive);
        hasIsActiveNext = _ramdaAdjunct.default.isNotNilOrEmpty(isActiveNext);
        hasAnyActive = hasIsActive || hasIsActiveNext;
      }
      return {
        hasAnyActive,
        hasIsActive,
        hasIsActiveNext,
        isActive,
        isActiveNext
      };
    },
    addNewTemplate() {
      const newTemplate = this.dataManager.newRecord({
        adapterName: 'products/templates',
        data: this.getTemplateHash()
      });
      this?._data?.templates?.pushObject(newTemplate);
      return newTemplate;
    },
    removeTemplate(template) {
      if (template._data) {
        this?._data?.templates?.removeObject(template);
      } else {
        const header = R.propOr('', 'header')(template);
        const templateModel = R.pipe(R.pathOr([], ['_data', 'templates']), R.find(R.pathEq(['_data', 'header'], header)))(this);
        this?._data?.templates?.removeObject(templateModel);
      }
    },
    _transformProductDisplayTags(data) {
      const productDisplayTags = R.propOr([], 'productDisplayTags')(data);
      return R.map(productDisplayTag => {
        return this.dataManager.setAsRecord({
          adapterName: 'products/product-display-tags',
          data: productDisplayTag
        });
      })(productDisplayTags);
    },
    addProductDisplayTags(productDisplayTag = {}) {
      const productDisplayTags = this.getData('productDisplayTags') || [];
      const productDisplayTagModel = this.dataManager.setAsRecord({
        adapterName: 'products/product-display-tags',
        data: productDisplayTag
      });
      productDisplayTags.pushObject(productDisplayTagModel);
      this.setData('productDisplayTags', productDisplayTags);
    },
    removeProductDisplayTag(productDisplayTag = {}) {
      const productDisplayTags = this.getData('productDisplayTags') || [];
      productDisplayTags.removeObject(productDisplayTag);
      this.setData('productDisplayTags', productDisplayTags);
    },
    _transformSchedules({
      data,
      schedulesProp
    }) {
      const schedules = R.propOr([], schedulesProp)(data);
      return R.map(schedule => {
        return this.dataManager.setAsRecord({
          adapterName: 'status-schedules',
          data: schedule
        });
      })(schedules);
    },
    addSchedule({
      schedule = {},
      schedulesProp = ''
    }) {
      if (schedulesProp) {
        const schedules = this.getData(schedulesProp) || [];
        const scheduleModel = this.dataManager.setAsRecord({
          adapterName: 'status-schedules',
          data: schedule
        });
        schedules.pushObject(scheduleModel);
        this.setData(schedulesProp, schedules);
      }
    },
    _transformCommissionBaseCost(data) {
      let commissionBaseCostNew = R.propOr({}, 'commissionBaseCostNew')(data);
      delete data.commissionBaseCostNew;
      const mapIndexed = R.addIndex(R.map);
      const mergeArrayOfObjects = (key, left, right) => {
        if (R.equals(key, 'setDetails') || R.equals(key, 'variantChildren')) {
          return mapIndexed((val, idx) => R.mergeDeepRight(val, right[idx]), left);
        }
        if (R.equals(key, 'choices') || R.equals(key, 'details')) {
          return mapIndexed((val, idx) => {
            return R.mergeDeepWithKey(mergeArrayOfObjects, val, right[idx]);
          })(left);
        }
        return right;
      };
      commissionBaseCostNew = R.pipe(R.pick(['choices', 'setDetails', 'variantChildren', 'itemKey', 'item', 'salesQty', 'campaignKey', 'isSet', 'isSetWithChoices', 'hasVariants']), R.mergeDeepWithKey(mergeArrayOfObjects, commissionBaseCostNew))(data);
      commissionBaseCostNew.master = data._key;
      commissionBaseCostNew.campaignKey = data.master;
      if (commissionBaseCostNew._key) {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/details/commission-base-cost',
          data: commissionBaseCostNew
        });
      } else {
        return this.dataManager.newRecord({
          adapterName: 'channels/shop/campaigns/details/commission-base-cost',
          data: commissionBaseCostNew
        });
      }
    },
    _transformLimits(data) {
      if (data.limits) {
        const limits = R.pipe(R.propOr([], 'limits'),
        // R.sortWith([
        //   R.ascend(R.prop('item'))
        // ]),
        R.map(limitData => {
          return this.dataManager.setPartialRecord({
            adapterName: this.get('childAdapters.detailsLimits'),
            data: limitData
          });
        }))(data);
        this.set('limits', limits);
        delete data.limits;
      }
      return data;
    },
    // overSalesLimitedQtyStatusClass: computed('_data.overSalesLimitedQtyStatus', function () {
    //   const status = this.get('_data.overSalesLimitedQtyStatus')
    //   return this.get('productsService').getStatusClass(status)
    // }),

    hasOnSaleSchedules: Ember.computed('_data.{hasOnSaleSchedules,onSaleSchedules.[]}', function () {
      const hasOnSaleSchedules = this?._data?.hasOnSaleSchedules === true;
      const onSaleSchedules = this?._data?.onSaleSchedules || [];
      if (hasOnSaleSchedules && _ramdaAdjunct.default.isNotNilOrEmpty(onSaleSchedules)) {
        return true;
      }
      return false;
    }),
    limitsSorted: Ember.computed('limits.@each.synced', function () {
      const limits = this.limits || [];
      return R.pipe(R.filter(R.pathEq(['_data', 'hasSalesLimitedQty'], true)), R.sortWith([R.ascend(R.path(['_data', 'salesLimitedQty']))]))(limits);
    }),
    limitsProducts: Ember.computed('limits.@each.synced', 'limits.@each.product', function () {
      const limits = this.limits || [];
      return R.pluck('product')(limits);
    }),
    limitsSortedByItem: Ember.computed('limits.@each.synced', 'limitsProducts.@each.synced', function () {
      const limits = this.limits || [];
      return _nventor.default.sortTextAscendingByPath(['product', '_data', 'name'])(limits);
    }),
    hasAllSalesLimitedQty: Ember.computed('synced', 'limits.@each.synced', function () {
      const limits = this.limits || [];
      return R.pipe(R.pluck('_data'), R.reject(_ramdaAdjunct.default.isNilOrEmpty), R.pluck('hasSalesLimitedQty'), R.reject(_ramdaAdjunct.default.isNilOrEmpty), _ramdaAdjunct.default.allEqualTo(true))(limits);
    }),
    hasAnySalesLimitedQty: Ember.computed('synced', 'limits.@each.synced', function () {
      const limits = this.limits || [];
      return R.reduce((acc, limitedDetail) => {
        if (acc) {
          return acc;
        }
        if (limitedDetail?._data?.hasSalesLimitedQty) {
          return true;
        }
        return false;
      }, false)(limits);
    }),
    hasAnyAllowSalesPreOrder: Ember.computed('synced', 'limits.@each.synced', function () {
      const limits = this.limits || [];
      return R.reduce((acc, limitedDetail) => {
        if (acc) {
          return acc;
        }
        if (limitedDetail?._data?.allowPreOrder) {
          return true;
        }
        return false;
      }, false)(limits);
    }),
    hasAllSalesPreOrderLimitedQty: Ember.computed('synced', 'limits.@each.synced', function () {
      const limits = this.limits || [];
      return R.pipe(R.pluck('_data'), R.reject(_ramdaAdjunct.default.isNilOrEmpty), R.pluck('isUnlimitedPreOrder'), R.reject(_ramdaAdjunct.default.isNilOrEmpty), R.any(R.equals(true)), R.not)(limits);
    }),
    hasAnySalesPreOrderLimitedQty: Ember.computed('synced', 'limits.@each.synced', function () {
      const limits = this.limits || [];
      return R.reduce((acc, limitedDetail) => {
        if (acc) {
          return acc;
        }
        if (limitedDetail?._data?.hasSalesPreOrderLimitedQty) {
          return true;
        }
        return false;
      }, false)(limits);
    }),
    salesLimitedQtyLabel: Ember.computed('synced', '_data.{isSetWithChoices,isSet,hasVariants,isUnlimited}', function () {
      const isUnlimited = this._data?.isUnlimited;
      if (isUnlimited) {
        return '∞';
      }
      return '';
    }),
    calculatedSalesLimitedQty: Ember.computed('synced', '_data.{isSetWithChoices,isSet,hasVariants,isUnlimited}', function () {
      const isUnlimited = this._data?.isUnlimited;
      if (isUnlimited) {
        return 0;
      }
      return this._calculateSalesLimitedQty(this._data);
    }),
    salesPreOrderLimitedQtyLabel: Ember.computed('synced', '_data.{isSetWithChoices,isSet,hasVariants,calculatedSalesPreOrderLimitedQty}', 'minSalesPreOrderLimitedQtyLabel', function () {
      if (this._data?.isSetWithChoices || this._data?.hasVariants || this._data?.isSet) {
        if (this._data?.hasVariants && !this.hasAllSalesPreOrderLimitedQty) {
          return '...';
        }
        return '';
      }
      if (!this.hasAnyAllowSalesPreOrder) {
        return '0';
      }
      if (!this.hasAnySalesPreOrderLimitedQty) {
        return '∞';
      }
      return '';
    }),
    _calculateSalesPreOrderLimitedQty(data = {}) {
      let calculatedSalesPreOrderLimitedQty = 0;
      if (data?.isSetWithChoices || data?.hasVariants || data?.isSet) {
        if (data?.hasVariants && !this.hasAllSalesPreOrderLimitedQty) {
          return 0;
        }
        calculatedSalesPreOrderLimitedQty = data?.calculatedSalesPreOrderLimitedQty || 0;
        const isSet = data?.isSet;
        if (isSet) {
          const qtyPerBuy = data?.qtyPerBuy || 1;
          calculatedSalesPreOrderLimitedQty = R.pipe(R.divide(R.__, qtyPerBuy), _ramdaAdjunct.default.floor)(calculatedSalesPreOrderLimitedQty);
        }
      } else {
        calculatedSalesPreOrderLimitedQty = this.minSalesLimitedData?.minSalesPreOrderLimitedQty;
      }
      calculatedSalesPreOrderLimitedQty = Number(calculatedSalesPreOrderLimitedQty) || 0;
      if (!this.hasAnyAllowSalesPreOrder) {
        return 0;
      }
      if (calculatedSalesPreOrderLimitedQty < 0) {
        return 0;
      }
      return calculatedSalesPreOrderLimitedQty;
    },
    calculatedSalesPreOrderLimitedQty: Ember.computed('synced', '_data.{isSetWithChoices,isSet,hasVariants,calculatedSalesPreOrderLimitedQty}', 'minSalesPreOrderLimitedQtyLabel', function () {
      if (this._data?.isSetWithChoices || this._data?.hasVariants || this._data?.isSet) {
        if (this._data?.hasVariants && !this.hasAllSalesPreOrderLimitedQty) {
          return 0;
        }
        return this._calculateSalesPreOrderLimitedQty(this._data);
      }
      if (!this.hasAnyAllowSalesPreOrder) {
        return 0;
      }
      if (!this.hasAnySalesPreOrderLimitedQty) {
        return 0;
      }
      return this.minSalesLimitedData?.minSalesPreOrderLimitedQty;
    }),
    minSalesLimitedQtyLabel: Ember.computed('synced', '_data.isSetWithChoices', 'minSalesLimitedData.{hasSalesLimitedChoices,minSalesLimitedQty,minSalesPreOrderLimitedQty,minAvailableSalesLimitedQty,minAvailableSalesPreOrderLimitedQty}', 'hasAnySalesLimitedQty', 'isUnlimited', function () {
      // only for normal set now, other is handled on API
      if (!this.hasAnySalesLimitedQty) {
        return '-';
      }
      if (this.isUnlimited) {
        return '∞';
      }

      // if (this._data?.isSetWithChoices && !this.minSalesLimitedData?.hasSalesLimitedChoices) {
      //   return '-'
      // }

      return this.intl.t('limited sales qty') + ': ' + this.intl.formatNumber(this.minSalesLimitedData?.minSalesLimitedQty || 0);
    }),
    minSalesPreOrderLimitedQtyLabel: Ember.computed('synced', 'minSalesLimitedData.{hasSalesLimitedChoices,minSalesLimitedQty,minSalesPreOrderLimitedQty,minAvailableSalesLimitedQty,minAvailableSalesPreOrderLimitedQty}', 'hasAnyAllowSalesPreOrder', 'hasAnySalesPreOrderLimitedQty', function () {
      if (!this.hasAnyAllowSalesPreOrder) {
        return '-';
      }
      if (!this.hasAnySalesPreOrderLimitedQty) {
        return '∞';
      }
      return this.intl.t('limited preOrder qty') + ': ' + this.intl.formatNumber(this.minSalesLimitedData?.minSalesPreOrderLimitedQty || 0);
    }),
    minAvailableToSellQtyLabel: Ember.computed('_data.isSetWithChoices', 'hasAnySalesLimitedQty', 'hasAnyAllowSalesPreOrder', 'hasAnySalesPreOrderLimitedQty', 'minSalesLimitedData.{hasSalesLimitedChoices,minSalesLimitedQty,minSalesPreOrderLimitedQty,minAvailableSalesLimitedQty,minAvailableSalesPreOrderLimitedQty}', function () {
      if (!this.hasAnySalesLimitedQty) {
        return '∞';
      }
      if (this.hasAnyAllowSalesPreOrder && !this.hasAnySalesPreOrderLimitedQty) {
        return '∞';
      }
      if (this?._data?.isSetWithChoices && !this.minSalesLimitedData?.hasSalesLimitedChoices) {
        return '∞';
      }
      let minAvailableSalesLimitedQty = this.minSalesLimitedData?.minAvailableSalesLimitedQty || 0;
      // let minAvailableSalesPreOrderLimitedQty = 0
      // if (this.hasAnyAllowSalesPreOrder) {
      //   minAvailableSalesPreOrderLimitedQty = this.minSalesLimitedData?.minAvailableSalesPreOrderLimitedQty
      // }

      return this.intl.formatNumber(minAvailableSalesLimitedQty);
    }),
    _calculateSalesLimitedQty(data = {}) {
      let calculatedSalesLimitedQty = 0;
      if (data?.isSetWithChoices || data?.hasVariants || data?.isSet) {
        calculatedSalesLimitedQty = data?.calculatedSalesLimitedQty || 0;
        const isSet = data?.isSet;
        if (isSet) {
          const qtyPerBuy = data?.qtyPerBuy || 1;
          calculatedSalesLimitedQty = R.pipe(R.divide(R.__, qtyPerBuy), _ramdaAdjunct.default.floor)(calculatedSalesLimitedQty);
        }
      } else {
        calculatedSalesLimitedQty = this.minSalesLimitedData?.minSalesLimitedQty;
      }
      return calculatedSalesLimitedQty;
    },
    _calculateSalesPreOrderLimitedQty(data = {}) {
      let calculatedSalesPreOrderLimitedQty = this.calculatedAvailablePreOrderQtyOnlyBeforeCart || 0;
      const isSet = data?.isSet;
      if (isSet) {
        const qtyPerBuy = data?.qtyPerBuy || 1;
        calculatedSalesPreOrderLimitedQty = R.pipe(R.divide(R.__, qtyPerBuy), _ramdaAdjunct.default.floor)(calculatedSalesPreOrderLimitedQty);
      }
      return calculatedSalesPreOrderLimitedQty;
    },
    hasSalesLimitedQtyProgressByAvailableQty: Ember.computed('synced', '_data.{isSetWithChoices,isUnlimited,calculatedSalesLimitedQty,isSetWithChoices,hasVariants,isSet}', 'calculatedAvailableQtyBeforeCart', 'minSalesLimitedData.minSalesLimitedQty', function () {
      // delete?
      if (this._data?.isSetWithChoices) {
        return false;
      }
      const isUnlimited = this._data?.isUnlimited;
      if (isUnlimited) {
        return false;
      }
      const calculatedSalesLimitedQty = this._calculateSalesLimitedQty(this._data);
      if (!calculatedSalesLimitedQty) {
        return false;
      }
      if (calculatedSalesLimitedQty < 0) {
        return false;
      }
      return true;
    }),
    hasSalesPreOrderLimitedQtyProgressByAvailableQty: Ember.computed('synced', '_data.{isSetWithChoices,isUnlimited,calculatedSalesPreOrderLimitedQty,isSetWithChoices,hasVariants,isSet}', 'calculatedAvailableQtyBeforeCart', 'minSalesLimitedData.minSalesPreOrderLimitedQty', function () {
      if (this._data?.isSetWithChoices || this._data?.hasVariants || this._data?.isSet) {
        if (this._data?.hasVariants && !this.hasAllSalesPreOrderLimitedQty) {
          return '-';
        }
        let calculatedSalesPreOrderLimitedQty = this._data?.calculatedSalesPreOrderLimitedQty || 0;
        const isSet = this.getData('isSet');
        if (isSet) {
          const qtyPerBuy = this.getData('qtyPerBuy') || 1;
          calculatedSalesPreOrderLimitedQty = R.pipe(R.divide(R.__, qtyPerBuy), _ramdaAdjunct.default.floor)(calculatedSalesPreOrderLimitedQty);
        }
        return this.intl.formatNumber(calculatedSalesPreOrderLimitedQty);
      }
      if (!this.hasAnyAllowSalesPreOrder) {
        return '-';
      }
      if (!this.hasAnySalesPreOrderLimitedQty) {
        return '∞';
      }
      return this.intl.t('limited preOrder qty') + ': ' + this.intl.formatNumber(this.minSalesLimitedData?.minSalesPreOrderLimitedQty || 0);
      return this.minSalesPreOrderLimitedQtyLabel;

      // const calculatedSalesPreOrderLimitedQty = this._calculateSalesPreOrderLimitedQty(this._data)

      // if (!calculatedSalesPreOrderLimitedQty) {
      //   return false
      // }

      // if (calculatedSalesPreOrderLimitedQty < 0) {
      //   return false
      // }

      // return true
    }),
    salesLimitedQtyProgressByAvailableQty: Ember.computed('synced', 'hasSalesLimitedQtyProgressByAvailableQty', '_data.calculatedAvailableQtyBeforeCart', function () {
      if (!this.hasSalesLimitedQtyProgressByAvailableQty) {
        return 0;
      }
      const calculatedSalesLimitedQty = this._calculateSalesLimitedQty(this._data);
      const calculatedAvailableQtyBeforeCart = Number(this._data?.calculatedAvailableQtyBeforeCart) || 0;
      const progress = this.big.newBig(calculatedAvailableQtyBeforeCart).div(calculatedSalesLimitedQty).toFixed(1);
      return progress;
    }),
    isOverSalesLimitedQtyItems: Ember.computed('hasAnySalesLimitedQty', 'limits.@each.synced', 'synced', function () {
      const hasAnySalesLimitedQty = this.hasAnySalesLimitedQty;
      if (hasAnySalesLimitedQty) {
        const limitsSorted = this.limitsSorted || [];
        return R.filter(limitDetail => {
          return R.path(['_data', 'isOverSalesLimitedQty'])(limitDetail);
        })(limitsSorted);
      }
      return [];
    }),
    currentStatus: Ember.computed('._data.{status,calculatedStatusBeforeCart}', 'isStatusAndCalculatedStatusDifferent', function () {
      const status = this?._data?.status;
      const calculatedStatusBeforeCart = this?._data?.calculatedStatusBeforeCart;
      const isStatusAndCalculatedStatusDifferent = this.isStatusAndCalculatedStatusDifferent || false;
      if (isStatusAndCalculatedStatusDifferent) {
        return calculatedStatusBeforeCart;
      }
      return status;
    }),
    currentStatusClass: Ember.computed('currentStatus', function () {
      const currentStatus = this?.currentStatus;
      return this.productsService.getStatusClass(currentStatus);
    }),
    setChildProductPrice(campaignOrAutomationModel, child, historyData) {
      const isCampaign = R.prop('campaign', historyData);
      this._setItemPrice(campaignOrAutomationModel, isCampaign, child, historyData);
      this._setVariantChildrenPrices(campaignOrAutomationModel, isCampaign, child, historyData);
    },
    _setItemPrice(campaignOrAutomationModel, isCampaign, item, historyData) {
      const isAutomationMustAdd = campaignOrAutomationModel?.isAutoAdd || false;
      if (isCampaign) {
        const price = R.propOr('', 'price', historyData);
        let priceDiscounted = R.propOr('', 'priceDiscounted', historyData);
        let priceFinal = R.propOr('', 'priceFinal', historyData);
        let onSale = R.propOr('', 'onSale', historyData);
        if (isAutomationMustAdd) {
          priceDiscounted = '0';
          priceFinal = '0';
          onSale = 'on';
        }
        const isSetNew = R.propOr('', 'isSetNew', historyData);
        if (onSale) {
          item.setData('onSale', onSale);
        }
        if (isSetNew) {
          item.setData('isSetNew', isSetNew);
        }
        item.setData('price', price);
        item.setData('priceDiscounted', priceDiscounted);
        item.setData('priceFinal', priceFinal);
      } else {
        let price;
        price = historyData.inclPrice;
        if (!price) {
          price = R.propOr('', 'salesInclPrice', historyData);
        }
        let priceDiscounted = '';
        if (isAutomationMustAdd) {
          priceDiscounted = 0;
        }
        item.setData('price', price);
        item.setData('priceDiscounted', priceDiscounted);
      }
    },
    _setVariantChildrenPrices(campaignOrAutomationModel, isCampaign, item, historyData) {
      const variantChildrenHistory = R.propOr([], 'variantChildren')(historyData);
      R.pipe(R.pathOr([], ['_data', 'variantChildren']), R.forEach(child => {
        const variantChildId = child._data.variantChildId;
        let childHistory = R.find(R.propEq('variantChildId', variantChildId))(variantChildrenHistory);
        if (!childHistory) {
          const variantChildUid = child._data.variantChildUid;
          childHistory = R.find(R.propEq('variantChildUid', variantChildUid))(variantChildrenHistory);
        }
        if (childHistory) {
          this._setItemPrice(campaignOrAutomationModel, isCampaign, child, childHistory);
        }
      }))(item);
    },
    _setSetDetailsPrices(campaign, isCampaign, item, historyData) {
      const setDetailsHistory = R.propOr([], 'setDetails')(historyData);
      R.pipe(R.pathOr([], ['_data', 'setDetails']), R.forEach(child => {
        const setDetailItemKey = child?._data?.itemKey;
        const setDetailHistory = R.find(R.propEq('itemKey', setDetailItemKey))(setDetailsHistory);
        if (setDetailHistory) {
          this._setItemPrice(campaign, isCampaign, child, setDetailHistory);
        }
      }))(item);
    },
    isOverSalesLimitedQtyForTag: Ember.computed('synced', '_data.{isSetWithChoices,isSet,hasVariants,calculatedStatusBeforeCart}', 'isOverSalesLimitedQty', function () {
      if (this._data?.isSetWithChoices || this._data?.hasVariants || this._data?.isSet) {
        const calculatedStatusBeforeCart = this._data.calculatedStatusBeforeCart;
        if (R.equals(calculatedStatusBeforeCart, 'active')) {
          return false;
        }
        return true;
      }
      return this.isOverSalesLimitedQty;
    }),
    isOverSalesLimitedQty: Ember.computed('isOverSalesLimitedQtyItems.[]', '_data.status', function () {
      if (this._data.status === this.constants.productsStatus.inactive) {
        return false;
      }
      const isOverSalesLimitedQtyItems = this.isOverSalesLimitedQtyItems;
      if (!R.isEmpty(isOverSalesLimitedQtyItems)) {
        return R.reduce((acc, limitDetail) => {
          if (acc) {
            return acc;
          }
          return R.path(['_data', 'isOverSalesLimitedQty'])(limitDetail);
        }, false)(isOverSalesLimitedQtyItems);
      }
      return false;
    }),
    // calculatedStatusBeforeCartForTag: computed('synced', '_data.{isSetWithChoices,isSet,hasVariants,calculatedStatusBeforeCart}', 'isOverSalesLimitedQtyStatus', function () {
    //   if (this._data?.isSetWithChoices || this._data?.hasVariants || this._data?.isSet) {
    //     return this._data.calculatedStatusBeforeCart
    //   }

    //   return this.isOverSalesLimitedQtyStatus
    // }),

    isOverSalesLimitedQtyStatus: Ember.computed('isOverSalesLimitedQtyItems.[]', 'isOverSalesLimitedQty', function () {
      const isOverSalesLimitedQtyItems = this.isOverSalesLimitedQtyItems;
      if (!R.isEmpty(isOverSalesLimitedQtyItems)) {
        const result = R.reduce((acc, limitDetail) => {
          if (acc === this.constants.productsStatus.soldOut) {
            return acc;
          }
          return R.path(['_data', 'overSalesLimitedQtyStatus'])(limitDetail);
        }, false)(isOverSalesLimitedQtyItems);
        if (!result) {
          return this._data.overSalesLimitedQtyStatus;
        }
        return result;
      }
      return this._data.overSalesLimitedQtyStatus;
    }),
    // minSalesDetailsLimitData: computed('hasAnySalesLimitedQty', 'limitsSorted.@each.synced', '_data.salesLimitedType', function () {
    //   const hasAnySalesLimitedQty = this.hasAnySalesLimitedQty
    //   if (hasAnySalesLimitedQty) {
    //     const limitsSorted = this.limitsSorted || []
    //     return nventor.safeHeadOr({})(limitsSorted)
    //   }

    //   return {}
    // }),

    // minSalesLimitedQty: computed('minSalesDetailsLimitData.{itemKey,salesLimitedQty,salesLimitedType}', function () {
    //   const minSalesDetailsLimitData = this.minSalesDetailsLimitData || {}
    //   const preOrderQty = parseInt(R.pathOr(0, ['salesPreOrderLimitedQty'])(minSalesDetailsLimitData)) || 0
    //   const salesLimitedQty = parseInt(R.pathOr(0, ['salesLimitedQty'])(minSalesDetailsLimitData)) || 0
    //   return preOrderQty + salesLimitedQty
    // }),

    _calculateMinSalesLimitedDataForSet(detail, limits) {
      const limitedDetail = R.find(R.pathEq(['_data', 'itemKey'], detail?._data?.itemKey))(limits);
      const salesLimitedQtys = [];
      const salesPreOrderLimitedQtys = [];
      const availableSalesLimitedQtys = [];
      const availableSalesPreOrderLimitedQtys = [];
      if (limitedDetail?._data?.hasSalesLimitedQty) {
        salesLimitedQtys.push(parseInt(limitedDetail?._data?.salesLimitedQty) || 0);
        availableSalesLimitedQtys.push(parseInt(limitedDetail?._data?.calculatedAvailableQtyBeforeCart) || 0);
      }
      if (limitedDetail?._data?.allowPreOrder) {
        if (limitedDetail?._data?.hasSalesPreOrderLimitedQty) {
          salesPreOrderLimitedQtys.push(parseInt(limitedDetail?._data?.salesPreOrderLimitedQty) || 0);
          availableSalesPreOrderLimitedQtys.push(parseInt(limitedDetail?._data?.calculatedAvailablePreOrderQtyBeforeCart) || 0);
        }
      } else if (limitedDetail?._data?.hasSalesLimitedQty) {
        salesPreOrderLimitedQtys.push(0);
        availableSalesPreOrderLimitedQtys.push(0);
      }
      R.pipe(R.pathOr([], ['_data', 'setDetails']), R.forEach(setDetail => {
        const childLimitedDetail = R.find(R.pathEq(['_data', 'itemKey'], setDetail?._data?.itemKey))(limits);
        const setChildQty = parseInt(setDetail?._data?.qty) || 1;
        if (childLimitedDetail) {
          if (childLimitedDetail?._data?.hasSalesLimitedQty) {
            const salesLimitedQty = parseInt(childLimitedDetail?._data?.salesLimitedQty) || 0;
            const parentSalesLimitedQty = Math.floor(salesLimitedQty / setChildQty);
            salesLimitedQtys.push(parentSalesLimitedQty);
            const availableSalesLimitedQty = parseInt(childLimitedDetail?._data?.calculatedAvailableQtyBeforeCart) || 0;
            const parentAvailableSalesLimitedQty = Math.floor(availableSalesLimitedQty / setChildQty);
            availableSalesLimitedQtys.push(parentAvailableSalesLimitedQty);
          }
          if (childLimitedDetail?._data?.allowPreOrder) {
            if (childLimitedDetail?._data.hasSalesLimitedQty) {
              const salesPreOrderLimitedQty = parseInt(childLimitedDetail?._data?.salesPreOrderLimitedQty) || 0;
              const parentSalesPreOrderLimitedQty = Math.floor(salesPreOrderLimitedQty / setChildQty);
              salesPreOrderLimitedQtys.push(parentSalesPreOrderLimitedQty);
              const availableSalesPreOrderLimitedQty = parseInt(childLimitedDetail?._data?.calculatedAvailablePreOrderQtyBeforeCart) || 0;
              const parentAvailableSalesPreOrderLimitedQty = Math.floor(availableSalesPreOrderLimitedQty / setChildQty);
              availableSalesPreOrderLimitedQtys.push(parentAvailableSalesPreOrderLimitedQty);
            }
          } else if (childLimitedDetail?._data?.hasSalesLimitedQty) {
            salesPreOrderLimitedQtys.push(0);
            availableSalesPreOrderLimitedQtys.push(0);
          }
        }
      }))(detail);
      return {
        hasSalesLimitedChoices: false,
        minSalesLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(salesLimitedQtys),
        minSalesPreOrderLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(salesPreOrderLimitedQtys),
        minAvailableSalesLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(availableSalesLimitedQtys),
        minAvailableSalesPreOrderLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(availableSalesPreOrderLimitedQtys)
      };
    },
    minSalesLimitedData: Ember.computed('limits.@each.synced', 'synced', function () {
      const limits = this.limits || [];
      const isSet = this?._data?.isSet;
      if (isSet) {
        return this._calculateMinSalesLimitedDataForSet(this, limits);
      }
      const isSetWithChoices = this?._data?.isSetWithChoices;
      if (isSetWithChoices) {
        const salesLimitedQtys = [];
        const salesPreOrderLimitedQtys = [];
        const availableSalesLimitedQtys = [];
        const availableSalesPreOrderLimitedQtys = [];
        let hasSalesLimitedChoices = false;
        R.pipe(R.pathOr([], ['_data', 'choices']), R.forEach(choice => {
          const choiceSalesLimitedQtys = [];
          const choiceSalesPreOrderLimitedQtys = [];
          const choiceAvailableSalesLimitedQtys = [];
          const choiceAvailableSalesPreOrderLimitedQtys = [];
          const choiceDetails = R.pathOr([], ['_data', 'details'])(choice);
          R.forEach(detail => {
            if (detail?._data?.isSet) {
              const setLimitedData = this._calculateMinSalesLimitedDataForSet(detail, limits);
              if (setLimitedData.minSalesLimitedQty !== false) {
                choiceSalesLimitedQtys.push(parseInt(setLimitedData.minSalesLimitedQty) || 0);
              }
              if (setLimitedData.minAvailableSalesLimitedQty !== false) {
                choiceAvailableSalesLimitedQtys.push(parseInt(setLimitedData.minAvailableSalesLimitedQty) || 0);
              }
              if (setLimitedData.minSalesLimitedQty !== false) {
                choiceSalesPreOrderLimitedQtys.push(parseInt(setLimitedData.minSalesPreOrderLimitedQty) || 0);
              }
              if (setLimitedData.minAvailableSalesPreOrderLimitedQty !== false) {
                choiceAvailableSalesPreOrderLimitedQtys.push(parseInt(setLimitedData.minAvailableSalesPreOrderLimitedQty) || 0);
              }
            } else {
              // find childLimitData
              const childLimitedDetail = R.find(R.pathEq(['_data', 'itemKey'], detail?._data.itemKey))(limits);
              if (childLimitedDetail?._data.hasSalesLimitedQty) {
                choiceSalesLimitedQtys.push(parseInt(childLimitedDetail?._data.salesLimitedQty) || 0);
                choiceAvailableSalesLimitedQtys.push(parseInt(childLimitedDetail?._data?.calculatedAvailableQtyBeforeCart) || 0);
              }
              if (childLimitedDetail?._data?.allowPreOrder) {
                if (childLimitedDetail?._data.hasSalesPreOrderLimitedQty) {
                  choiceSalesPreOrderLimitedQtys.push(parseInt(childLimitedDetail?._data.salesPreOrderLimitedQty) || 0);
                  choiceAvailableSalesPreOrderLimitedQtys.push(parseInt(childLimitedDetail?._data?.calculatedAvailablePreOrderQtyBeforeCart) || 0);
                }
              } else if (childLimitedDetail?._data?.hasSalesLimitedQty) {
                choiceSalesPreOrderLimitedQtys.push(0);
                choiceAvailableSalesPreOrderLimitedQtys.push(0);
              }
            }
          })(choiceDetails);
          if (choiceSalesLimitedQtys.length === choiceDetails.length) {
            hasSalesLimitedChoices = true;
            const choiceQty = choice?._data?.qty || 1;
            if (choiceSalesLimitedQtys.length > 0) {
              salesLimitedQtys.push(Math.floor(R.sum(choiceSalesLimitedQtys) / choiceQty));
            }
            if (choiceAvailableSalesLimitedQtys.length > 0) {
              availableSalesLimitedQtys.push(Math.floor(R.sum(choiceAvailableSalesLimitedQtys) / choiceQty));
            }
            if (choiceSalesPreOrderLimitedQtys.length > 0) {
              salesPreOrderLimitedQtys.push(Math.floor(R.sum(choiceSalesPreOrderLimitedQtys) / choiceQty));
            }
            if (choiceAvailableSalesPreOrderLimitedQtys.length > 0) {
              availableSalesPreOrderLimitedQtys.push(Math.floor(R.sum(choiceAvailableSalesPreOrderLimitedQtys) / choiceQty));
            }
          }
        }))(this);
        return {
          hasSalesLimitedChoices,
          minSalesLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(salesLimitedQtys),
          minSalesPreOrderLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(salesPreOrderLimitedQtys),
          minAvailableSalesLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(availableSalesLimitedQtys),
          minAvailableSalesPreOrderLimitedQty: R.pipe(_nventor.default.sortAscending, R.propOr(false, 0))(availableSalesPreOrderLimitedQtys)
        };
      }
      const limitedDetail = R.find(R.pathEq(['_data', 'itemKey'], this?._data?.itemKey))(limits);
      let minSalesLimitedQty = false;
      let minAvailableSalesLimitedQty = false;
      if (limitedDetail?._data?.hasSalesLimitedQty) {
        minSalesLimitedQty = R.pathOr(false, ['_data', 'salesLimitedQty'])(limitedDetail);
        minAvailableSalesLimitedQty = R.pathOr(false, ['_data', 'calculatedAvailableQtyBeforeCart'])(limitedDetail);
      }
      let minSalesPreOrderLimitedQty = false;
      let minAvailableSalesPreOrderLimitedQty = false;
      if (limitedDetail?._data.allowPreOrder) {
        if (limitedDetail?._data?.hasSalesPreOrderLimitedQty) {
          minSalesPreOrderLimitedQty = R.pathOr(false, ['_data', 'salesPreOrderLimitedQty'])(limitedDetail);
          minAvailableSalesPreOrderLimitedQty = R.pathOr(false, ['_data', 'calculatedAvailablePreOrderQtyBeforeCart'])(limitedDetail);
        }
      }
      return {
        hasSalesLimitedChoices: false,
        minSalesLimitedQty,
        minSalesPreOrderLimitedQty,
        minAvailableSalesLimitedQty,
        minAvailableSalesPreOrderLimitedQty
      };
    }),
    availableQty: Ember.computed('_data.salesQty', 'minSalesLimitedData.{hasSalesLimitedChoices,minSalesLimitedQty,minSalesPreOrderLimitedQty,minAvailableSalesLimitedQty,minAvailableSalesPreOrderLimitedQty}', function () {
      const minAvailableSalesLimitedQty = this.minSalesLimitedData?.minAvailableSalesLimitedQty;
      if (minAvailableSalesLimitedQty === false) {
        return '';
      }
      if (this._data.isSetWithChoices) {
        if (!this.minSalesLimitedData?.hasSalesLimitedChoices) {
          return '';
        }
      }
      return this.intl.formatNumber(parseInt(minAvailableSalesLimitedQty) || 0);
    }),
    availablePreOrderQty: Ember.computed('_data.salesQty', 'minSalesLimitedData.{hasSalesLimitedChoices,minSalesLimitedQty,minSalesPreOrderLimitedQty,minAvailableSalesLimitedQty,minAvailableSalesPreOrderLimitedQty}', function () {
      const minAvailableSalesPreOrderLimitedQty = this.minSalesLimitedData?.minAvailableSalesPreOrderLimitedQty;
      if (minAvailableSalesPreOrderLimitedQty === false) {
        return '';
      }
      if (this._data.isSetWithChoices) {
        if (!this.minSalesLimitedData?.hasSalesLimitedChoices) {
          return '';
        }
      }
      return this.intl.formatNumber(parseInt(minAvailableSalesPreOrderLimitedQty) || 0);
    }),
    // currentStatus: computed('hasAnySalesLimitedQty', '_data.status', 'limitsSorted.[]', function () {
    //   const limits = this.get('limitsSorted') || []
    //   if (limits)
    // }),

    loadProduct(campaign, item, product, {
      isResync = false
    } = {}) {
      if (isResync) {
        return this._resyncProduct(campaign, item, product);
      }
      const itemDataBeforeReset = R.clone(item.serialize());
      itemDataBeforeReset.campaign = true;
      this.resetDetail();
      const itemData = this.serialize();
      // @TODO: get from settings?
      if (_ramdaAdjunct.default.isEmptyArray(itemData.productSync)) {
        itemData.productSync = ['item', 'templates', 'productTags', 'productDisplayTags', 'description', 'additionalDescription', 'recurringSchedules'];
      }

      // @NOTE: WHEN OVERRIDING DETAIL WITH PRODUCT OMIT THIS KEYS
      const exclude = ['_key', '_rev', '_id', 'status', 'creator', 'createdDateZ', 'modified', 'timestampZ', 'timestampZ_update', 'timestampZ_latest'];
      const productData = product.serialize({
        exclude
      });
      itemData.itemKey = product.getData('_key');
      itemData.item = productData.name || itemData?.item;
      itemData.checkoutDescription = productData.checkoutDescription;
      itemData.itemCode = productData.code;
      itemData.productTags = R.pathOr([], ['_data', 'tags'], product) || itemData?.productTags || [];
      itemData.roundingInventoryQty = productData.roundingInventoryQty || this.settings.getProp('roundingInventoryQty');
      itemData.roundingInventoryPrice = productData.roundingInventoryPrice || this.settings.getProp('roundingInventoryPrice');
      const onSale = itemData.onSale;
      this._loadIsSetWithChoices(campaign, productData, onSale);
      this._loadVariantChildren(campaign, productData, onSale);
      this._loadCustomizationsPrice(itemData, productData); // @TODO darwin: is this correct? should be itemDataBeforeReset?

      const newItemData = R.mergeRight(itemData, productData);
      this.populate(newItemData);

      // @NOTE: DO NOT SET PRICES ON LOAD OR RE-SYNC
      // setting price back when resync. if adding product for the first time will not set anything
      this.setDetailPrice(campaign, item, itemDataBeforeReset);
    },
    _resyncProduct(campaign, item, product, {
      isResync = false
    } = {}) {
      const itemData = R.clone(item.serialize());
      itemData.campaign = true;

      // @TODO: get from settings?
      if (_ramdaAdjunct.default.isEmptyArray(itemData.productSync)) {
        itemData.productSync = ['item', 'templates', 'productTags', 'productDisplayTags', 'description', 'additionalDescription', 'recurringSchedules'];
      }

      // @NOTE: WHEN OVERRIDING DETAIL WITH PRODUCT OMIT THIS KEYS
      const exclude = ['_key', '_rev', '_id', 'status', 'creator', 'createdDateZ', 'modified', 'timestampZ', 'timestampZ_update', 'timestampZ_latest'];
      const productSync = this._data?.productSync || [];
      if (!R.includes('item')(productSync)) {
        exclude.pushObject('name');
      }
      if (!R.includes('description')(productSync)) {
        exclude.pushObject('description');
      }
      if (!R.includes('additionalDescription')(productSync)) {
        exclude.pushObject('additionalDescription');
      }
      if (!R.includes('templates')(productSync)) {
        exclude.pushObject('template');
        exclude.pushObject('templates');
      }
      if (!R.includes('productTags')(productSync)) {
        exclude.pushObject('tags');
      }
      if (!R.includes('productDisplayTags')(productSync)) {
        exclude.pushObject('productDisplayTags');
      }
      const productData = product.serialize({
        exclude
      });
      itemData.itemKey = product.getData('_key');
      itemData.item = productData.name || itemData?.item;
      itemData.checkoutDescription = productData.checkoutDescription;
      itemData.itemCode = productData.code;
      itemData.productTags = R.pathOr([], ['_data', 'tags'], product) || itemData?.productTags || [];
      itemData.roundingInventoryQty = productData.roundingInventoryQty || this.settings.getProp('roundingInventoryQty');
      itemData.roundingInventoryPrice = productData.roundingInventoryPrice || this.settings.getProp('roundingInventoryPrice');
      const onSale = itemData.onSale;
      this._loadIsSetWithChoices(campaign, productData, onSale);
      this._loadVariantChildren(campaign, productData, onSale);

      // @TODO darwin: is this correct? should be itemDataBeforeReset?
      this._loadCustomizationsPrice(itemData, productData);
      const newItemData = R.mergeRight(itemData, productData);
      this.populate(newItemData);

      // @NOTE: DO NOT SET PRICES ON LOAD OR RE-SYNC
      // setting price back when resync. if adding product for the first time will not set anything
      this.setDetailPrice(campaign, item, itemData);
    },
    _loadCustomizationsPrice(itemData, productData) {
      // USED TO ADD BACK THE PRICE BASED ON OPTIONUID AFTER SYNC
      const itemDataCustomizationsOptions = R.pipe(R.propOr([], 'customizations'), R.map(R.propOr([], 'options')), R.flatten, R.reject(_ramdaAdjunct.default.isNilOrEmpty))(itemData);
      productData.customizations = R.pipe(R.propOr([], 'customizations'), R.map(customization => {
        const options = customization?.options || [];
        R.forEach(option => {
          const optionUid = option?.optionUid;
          const itemDataCustomizationOption = R.find(R.propEq('optionUid', optionUid))(itemDataCustomizationsOptions);
          if (_ramdaAdjunct.default.isNotNilOrEmpty(itemDataCustomizationOption)) {
            option.price = itemDataCustomizationOption?.price;
            option.priceDiscounted = itemDataCustomizationOption?.priceDiscounted;
          }
        })(options);
        return customization;
      }))(productData);
    },
    _loadIsSetWithChoices(campaign, productData, onSale) {
      // isSetWithChoices is ignored for now (refactoring on new branch)
      if (productData.isSetWithChoices) {
        productData.choices = R.pipe(R.propOr([], 'choices'), R.map(choiceData => {
          // const details = choiceData.details || []
          choiceData.onSale = onSale;
          choiceData.details = R.pipe(R.propOr([], 'details'), R.map(detailData => {
            detailData.onSale = onSale;
            if (detailData.hasVariants) {
              detailData = this._loadVariantChildren(campaign, detailData, onSale);
            }
            return detailData;
          }))(choiceData);
          return choiceData;
        }))(productData);
        productData.price = 0;
        productData.priceDiscounted = 0;
      }
      return productData;
    },
    _loadVariantChildren(campaign, productData, onSale) {
      const hasVariants = productData.hasVariants;
      if (hasVariants) {
        productData.variantChildren = R.pipe(R.propOr([], 'variantChildren'), R.map(variantChild => {
          variantChild.onSale = onSale;

          // when child product is isSetWithChoice.variant it already has child.itemKey
          // but when child product is from hasVariants (not in set with choice) then it does not have itemKey (as its coming from the products collection)
          if (!variantChild.itemKey) {
            variantChild.itemKey = variantChild._key || '';
          }
          if (!variantChild.item) {
            variantChild.item = variantChild.name || '';
          }
          if (!variantChild.itemCode) {
            variantChild.itemCode = variantChild.code || '';
          }
          variantChild.price = '';
          variantChild.priceDiscounted = '';
          variantChild.priceFinal = '';

          // product data has variantChildren hasVariants = false, fucking up schema in API to ignore price when hasVariants=true
          delete variantChild.hasVariants;
          return variantChild;
        }))(productData);
      }
      return productData;
    },
    setDetailPrice(campaignOrAutomationModel, item, historyData) {
      // if from campaign:
      //    - use price
      //    - set priceDiscounted
      //    - set onSale
      //
      // if from history doc
      //    - use inclPrice
      // if from product
      //    - use salesInclPrice
      const isCampaign = R.prop('campaign', historyData);
      this._setItemPrice(campaignOrAutomationModel, isCampaign, item, historyData);
      // @TODO darwin: refactor set with choices in new branch
      this._setIsSetWithChoicesPrices(campaignOrAutomationModel, isCampaign, item, historyData);
      this._setVariantChildrenPrices(campaignOrAutomationModel, isCampaign, item, historyData);
      this._setSetDetailsPrices(campaignOrAutomationModel, isCampaign, item, historyData);
      this.recalculatePrice(item);
    },
    // @TODO darwin: refactor in new branch
    _setIsSetWithChoicesPrices(campaignOrAutomationModel, isCampaign, item, historyData) {
      const choicesChildrenHistory = R.pipe(R.propOr([], 'choices'), R.pluck('details'), R.flatten)(historyData);
      const choicesHistory = R.pipe(R.propOr([], 'choices'), R.flatten)(historyData);
      R.pipe(R.pathOr([], ['_data', 'choices']), R.forEach(choice => {
        const name = choice.getData('name');
        const choiceHistory = R.find(R.propEq('name', name), choicesHistory);
        if (!choiceHistory) {
          return false;
        }
        if (choiceHistory.isSetPriceOnBundle) {
          choice.setData('isSetPriceOnBundle', choiceHistory.isSetPriceOnBundle);
        }
        if (choiceHistory.price) {
          choice.setData('price', choiceHistory.price);
        }
        if (choiceHistory.priceDiscounted) {
          choice.setData('priceDiscounted', choiceHistory.priceDiscounted);
        }
        if (choiceHistory.priceFinal) {
          choice.setData('priceFinal', choiceHistory.priceFinal);
        }
      }))(item);
      R.pipe(R.pathOr([], ['_data', 'choices']), R.pluck('_data'), R.pluck('details'), R.flatten, R.forEach(child => {
        const itemKey = child.getData('itemKey');
        const childHistory = R.find(R.propEq('itemKey', itemKey))(choicesChildrenHistory);
        this._setVariantChildrenPrices(campaignOrAutomationModel, isCampaign, child, childHistory);
        this._setSetDetailsPrices(campaignOrAutomationModel, isCampaign, child, childHistory);
        this._setItemPrice(campaignOrAutomationModel, isCampaign, child, childHistory);
      }))(item);
    },
    resetDetail: function () {
      this.setData('item', '');
      this.setData('itemKey', '');
      this.setData('itemCode', '');
      this.setData('setType', '');
      this.setData('hasVariants', false);
      this.setData('variantChildren', []);
      this.setData('isSetWithChoices', false);
      this.setData('choices', []);
      this.setData('isSet', false);
      this.setData('setDetails', []);
    },
    recalculatePriceForSet(detail, parent) {
      const isSetNew = R.pathOr(false, ['_data', 'isSetNew'], detail);
      if (isSetNew) {
        const setDetailsArray = _nventor.default.ensurePathOr([], ['_data', 'setDetails'], detail);
        let totalPrice = detail.big.newBig(0);
        let totalPriceDiscounted = detail.big.newBig(0);
        let totalPriceFinal = detail.big.newBig(0);
        let totalCommissionBaseCost = detail.big.newBig(0);
        R.forEach(detailChild => {
          const detailChildData = R.propOr({}, '_data', detailChild);
          const detailChildPrice = detail.big.newBig(detailChildData.price || 0).times(detailChildData.qty);
          const detailChildPriceDiscounted = detail.big.newBig(detailChildData.priceDiscounted || 0).times(detailChildData.qty);
          const detailChildPriceFinal = detail.big.newBig(detailChildData.priceFinal || 0).times(detailChildData.qty);
          totalPrice = totalPrice.plus(detailChildPrice);
          totalPriceDiscounted = totalPriceDiscounted.plus(detailChildPriceDiscounted);
          totalPriceFinal = totalPriceFinal.plus(detailChildPriceFinal);
        })(setDetailsArray);
        detail.setData('price', totalPrice.toFixed(0));
        detail.setData('priceDiscounted', totalPriceDiscounted.toFixed(0));
        detail.setData('priceFinal', totalPriceFinal.toFixed(0));
        if (parent?.updateSynced) {
          parent.updateSynced();
        }
      }
      return detail;
    },
    recalculatePrice(detail) {
      if (detail.updateSynced) {
        detail.updateSynced();
      }
      return detail;
    }
  }, _applyDecoratedDescriptor(_obj, "addNewTemplate", [_dec], Object.getOwnPropertyDescriptor(_obj, "addNewTemplate"), _obj), _applyDecoratedDescriptor(_obj, "removeTemplate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "removeTemplate"), _obj), _applyDecoratedDescriptor(_obj, "addProductDisplayTags", [_dec3], Object.getOwnPropertyDescriptor(_obj, "addProductDisplayTags"), _obj), _applyDecoratedDescriptor(_obj, "removeProductDisplayTag", [_dec4], Object.getOwnPropertyDescriptor(_obj, "removeProductDisplayTag"), _obj), _applyDecoratedDescriptor(_obj, "_calculateSalesLimitedQty", [_dec5], Object.getOwnPropertyDescriptor(_obj, "_calculateSalesLimitedQty"), _obj), _applyDecoratedDescriptor(_obj, "_calculateSalesPreOrderLimitedQty", [_dec6], Object.getOwnPropertyDescriptor(_obj, "_calculateSalesPreOrderLimitedQty"), _obj), _applyDecoratedDescriptor(_obj, "recalculatePrice", [_dec7], Object.getOwnPropertyDescriptor(_obj, "recalculatePrice"), _obj), _obj));
  let ChannelsShopCampaignsDetailsModel = _exports.default = (_dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._action, _class = class ChannelsShopCampaignsDetailsModel extends ChannelsShopCampaignsDetailsBaseModel {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "uploadcare", _descriptor, this);
      _initializerDefineProperty(this, "_data", _descriptor2, this);
    }
    get hasProductSync() {
      const productSync = R.pathOr([], ['_data', 'productSync'])(this);
      const syncName = R.includes('item');
      const syncTemplates = R.includes('templates');
      const syncTags = R.includes('productTags');
      const syncDisplayTags = R.includes('productDisplayTags');
      return R.anyPass([syncName, syncTemplates, syncTags, syncDisplayTags])(productSync);
    }
    get parseAsProduct() {
      const productOriginalData = this?.product?._data;
      const detail = this._data;
      const productData = R.mergeRight(productOriginalData, {
        name: detail.item,
        code: detail?.itemCode,
        salesExclPrice: detail.price
      });
      const product = this.dataManager.newRecord({
        adapterName: 'products',
        data: productData
      });
      return product;
    }
    getDefaultImageUrl(transform) {
      const defaultUploadUuid = R.pipe(R.pathOr([], ['_data', 'uploads']), R.find(R.pathEq(['_data', 'isDefault'], true)), R.pathOr('', ['_data', 'uuid']))(this);
      return this.uploadcare.getFileUrl(defaultUploadUuid, transform);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uploadcare", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getDefaultImageUrl", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "getDefaultImageUrl"), _class.prototype), _class);
});