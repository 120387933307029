define("client/pods/components/layouts/section/sub/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // use this instead of section-container

  class LayoutsSectionSubComponent extends _component.default {}
  _exports.default = LayoutsSectionSubComponent;
});