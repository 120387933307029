define("client/pods/components/reports/dashboard/panels-dash/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/crud", "client/mixins/date", "ember-concurrency", "ramda-extension"], function (_exports, R, RA, _crud, _date, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  // import { computed } from '@ember/object'
  // import { isEmpty } from '@ember/utils'
  var _default = _exports.default = Ember.Component.extend(_crud.default, _date.default, (_dec = Ember._action, _dec2 = Ember._action, _obj = {
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    applicationService: Ember.inject.service('application'),
    showResults: true,
    mm: '',
    yyyy: '',
    docType: 'sales',
    isTaxExclusive: true,
    init() {
      this.crud.addLists(this, ['dispatch/lists/processes', 'users/lists/menu', 'periods/months', 'periods/years']);
      this._super(...arguments);
      const customDateStartZ = this.dateService.getMonthStart().toISOString();
      const customDateEndZ = this.dateService.getMonthEnd().toISOString();
      this.customDateStartZ = customDateStartZ;
      this.customDateEndZ = customDateEndZ;
      this.set('chartOptions', {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      });
      const period = this.getPeriod();

      // this.set('mm', currentPeriod.mm)

      const pastMonths = this.getPastMonths(14);
      this.set('pastMonths', pastMonths);
      this.displayByMonth(period);
      const exportSpecs = {
        info: [{
          column: '',
          prop: '_index_'
        }, {
          column: '日期',
          prop: 'dateZ',
          transform: value => this.formatDate(value)
        }, {
          column: '出貨日期',
          prop: 'dispatchDateZ',
          transform: value => this.formatDate(value)
        }, {
          column: '收貨人',
          prop: 'contact'
        }, {
          column: '收貨人地址',
          prop: 'address'
        }, {
          column: '收貨人電話',
          prop: 'telephone'
        }, {
          column: '單號',
          prop: 'ref'
        }, {
          column: '付款方式',
          prop: 'paymentMethodKey'
        }, {
          column: '匯款帳號後五碼',
          prop: 'paymentLastFiveDigits'
        }, {
          column: '總計',
          prop: '_inclTotal_',
          transform: value => parseInt(value) || value
        }, {
          column: '備註',
          prop: 'notes'
        }, {
          column: '統編',
          prop: 'taxNumber'
        }, {
          column: '件數',
          prop: 'packages',
          transform: value => parseInt(value) || value
        }, {
          column: '託運單號',
          prop: 'waybill'
        }, {
          column: '商店',
          prop: 'source'
        }],
        transporter: [{
          column: '收貨人',
          prop: 'contact'
        }, {
          column: '收貨人地址',
          prop: 'address'
        }, {
          column: '收貨人電話',
          prop: 'telephone'
        }, {
          column: '付款方式',
          prop: 'paymentMethodKey'
        }, {
          column: '總計',
          prop: '_inclTotal_'
        }, {
          column: '件數',
          prop: 'packages',
          transform: value => parseInt(value) || value
        }, {
          column: '備註',
          prop: 'notes'
        }, {
          column: '單號',
          prop: 'ref'
        }, {
          column: '託運單號',
          prop: 'waybill'
        }],
        details: [{
          column: '',
          prop: ''
        }, {
          column: '數量',
          prop: 'qty',
          transform: value => parseInt(value) || value
        }, {
          column: '品項',
          prop: 'item'
        }, {
          column: '編碼',
          prop: 'itemCode'
        }, {
          column: '含稅額',
          prop: 'postDiscInclAmt',
          transform: value => parseInt(value) || value
        }]
      };
      this.set('exportSpecs', exportSpecs);
    },
    displayByMonth(period) {
      const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm));
      this.displayTask.perform({
        dateStart,
        dateEnd
      });
    },
    displayTask: (0, _emberConcurrency.task)(function* (period) {
      const {
        dateStart,
        dateEnd
      } = period;
      this.set('period', period);
      this.set('dateStart', dateStart);
      this.set('dateEnd', dateEnd);
      const monthlyReport = yield this.fetchReportTask.perform({
        dateStart,
        dateEnd,
        segments: ['dateZ']
      });
      // this.fetchYearTask.perform({ dateStart, dateEnd })
      this.set('monthlyReport', monthlyReport);
      this.set('itemReport', []);
      this.set('channelAndSourceReport', []);
      this.set('paymentMethodKeyAndPromoCodeReport', []);
      this.set('contactReport', []);
    }),
    displayItemReportTask: (0, _emberConcurrency.task)(function* () {
      const dateStart = this.dateStart || '';
      const dateEnd = this.dateEnd || '';
      if (RA.isNotNilOrEmpty(dateStart) && RA.isNotNilOrEmpty(dateEnd)) {
        const itemReport = yield this.fetchReportTask.perform({
          dateStart,
          dateEnd,
          segments: ['item']
        });
        this.set('itemReport', itemReport);
      }
    }),
    displayChannelAndSourceReportTask: (0, _emberConcurrency.task)(function* () {
      const dateStart = this.dateStart || '';
      const dateEnd = this.dateEnd || '';
      if (RA.isNotNilOrEmpty(dateStart) && RA.isNotNilOrEmpty(dateEnd)) {
        const channelAndSourceReport = yield this.fetchReportTask.perform({
          dateStart,
          dateEnd,
          segments: ['channel', 'source']
        });
        this.set('channelAndSourceReport', channelAndSourceReport);
      }
    }),
    displayPaymentMethodKeyAndPromoCodeReportTask: (0, _emberConcurrency.task)(function* () {
      const dateStart = this.dateStart || '';
      const dateEnd = this.dateEnd || '';
      if (RA.isNotNilOrEmpty(dateStart) && RA.isNotNilOrEmpty(dateEnd)) {
        const paymentMethodKeyAndPromoCodeReport = yield this.fetchReportTask.perform({
          dateStart,
          dateEnd,
          segments: ['paymentMethodKey', 'promoCode']
        });
        this.set('paymentMethodKeyAndPromoCodeReport', paymentMethodKeyAndPromoCodeReport);
      }
    }),
    displayContactReportTask: (0, _emberConcurrency.task)(function* () {
      const dateStart = this.dateStart || '';
      const dateEnd = this.dateEnd || '';
      if (RA.isNotNilOrEmpty(dateStart) && RA.isNotNilOrEmpty(dateEnd)) {
        const contactReport = yield this.fetchReportTask.perform({
          dateStart,
          dateEnd,
          segments: ['contact', 'contactTags', 'customerType', 'memberType', 'gender', 'age']
        });
        this.set('contactReport', contactReport);
      }
    }),
    fetchReportTask: (0, _emberConcurrency.task)(function* ({
      dateStart,
      dateEnd,
      segments = []
    }) {
      const monthParams = {
        docType: this.docType,
        dateStart,
        dateEnd,
        // segments: [
        //   'dateZ',
        //   'contact',
        //   'item',
        //   'channel',
        //   'source',
        //   'paymentMethodKey',
        //   'promoCode',
        //   'customerType',
        //   'memberType',
        //   'gender',
        //   'age'
        // ]
        segments
      };
      const results = yield this.server.call('GET', 'api/protected/reports/dashboard/', monthParams);
      return results.data;

      // const month = yield this.server.call('GET', 'api/protected/reports/dashboard/', monthParams)
      // this.set('monthlyReport', month.data)
    }),
    // fetchYearTask: task(function * ({ dateStart, dateEnd }) {
    //   const yearParams = {
    //     docType: this.docType,
    //     dateStart: this.formatDateForUrl(this.getYearStart(dateStart)),
    //     dateEnd: dateEnd,
    //     segments: ['month']
    //   }
    //   const year = yield this.server.call('GET', 'api/protected/reports/dashboard/', yearParams)
    //   this.set('yearlyReport', year.data)
    // }),

    isDispatchedDispatchProcess: Ember.computed('crud.lists.dispatch-lists-processes.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      return R.find(R.pathEq(['_data', 'code'], 'dispatched'), dispatchProcesses);
    }),
    selectByMonth(period) {
      this.displayByMonth(period);
      if (this.applicationService.isMobile) {
        this.set('showResults', false);
      }
    },
    selectCustomDate() {
      if (!this.customDateEndZ || !this.customDateStartZ) {
        return;
      }
      const period = this.dateService.getCustomPeriod(this.customDateEndZ, this.customDateStartZ);
      this.displayTask.perform(period);
      if (this.applicationService.isMobile) {
        this.set('showResults', false);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "selectByMonth", [_dec], Object.getOwnPropertyDescriptor(_obj, "selectByMonth"), _obj), _applyDecoratedDescriptor(_obj, "selectCustomDate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectCustomDate"), _obj), _obj));
});