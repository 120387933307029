define("client/pods/channels/shop/campaigns/details/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/channels/shop/campaigns/details/model", "ramda"], function (_exports, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/channels/shop/campaigns/details',
    modelObj: _model.default,
    idParam: '_id',
    serialize(model) {
      const data = this._super(model);
      if (data.useDefaultsFromProduct) {
        data.checkoutDescription = data.defaultCheckoutDescription;
      }
      const dirtyLimitsSortedByItem = model?.dirtyLimitsSortedByItem ?? [];
      data.limits = R.map(limit => {
        return limit.serialize();
      })(dirtyLimitsSortedByItem);
      return data;
    }
  });
});