define("client/helpers/product-title", ["exports", "ramda-adjunct"], function (_exports, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute(params) {
      const name = params[0];
      const code = params[1];
      if (code) {
        return `${name} (${code})`;
      }
      return name;
    }
  });
});