define("client/pods/components/files/image-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    transform: '',
    width: '',
    height: '',
    showNotFound: false,
    uploadcareService: Ember.inject.service('uploadcare'),
    src: Ember.computed('image', '_transform', function () {
      return this.uploadcareService.getFileUrl(this.image, this._transform);
    }),
    showImage: Ember.computed('showNotFound', 'image', function () {
      if (!this.showNotFound && !this.image) {
        return false;
      }
      return true;
    }),
    _transform: Ember.computed('transform', 'width', 'height', function () {
      if (this.transform) {
        return this.transform;
      }
      if (this.maxWidthOrHeight) {
        let imgWidth = Number(this.imgWidth) || 0;
        let imgHeight = Number(this.imgHeight) || 0;
        if (imgWidth > imgHeight) {
          if (imgWidth > Number(this.maxWidthOrHeight)) {
            imgWidth = Number(this.maxWidthOrHeight);
          }
          return `/-/resize/${imgWidth}x`;
        }
        if (imgHeight > Number(this.maxWidthOrHeight)) {
          imgHeight = Number(this.maxWidthOrHeight);
        }
        return `/-/resize/x${imgHeight}`;
      }
      let width = this.width || '';
      if (width === 'auto') {
        width = '';
      }
      let height = this.height || '';
      if (height === 'auto') {
        height = '';
      }
      const scaleCrop = this.scaleCrop;
      if (width && height && scaleCrop) {
        return `/-/scale_crop/${width}x${height}/smart`;
      }
      if (width || height) {
        return `/-/resize/${width}x${height}`;
      }
      return '';
    }),
    actions: {
      onSubmit() {
        if (this.onSubmit) {
          return this.onSubmit();
        }
      }
    }
  });
});