define("client/pods/products/lists/products-shop-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,
    findAll({
      data = {},
      appendPath,
      serverType
    } = {}) {
      const list = [{
        value: 'productNew',
        label: 'edit product',
        resource: 'api/protected/products',
        component: 'products/panels-new',
        allowedFor: ['productNew']
        // tabType: 'replaceSubTab',
        // editingComponent: 'products/products-editor/details-editor'
      }, {
        value: 'productEdit',
        label: 'edit product',
        resource: 'api/protected/products',
        component: 'products/panels-edit',
        allowedFor: ['productEdit', 'campaignDetail', 'productLimits']
        // component: 'products/panels-display/details-display',
        // tabType: 'replaceSubTab',
        // editingComponent: 'products/products-editor/details-editor'
      }, {
        value: 'campaignDetail',
        label: 'edit campaign detail',
        resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/products',
        component: 'channels/shop/campaigns/campaigns-item-editor',
        allowedFor: ['productEdit', 'campaignDetail', 'productLimits']
        // disabledFor: ['productNew']
        // tabType: 'replaceSubTab',
        // editingComponent: 'products/products-editor/pages-editor'
      }
      // {
      //   value: 'productLimits',
      //   label: 'product limits',
      //   component: 'channels/shop/campaigns/campaigns-item-limits-editor',
      //   resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/products-limits',
      //   allowedFor: ['productEdit', 'campaignDetail', 'productLimits'],
      //   // disabledFor: ['productNew']
      // }
      ];
      const customData = {
        list
      };
      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }
  });
});