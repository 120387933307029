define("client/pods/channels/shop/campaigns/lists/item-editor-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        value: 'basic',
        label: 'basic information',
        component: 'channels/shop/campaigns/campaigns-item-editor/basic-editor',
        tabType: 'reuseSubTab'
      }, {
        value: 'template',
        label: 'template',
        disallowedFor: ['templates', 'description', 'additionalDescription'],
        // disallow for when productSync includes 'templates'
        component: 'channels/shop/campaigns/campaigns-item-editor/template-editor',
        tabType: 'reuseSubTab'
      }, {
        value: 'recurringSchedules',
        label: 'recurring schedules',
        disallowedFor: ['recurringSchedules'],
        // disallow for when productSync includes 'recurringSchedules'
        component: 'channels/shop/campaigns/campaigns-item-editor/recurring-schedules-editor',
        tabType: 'reuseSubTab'
      }];
      if (this.settings.getProp('beta.enableCustomizations')) {
        data.pushObject({
          value: 'customizations',
          label: 'customizations',
          resource: 'api/protected/products/customizations',
          component: 'channels/shop/campaigns/campaigns-item-editor/customizations-editor',
          tabType: 'reuseSubTab',
          editingComponent: 'channels/shop/campaigns/campaigns-item-editor/customizations-editor'
        });
      }
      data.pushObject({
        value: 'productLimits',
        label: 'product limits',
        component: 'channels/shop/campaigns/campaigns-item-editor/limits-editor',
        tabType: 'reuseSubTab'
      });
      return _nventor.default.resolve(data);
    }
  });
});