define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/limits-editor/limits-editor-row/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsCampaignsItemLimitsEditorLimitsEditorRowComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember.computed('args.model.{_data.overrideUseInventoryAsLimitedQty,_data.useInventoryAsLimitedQty,product._data.isSet}', 'args.campaign._data.useInventoryAsLimitedQty', 'settings.settingsModel._data.beta.useInventoryAsLimitedQty'), _dec15 = Ember.computed('settings.settingsModel._data.beta.useInventoryAsLimitedQty', 'args.{campaign._data.useInventoryAsLimitedQty,model._data.hasSalesLimitedQty}'), _class = class ChannelsShopCampaignsCampaignsItemLimitsEditorLimitsEditorRowComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "settings", _descriptor2, this);
      _initializerDefineProperty(this, "constants", _descriptor3, this);
      _initializerDefineProperty(this, "big", _descriptor4, this);
      _initializerDefineProperty(this, "products", _descriptor5, this);
      _initializerDefineProperty(this, "tempCalculatedAvailablePreOrderQtyOnlyBeforeCart", _descriptor6, this);
      _initializerDefineProperty(this, "children", _descriptor7, this);
      this.fetchProductTask.perform();
    }
    *fetchProductTask() {
      const model = this.args?.model || {};
      if (!model) {
        return;
      }
      const itemKey = model?._data?.itemKey || {};
      if (!model.get('product.inventory') && itemKey) {
        const product = yield this.crud.findTask.perform({
          adapterName: 'products',
          appendPath: itemKey
        });
        model.set('product', product);
      }
      const children = yield this.products.fetchChildrenInventoryTask.perform(model?.product || {});
      this.children = children;
    }
    toggleHasSalesLimitedQty(model) {
      if (RA.isNilOrEmpty(model)) {
        model = this?.args?.model || {};
      }
      if (RA.isNilOrEmpty(model)) {
        return;
      }
      const hasSalesLimitedQty = R.pathOr(false, ['_data', 'hasSalesLimitedQty'])(model);
      model.setData('salesLimitedQty', '');
      model.setData('salesPreOrderLimitedQty', '');
      if (!hasSalesLimitedQty) {
        model.setData('allowPreOrder', false);
        model.setData('hasSalesPreOrderLimitedQty', false);
      }
    }
    toggleUseInventoryAsLimitedQty(model) {
      if (RA.isNilOrEmpty(model)) {
        model = this?.args?.model || {};
      }
      if (RA.isNilOrEmpty(model)) {
        return;
      }
      const hasSalesLimitedQty = R.pathOr(false, ['_data', 'hasSalesLimitedQty'])(model);
      if (!hasSalesLimitedQty) {
        model.setData('allowPreOrder', false);
        model.setData('hasSalesPreOrderLimitedQty', false);
      }
    }
    toggleAllowPreOrder(model) {
      if (RA.isNilOrEmpty(model)) {
        model = this?.args?.model || {};
      }
      if (RA.isNilOrEmpty(model)) {
        return;
      }
      model.setData('salesPreOrderLimitedQty', '');
      model.setData('hasSalesPreOrderLimitedQty', false);
    }
    toggleHasPreOrderLimitedQty(model) {
      if (RA.isNilOrEmpty(model)) {
        model = this?.args?.model || {};
      }
      if (RA.isNilOrEmpty(model)) {
        return;
      }
      model.setData('salesPreOrderLimitedQty', '');
    }
    calculateSalesLimitedQty(operator) {
      const model = this?.args?.model || {};
      if (RA.isNilOrEmpty(model)) {
        return;
      }
      let newSalesLimitedQtyBig = this.big.newBig(model?._data?.salesLimitedQty || 0);
      let newCalculatedAvailableQtyBeforeCartBig = this.big.newBig(model?._data?.calculatedAvailableQtyBeforeCart || 0);
      const salesQty = model?._data?.salesQty || 0;
      if (R.equals(operator, 'add')) {
        newSalesLimitedQtyBig = newSalesLimitedQtyBig.plus(1);
        newCalculatedAvailableQtyBeforeCartBig = newSalesLimitedQtyBig.minus(salesQty);
      } else if (R.equals(operator, 'subtract')) {
        newSalesLimitedQtyBig = newSalesLimitedQtyBig.minus(1);
        newCalculatedAvailableQtyBeforeCartBig = newSalesLimitedQtyBig.minus(salesQty);
      } else if (R.equals(operator, 'direct')) {
        newCalculatedAvailableQtyBeforeCartBig = newSalesLimitedQtyBig.minus(salesQty);
      } else if (R.equals(operator, 'addAvailableQty')) {
        newCalculatedAvailableQtyBeforeCartBig = newCalculatedAvailableQtyBeforeCartBig.plus(1);
        newSalesLimitedQtyBig = newCalculatedAvailableQtyBeforeCartBig.plus(salesQty);
      } else if (R.equals(operator, 'subtractAvailableQty')) {
        newCalculatedAvailableQtyBeforeCartBig = newCalculatedAvailableQtyBeforeCartBig.minus(1);
        newSalesLimitedQtyBig = newCalculatedAvailableQtyBeforeCartBig.plus(salesQty);
      } else if (R.equals(operator, 'directAvailableQty')) {
        newSalesLimitedQtyBig = newCalculatedAvailableQtyBeforeCartBig.plus(salesQty);
      }
      if (newSalesLimitedQtyBig.lte(0)) {
        newSalesLimitedQtyBig = this.big.newBig(0);
      }
      if (newCalculatedAvailableQtyBeforeCartBig.lte(0)) {
        newCalculatedAvailableQtyBeforeCartBig = this.big.newBig(0);
      }
      const roundingInventoryQty = this.settings.getProp('roundingInventoryQty');
      model.setData('salesLimitedQty', newSalesLimitedQtyBig.toFixed(roundingInventoryQty));
      model.setData('calculatedAvailableQtyBeforeCart', newCalculatedAvailableQtyBeforeCartBig.toFixed(roundingInventoryQty));
      let limitedQtyDeficitBig = this.big.newBig(0);
      if (newCalculatedAvailableQtyBeforeCartBig.lte(0)) {
        limitedQtyDeficitBig = this.big.newBig(newSalesLimitedQtyBig).minus(salesQty);
      }
      let newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = this.big.newBig(this?.tempCalculatedAvailablePreOrderQtyOnlyBeforeCart || 0);
      const newSalesPreOrderLimitedQtyBig = this.big.newBig(model?._data?.salesPreOrderLimitedQty || 0);
      newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = newSalesPreOrderLimitedQtyBig.plus(limitedQtyDeficitBig);
      this.tempCalculatedAvailablePreOrderQtyOnlyBeforeCart = newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.toFixed(roundingInventoryQty);
    }
    calculateSalesPreOrderLimitedQty(operator) {
      const model = this?.args?.model || {};
      if (RA.isNilOrEmpty(model)) {
        return;
      }
      let newSalesPreOrderLimitedQtyBig = this.big.newBig(model?._data?.salesPreOrderLimitedQty || 0);
      let newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = this.big.newBig(this?.tempCalculatedAvailablePreOrderQtyOnlyBeforeCart || 0);
      const salesLimitedQty = model?._data?.salesLimitedQty || 0;
      const salesQty = model?._data?.salesQty || 0;
      const calculatedAvailableQtyBeforeCartBig = this.big.newBig(model?._data?.calculatedAvailableQtyBeforeCart || 0);
      let limitedQtyDeficitBig = this.big.newBig(0);
      if (calculatedAvailableQtyBeforeCartBig.lte(0)) {
        limitedQtyDeficitBig = this.big.newBig(salesLimitedQty).minus(salesQty);
      }
      if (R.equals(operator, 'add')) {
        newSalesPreOrderLimitedQtyBig = newSalesPreOrderLimitedQtyBig.plus(1);
        newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = newSalesPreOrderLimitedQtyBig.plus(limitedQtyDeficitBig);
      } else if (R.equals(operator, 'subtract')) {
        newSalesPreOrderLimitedQtyBig = newSalesPreOrderLimitedQtyBig.minus(1);
        newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = newSalesPreOrderLimitedQtyBig.plus(limitedQtyDeficitBig);
      } else if (R.equals(operator, 'direct')) {
        newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = newSalesPreOrderLimitedQtyBig.plus(limitedQtyDeficitBig);
      } else if (R.equals(operator, 'addAvailablePreOrderQty')) {
        newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.plus(1);
        newSalesPreOrderLimitedQtyBig = newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.minus(limitedQtyDeficitBig);
      } else if (R.equals(operator, 'subtractAvailablePreOrderQty')) {
        newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.minus(1);
        newSalesPreOrderLimitedQtyBig = newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.minus(limitedQtyDeficitBig);
      } else if (R.equals(operator, 'directAvailablePreOrderQty')) {
        newSalesPreOrderLimitedQtyBig = newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.minus(limitedQtyDeficitBig);
      }
      if (newSalesPreOrderLimitedQtyBig.lte(0)) {
        newSalesPreOrderLimitedQtyBig = this.big.newBig(0);
      }
      const roundingInventoryQty = this.settings.getProp('roundingInventoryQty');
      model.setData('salesPreOrderLimitedQty', newSalesPreOrderLimitedQtyBig.toFixed(roundingInventoryQty));
      this.tempCalculatedAvailablePreOrderQtyOnlyBeforeCart = newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.toFixed(roundingInventoryQty);
    }
    get isLimitedQtyDisabled() {
      const isSet = this?.args?.model?.product?._data?.isSet ?? false;
      if (isSet) {
        return false;
      }
      const betaUseInventoryAsLimitedQty = this?.settings?.settingsModel?._data?.beta?.useInventoryAsLimitedQty ?? false;
      if (betaUseInventoryAsLimitedQty) {
        const campaignUseInventoryAsLimitedQty = this?.args?.campaign?._data?.useInventoryAsLimitedQty ?? false;
        if (campaignUseInventoryAsLimitedQty) {
          const overrideUseInventoryAsLimitedQty = this?.args?.model?._data?.overrideUseInventoryAsLimitedQty ?? false;
          if (!overrideUseInventoryAsLimitedQty) {
            return true;
          }
        } else {
          const useInventoryAsLimitedQty = this?.args?.model?._data?.useInventoryAsLimitedQty ?? false;
          if (useInventoryAsLimitedQty) {
            return true;
          }
        }
      }
      return false;
    }
    get isUseInventoryAsLimitedQtyDisabled() {
      const betaUseInventoryAsLimitedQty = this?.settings?.settingsModel?._data?.beta?.useInventoryAsLimitedQty ?? false;
      if (betaUseInventoryAsLimitedQty) {
        const campaignUseInventoryAsLimitedQty = this?.args?.campaign?._data?.useInventoryAsLimitedQty ?? false;
        if (!campaignUseInventoryAsLimitedQty) {
          const hasSalesLimitedQty = this?.args?.model?._data?.hasSalesLimitedQty ?? false;
          if (!hasSalesLimitedQty) {
            return true;
          }
        }
      }
      return false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "big", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "products", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tempCalculatedAvailablePreOrderQtyOnlyBeforeCart", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this?.args?.model?.calculatedAvailablePreOrderQtyOnlyBeforeCart || 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "children", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchProductTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchProductTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleHasSalesLimitedQty", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleHasSalesLimitedQty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleUseInventoryAsLimitedQty", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleUseInventoryAsLimitedQty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAllowPreOrder", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAllowPreOrder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleHasPreOrderLimitedQty", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "toggleHasPreOrderLimitedQty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "calculateSalesLimitedQty", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "calculateSalesLimitedQty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "calculateSalesPreOrderLimitedQty", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "calculateSalesPreOrderLimitedQty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isLimitedQtyDisabled", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "isLimitedQtyDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isUseInventoryAsLimitedQtyDisabled", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "isUseInventoryAsLimitedQtyDisabled"), _class.prototype), _class);
});