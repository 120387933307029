define("client/pods/components/products/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hdVkWE4K",
    "block": "{\"symbols\":[\"@isDuplicate\",\"@isFromShop\",\"@campaign\",\"@onCancel\",\"@onImportTask\",\"@tab\"],\"statements\":[[5,\"products/products-editor\",[],[[\"@adapterName\",\"@isDuplicate\",\"@isNew\",\"@isFromShop\",\"@campaign\",\"@isRunning\",\"@model\",\"@onCancel\",\"@onImportTask\",\"@onSaveTask\",\"@tab\"],[[23,0,[\"adapterName\"]],[23,1,[]],true,[23,2,[]],[23,3,[]],[23,0,[\"crud\",\"createRecordTask\",\"isRunning\"]],[23,0,[\"model\"]],[23,4,[]],[23,5,[]],[28,\"perform\",[[23,0,[\"saveRecordTask\"]]],null],[23,6,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-new/template.hbs"
    }
  });
});