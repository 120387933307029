define("client/pods/components/layouts/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WaMVQsgI",
    "block": "{\"symbols\":[\"&attrs\",\"@title\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"layouts-section\"],[13,1],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\",true],[10,\"class\",\"section-title\"],[8],[1,[28,\"tt\",[[24,[\"title\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/section/template.hbs"
    }
  });
});