define("client/pods/grapesjs/plugins/slider-native/components/Slide", ["exports", "client/pods/grapesjs/plugins/slider-native/constants", "client/pods/grapesjs/plugins/slider-native/utils", "ramda"], function (_exports, _constants, _utils, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (dc, config = {}) => {
    const defaultType = dc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const {
      slideName,
      slidesSelector
    } = _constants.default;
    const {
      intl
    } = config;
    dc.addType(slideName, {
      model: defaultModel.extend({
        defaults: {
          ...defaultModel.prototype.defaults,
          name: intl.t('slide'),
          draggable: slidesSelector,
          selectable: false,
          style: {
            // display: 'inline-block',
            // position: 'relative',
            // color: '#fff',
            // width: '880px',
            // 'min-width': '880px',
            // 'margin-right': '10px',
            // 'vertical-align': 'top',
            // 'min-height': '100px',
            // 'white-space': 'normal'
            // 'background-color': 'rgba(0, 0, 0, 0.1)'
          },
          ...config.slideProps,
          droppable(itemModel, targetModel) {
            const itemType = itemModel.get('type');
            const slider = targetModel.closestType('lory-slider');
            if (slider) {
              const disabledArr = ['bigordr-slider', 'bigordr-blogs-wrapper', 'bigordr-products-wrapper', 'bigordr-tabs-with-header', 'bigordr-grid-images', 'bigordr-scratch-card', 'tabs', 'lory-slider'];
              const disableDrop = R.includes(itemType)(disabledArr);
              if (disableDrop) {
                return false;
              }
            }
            return true;
          },
          components: [{
            type: 'row',
            style: {
              padding: '0px'
            },
            selectable: false,
            components: [{
              type: 'cell',
              selectable: false,
              classes: ['sider-cell__view', 'bigordr-cell', 'flex-container-column']
            }]
          }]
        },
        init() {
          // fade-in-out
          this.addClass('fade-in-out');
        }
      }, {
        isComponent(el) {
          if ((0, _utils.elHasClass)(el, config.classSlide)) return {
            type: slideName
          };
        }
      }),
      // onInit(el, model){
      //   const slider = this.getParent()
      //   slider.autoLoadComponetns
      //   this.add(autoLoadComp)
      // },

      view: defaultView
    });
  };
  _exports.default = _default;
});