define("client/pods/components/comments/tab-container/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopRewardsTabContainerComponent = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _class = class ChannelsShopRewardsTabContainerComponent extends _component.default {
    constructor() {
      super(...arguments);
      // this.crud.addLists(this, [
      //   {
      //     name: 'taxes',
      //     appendPath: 'products'
      //   },
      //   'filters/count',
      //   'filters/criterias',
      //   'products/lists/menu',
      //   'products/inventory/lists/qtyTypes',
      //   'products/lists/invoiceDescriptions',
      //   'products/lists/tags',
      //   'products/lists/tracking',
      //   'products/lists/types',
      //   'products/lists/setTypes',
      //   'products/lists/setTypesFilters',
      //   'settings/roundingAuto',
      //   'statuses',
      //   'toggles/yesNo',
      //   'units',
      //   'units/dimensions',
      //   'units/weight'
      // ])
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "constants", _descriptor2, this);
      _initializerDefineProperty(this, "crud", _descriptor3, this);
      _initializerDefineProperty(this, "search", _descriptor4, this);
      _initializerDefineProperty(this, "dataManager", _descriptor5, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor6, this);
      _initializerDefineProperty(this, "filters", _descriptor7, this);
      _initializerDefineProperty(this, "resultsToggle", _descriptor8, this);
      _defineProperty(this, "adapterName", 'comments/contacts');
      _defineProperty(this, "filtersAdapterName", 'comments/contacts/filters');
      _defineProperty(this, "defaultStatus", 'awaitingReplyFromMerchant');
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          fetchWithActiveCampaigns: true,
          status: this.defaultStatus || ''
        }
      });
      filters.set('showResults', true);
      this.filters = filters;
      this.loadTabTask.perform();
    }

    // afterLoadLists () {
    //   this.super(...arguments)
    //   this.loadTabTask.perform()
    // }

    *loadTabTask() {
      const tab = this.args.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);
      if (loadInstructions) {
        const model = loadInstructions.getData('model');
        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');
          const found = yield this.crud.findTask.perform({
            adapterName: this.adapterName,
            appendPath: id
          });
          const filters = this.filters;
          filters.set('results', RA.list(found));
          filters.setData('query', found.getData('docNo'));
          this.display(found);
        }
      } else {
        const adapterName = this.adapterName;
        const filters = this.filters;
        yield this.fetchDefaultDataTask.perform({
          adapterName,
          filters
        });
      }
    }
    *filterDefaultDataTask({
      status
    }) {
      const adapterName = this.adapterName;
      const filters = this.filters || {};
      if (RA.isNotNilOrEmpty(filters)) {
        const originalStatus = filters.getData('status') || '';
        if (R.equals(status, originalStatus)) {
          filters.setData('status', '');
        } else {
          filters.setData('status', status);
        }
      }
      yield this.fetchDefaultDataTask.perform({
        adapterName,
        filters
      });
      const subTabs = this?.args?.tab?.subTabs || [];
      if (RA.isNotNilOrEmpty(subTabs) && RA.isNotNilOrEmpty(subTabs[0])) {
        this.tabsManager.closeTab(subTabs, subTabs[0]);
      }
    }
    *fetchDefaultDataTask({
      adapterName,
      filters
    }) {
      yield this.crud.searchRecordsTask.perform({
        adapterName: `${this.adapterName}`,
        filters: this.filters
      });
    }

    /**
     * display the product
     * @param  {object} product model
     */
    display(model, {
      tabOptions
    } = {}) {
      if (this.applicationService.isMobile) {
        Ember.set(this, 'filters.showResults', false);
      }
      const tab = this.args.tab;
      const panelOptions = {
        component: 'comments/panels-display',
        model: model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, tabOptions);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "resultsToggle", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadTabTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadTabTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterDefaultDataTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "filterDefaultDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchDefaultDataTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDefaultDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "display", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "display"), _class.prototype), _class);
});