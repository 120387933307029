define("client/pods/components/elements/element-btn/component", ["exports", "client/mixins/has-dropdown", "client/pods/components/elements/mixins/has-date-picker"], function (_exports, _hasDropdown, _hasDatePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_hasDropdown.default, _hasDatePicker.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _obj = {
    applicationService: Ember.inject.service('application'),
    tagName: 'button',
    attributeBindings: ['id', 'disabled', 'tabindex', 'style'],
    tabindex: 0,
    translate: true,
    classNames: ['button', 'element-btn', 'u-tappable'],
    btnClassNames: null,
    classNameBindings: ['disabled', 'toggleIsOn:on:off', 'isRunning:is-loading', 'isOn:is-on', 'isActivated:is-primary'],
    isRunning: false,
    datePickerClassName: null,
    iconClassName: null,
    toggleIsOn: false,
    isToggler: false,
    hasModalDialog: false,
    modalDialogIsVisible: false,
    toggleOnIconClassName: 'fas fa-caret-up',
    toggleOffIconClassName: 'fas fa-caret-down',
    toggleOnIconRightClassName: 'fas fa-caret-up',
    toggleOffIconRightClassName: 'fas fa-caret-down',
    bubbles: false,
    hasToggleIcon: false,
    hasToggleIconRight: true,
    isPrintBtn: false,
    hasBadge: true,
    hasIconSpacers: true,
    _tooltipTranslate: Ember.computed('tooltipTranslate', function () {
      const tooltipTranslate = this.get('tooltipTranslate');
      if (tooltipTranslate === false) {
        return false;
      }
      if (tooltipTranslate === true) {
        return true;
      }
      return this.translate;
    }),
    hasBadgeComputed: Ember.computed('hasBadge', function () {
      if (this.hasBadge === null || this.hasBadge === undefined) {
        return true;
      }
      return Boolean(this.hasBadge);
    }),
    submit(e) {
      if (this.isPrintBtn) {
        this.printDialog();
      } else {
        if (this.disabled !== true) {
          if (this.hasModalDialog) {
            this.showModalDialog();
          }
          this._submit(e);
        }
      }
      this.applicationService.trackMouseEventPosition(e);
    },
    _submit(e) {
      if (this.onPreSubmit) {
        this.onPreSubmit(e, this);
      }
      var isToggler = this.isToggler;
      if (isToggler) {
        this.toggleProperty('toggleIsOn');
      }
      if (this.isDatePicker) {
        this.showDatePicker();
      }
      if (this.onSubmit) {
        if (this.requiresConfirmation) {
          const msg = this.confirmMsgTranslate ? this.intl.t(this.confirmMsg) : this.confirmMsg || this.intl.t('are you sure you want to delete');
          const confirmed = window.confirm(msg);
          if (confirmed) {
            return this.onSubmit(e, this);
          }
        } else {
          this.onSubmit(e, this);
        }
      }
    },
    click(e) {
      if (this.bubbles !== true) {
        e.stopPropagation();
      }
      this.submit(e);
    },
    keyUp(e) {
      if (this.bubbles !== true) {
        e.stopPropagation();
      }
      if (e.which === 13) {
        this.submit(e);
      }
    },
    showModalDialog() {
      this.set('modalDialogIsVisible', true);
    },
    hideModalDialog() {
      this.set('modalDialogIsVisible', false);
    },
    printDialog() {
      window.print();
    }
  }, _applyDecoratedDescriptor(_obj, "submit", [_dec], Object.getOwnPropertyDescriptor(_obj, "submit"), _obj), _applyDecoratedDescriptor(_obj, "_submit", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_submit"), _obj), _applyDecoratedDescriptor(_obj, "showModalDialog", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showModalDialog"), _obj), _applyDecoratedDescriptor(_obj, "hideModalDialog", [_dec4], Object.getOwnPropertyDescriptor(_obj, "hideModalDialog"), _obj), _applyDecoratedDescriptor(_obj, "printDialog", [_dec5], Object.getOwnPropertyDescriptor(_obj, "printDialog"), _obj), _obj));
});