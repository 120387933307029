define("client/pods/components/layouts/tabs/grid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ke9YpC/x",
    "block": "{\"symbols\":[\"&default\",\"@parentComponent\",\"@tab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-grid\"],[8],[0,\"\\n\\n  \"],[14,1],[0,\"\\n\\n  \"],[5,\"layouts/sidebar\",[],[[\"@isRight\",\"@showSidebar\",\"@toggleSidebar\",\"@hasMobileCloseBtn\"],[true,[23,0,[\"hasSidebarComponent\"]],[28,\"fn\",[[23,0,[\"applicationService\",\"modelSetPath\"]],[23,2,[]],\"sidebarRight\",\"\"],null],false]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"layouts__sidebar is-sticky-row-auto allow-mobile layouts__close-btn-container\"],[3,\"did-insert\",[[23,0,[\"applicationService\",\"setStickyTopMobile\"]]]],[8],[0,\"\\n      \"],[5,\"elements/element-pushbtn\",[],[[\"@classNames\",\"@onSubmit\"],[\"delete\",[28,\"fn\",[[23,0,[\"applicationService\",\"closeSidebarRight\"]],[23,2,[]]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"layouts/sidebar/content\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"component\",[[23,2,[\"sidebarRight\",\"component\"]]],[[\"tab\",\"sidebarOptions\"],[[23,3,[]],[23,2,[\"sidebarRight\"]]]]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"tab-grid__modal\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/tabs/grid/template.hbs"
    }
  });
});