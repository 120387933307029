define("client/pods/settings/model", ["exports", "client/pods/base/model", "client/utils/nventor", "client/pods/options/model", "client/pods/faqs/model", "client/pods/open-hours/model", "ramda", "ramda-adjunct"], function (_exports, _model, _nventor, _model2, _model3, _model4, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    populate(data, attrs) {
      data.paymentReminderEmails = this._transformPaymentReminderEmails(data.paymentReminderEmails);
      data.facebookPages = this._transformFacebookPages(data.facebookPages);
      data.orderedEmailCopyTo = this.emails.joinAsString(data.orderedEmailCopyTo);
      data.orderMessagesEmailCopyTo = this.emails.joinAsString(data.orderMessagesEmailCopyTo);
      data.returnsEmailCopyTo = this.emails.joinAsString(data.returnsEmailCopyTo);
      data.tw_eInvoices_notifyEmailAddress = this.emails.joinAsString(data.tw_eInvoices_notifyEmailAddress);
      data.faqs = this._transformFaqs(data);
      data.shopPriceFilters = this._transformRates(data.shopPriceFilters);
      data.tw_payments_bsp = this._transformBspPayments(data);
      data.openHours = this._transformOpenHours(data);
      this._super(data, attrs);
    },
    defaults() {
      const defaultData = {
        shopAllowConvenienceStorePickup: false,
        shopAllowConvenienceStoreBrands: [],
        shopAllowConvenienceStoreBrandDefault: 'sevenEleven',
        shopAllowShipping: true,
        shopAllowPickup: false,
        shopHideCampaignHeader: false,
        shopPickupLocations: [],
        shopCheckoutConvenienceStoreDescription: '',
        shopCheckoutSummaryDescription: '',
        shopCheckoutDetailsDescription: '',
        shopCheckoutPaymentDescription: '',
        successOrderDescription: '',
        openHours: {
          hasBreakTime: false,
          monday: {},
          tuesday: {},
          wednesday: {},
          thursday: {},
          friday: {},
          saturday: {},
          sunday: {}
        },
        blogsImagesRatio: this.get('constants.status.oneByOne'),
        shopDispatchProcess: this.get('constants.dispatchProcess.unprepared'),
        shopShowEnterPromoCode: true,
        shopSortOrderDetailsByIndex: false,
        shippingFee: '',
        shippingFeeCondition: '',
        shippingFeeAlwaysCharge: false,
        faqs: [],
        facebookPixelKeys: [],
        googleTrackingKeys: [],
        isAllowedLinkMember: false,
        isCommissionsUsingAverage: true,
        isShowTotalQty: false,
        shopVirtualAccountAutoExpiry: false,
        shopVirtualAccountExpiryDays: 7,
        dispatchFiltersCount: 10,
        salesPendingTaxMethod: this.get('constants.taxMethods.exclusive'),
        purchasesPendingTaxMethod: this.get('constants.taxMethods.exclusive'),
        creditCard_gmo: {
          enabled: false
        },
        creditCard_tapPay: {
          enabled: false,
          merchantId: '',
          partnerKey: '',
          bank: '',
          bankLabel: '',
          threeDomainSecure: true,
          isBig5Encoding: true
        },
        creditCard_tapPay_installments: {
          enabled: false,
          merchantId: '',
          threeDomainSecure: true,
          isBig5Encoding: true,
          bank: '',
          bankLabel: '',
          allowRememberCard: '',
          periods: '',
          minRequiredAmountPerPeriod: '',
          isInstallments: true
        },
        creditCard_tapPay_scheduledPayment: {
          enabled: false,
          merchantId: '',
          threeDomainSecure: false,
          isBig5Encoding: true,
          bank: '',
          bankLabel: '',
          allowRememberCard: '',
          periods: '',
          isInstallments: ''
        },
        linePay_tapPay: {
          enabled: false,
          merchantId: ''
        },
        emails_mailgun: {
          enabled: false,
          apiKey: ''
        },
        features: {
          allowMasterChildCampaigns: false,
          hasRewardForSpecificProductTags: false,
          extensions: {
            tw_eInvoices_print_allow_print: false,
            tw_eInvoices_print_allow_shop: false,
            tw_eInvoice_print_custom_block: '',
            tw_eInvoices_print_aesKey: ''
          },
          hasCustomProductsDisplaySettings: false
        },
        tw_payments_ecPay: {
          enabledVirtualAccount: false,
          merchantId: '',
          hashKey: '',
          hashIv: '',
          periods: '',
          minRequiredAmountPerPeriod: ''
        },
        tw_eInvoice_ecPay: {
          enabled: false,
          hasCustom: false,
          merchant: '',
          merchantId: '',
          hashKey: '',
          hashIv: ''
        },
        tw_payments_payuni: {
          enabled: false,
          paymentMethods: [],
          merchantId: '',
          merchantKey: '',
          merchantIV: '',
          allowRememberCard: true,
          threeDomainSecure: true
        },
        tw_eInvoice_systemlead: {
          companyId: '',
          enabled: false,
          hasCustom: false,
          remind: true,
          remindCount: 100,
          salt: ''
        },
        thirdParty: {
          shopDotCom: {
            enabled: false,
            advertiserId: '',
            offerId: '',
            commissionRatio: 0,
            antiFraudText: ''
          }
        },
        defaultWorkflowsItemsActivities: {},
        enableAuthorizationWorkflows: false,
        showFaqsOnFooter: false,
        showContactsStatementAging: true,
        enableShopPriceFilter: true,
        shopRecurringShippingFeeBehavior: this.get('constants.recurringShippingFeeBehavior.all'),
        shopRecurringPromoCodeBehavior: this.get('constants.recurringPromoCodeBehavior.last'),
        shopRecurringPaymentReminderTime: '',
        shopRecurringPaymentReminderPeriod: 'days',
        shopPriceFilters: [],
        variantSelectorStyle: this.get('constants.variantSelectorStyle.dropdown'),
        shippingRateMethod: 'highest',
        shippingRateMethodFreeShipping: 'includeFreeShipping',
        shopProductsSort: 'recommended',
        shopProductsImagesRatio: 'oneByOne',
        recurringAgreement: '',
        deliveryNotePrintFormat: 'default',
        showUnit: 'showUnitBelowItems',
        showProductCode: 'showCodeBelowItems',
        productsImagesSize: 'small',
        imagesHoverFilter: 100,
        imagesHoverTransform: 'bigger',
        productsNameFontSize: 'small',
        showOnSaleTag: true,
        onSaleTagDisplayPosition: 'left-top',
        onSaleTagDisplayStyle: 'default',
        productsNameLineCount: 2,
        showItemCode: false,
        contactTagsReportSummaryOption: 'uniqueTagsOnly',
        // need to know what should be the default
        defaultTransporters: {},
        isAllowToCrawl: true,
        cartBtnStyle: 'addToCartAndChooseOptions',
        showImagesOnDocuments: false,
        printImagesOnDocuments: false,
        dispatchedEmailAutoSend: false,
        dispatchedEmailHasEstimateTransporterArrivalDate: true,
        dispatchedEmailEstimateTransporterArrivalDaysFromDispatch: 1,
        dispatchedEmailTransporterArrivalDateSkipWeekends: false,
        hideTaxNumber: false,
        automationsShippingBehavior: 'shipWithCartItems',
        expectedDateMethod: 'expectedArrivalDate',
        salesInvoicesDatesColumns: ['dispatchDateZ', 'expectedPreparedDateZ', 'latestDispatchDateZ', 'expectedArrivalDateZ'],
        dispatchSummaryType: 'collapseUnderProductName',
        separateCustomizationByName: true,
        showCustomizationsPriceType: 'hideIfZeroPrice',
        showBackToShoppingButton: true,
        hasCustomLimitedQtyPerPersonText: false,
        customLimitedQtyPerPersonText: '',
        productsPerRow: 3,
        defaultInvoicesView: 'invoice',
        defaultTCatTransporterWaybillPrintType: 'twoPerPage',
        shopCheckoutNoteMaxCharacterLimit: 200,
        dispatchFiltersSortBy: 'dateZ',
        dispatchFiltersSortByDirection: 'DESC',
        groupDocumentsByTransporter: false,
        hctOptions: {},
        tw_shipping_payuni: {
          sevenEleven: {
            waybillsPerPageC2C: 4,
            waybillsPerPageB2C: 6
          }
        },
        autoIssueEInvoicesDaily: false,
        autoIssueAfterDays: 0,
        froalaImageAlignment: 'center'
      };
      return defaultData;
    },
    _transformOpenHours(data) {
      const defaults = this.defaults() || {};
      const defaultsOpenHours = defaults.openHours || {};
      const openHours = R.propOr(defaultsOpenHours, 'openHours')(data);
      return R.mapObjIndexed((value, key) => {
        if (R.type(value) === 'Boolean') {
          return value;
        }
        value.name = key;
        return _nventor.default.createModel(_model4.default, value);
      })(openHours);
    },
    _transformPaymentReminderEmails(emails = []) {
      return R.map(this.newBlankPaymentReminderEmail, emails);
    },
    _transformFaqs(data) {
      return R.pipe(R.propOr([], 'faqs'), R.reject(_nventor.default.isNilOrEmpty), R.map(faq => _nventor.default.createModel(_model3.default, faq)))(data);
    },
    _transformRates(shopPriceFilters) {
      if (!shopPriceFilters) {
        return [{
          _data: {
            min: 0,
            max: 99
          }
        }, {
          _data: {
            min: 100,
            max: 499
          }
        }, {
          _data: {
            min: 500,
            max: 999
          }
        }, {
          _data: {
            min: 1000
          }
        }];
      }
      return shopPriceFilters.map(range => {
        return this.dataManager.setPartialRecord({
          adapterName: 'channels/shop/campaigns/price-filter',
          data: range
        });
      });
    },
    newBlankPaymentReminderEmail(data = {}) {
      return _nventor.default.createModel(_model2.default, data);
    },
    _transformFacebookPages(data = []) {
      return R.map(this.newFacebookPage, data);
    },
    createFacebookPages(data = []) {
      const pages = this._transformFacebookPages(data);
      this.setData('facebookPages', pages);
    },
    newFacebookPage(data = {}) {
      return _nventor.default.createModel(_model2.default, data);
    },
    _transformBspPayments(data) {
      const tw_payments_bsp = R.pathOr({}, ['tw_payments_bsp'])(data);
      tw_payments_bsp.virtualAccounts = R.pipe(R.pathOr([], ['virtualAccounts']), R.map(virtualAccount => _nventor.default.createModel(_model2.default, virtualAccount)))(tw_payments_bsp);
      return tw_payments_bsp;
    },
    hasCashPaymentFee: Ember.computed('_data.shopPaymentMethods.[]', function () {
      const paymentMethods = this.getData('shopPaymentMethods');
      if (paymentMethods.indexOf('cash') > -1) {
        return true;
      }
      return false;
    }),
    hasInstallments: Ember.computed('_data.{creditCard_tapPay_installments.enabled,tw_payments_ecPay.enabledCreditCardInstallments}', function () {
      if (this?._data?.creditCard_tapPay_installments?.enabled) {
        return true;
      }
      if (this?._data?.tw_payments_ecPay?.enabledCreditCardInstallments) {
        return true;
      }
      return false;
    }),
    openDays: Ember.computed('_data.openHours.monday.{open,close}', '_data.openHours.tuesday.{open,close}', '_data.openHours.wednesday.{open,close}', '_data.openHours.thursday.{open,close}', '_data.openHours.friday.{open,close}', '_data.openHours.saturday.{open,close}', '_data.openHours.sunday.{open,close}', function () {
      const openHours = this._data.openHours;
      if (openHours) {
        return R.map(R.ifElse(R.propEq('open', 'none'), R.always({
          hasOpeningTime: true
        }), R.always({
          hasOpeningTime: false
        })))(openHours);
      }
    }),
    sortedOpenHours: Ember.computed('_data.openHours.monday.{open,close,hasOpeningHours}', '_data.openHours.tuesday.{open,close,hasOpeningHours}', '_data.openHours.wednesday.{open,close,hasOpeningHours}', '_data.openHours.thursday.{open,close,hasOpeningHours}', '_data.openHours.friday.{open,close,hasOpeningHours}', '_data.openHours.saturday.{open,close,hasOpeningHours}', '_data.openHours.sunday.{open,close,hasOpeningHours}', function () {
      const openHours = this._data.openHours;
      const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      const sortDays = R.pipe(R.toPairs, R.sortBy(R.pipe(R.head, R.indexOf(R.__, weekdays))), R.fromPairs, R.values, R.reject(day => R.type(day) === 'Boolean'));
      return sortDays(openHours);
    }),
    allowPrintEInvoices: Ember.computed('_data.{tw_eInvoices_allow_print,tw_eInvoice_systemlead.enabled}', function () {
      const twEInvoiceSystemleadEnabled = R.pathOr(false, ['_data', 'tw_eInvoice_systemlead', 'enabled'])(this);
      const twEInvoicesAllowPrint = R.pathOr(false, ['_data', 'features', 'extensions', 'tw_eInvoices_print_allow_print'])(this);
      return twEInvoiceSystemleadEnabled && twEInvoicesAllowPrint;
    }),
    setBspDefaultVirtualAccount(defaultVirtualAccountModel) {
      const bspVirtualAccounts = R.pathOr([{}], ['_data', 'tw_payments_bsp', 'virtualAccounts'])(this);
      R.forEach(bspVirtualAccount => {
        if (defaultVirtualAccountModel._data.merchantId === bspVirtualAccount._data.merchantId) {
          bspVirtualAccount.setData('enabled', true);
          bspVirtualAccount.setData('isDefault', true);
        } else {
          bspVirtualAccount.setData('enabled', false);
          bspVirtualAccount.setData('isDefault', false);
        }
      })(bspVirtualAccounts);
    },
    isShopDotComEnabled: Ember.computed('_data.thirdParty.shopDotCom.enabled', function () {
      return R.pathOr(false, ['_data', 'thirdParty', 'shopDotCom', 'enabled'])(this);
    }),
    limitAdjustType: Ember.computed('_data.adjustCampaignsDetailsLimitQtyBy', function () {
      let limitAdjustType = 'adjustTotalQty';
      const adjustCampaignsDetailsLimitQtyBy = this._data.adjustCampaignsDetailsLimitQtyBy;
      if (RA.isNotNilOrEmpty(adjustCampaignsDetailsLimitQtyBy)) {
        return adjustCampaignsDetailsLimitQtyBy;
      }
      return limitAdjustType;
    }),
    linePayEnabled: Ember.computed('_data.linePay_tapPay.enabled', '_data.tw_payments_payuni.enabled', function () {
      const isTapPayLinePayEnabled = R.pathEq(['_data', 'linePay_tapPay', 'enabled'], true)(this);
      if (isTapPayLinePayEnabled) {
        return true;
      }
      const isPayuniEnabled = R.pathEq(['_data', 'tw_payments_payuni', 'enabled'], true)(this);
      const isPayuniLinePayEnabled = R.pipe(R.pathOr([], ['_data', 'tw_payments_payuni', 'paymentMethods']), R.includes(this.constants.paymentMethods.linePay))(this);
      if (isPayuniEnabled && isPayuniLinePayEnabled) {
        return true;
      }
      return false;
    }),
    applePayEnabled: Ember.computed('_data.tw_payments_payuni.enabled', function () {
      const isPayuniEnabled = R.pathEq(['_data', 'tw_payments_payuni', 'enabled'], true)(this);
      const isPayuniApplePayEnabled = R.pipe(R.pathOr([], ['_data', 'tw_payments_payuni', 'paymentMethods']), R.includes(this.constants.paymentMethods.applePay))(this);
      if (isPayuniEnabled && isPayuniApplePayEnabled) {
        return true;
      }
      return false;
    }),
    googlePayEnabled: Ember.computed('_data.tw_payments_payuni.enabled', function () {
      const isPayuniEnabled = R.pathEq(['_data', 'tw_payments_payuni', 'enabled'], true)(this);
      const isPayuniGooglePayEnabled = R.pipe(R.pathOr([], ['_data', 'tw_payments_payuni', 'paymentMethods']), R.includes(this.constants.paymentMethods.googlePay))();
      if (isPayuniEnabled && isPayuniGooglePayEnabled) {
        return true;
      }
      return false;
    }),
    allowedPaymentMethods: Ember.computed('_data.tw_payments_payuni.enabled', '_data.linePay_tapPay.enabled', function () {
      const allowedPaymentMethods = new Set();

      // Always add cash to allowPaymentMethods
      allowedPaymentMethods.add(this.constants.paymentMethods.cash);
      allowedPaymentMethods.add(this.constants.paymentMethods.cashBeforeDelivery);

      /**
       * PayUni
       */
      const isPayuniEnabled = R.pathEq(['_data', 'tw_payments_payuni', 'enabled'], true)(this);
      if (isPayuniEnabled) {
        const payuniPaymentMethods = R.pathOr([], ['_data', 'tw_payments_payuni', 'paymentMethods'])(this);
        payuniPaymentMethods.forEach(paymentMethod => {
          if (paymentMethod === 'virtualAccount') {
            allowedPaymentMethods.add(this.constants.paymentMethods.cashBeforeDelivery);
            return;
          }
          allowedPaymentMethods.add(paymentMethod);
        });
      }

      /**
       * TapPay
       */
      const isTapPayLinePayEnabled = R.pathEq(['_data', 'linePay_tapPay', 'enabled'], true)(this);
      if (isTapPayLinePayEnabled) {
        allowedPaymentMethods.add(this.constants.paymentMethods.linePay);
      }
      const isTapPayCreditCardEnabled = R.pathEq(['_data', 'creditCard_tapPay', 'enabled'], true)(this);
      const isTapPayCreditCardInstallmentsEnabled = R.pathEq(['_data', 'creditCard_tapPay_installments', 'enabled'], true)(this);
      const isTapPayCreditCardScheduledPaymentEnabled = R.pathEq(['_data', 'creditCard_tapPay_scheduledPayment', 'enabled'], true)(this);
      if (isTapPayCreditCardEnabled || isTapPayCreditCardInstallmentsEnabled || isTapPayCreditCardScheduledPaymentEnabled) {
        allowedPaymentMethods.add(this.constants.paymentMethods.creditCard);
      }

      /**
       * EcPay
       */
      const isEcPayEnabled = R.pathEq(['_data', 'tw_payments_ecPay', 'enabled'], true)(this);
      if (isEcPayEnabled) {
        const isEcPayCreditCardEnabled = R.pathEq(['_data', 'tw_payments_ecPay', 'enabledCreditCard'], true)(this);
        if (isEcPayCreditCardEnabled) {
          allowedPaymentMethods.add(this.constants.paymentMethods.creditCard);
        }
        const isEcPayVirtualAccountEnabled = R.pathEq(['_data', 'tw_payments_ecPay', 'enabledVirtualAccount'], true)(this);
        if (isEcPayVirtualAccountEnabled) {
          allowedPaymentMethods.add(this.constants.paymentMethods.cashBeforeDelivery);
        }
      }

      /**
       * BSP
       */
      const isBspEnabled = R.pathEq(['_data', 'tw_payments_bsp', 'enabled'], true)(this);
      const isBspAnyVirtualAccountEnabled = R.pipe(R.pathOr([], ['_data', 'tw_payments_bsp', 'virtualAccounts']), R.find(R.pathEq(['enabled'], true)), RA.isNotNilOrEmpty)(this);
      if (isBspEnabled && isBspAnyVirtualAccountEnabled) {
        allowedPaymentMethods.add(this.constants.paymentMethods.cashBeforeDelivery);
      }

      /**
       * UBOT
       */
      const isUbotAnyVirtualAccountEnabled = R.pipe(R.pathOr([], ['_data', 'tw_payments_ubot', 'virtualAccounts']), R.find(R.pathEq(['enabled'], true)), RA.isNotNilOrEmpty)(this);
      if (isUbotAnyVirtualAccountEnabled) {
        allowedPaymentMethods.add(this.constants.paymentMethods.cashBeforeDelivery);
      }

      /**
       * ESUN
       */
      const isESunAnyVirtualAccountEnabled = R.pipe(R.pathOr([], ['_data', 'tw_payments_eSun', 'virtualAccounts']), R.find(R.pathEq(['enabled'], true)), RA.isNotNilOrEmpty)(this);
      if (isESunAnyVirtualAccountEnabled) {
        allowedPaymentMethods.add(this.constants.paymentMethods.cashBeforeDelivery);
      }
      return Array.from(allowedPaymentMethods);
    })
  });
});