define("client/pods/components/products/products-shop-wizard-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "T6DSi9fu",
    "block": "{\"symbols\":[\"subTab\",\"@tab\",\"@isFromShop\",\"@name\",\"@onCancel\",\"@onDone\",\"@isAutomations\",\"@automationModel\",\"@campaign\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"panel-grid__header is-sticky-row-auto\"],[3,\"did-insert\",[[23,0,[\"applicationService\",\"setStickyTop\"]]]],[8],[0,\"\\n    \"],[5,\"menus/tab-menu-new\",[],[[\"@autoSelectFirstTab\",\"@tab\",\"@tabs\",\"@onSelect\",\"@menu\",\"@isWizard\"],[[23,0,[\"autoSelectFirstTab\"]],[23,2,[]],[23,2,[\"subTabs\"]],[28,\"perform\",[[23,0,[\"selectStepTask\"]]],null],[23,0,[\"subPanels\"]],true]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n    \"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[23,2,[\"subTabs\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tab\",\"campaign\",\"campaignDetail\",\"automationModel\",\"isAutomations\",\"isDisplay\",\"isEditing\",\"isNew\",\"allowUploads\",\"item\",\"editingDefaults\",\"campaignDetailAdapterName\",\"onDone\",\"onCancel\",\"name\",\"isFromShop\",\"onDisplay\",\"onDash\",\"onAfterCreateRecordTask\"],[[23,1,[]],[23,9,[]],[23,0,[\"campaignDetail\"]],[23,8,[]],[23,7,[]],false,true,false,false,[23,0,[\"campaignDetail\"]],[28,\"hash\",null,[[\"status\"],[\"active\"]]],[23,0,[\"campaignDetailAdapterName\"]],[23,6,[]],[23,5,[]],[23,4,[]],[23,3,[]],[28,\"perform\",[[23,0,[\"convertToCampaignDetailEditModeTask\"]]],null],[28,\"perform\",[[23,0,[\"convertToCampaignDetailEditModeTask\"]]],null],[28,\"perform\",[[23,0,[\"addProductToShopAndEnterEditCampaignDetail\"]]],null]]]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/products-shop-wizard-editor/template.hbs"
    }
  });
});