define("client/pods/guests/shop/campaigns/adapter", ["exports", "ramda", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/channels/shop/campaigns/model"], function (_exports, R, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    emails: Ember.inject.service(),
    // resourcePath: 'api/protected/channels/shop/campaigns',
    resourcePath: 'api/guests/channels/shop/campaigns',
    idParam: false,
    modelObj: _model.default,
    serialize(model) {
      const data = this._super(model);
      data.orderedEmailCopyTo = this.emails.splitToArray(data.orderedEmailCopyTo);
      delete data.details;
      return data;
    }
  });
});